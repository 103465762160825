import {
  HttpErrorResponse,
  HttpHandlerFn,
  HttpInterceptorFn,
  HttpRequest,
} from '@angular/common/http';
import { inject } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { TokenResponse } from '../models/token.model';
import { catchError, from, switchMap, throwError } from 'rxjs';
import { Router } from '@angular/router';

export const refreshTokenInterceptor: HttpInterceptorFn = (req, next) => {
  const authService = inject(AuthService);
  const router = inject(Router);

  const jwtToken = authService.getToken();

  if (jwtToken) {
    const token: TokenResponse = JSON.parse(jwtToken);

    return next(req).pipe(
      catchError((error: HttpErrorResponse) => {
        if ([401, 403].includes(error.status)) {
          return handleRefresh(
            req,
            next,
            token.refresh_token,
            authService,
            router
          );
        } else {
          return throwError(() => error);
        }
      })
    );
  }

  return next(req);
};

const handleRefresh = (
  request: HttpRequest<unknown>,
  next: HttpHandlerFn,
  refreshToken: string,
  authService: AuthService,
  router: Router
) => {
  return from(authService.refreshToken(refreshToken)).pipe(
    switchMap((res: TokenResponse) => {
      request = request.clone({
        setHeaders: {
          Authorization: 'Bearer ' + res.access_token,
        },
      });
      return next(request);
    }),
    catchError((error) => {
      if (error.status == 403 || error.status == 500) {
        router.navigate(['/login']);
      }
      return throwError(() => error);
    })
  );
};
