<div>
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="card">
      <h1 class="font-medium text-2xl">
        Áreas do Empreendimento<font color="red">*</font>
      </h1>
      <h3 class="mt-2 mb-4 font-medium text-xl">Área do terreno</h3>
      <div
        formGroupName="landArea"
        class="flex flex-col md:flex-row gap-4 mb-4"
      >
        <div class="flex flex-col w-1/4">
          <label for="landAreaTotalArea">Área do Terreno Total (m²)</label>
          <input
            class="input"
            mask="separator.0"
            thousandSeparator="."
            formControlName="totalArea"
            id="landAreaTotalArea"
            type="text"
            (input)="calculateRemainingArea('landArea')"
          />
          <div
            *ngIf="
              formControl('landArea.totalArea')?.invalid &&
              formControl('landArea.totalArea')?.touched
            "
          >
            <span
              class="text-sm text-red-600"
              *ngIf="formControl('landArea.totalArea')?.hasError('required')"
              >Campo obrigatório.</span
            >
            <span
              class="text-sm text-red-600"
              *ngIf="formControl('landArea.totalArea')?.hasError('min')"
              >Esse valor não é permitido.</span
            >
          </div>
        </div>
        <div class="flex flex-col w-1/4">
          <label for="landAreaUsedArea">Área do Terreno Utilizada (m²)</label>
          <input
            class="input"
            mask="separator.0"
            thousandSeparator="."
            id="landAreaUsedArea"
            formControlName="usedArea"
            type="text"
            (input)="calculateRemainingArea('landArea')"
          />
          <div
            *ngIf="
              formControl('landArea.usedArea')?.invalid &&
              formControl('landArea.usedArea')?.touched
            "
          >
            <span
              class="text-sm text-red-600"
              *ngIf="formControl('landArea.usedArea')?.hasError('required')"
              >Campo obrigatório.</span
            >
            <span
              class="text-sm text-red-600"
              *ngIf="formControl('landArea.usedArea')?.hasError('min')"
              >Esse valor não é permitido.</span
            >
          </div>
          <div
            class="text-sm text-red-600"
            *ngIf="form.get('landArea')?.hasError('compare')"
          >
            A Área do Terreno Total deve ser maior ou igual que a Área do
            Terreno Utilizada.
          </div>
        </div>
        <div class="flex flex-col w-1/4">
          <label for="remainingArea">Área do Terreno Remanescente (m²)</label>
          <input
            class="input"
            mask="separator.0"
            id="remainingArea"
            thousandSeparator="."
            type="number"
            formControlName="remainingArea"
            (input)="calculateBuildingArea('landArea')"
          />
        </div>
      </div>

      <mat-divider class="my-4"></mat-divider>
      <h3 class="my-4 font-medium text-xl">Área construída</h3>
      <div formGroupName="buildingArea" class="flex flex-col md:flex-row gap-4">
        <div class="flex flex-col w-1/4">
          <label for="buildingAreaTotalArea">Área Construída Total (m²)</label>
          <input
            class="input"
            mask="separator.0"
            thousandSeparator="."
            formControlName="totalArea"
            id="buildingAreaTotalArea"
            type="text"
            (input)="calculateRemainingArea('buildingArea')"
          />
          <div
            *ngIf="
              formControl('buildingArea.totalArea')?.invalid &&
              formControl('buildingArea.totalArea')?.touched
            "
          >
            <span
              class="text-sm text-red-600"
              *ngIf="
                formControl('buildingArea.totalArea')?.hasError('required')
              "
              >Campo obrigatório.</span
            >
            <span
              class="text-sm text-red-600"
              *ngIf="formControl('buildingArea.totalArea')?.hasError('min')"
              >Esse valor não é permitido.</span
            >
          </div>
        </div>
        <div class="flex flex-col w-1/4">
          <label for="buildingAreaUsedArea">Área do Estabelecimento (m²)</label>
          <input
            class="input"
            mask="separator.0"
            thousandSeparator="."
            id="buildingAreaUsedArea"
            formControlName="usedArea"
            type="text"
            (input)="calculateRemainingArea('buildingArea')"
          />
          <div
            *ngIf="
              formControl('buildingArea.usedArea')?.invalid &&
              formControl('buildingArea.usedArea')?.touched
            "
          >
            <span
              class="text-sm text-red-600"
              *ngIf="formControl('buildingArea.usedArea')?.hasError('required')"
              >Campo obrigatório.</span
            >
            <span
              class="text-sm text-red-600"
              *ngIf="formControl('buildingArea.usedArea')?.hasError('min')"
              >Esse valor não é permitido.</span
            >
          </div>
          <div
            class="text-sm text-red-600"
            *ngIf="
              form.get('buildingArea')?.hasError('compare') &&
              form.get('buildingArea')?.touched
            "
          >
            {{
              typeOfEnterprise === 1
                ? "A Área Construída Total deve ser maior que a Área do\
          Estabelecimento."
                : "A Área Construída Total deve ser maior ou igual que a Área do Estabelecimento."
            }}
          </div>
        </div>
        <div class="flex flex-col w-1/4">
          <label for="remainingArea">Área não utilizada (m²)</label>
          <input
            class="input"
            mask="separator.0"
            id="remainingArea"
            thousandSeparator="."
            type="number"
            formControlName="remainingArea"
            (input)="calculateBuildingArea('buildingArea')"
          />
        </div>
      </div>
    </div>
    <div class="w-full flex flex-row justify-end items-center mt-6">
      <button
        type="button"
        class="btn btn-secondary text-sm"
        (click)="this.setStep.emit(3)"
      >
        Voltar
      </button>
      <button
        class="btn btn-primary text-sm ml-2 disabled:bg-opacity-65"
        [disabled]="form.invalid"
        type="submit"
      >
        Próximo
      </button>
    </div>
  </form>
</div>
