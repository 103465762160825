/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @angular-eslint/use-lifecycle-interface */
/* eslint-disable no-useless-escape */
import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  inject,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { Subject, debounceTime, filter, takeUntil } from 'rxjs';
import { FeasibilityService } from '../../../../services/feasibility.service';
import { ModalService } from '../../../../services/modal.service';

@Component({
  selector: 'app-feasibility-responsible',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatDividerModule,
    MatIconModule,
    NgxMaskDirective,
    NgxMaskPipe,
  ],
  templateUrl: './responsible.component.html',
  styleUrl: './responsible.component.scss',
  providers: [provideNgxMask()],
})
export class ResponsibleFeasibilityComponent implements OnInit {
  @Input() feasibilityForm!: FormGroup;
  @Output() setStep = new EventEmitter<number>();
  form!: FormGroup;
  @Output() emitForm = new EventEmitter<FormGroup>();

  private documentChange$ = new Subject<string>();
  private destroy$ = new Subject<void>();

  private feasibilityService = inject(FeasibilityService);

  responsible: boolean | null = null;
  isResponsibleLegal: boolean = false;
  alphabet: string[] = [];
  initials: string = '';

  isLoadingInitials: boolean = false;
  isLoadingNext: boolean = false;

  isValidForm: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private modalService: ModalService
  ) {}

  ngOnInit(): void {
    this.createForm();
    this.getForm();

    this.documentChange$
      .pipe(
        debounceTime(1000),
        filter((value) => value.length === 14),
        takeUntil(this.destroy$)
      )
      .subscribe((value) => {
        this.isLoadingInitials = true;

        this.feasibilityService
          .findResponsible(value.replace(/[^0-9]/g, ''))
          .subscribe({
            next: (response) => {
              this.alphabet = response.initials;
              this.isLoadingInitials = false;
              this.isValidForm = this.form.valid;
            },
            error: (error) => {
              this.alphabet = [];
              this.modalService.add({
                icon: 'warning',
                id: 'warning-feasibility-responsible-modal',
                title: 'Este CPF não está cadastrado!',
                description:
                  'A sua consulta de viabilidade tem o objetivo de emitir alguma licença?',
                cancelLabel: 'Não',
                actionCancel: () => {
                  this.modalService.remove(
                    'warning-feasibility-responsible-modal'
                  );
                  this.isValidForm = true;
                },
                actionLabel: 'Sim',
                action: () => {
                  this.modalService.remove(
                    'warning-feasibility-responsible-modal'
                  );
                  this.modalService.add({
                    icon: 'warning',
                    id: 'warning-feasibility-responsible-yes-modal',
                    title: 'Atenção!',
                    description:
                      'Solicite ao Responsável Legal que realize cadastro na plataforma.',
                    actionLabel: 'Continuar',
                  });
                },
              });
              console.error(error);
              this.isLoadingInitials = false;
            },
          });
      });
  }

  onChangeDocument(event: any): void {
    this.documentChange$.next(event.target.value);
    this.isValidForm = this.form.valid;
  }

  getForm(): void {
    const responsible = this.feasibilityForm.value['responsible']?.responsible;

    if (responsible === 'no') {
      this.form.get('document')?.setValidators([Validators.required]);
      this.form.get('document')?.updateValueAndValidity();
      this.form.get('initials')?.setValidators([Validators.required]);
      this.form.get('initials')?.updateValueAndValidity();

      this.form.patchValue({
        responsible: responsible,
        document: this.feasibilityForm.value['responsible'].document,
        initials: this.feasibilityForm.value['responsible'].initials,
      });
    } else {
      this.form.get('document')?.clearValidators();
      this.form.get('document')?.updateValueAndValidity();
      this.form.get('initials')?.clearValidators();
      this.form.get('initials')?.updateValueAndValidity();

      this.form
        .get('document')
        ?.setValidators([
          Validators.minLength(11),
          Validators.pattern(/^\d{3}\.\d{3}\.\d{3}\-\d{2}$/),
        ]);
      this.form.get('document')?.updateValueAndValidity();

      this.form.patchValue({
        responsible: responsible,
      });
    }

    this.isValidForm = this.form.valid;
  }

  createForm(): void {
    this.form = this.formBuilder.group({
      responsible: this.formBuilder.control(null, [Validators.required]),
      document: this.formBuilder.control(null, [
        Validators.minLength(11),
        Validators.pattern(/^\d{3}\.\d{3}\.\d{3}\-\d{2}$/),
      ]),
      initials: this.formBuilder.control(null),
    });

    this.isValidForm = this.form.valid;
  }

  changeResponsible(key: string): void {
    if (key === 'no') {
      this.form.get('document')?.setValidators([Validators.required]);
      this.form.get('document')?.updateValueAndValidity();
      this.form.get('initials')?.setValidators([Validators.required]);
      this.form.get('initials')?.updateValueAndValidity();
    } else {
      this.form.get('document')?.clearValidators();
      this.form.get('document')?.updateValueAndValidity();
      this.form.get('initials')?.clearValidators();
      this.form.get('initials')?.updateValueAndValidity();

      this.form
        .get('document')
        ?.setValidators([
          Validators.minLength(11),
          Validators.pattern(/^\d{3}\.\d{3}\.\d{3}\-\d{2}$/),
        ]);
      this.form.get('document')?.updateValueAndValidity();
    }

    this.form.patchValue({
      responsible: key,
    });

    this.isValidForm = this.form.valid;
  }

  onSubmit() {
    if (this.form.valid) {
      this.isLoadingNext = true;

      const responsible = this.form.get('responsible')?.value;

      if (responsible === 'no') {
        const document = this.form.get('document')?.value;
        const initials = this.form.get('initials')?.value;

        this.feasibilityService
          .ValidateResponsible({
            cpf: document.replace(/[^0-9]/g, ''),
            initial: initials,
          })
          .subscribe({
            next: () => {
              this.setStep.emit(2);
              this.emitForm.emit(this.form);
              this.isLoadingNext = true;
            },
            error: (error) => {
              this.modalService.add({
                icon: 'warning',
                id: 'warning-feasibility-responsible-modal',
                title: 'Houve um problema!',
                description:
                  'Informações conflitantes na base de dados, tente novamente.',
                actionLabel: 'Continuar',
              });
              console.error(error);
              this.isLoadingNext = false;
            },
          });
      } else {
        this.setStep.emit(2);
        this.emitForm.emit(this.form);
        this.isLoadingNext = false;
      }
    } else {
      const responsible = this.form.get('responsible')?.value;

      if (responsible === 'no' && this.isValidForm) {
        this.setStep.emit(2);
        this.emitForm.emit(this.form);
      } else this.form.markAllAsTouched();
    }
  }

  onChangeInitials(ev: any): void {
    this.isValidForm = true;
  }

  formControl(control: string) {
    return this.form.get(control);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
