/* eslint-disable @typescript-eslint/no-explicit-any */
import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';

@Component({
  selector: 'app-feasibility-type',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatCardModule,
    MatIconModule,
    MatDividerModule,
    MatButtonModule,
    MatCheckboxModule,
  ],
  templateUrl: './type.component.html',
  styleUrl: './type.component.scss',
})
export class TypeFeasibilityComponent implements OnInit {
  @Input() feasibilityForm!: FormGroup;
  @Input() step!: number;
  @Output() setStep = new EventEmitter<number>();
  form!: FormGroup;
  @Output() emitForm = new EventEmitter<FormGroup>();

  typeOfEnterpriseOptions = [
    {
      value: 0,
      label: 'Estabelecimento Físico',
    },
    {
      value: 1,
      label: 'Domicílio Fiscal',
    },
  ];

  typeOfTaxDomicileOptions = [
    {
      value: 0,
      label: 'Residencial',
    },
    {
      value: 1,
      label: 'Espaço de Trabalho Coletivo',
    },
    {
      value: 2,
      label: 'Outros',
    },
  ];
  constructor(private formBuilder: FormBuilder) {}

  ngOnInit(): void {
    this.createForm();
    this.getForm();
  }

  getFormValue(control: string): string | number {
    if (control === 'typeOfEnterprise') {
      return Number(this.formControl(control)?.value) || 0;
    }

    return this.formControl(control)?.value || '';
  }

  getForm(): void {
    const form = this.feasibilityForm.get('type');

    if (form) {
      this.form = form as FormGroup;
    }
  }

  createForm(): void {
    this.form = this.formBuilder.group({
      typeOfViability: new FormControl(null, Validators.required),
      isToOpenCNPJ: new FormControl(true, Validators.required),
      typeOfEnterprise: new FormControl(null, Validators.required),
      typeOfTaxDomicile: new FormControl(null),
    });
  }

  changeTypeOfEnterprise(ev: any): void {
    if (ev.target.value === '1') {
      this.form.get('typeOfTaxDomicile')?.setValidators([Validators.required]);
      this.form.get('typeOfTaxDomicile')?.updateValueAndValidity();
    } else {
      this.form.get('typeOfTaxDomicile')?.clearValidators();
      this.form.get('typeOfTaxDomicile')?.updateValueAndValidity();
    }
  }

  formControl(control: string): AbstractControl | null {
    return this.form.get(control);
  }

  setTypeOfViability(type: number): void {
    this.formControl('typeOfViability')?.setValue(type);
  }

  onSubmit() {
    if (this.form.valid) {
      this.setStep.emit(3);
      this.emitForm.emit(this.form);
    } else {
      this.form.markAllAsTouched();
    }
  }
}
