import { Router } from '@angular/router';
import { Component, inject } from '@angular/core';
import {
  FormGroup,
  FormControl,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import { CommonModule } from '@angular/common';
import { AlertService } from '../../services/alert.service';
import { AlertComponent } from '../../components/alert/alert.component';

@Component({
  selector: 'app-forget-password',
  standalone: true,
  templateUrl: './forget-password.component.html',
  styleUrl: './forget-password.component.scss',
  imports: [ReactiveFormsModule, CommonModule, AlertComponent],
})
export class ForgetPasswordComponent {
  authService = inject(AuthService);

  isLoading = false;

  constructor(
    private router: Router,
    private alertService: AlertService,
  ) {}

  forgetPasswordForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
  });

  push(route: string): void {
    this.router.navigate([route]);
  }

  onSubmit = () => {
    if (this.forgetPasswordForm.invalid)
      return this.alertService.add({
        id: 'forgot-password-field-warning',
        type: 'warning',
        message: 'Preencha os campos!',
      });

    const dto = {
      email: this.forgetPasswordForm.controls.email.value as string,
    };

    this.isLoading = true;

    this.authService.forgetPassword(dto).subscribe({
      next: () => {
        this.router.navigate(['/login']);
        this.alertService.add({
          id: 'forgot-password-field-success',
          type: 'success',
          message:
            'Foi enviado um link para o seu E-mail, siga os passos para recuperar sua conta!',
          autoClose: true,
        });
        this.isLoading = false;
      },
      error: () => {
        this.isLoading = false;
      },
    });
  };
}
