import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import {
  CreateEmployeeInterface,
  EmployeeInterface,
  UpdateEmployeesInterfaceInput,
  UpdateStatusEmployeesInputInterface,
} from '../models/employee.model';
import { Paginate } from '../models/paginate.model';

@Injectable({
  providedIn: 'root',
})
export class EmployeeService {
  private httpService = inject(HttpClient);
  private url = `${environment.urlApi}/employee`;

  constructor() {}

  create(dto: CreateEmployeeInterface): Observable<unknown> {
    return this.httpService.post(`${this.url}`, dto);
  }

  findAll(
    page: number,
    limit: number,
    search?: string,
    orderBy?: string,
    orderField?: string,
  ): Observable<Paginate<EmployeeInterface>> {
    return this.httpService.get<Paginate<EmployeeInterface>>(`${this.url}`, {
      params: {
        page: page.toString(),
        limit: limit.toString(),
        search: search || '',
        orderBy: orderBy || '',
        orderField: orderField || '',
      },
    });
  }

  updateStatusEmployee(
    dto: UpdateStatusEmployeesInputInterface,
  ): Observable<unknown> {
    return this.httpService.put(`${this.url}/status`, dto);
  }

  updateEmployee(dto: UpdateEmployeesInterfaceInput): Observable<unknown> {
    return this.httpService.put(`${this.url}/update`, dto);
  }

  getById(id: string): Observable<EmployeeInterface> {
    return this.httpService.get<EmployeeInterface>(`${this.url}/${id}`);
  }

  findUsersByFilteredValue(dto: {
    searchText: string;
  }): Observable<EmployeeInterface[]> {
    return this.httpService.post<EmployeeInterface[]>(
      `${this.url}/filter`,
      dto,
    );
  }
}
