import { CommonModule } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  Output,
  ViewChild,
} from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';

@Component({
  selector: 'app-notification',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatDividerModule,
    MatIconModule,
    NgxMaskDirective,
    NgxMaskPipe,
  ],
  templateUrl: './notification.component.html',
  styleUrl: './notification.component.scss',
  providers: [provideNgxMask()],
})
export class NotificationComponent {
  @ViewChild('upload_notification')
  upload_notification!: ElementRef<HTMLInputElement>;
  file!: File;

  @Output() fileEmmit: EventEmitter<File> = new EventEmitter<File>();
  @Output() deadline: EventEmitter<number> = new EventEmitter<number>();

  constructor() {}

  handleUpload(): void {
    this.upload_notification.nativeElement.click();
  }

  handleDeadlineChange(ev: Event): void {
    const target = ev.target as HTMLInputElement;
    this.deadline.emit(parseInt(target.value));
  }

  onFileSelected(): void {
    const files = this.upload_notification.nativeElement.files;
    if (files && files.length > 0) {
      this.file = files[0];
      this.fileEmmit.emit(this.file);
    }
  }

  getFileSize(size: number): string {
    if (size < 1024) return `${size} B`;
    else if (size < 1024 * 1024) return `${(size / 1024).toFixed(2)} KB`;
    else return `${(size / 1024 / 1024).toFixed(2)} MB`;
  }
}
