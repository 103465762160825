import { HttpInterceptorFn } from '@angular/common/http';
import { AuthService } from '../services/auth.service';
import { TokenResponse } from '../models/token.model';

export const authInterceptor: HttpInterceptorFn = (req, next) => {
  const authService = new AuthService();
  const jwtToken = authService.getToken();

  if (jwtToken) {
    const token: TokenResponse = JSON.parse(jwtToken);

    req = req.clone({
      setHeaders: {
        Authorization: `Bearer ${token.access_token}`,
      },
    });
  }

  return next(req);
};
