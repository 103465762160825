import { Component, OnDestroy, OnInit } from '@angular/core';
import { AlertComponent } from '../../components/alert/alert.component';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService } from '../../services/alert.service';
import { Subscription } from 'rxjs';
import { UpdatePasswordInterface } from '../../models/user.model';
import { HttpErrorResponse } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-update-password',
  standalone: true,
  imports: [
    AlertComponent,
    ReactiveFormsModule,
    CommonModule,
    ReactiveFormsModule,
  ],
  templateUrl: './update-password.component.html',
  styleUrls: ['./update-password.component.scss'],
})
export class UpdatePasswordComponent implements OnInit, OnDestroy {
  private params: Subscription = Subscription.EMPTY;
  updatePasswordForm!: FormGroup;
  key: string = '';

  constructor(
    private router: Router,
    private activedRoute: ActivatedRoute,
    private alertService: AlertService,
    private authService: AuthService,
    private formBuilder: FormBuilder,
  ) {}

  push(route: string): void {
    this.router.navigate([route]);
  }

  return() {
    this.router.navigate(['/login']);
  }

  mismatch() {
    const password = this.updatePasswordForm.controls['password'].value;
    const confirmPassword =
      this.updatePasswordForm.controls['confirm_password'].value;

    if (password !== confirmPassword) {
      this.updatePasswordForm.controls['confirm_password'].setErrors({
        not_match: true,
      });
    } else {
      this.updatePasswordForm.controls['confirm_password'].setErrors(null);
    }
  }

  ngOnInit(): void {
    this.params = this.activedRoute.queryParams.subscribe((params) => {
      this.key = params['key'];
    });

    if (this.key)
      this.updatePasswordForm = this.formBuilder.group({
        password: ['', [Validators.required, Validators.pattern(/^.{8,}$/)]],
        confirm_password: ['', [Validators.required]],
      });
  }

  onSubmit() {
    this.mismatch();

    if (this.updatePasswordForm.valid) {
      const dto: UpdatePasswordInterface = {
        token: this.key,
        newPassword: this.updatePasswordForm.controls['password'].value,
      };

      this.authService.updatePassword(dto).subscribe({
        next: () => {
          this.push('/login');
          this.alertService.add({
            id: 'update-password-field-success',
            type: 'success',
            message:
              'Seus dados foram atualizados com sucesso, acesse a plataforma!',
            autoClose: true,
          });
        },
        error: (e: HttpErrorResponse) => {
          console.error(e);
        },
      });
    } else this.updatePasswordForm.markAllAsTouched();
  }

  ngOnDestroy(): void {
    this.params.unsubscribe();
  }
}
