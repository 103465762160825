<app-alert></app-alert>
<section class="h-full bg-blue-950">
  <div
    class="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0"
  >
    <a
      href="#"
      class="flex items-center mb-6 text-2xl font-semibold text-white"
    >
      <div class="rounded-full mr-5 bg-white">
        <img class="w-16 h-16" src="assets/images/HUBlic.png" alt="logo" />
      </div>
      Hublic
    </a>
    <div
      class="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700"
    >
      <div class="w-full flex flex-col justify-start items-start p-6">
        <h1
          class="text-md font-bold leading-tight tracking-tight text-gray-900 dark:text-white"
        >
          Preencha os campos abaixo para atualizar sua senha
        </h1>
        <form
          class="w-full mt-4"
          [formGroup]="updatePasswordForm"
          (ngSubmit)="onSubmit()"
        >
          <div class="grid grid-rows-2 gap-2.5 mb-6">
            <div>
              <label
                for="password"
                class="text-sm font-medium text-gray-900 dark:text-white"
                >Senha</label
              >
              <input
                formControlName="password"
                type="password"
                id="password"
                class="input-text"
                placeholder="***************"
                required=""
              />
              <div
                class="text-xs font-medium text-red-500 mt-1"
                *ngIf="
                  (updatePasswordForm.controls['password'].touched ||
                    updatePasswordForm.controls['password'].dirty) &&
                  updatePasswordForm.controls['password'].errors &&
                  updatePasswordForm.controls['password'].errors['required']
                "
              >
                Senha é obrigatória.
              </div>
              <div
                class="text-xs font-medium text-red-500 mt-1"
                *ngIf="
                  (updatePasswordForm.controls['password'].touched ||
                    updatePasswordForm.controls['password'].dirty) &&
                  updatePasswordForm.controls['password'].errors &&
                  updatePasswordForm.controls['password'].errors['pattern']
                "
              >
                Necessário ter pelo menos 8 caracteres.
              </div>
            </div>
            <div>
              <label
                for="confirm_password"
                class="text-sm font-medium text-gray-900 dark:text-white"
                >Confirmar senha</label
              >
              <input
                formControlName="confirm_password"
                type="password"
                id="confirm-password"
                class="input-text"
                placeholder="***************"
                required=""
              />
              <div
                class="text-xs font-medium text-red-500 mt-1"
                *ngIf="
                  (updatePasswordForm.controls['confirm_password'].touched ||
                    updatePasswordForm.controls['confirm_password'].dirty) &&
                  updatePasswordForm.controls['confirm_password'].errors &&
                  updatePasswordForm.controls['confirm_password'].errors['required']
                "
              >
                Confirmar senha é obrigatória.
              </div>
              <div
                class="text-xs font-medium text-red-500 mt-1"
                *ngIf="
                  (updatePasswordForm.controls['confirm_password'].touched ||
                    updatePasswordForm.controls['confirm_password'].dirty) &&
                  updatePasswordForm.controls['confirm_password'].errors &&
                  updatePasswordForm.controls['confirm_password'].errors['not_match']
                "
              >
                As senhas devem ser iguais.
              </div>
            </div>
          </div>
          <div
            class="w-full flex flex-col md:flex-row justify-end items-center"
          >
            <button type="button" class="btn-secondary" (click)="push('/')">
              VOLTAR
            </button>
            <button type="submit" class="btn">ATUALIZAR</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</section>
