<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<div class="m-3 p-4">
  <h1 class="font-bold text-3xl">Consulta de Viabilidade</h1>
  <app-stepper
    [step]="currentStep"
    [steps]="steps"
    (stepChange)="onChangeStep($event)"
  ></app-stepper>

  <!-- Responsible -->
  <app-feasibility-responsible
    *ngIf="currentStep === 1"
    [feasibilityForm]="feasibilityForm"
    (emitForm)="onEmitForm($event, 'responsible')"
    (setStep)="onChangeStep($event)"
  ></app-feasibility-responsible>

  <!-- Type -->
  <app-feasibility-type
    *ngIf="currentStep === 2"
    [feasibilityForm]="feasibilityForm"
    (emitForm)="onEmitForm($event, 'type')"
    (setStep)="onChangeStep($event)"
  ></app-feasibility-type>

  <!-- Address -->
  <app-feasibility-address
    *ngIf="currentStep === 3"
    [feasibilityForm]="feasibilityForm"
    (emitForm)="onEmitForm($event, 'address')"
    (setStep)="onChangeStep($event)"
  ></app-feasibility-address>

  <!-- Enterprise -->
  <app-feasibility-enterprise
    *ngIf="currentStep === 4"
    [feasibilityForm]="feasibilityForm"
    (emitForm)="onEmitForm($event, 'enterprise')"
    (setStep)="onChangeStep($event)"
  ></app-feasibility-enterprise>

  <!-- Activiy -->
  <app-feasibility-activity
    *ngIf="currentStep === 5"
    [feasibilityForm]="feasibilityForm"
    (emitForm)="onEmitForm($event, 'activity')"
    (emitFeasibilityId)="onEmitFeasibilityId($event)"
    (emitFeasibilityResponse)="onEmitFeasibilityResponse($event)"
    (setStep)="onChangeStep($event)"
    (resultEvent)="onEmitResult($event)"
  ></app-feasibility-activity>

  <!-- Result -->
  <app-feasibility-result
    *ngIf="currentStep === 6"
    [feasibilityForm]="feasibilityForm"
    [feasibilityId]="feasibilityId"
    [feasibilityResponse]="feasibilityResponse"
    [result]="result"
  ></app-feasibility-result>
</div>
