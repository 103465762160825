<div class="m-3">
  <div class="card">
    <h1 class="text-3xl font-bold mb-3">Novo CNAE</h1>
    <div class="flex flex-col">
      <div class="flex flex-row">
        <div class="flex flex-col w-1/2 mb-2">
          <p class="label">Código</p>
          <input type="text" class="input" placeholder="---" />
        </div>
        <div class="flex flex-col w-1/2 ml-2">
          <p class="label">Nome</p>
          <input type="text" class="input" />
        </div>
      </div>
      <div class="flex flex-row">
        <div class="flex flex-col w-1/2 mb-2">
          <p class="label">Risco Ambiental</p>
          <select [(ngModel)]="risk" class="input">
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="Pergunta">Depende de Pergunta</option>
          </select>
        </div>
        <div class="flex flex-col w-1/2 ml-2 mb-2">
          <p class="label">Risco Sanitário</p>
          <select class="input">
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="Pergunta">Depende de Pergunta</option>
          </select>
        </div>
        <div class="flex flex-col w-1/2 ml-2 mb-2">
          <p class="label">Risco de Bombeiros</p>
          <select class="input">
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="Pergunta">Depende de Pergunta</option>
          </select>
        </div>
      </div>
      <div class="flex flex-row mt-3">
        <div class="flex flex-col w-1/2 mb-2">
          <p class="label">É de uso especial?</p>
          <div class="mt-3 flex flex-row">
            <div class="pr-2 flex">
              <p class="label mr-3">Sim</p>
              <input type="radio" [value]="true" name="responsible" />
            </div>
            <div class="pr-2 flex">
              <p class="label mr-3">Não</p>
              <input type="radio" [value]="false" name="responsible" />
            </div>
          </div>
        </div>

        <div class="flex flex-col w-1/2 mb-2">
          <p class="label">É Polo Gerador de Viagem?</p>
          <div class="mt-3 flex flex-row">
            <div class="pr-2 flex">
              <p class="label mr-3">Sim</p>
              <input type="radio" [value]="true" name="responsible" />
            </div>
            <div class="pr-2 flex">
              <p class="label mr-3">Não</p>
              <input type="radio" [value]="false" name="responsible" />
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-col w-1/2 mt-5 mb-2">
        <p class="label">Vai ser avalidado por?</p>
        <div class="mt-3 flex flex-row">
          <div class="pr-2 flex">
            <p class="label mr-3">Tamanho do Terreno</p>
            <input type="radio" [value]="true" name="responsible" />
          </div>
          <div class="pr-2 flex">
            <p class="label mr-3">Área Construída</p>
            <input type="radio" [value]="false" name="responsible" />
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="risk === 'Pergunta'" class="mt-3 pl-10 pr-10 mb-5">
      <h2 class="text-lg font-bold mb-3">Perguntas</h2>
      <div class="flex flex-row items-end">
        <div class="w-1/3">
          <p class="label">Pergunta</p>
          <input type="text" class="input" />
        </div>
        <div class="w-1/3 ml-2">
          <p class="label">Se sim? O risco:</p>
          <select class="input">
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
          </select>
        </div>
        <div class="w-1/3 ml-2">
          <p class="label">Se não? O risco:</p>
          <select class="input">
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
          </select>
        </div>
        <button class="btn btn-primary ml-2">Adicionar</button>
      </div>
      <app-table [data]="questions" [columns]="columns"></app-table>
    </div>

    <div>
      <div class="flex flex-row justify-between">
        <h2 class="font-bold text-lg mt-5">Regras</h2>
        <button (click)="newRule()" class="btn btn-primary text-sm ml-2 mt-3">
          Adicionar
        </button>
      </div>
      <app-table [data]="rules" [columns]="columnsRules"></app-table>
    </div>
    <div class="flex flex-row mt-10">
      <button (click)="save()" class="btn btn-primary mt-3">Salvar</button>
      <button (click)="goBack()" class="btn btn-secondary mt-3 ml-3">
        Cancelar
      </button>
    </div>
  </div>
</div>
