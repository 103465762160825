<div
  *ngIf="showIf"
  class="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-10"
>
  <div
    class="relative top-20 mx-auto p-4 border w-[70%] max-w-screen-lg max-h-[80%] overflow-y-auto shadow-lg rounded bg-white flex flex-col justify-start items-start"
  >
    <h3 class="text-lg font-medium">Banco de perguntas</h3>
    <p class="text-sm text-gray-700">
      Selecione uma ou mais perguntas salvas para adicioná-las ao cálculo de
      risco da atividade (CNAE).
    </p>

    <div class="w-full">
      <app-filter-box
        [showInclude]="true"
        [placeholder]="'Pesquisar por código ou palavra-chave'"
        (filter)="filterQuestions($event)"
        [loading]="loading"
      >
        <div class="mb-3">
          <div class="flex flex-col">
            <label for="" class="label">Pesquisar perguntas</label>
          </div>
        </div>
      </app-filter-box>
    </div>

    <table class="w-full">
      <thead>
        <tr>
          <th>
            <div class="w-full flex flex-row justify-start items-center p-2">
              <p class="text-sm font-medium">Seleção</p>
              <mat-icon class="ml-2" fontIcon="unfold_more"></mat-icon>
            </div>
          </th>
          <th>
            <div class="w-full flex flex-row justify-start items-center p-2">
              <p class="text-sm font-medium">Código</p>
              <mat-icon
                class="text-sm ml-1 mt-1 text-black"
                fontIcon="unfold_more"
              ></mat-icon>
            </div>
          </th>
          <th>
            <div class="w-full flex flex-row justify-start items-center p-2">
              <p class="text-sm font-medium">Pergunta*</p>
              <mat-icon
                class="text-sm ml-1 mt-1 text-black"
                fontIcon="unfold_more"
              ></mat-icon>
            </div>
          </th>
          <th>
            <div class="w-full flex flex-row justify-start items-center p-2">
              <p class="text-sm font-medium">Se SIM*</p>
              <mat-icon
                class="text-sm ml-1 mt-1 text-black"
                fontIcon="unfold_more"
              ></mat-icon>
            </div>
          </th>
          <th>
            <div class="w-full flex flex-row justify-start items-center p-2">
              <p class="text-sm font-medium">Se NÃO*</p>
              <mat-icon
                class="text-sm ml-1 mt-1 text-black"
                fontIcon="unfold_more"
              ></mat-icon>
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let d of data; let index = index" class="py-2">
          <td>
            <div class="w-full flex flex-row justify-center items-center p-2">
              <input
                type="checkbox"
                (change)="handleSelectQuestion(d.id, $event)"
              />
            </div>
          </td>
          <td>
            <div class="w-full flex flex-row justify-center items-center p-2">
              <p class="text-sm font-normal">{{ d.id }}</p>
            </div>
          </td>
          <td>
            <div class="w-full flex flex-row justify-center items-center p-2">
              <p class="text-sm font-normal">
                {{ d.description }}
              </p>
            </div>
          </td>
          <td>
            <div class="w-full flex flex-row justify-center items-center p-2">
              <p class="text-sm font-normal">{{ getRisk(d.riskIfYes) }}</p>
            </div>
          </td>
          <td>
            <div class="w-full flex flex-row justify-center items-center p-2">
              <p class="text-sm font-normal">{{ getRisk(d.riskIfNo) }}</p>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <div
      *ngIf="data.length === 0 && !loading"
      class="w-full flex flex-row justify-center py-4"
    >
      <div class="flex p-3 flex-row items-center">
        <p class="text-sm font-medium">Nenhum registro encontrado.</p>
      </div>
    </div>

    <div *ngIf="loading" class="w-full flex flex-row justify-center py-4">
      <div class="flex p-3 flex-row items-center">
        <ng-icon class="mr-3 animate-spin" name="matSync"></ng-icon>
        <p class="text-sm font-medium">Carregando dados...</p>
      </div>
    </div>

    <div class="w-full flex flex-row justify-between mt-4">
      <select class="mt-3 p-1 rounded-md">
        <option value="10">10</option>
        <option value="20">20</option>
        <option value="50">50</option>
      </select>

      <nav
        class="mt-3 flex flex-row justify-end"
        aria-label="Page navigation example"
      >
        <ul class="flex items-center -space-x-px h-10 text-base">
          <li>
            <div
              class="flex cursor-pointer items-center justify-center px-4 h-10 ms-0 leading-tight text-gray-500 bg-white border border-e-0 border-gray-300 rounded-s-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
            >
              <span class="sr-only">Previous</span>
              <ng-icon name="matArrowBackIos"></ng-icon>
            </div>
          </li>
          <div
            class="flex cursor-pointer items-center justify-center px-4 h-10 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
          >
            {{ page }}
          </div>
          <li>
            <div
              class="flex cursor-pointer items-center justify-center px-4 h-10 leading-tight text-gray-500 bg-white border border-gray-300 rounded-e-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
            >
              <span class="sr-only">Next</span>
              <ng-icon name="matArrowForwardIos"></ng-icon>
            </div>
          </li>
        </ul>
      </nav>
    </div>

    <div class="w-full flex flex-row justify-end items-center mt-6">
      <button class="btn btn-secondary text-sm" (click)="handleFunction()">
        Cancelar
      </button>
      <button
        [ngClass]="
          loading
            ? 'btn btn-primary text-sm ml-2 opacity-40 cursor-not-allowed pointer-events-none'
            : 'btn btn-primary text-sm ml-2'
        "
        (click)="handleSaveQuestions(toEditData)"
      >
        {{ loading ? "Adicionando..." : "Adicionar selecionado(s)" }}
      </button>
    </div>
  </div>
</div>
