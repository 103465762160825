import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Alert } from '../models/alert.models';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  private alertsSubject = new BehaviorSubject<Alert[]>([]);
  public alerts = this.alertsSubject.asObservable();

  constructor() {}

  add(alert: Alert) {
    const newAlert = { ...alert, visible: true };
    const currentAlerts = this.alertsSubject.value;
    this.alertsSubject.next([...currentAlerts, newAlert]);
  }

  remove(id: string) {
    const currentAlerts = this.alertsSubject.value.filter(
      (alert) => alert.id !== id
    );
    this.alertsSubject.next(currentAlerts);
  }

  clear(): void {
    this.alertsSubject.next([]);
  }
}
