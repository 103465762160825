import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgIconComponent, provideIcons } from '@ng-icons/core';

import { MatIcon } from '@angular/material/icon';
import {
  BreadcrumbsComponent,
  IRoute,
} from '../../components/breadcrumbs/breadcrumbs.component';
import {
  FormArray,
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { UpdateEmployeesInterfaceInput } from '../../models/employee.model';
import { EmployeeService } from '../../services/employee.service';
import { NgxMaskDirective } from 'ngx-mask';
import {
  matArrowBackIos,
  matArrowForwardIos,
  matSync,
} from '@ng-icons/material-icons/baseline';
import { ModalService } from '../../services/modal.service';

@Component({
  selector: 'app-edit-user',
  standalone: true,
  imports: [
    CommonModule,
    NgIconComponent,
    MatIcon,
    BreadcrumbsComponent,
    ReactiveFormsModule,
    NgxMaskDirective,
  ],
  providers: [
    provideIcons({
      matArrowBackIos,
      matArrowForwardIos,
      matSync,
    }),
  ],
  templateUrl: './edit-user.component.html',
  styleUrl: './edit-user.component.scss',
})
export class EditUserComponent implements OnInit {
  id!: string;
  routes: IRoute[] = [
    {
      label: 'Servidores',
      link: {
        route: '/servers',
      },
    },
    {
      label: 'Editar usuário',
      link: {
        route: '/update-user',
      },
    },
  ];

  userForm!: FormGroup;
  contactTypes: string[][] = [
    ['Celular', 'Celular/WhatsApp', 'Telefone fixo'],
    ['Celular', 'Celular/WhatsApp', 'Telefone fixo'],
    ['Celular', 'Celular/WhatsApp', 'Telefone fixo'],
  ];

  public loading: boolean = true;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private employeeService: EmployeeService,
    private modalService: ModalService,
  ) {}

  ngOnInit(): void {
    this.userForm = this.formBuilder.group({
      fullname: ['', Validators.required],
      cpf: [
        '',
        [
          Validators.required,
          Validators.pattern(/^\d{3}\.\d{3}\.\d{3}-\d{2}$/),
        ],
      ],
      complement: [''],
      birthdate: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      street: ['', Validators.required],
      neighborhood: ['', Validators.required],
      city: ['', Validators.required],
      state: ['', Validators.required],
      role: ['', Validators.required],
      jobTitle: ['', Validators.required],
      contacts: this.formBuilder.array(this.initContacts()),
    });

    this.route.params.subscribe((params) => {
      this.id = params['id'];
      if (this.id) {
        this.getUser();
      }
    });
  }

  private getUser(): void {
    this.employeeService.getById(this.id).subscribe({
      next: (employee) => {
        this.userForm.patchValue({
          fullname: employee.fullname,
          cpf: employee.cpf,
          complement: employee.complement,
          birthdate: employee.birthdate,
          email: employee.email,
          street: employee.street,
          neighborhood: employee.neighborhood,
          city: employee.city,
          state: employee.state,
          role: employee.role,
          jobTitle: employee.jobTitle,
        });

        if (employee.contacts && employee.contacts.length > 0)
          this.userForm.setControl(
            'contacts',
            this.formBuilder.array(
              employee.contacts.map((contact) =>
                this.formBuilder.group({
                  type: [contact.type, Validators.required],
                  number: [contact.number, Validators.required],
                }),
              ),
            ),
          );

        this.loading = false;
      },
      error: (err: HttpErrorResponse) => {
        console.error(err);
        this.loading = false;
        this.modalService.add({
          id: 'get-user-error',
          icon: 'warning',
          title: 'Ops! Algo errado.',
          description:
            'Não foi possível carregar as informações do servidor. Por favor, tente novamente.',
          actionLabel: 'Fechar',
        });
      },
    });
  }

  private initContacts(): FormGroup[] {
    const initialContacts = [{ type: '', number: '' }];
    return initialContacts
      .map((contact) =>
        this.formBuilder.group({
          type: [contact.type, Validators.required],
          number: [contact.number, Validators.required],
        }),
      )
      .filter((contact) => contact.get('type')?.value !== '');
  }

  get contacts(): FormArray {
    return this.userForm.get('contacts') as FormArray;
  }

  addContact() {
    if ((this.userForm.get('contacts') as FormArray).length === 3) return;

    const contactForm = this.formBuilder.group({
      type: ['', Validators.required],
      number: ['', Validators.required],
    });
    this.contacts.push(contactForm);
  }

  onContactTypeChange(event: Event, index: number) {
    const input = event.target as HTMLInputElement;

    const contactForm = (this.userForm.get('contacts') as FormArray).at(index);
    const newType = input.value;
    const numberControl = contactForm.get('number');

    if (newType === 'cellphone' || newType === 'whatsapp') {
      numberControl?.setValidators([
        Validators.required,
        Validators.pattern(/^\(\d{2}\) \d{5}-\d{4}$/),
      ]);
    } else {
      numberControl?.setValidators([
        Validators.required,
        Validators.pattern(/^\(\d{2}\) \d{4}-\d{4}$/),
      ]);
    }

    numberControl?.updateValueAndValidity();
  }

  removeContact(index: number) {
    this.contacts.removeAt(index);
  }

  onSubmit() {
    this.loading = true;

    const contactsDto = this.contacts.controls.map((control) => {
      return {
        type: control.get('type')?.value,
        number: control.get('number')?.value.replace(/[^0-9]/g, ''),
      };
    });

    if (this.userForm.valid) {
      const userDto: UpdateEmployeesInterfaceInput = {
        fullname: this.userForm.controls['fullname'].value,
        cpf: this.userForm.controls['cpf'].value.replace(/[^0-9]/g, ''),
        complement: this.userForm.controls['complement'].value,
        birthdate: this.userForm.controls['birthdate'].value,
        email: this.userForm.controls['email'].value,
        neighborhood: this.userForm.controls['neighborhood'].value,
        street: this.userForm.controls['street'].value,
        city: this.userForm.controls['city'].value,
        state: this.userForm.controls['state'].value,
        role: this.userForm.controls['role'].value,
        jobTitle: this.userForm.controls['jobTitle'].value,
        contacts: contactsDto,
      };

      this.employeeService.updateEmployee(userDto).subscribe({
        next: () => {
          this.modalService.add({
            id: 'update-server-success',
            icon: 'success',
            title: 'Tudo certo!',
            description: 'Servidor atualizado com sucesso. Redirecionando...',
            actionLabel: 'Fechar',
            action: () => {
              this.router.navigate(['/servers']);
              this.modalService.remove('update-server-success');
            },
          });
          this.loading = false;
        },
        error: (err: HttpErrorResponse) => {
          this.loading = false;
          console.error(err),
            this.modalService.add({
              id: 'update-server-error',
              icon: 'warning',
              title: 'Ops! Algo errado.',
              description:
                'Não foi possível atualizar o servidor. Por favor, tente novamente.',
              actionLabel: 'Fechar',
            });
        },
      });
    } else {
      this.loading = false;
      console.log(this.userForm.controls);
      this.userForm.markAllAsTouched();
    }
  }

  markFormGroupTouched(formGroup: FormGroup) {
    Object.values(formGroup.controls).forEach((control) => {
      control.markAsTouched();

      if (control instanceof FormGroup) {
        this.markFormGroupTouched(control);
      }
    });
  }

  goBack() {
    this.router.navigate(['/servers']);
  }
}
