<!-- TOP MENU -->

<nav class="fixed top-0 z-100 w-full bg-gray-700 border-b border-gray-700">
  <div class="px-3 py-3 lg:px-5 lg:pl-3">
    <div class="flex items-center justify-between">
      <button
        (click)="switchType()"
        class="flex items-center justify-start rtl:justify-end"
      >
        <button
          data-drawer-target="logo-sidebar"
          data-drawer-toggle="logo-sidebar"
          aria-controls="logo-sidebar"
          type="button"
          class="inline-flex items-center p-2 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400"
        >
          <span class="sr-only">Abrir sidemenu</span>
          <svg
            class="w-6 h-6"
            aria-hidden="true"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              clip-rule="evenodd"
              fill-rule="evenodd"
              d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"
            ></path>
          </svg>
        </button>
        <div class="flex ms-2 md:me-24">
          <img
            src="assets/images/HUBlic.png"
            class="h-8"
            alt="Liconsigit Logo"
          />
        </div>
      </button>
      <div class="flex items-center">
        <div>
          <button class="mr-5" data-dropdown-toggle="dropdown-files">
            <ng-icon
              size="20"
              strokeWidth="2"
              class="text-white"
              name="matNotificationsActive"
            ></ng-icon>
          </button>
          <div
            class="z-50 hidden my-4 text-base list-none bg-white divide-y divide-gray-100 rounded shadow max-w-56"
            id="dropdown-files"
          >
            <ul>
              <li class="flex flex-row items-center p-3">
                <p class="text-sm">
                  Você recebeu retorno sobre o pedido de viabilidade!
                </p>
              </li>
            </ul>
          </div>
        </div>
        <div class="flex items-center ms-3">
          <div class="flex flex-row justify-end items-center">
            <div class="flex flex-col justify-center items-end mr-2">
              <span class="text-xs text-white">Bem-vindo(a) de volta,</span>
              <span class="text-sm font-medium text-white">{{ name }}</span>
            </div>
            <button
              type="button"
              class="flex text-sm bg-gray-800 rounded-full focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-600"
              aria-expanded="false"
              data-dropdown-toggle="dropdownUser"
            >
              <img
                class="w-8 h-8 rounded-full"
                src="https://flowbite.com/docs/images/people/profile-picture-5.jpg"
                alt="user photo"
              />
            </button>
          </div>
          <div
            class="z-50 hidden my-4 text-base list-none bg-white divide-y divide-gray-100 rounded shadow dark:bg-gray-700 dark:divide-gray-600"
            id="dropdownUser"
          >
            <div class="px-4 py-3" role="none">
              <p class="text-sm text-gray-900 dark:text-white" role="none">
                {{ name }}
              </p>
            </div>
            <ul *ngIf="name !== null" class="py-1 cursor-pointer" role="none">
              <li>
                <a
                  href="#"
                  class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white"
                  role="menuitem"
                  >Meu Perfil</a
                >
              </li>
              <li>
                <a
                  href="#"
                  class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white"
                  role="menuitem"
                  >Alterar cadastro</a
                >
              </li>
              <li>
                <a
                  href="#"
                  class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white"
                  role="menuitem"
                  >Alterar senha</a
                >
              </li>
              <li>
                <button
                  (click)="logout()"
                  class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white"
                  role="menuitem"
                >
                  SAIR
                </button>
              </li>
            </ul>
            <ul *ngIf="name === null" class="py-1" role="none">
              <li>
                <a
                  href="login"
                  class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white"
                  role="menuitem"
                  >Logar</a
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</nav>

<!-- SIDE MENU -->

<aside
  id="logo-sidebar"
  [ngClass]="collapsed ? 'menu-collapsed' : 'menu-expanded'"
>
  <div class="px-3 pt-5 pb-4 overflow-y-auto bg-gray-800">
    <ul class="space-y-2 font-medium">
      <li *ngFor="let option of menu; let index = index">
        <a
          *ngIf="
            (!option.children || option.children.length === 0) &&
            !option.action &&
            option.permission
          "
          [routerLink]="option.link"
          [ngClass]="collapsed ? 'link-collapsed' : 'link-expanded'"
        >
          <ng-icon strokeWidth="2" [name]="option.icon"></ng-icon>
          <span *ngIf="!collapsed" class="ms-3">{{ option.label }}</span>
        </a>

        <div
          *ngIf="
            option.children &&
            option.children.length > 0 &&
            !option.action &&
            option.permission
          "
        >
          <button
            type="button"
            [ngClass]="
              option.open
                ? 'justify-start btn-action bg-gray-600'
                : !collapsed
                  ? 'justify-start btn-action'
                  : 'btn-action'
            "
            (click)="handleOpenOption(index)"
          >
            <ng-icon strokeWidth="2" [name]="option.icon"></ng-icon>
            <span *ngIf="!collapsed" class="ms-3">{{ option.label }}</span>
          </button>
          <div
            *ngIf="!collapsed"
            class="space-y-2 mt-2 bg-gray-700 rounded-lg p-2"
            [ngClass]="option.open ? 'block' : 'hidden'"
          >
            <ul class="space-y-2">
              <li *ngFor="let child of option.children">
                <a
                  *ngIf="child.permission"
                  [routerLink]="child.link"
                  [ngClass]="collapsed ? 'link-collapsed' : 'link-expanded'"
                  (click)="onLinkClick($event, child.link)"
                >
                  <ng-icon strokeWidth="2" [name]="child.icon"></ng-icon>
                  <span *ngIf="!collapsed" class="ms-3">{{ child.label }}</span>
                </a>
              </li>
            </ul>
          </div>
        </div>

        <button
          *ngIf="option.action"
          type="button"
          [ngClass]="!collapsed ? 'justify-start btn-action' : 'btn-action'"
          (click)="option.action()"
        >
          <ng-icon strokeWidth="2" [name]="option.icon"></ng-icon>
          <span *ngIf="!collapsed" class="ms-3">{{ option.label }}</span>
        </button>
      </li>
    </ul>
  </div>
  <button
    (click)="handleMenuToggle()"
    class="cursor-pointer bg-gray-700 w-full flex items-center justify-center p-6"
  >
    <ng-icon
      class="text-white"
      strokeWidth="2"
      [name]="collapsed ? 'matArrowForwardIos' : 'matArrowBackIos'"
    ></ng-icon>
  </button>
</aside>
