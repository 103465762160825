<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->

<div class="m-3 p-4">
  <app-breadcrumbs [routes]="routes"></app-breadcrumbs>

  <h1 class="text-3xl font-bold mb-3">Criar regras</h1>

  <div class="relative w-full grid grid-cols-2 gap-4">
    <div class="w-full flex flex-col card justify-start items-start">
      <div class="w-full flex flex-col justify-start items-start mb-2">
        <label
          for="zone_name"
          class="text-sm font-medium text-gray-900 dark:text-white"
          >Nome da zona</label
        >
        <input
          type="text"
          id="zone_name"
          class="input opacity-40"
          placeholder="Nome da zona"
          [value]="zone.name"
          disabled
        />
      </div>
      <div class="w-full md:w-1/2 flex flex-col justify-start items-start">
        <label
          for="zone_tag"
          class="text-sm font-medium text-gray-900 dark:text-white"
          >Sigla</label
        >
        <input
          formControlName="zone_tag"
          type="text"
          id="zone_tag"
          class="input opacity-40"
          placeholder="ZO 1"
          [value]="zone.acronym"
          disabled
        />
      </div>
    </div>
    <div class="w-full flex flex-col card justify-start items-start">
      <div class="w-full h-full flex flex-col justify-start items-start">
        <label
          for="allowed_risk"
          class="text-lg font-medium text-gray-900 dark:text-white"
          >Definir risco(s) permitido(s)<font color="red">*</font></label
        >
        <div class="w-full grid grid-cols-1 md:grid-cols-3 gap-4 mt-4">
          <div
            [ngClass]="
              notRisk
                ? 'w-full flex flex-row justify-start items-center opacity-40 pointer-events-none cursor-not-allowed'
                : 'w-full flex flex-row justify-start items-center'
            "
          >
            <input
              type="checkbox"
              name="risco_1"
              id="risco_1"
              [checked]="verifiyRisk(1)"
              (change)="handleSelectRisk($event, 1)"
            />
            <label class="text-sm ml-2" for="risco_1">Risco I</label>
          </div>
          <div
            [ngClass]="
              notRisk
                ? 'w-full flex flex-row justify-start items-center opacity-40 pointer-events-none cursor-not-allowed'
                : 'w-full flex flex-row justify-start items-center'
            "
          >
            <input
              type="checkbox"
              name="risco_2"
              id="risco_2"
              [checked]="verifiyRisk(2)"
              (change)="handleSelectRisk($event, 2)"
            />
            <label class="text-sm ml-2" for="risco_2">Risco II</label>
          </div>
          <div
            [ngClass]="
              notRisk
                ? 'w-full flex flex-row justify-start items-center opacity-40 pointer-events-none cursor-not-allowed'
                : 'w-full flex flex-row justify-start items-center'
            "
          >
            <input
              type="checkbox"
              name="risco_3"
              id="risco_3"
              [checked]="verifiyRisk(3)"
              (change)="handleSelectRisk($event, 3)"
            />
            <label class="text-sm ml-2" for="risco_3">Risco III</label>
          </div>
          <div
            [ngClass]="
              notRisk
                ? 'w-full flex flex-row justify-start items-center opacity-40 pointer-events-none cursor-not-allowed'
                : 'w-full flex flex-row justify-start items-center'
            "
          >
            <input
              type="checkbox"
              name="comp"
              id="comp"
              [checked]="verifiyRisk(4)"
              (change)="handleSelectRisk($event, 4)"
            />
            <label class="text-sm ml-2" for="comp">Competência Estadual</label>
          </div>
          <div
            [ngClass]="
              notRisk
                ? 'w-full flex flex-row justify-start items-center opacity-40 pointer-events-none cursor-not-allowed'
                : 'w-full flex flex-row justify-start items-center'
            "
          >
            <input
              type="checkbox"
              name="info"
              id="info"
              [checked]="verifiyRisk(0)"
              (change)="handleSelectRisk($event, 0)"
            />
            <label class="text-sm ml-2" for="info">Requer Informações</label>
          </div>
          <div class="w-full flex flex-row justify-start items-center">
            <input
              type="checkbox"
              name="none"
              id="none"
              (change)="handleChangeNotRisk()"
              [checked]="notRisk"
            />
            <label class="text-sm ml-2" for="none"
              >Nenhum risco permitido</label
            >
          </div>
        </div>
        <div class="w-full flex flex-row justify-start items-center mt-2">
          <p
            *ngIf="errors['risks']?.message"
            class="text-sm font-medium text-[#f44336]"
          >
            {{ errors["risks"].message }}
          </p>
        </div>
      </div>
    </div>
  </div>

  <div
    [ngClass]="
      notRisk
        ? 'card w-full flex flex-col justify-start items-start opacity-40 pointer-events-none cursor-not-allowed'
        : 'card w-full flex flex-col justify-start items-start'
    "
  >
    <h3 class="text-lg font-medium text-gray-900 dark:text-white">
      Selecionar atividades (CNAE) <font color="red">NÃO</font> permitidas<font
        color="red"
        >*</font
      >
    </h3>

    <input
      type="text"
      class="input"
      placeholder="Pesquisar por palavra-chave ou código da atividade (CNAE)"
      required=""
      (input)="handleChangeFilter($event)"
    />
    <div class="flex flex-row justify-start items-center mt-2">
      <input
        type="checkbox"
        [checked]="allCnaesIsSelected"
        (click)="handleSelectedAll()"
      />
      <p class="text-sm text-gray-900 dark:text-white ml-2">
        Todas as atividades são permitidas
      </p>
    </div>

    <div class="w-full flex flex-row justify-start items-center mt-2">
      <p
        *ngIf="errors['allCnaesIsSelected']?.message"
        class="text-sm font-medium text-[#f44336]"
      >
        {{ errors["allCnaesIsSelected"].message }}
      </p>
    </div>
    <div class="w-full flex flex-row justify-start items-center mt-2">
      <p
        *ngIf="errors['selectedCnaes']?.message"
        class="text-sm font-medium text-[#f44336]"
      >
        {{ errors["selectedCnaes"].message }}
      </p>
    </div>

    <table
      [ngClass]="
        allCnaesIsSelected
          ? 'w-full mt-6 opacity-40 cursor-not-allowed pointer-events-none'
          : 'w-full mt-6'
      "
    >
      <thead>
        <tr>
          <th>
            <div class="w-full flex flex-row justify-start items-center p-2">
              <p class="text-sm font-medium">Seleção</p>
              <mat-icon
                class="text-sm ml-1 mt-1 text-black"
                fontIcon="unfold_more"
              ></mat-icon>
            </div>
          </th>
          <th>
            <div class="w-full flex flex-row justify-start items-center p-2">
              <p class="text-sm font-medium">Código</p>
              <mat-icon
                class="text-sm ml-1 mt-1 text-black"
                fontIcon="unfold_more"
              ></mat-icon>
            </div>
          </th>
          <th>
            <div class="w-full flex flex-row justify-start items-center p-2">
              <p class="text-sm font-medium">Denominação</p>
              <mat-icon
                class="text-sm ml-1 mt-1 text-black"
                fontIcon="unfold_more"
              ></mat-icon>
            </div>
          </th>
          <th>
            <div class="w-full flex flex-row justify-start items-center p-2">
              <p class="text-sm font-medium">Risco</p>
              <mat-icon
                class="text-sm ml-1 mt-1 text-black"
                fontIcon="unfold_more"
              ></mat-icon>
            </div>
          </th>
          <th>
            <div class="w-full flex flex-row justify-start items-center p-2">
              <p class="text-sm font-medium">Ação</p>
            </div>
          </th>
        </tr>
      </thead>
      <tbody *ngIf="!loadingCnaes">
        <tr *ngFor="let cnae of cnaes; let index = index" class="py-2">
          <td>
            <div
              class="w-full flex flex-row justify-start items-center px-2 py-4"
            >
              <input
                *ngIf="cnae.selected"
                type="checkbox"
                [checked]="cnae.selected"
                (click)="handleRemoveCnae(cnae.id)"
              />
              <input
                *ngIf="!cnae.selected"
                type="checkbox"
                [checked]="cnae.selected"
                (click)="handleAddCnae(cnae)"
              />
            </div>
          </td>
          <td>
            <div
              class="w-full flex flex-row justify-start items-center px-2 py-4"
            >
              <p class="text-sm font-normal">{{ cnae.code }}</p>
            </div>
          </td>
          <td>
            <div
              class="w-full flex flex-row justify-start items-center px-2 py-4"
            >
              <p class="text-sm font-normal uppercase">
                {{ cnae.name }}
              </p>
            </div>
          </td>
          <td>
            <div
              class="w-full flex flex-row justify-start items-center px-2 py-4"
            >
              <p class="text-sm font-normal">
                {{
                  getRisk(getFinalRisk(cnae.environmentalRisk, cnae.healthRisk))
                }}
              </p>
            </div>
          </td>
          <td>
            <div
              class="w-full flex flex-row justify-start items-center px-2 py-4"
            >
              <p
                *ngIf="cnae.selected"
                class="text-sm font-medium hover:cursor-pointer text-[red]"
                (click)="handleRemoveCnae(cnae.id)"
              >
                Remover
              </p>
              <p
                *ngIf="!cnae.selected"
                class="text-sm font-medium hover:cursor-pointer"
                (click)="handleAddCnae(cnae)"
              >
                + Adicionar
              </p>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <div
      *ngIf="cnaes.length === 0 && !loadingCnaes"
      class="w-full flex flex-row justify-center py-4"
    >
      <div class="flex p-3 flex-row items-center">
        <p class="text-sm font-medium">Nenhum registro encontrado.</p>
      </div>
    </div>

    <div *ngIf="loadingCnaes" class="w-full flex flex-row justify-center py-4">
      <div class="flex p-3 flex-row items-center">
        <ng-icon class="mr-3 animate-spin" name="matSync"></ng-icon>
        <p class="text-sm font-medium">Carregando dados...</p>
      </div>
    </div>

    <div
      *ngIf="!loadingCnaes"
      class="w-full flex flex-row justify-between mt-4"
    >
      <select
        class="w-16 mt-3 p-1 rounded-md"
        (input)="handleLimitChange($event, 'limit')"
      >
        <option value="10" [selected]="limit === 10">10</option>
        <option value="20" [selected]="limit === 20">20</option>
        <option value="50" [selected]="limit === 50">50</option>
      </select>

      <nav
        class="mt-3 flex flex-row justify-end"
        aria-label="Page navigation example"
      >
        <ul class="flex items-center -space-x-px h-10 text-base">
          <li>
            <div
              [ngClass]="
                page > 1
                  ? 'flex cursor-pointer items-center justify-center px-4 h-10 ms-0 leading-tight text-gray-500 bg-white border border-e-0 border-gray-300 rounded-s-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white'
                  : 'opacity-40 pointer-events-none cursor-not-allowed flex cursor-pointer items-center justify-center px-4 h-10 ms-0 leading-tight text-gray-500 bg-white border border-e-0 border-gray-300 rounded-s-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white'
              "
              (click)="handlePageChange('previous')"
            >
              <span class="sr-only">Previous</span>
              <ng-icon name="matArrowBackIos"></ng-icon>
            </div>
          </li>
          <div
            class="flex cursor-pointer items-center justify-center px-4 h-10 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
          >
            {{ page }}
          </div>
          <li>
            <div
              class="flex cursor-pointer items-center justify-center px-4 h-10 leading-tight text-gray-500 bg-white border border-gray-300 rounded-e-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
              (click)="handlePageChange('next')"
            >
              <span class="sr-only">Next</span>
              <ng-icon name="matArrowForwardIos"></ng-icon>
            </div>
          </li>
        </ul>
      </nav>
    </div>
  </div>

  <div class="w-full card flex flex-col justify-start items-start mt-4">
    <h1 class="text-2xl font-medium">Restringir por áreas</h1>

    <div class="flex flex-row justify-start items-center mt-6">
      <input
        type="checkbox"
        name="yes"
        id="yes"
        [checked]="restrictByArea"
        (change)="handleIsRestrict()"
      />
      <label class="text-sm font-medium ml-2 mr-6" for="yes">Sim</label>
      <input
        type="checkbox"
        name="no"
        id="no"
        [checked]="!restrictByArea"
        (change)="handleIsRestrict()"
      />
      <label class="text-sm font-medium ml-2 mr-6" for="no">Não</label>
    </div>

    <div
      *ngIf="restrictByArea"
      class="w-full flex flex-row justify-start items-start border-b border-solid border-[#CACED7] py-4 mt-4"
    >
      <div class="w-full grid grid-cols-1 md:grid-cols-3 gap-4">
        <div class="w-full flex flex-row justify-start">
          <p class="text-xl">Área do Terreno Total (m²)</p>
        </div>
        <div class="w-full flex flex-col justify-start items-start">
          <p class="text-sm">Mínima</p>
          <div class="w-full flex flex-row justify-start items-center">
            <input
              formControlName="total_m2"
              type="text"
              id="total_m2"
              [ngClass]="
                ruleObject.minTotalLandAreaNotApplied
                  ? 'input max-w-52 mr-2 opacity-40 pointer-events-none cursor-not-allowed'
                  : 'input max-w-52 mr-2'
              "
              [disabled]="ruleObject.minTotalLandAreaNotApplied"
              [value]="ruleObject.minTotalLandArea || ''"
              placeholder="Inserir"
              required=""
              (input)="handleObjectValue($event, 'minTotalLandArea')"
            />
            <input
              type="checkbox"
              name="not_applied1"
              id="not_applied1"
              [checked]="ruleObject.minTotalLandAreaNotApplied || false"
              (change)="handleObjectValue($event, 'minTotalLandAreaNotApplied')"
            />
            <label class="text-sm font-medium ml-2 mr-6" for="not_applied1"
              >Não se aplica</label
            >
          </div>

          <div class="w-full flex flex-row justify-start items-center mt-2">
            <p
              *ngIf="errors['minTotalLandArea']?.message"
              class="text-sm font-medium text-[#f44336]"
            >
              {{ errors["minTotalLandArea"].message }}
            </p>
          </div>
        </div>
        <div class="w-full flex flex-col justify-start items-start">
          <p class="text-sm">Máxima</p>
          <div class="w-full flex flex-row justify-start items-center">
            <input
              formControlName="total_m2"
              type="text"
              id="total_m2"
              [ngClass]="
                ruleObject.maxTotalLandAreaNotApplied
                  ? 'input max-w-52 mr-2 opacity-40 pointer-events-none cursor-not-allowed'
                  : 'input max-w-52 mr-2'
              "
              [disabled]="ruleObject.maxTotalLandAreaNotApplied"
              [value]="ruleObject.maxTotalLandArea || ''"
              placeholder="Inserir"
              required=""
              (input)="handleObjectValue($event, 'maxTotalLandArea')"
            />
            <input
              type="checkbox"
              name="not_applied2"
              id="not_applied2"
              [checked]="ruleObject.maxTotalLandAreaNotApplied || false"
              (change)="handleObjectValue($event, 'maxTotalLandAreaNotApplied')"
            />
            <label class="text-sm font-medium ml-2 mr-6" for="not_applied2"
              >Não se aplica</label
            >
          </div>

          <div class="w-full flex flex-row justify-start items-center mt-2">
            <p
              *ngIf="errors['maxTotalLandArea']?.message"
              class="text-sm font-medium text-[#f44336]"
            >
              {{ errors["maxTotalLandArea"].message }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div
      *ngIf="restrictByArea"
      class="w-full flex flex-row justify-start items-start border-b border-solid border-[#CACED7] py-4"
    >
      <div class="w-full grid grid-cols-1 md:grid-cols-3 gap-4">
        <div class="w-full flex flex-row justify-start">
          <p class="text-xl">Área do Terreno (m²)</p>
        </div>
        <div class="w-full flex flex-col justify-start items-start">
          <p class="text-sm">Mínima</p>
          <div class="w-full flex flex-row justify-start items-center">
            <input
              formControlName="total_m2"
              type="text"
              id="total_m2"
              [ngClass]="
                ruleObject.minLandAreaNotApplied
                  ? 'input max-w-52 mr-2 opacity-40 pointer-events-none cursor-not-allowed'
                  : 'input max-w-52 mr-2'
              "
              [disabled]="ruleObject.minLandAreaNotApplied"
              [value]="ruleObject.minLandArea || ''"
              placeholder="Inserir"
              required=""
              (input)="handleObjectValue($event, 'minLandArea')"
            />
            <input
              type="checkbox"
              name="not_applied3"
              id="not_applied3"
              [checked]="ruleObject.minLandAreaNotApplied || false"
              (change)="handleObjectValue($event, 'minLandAreaNotApplied')"
            />
            <label class="text-sm font-medium ml-2 mr-6" for="not_applied3"
              >Não se aplica</label
            >
          </div>
          <div class="w-full flex flex-row justify-start items-center mt-2">
            <p
              *ngIf="errors['minLandArea']?.message"
              class="text-sm font-medium text-[#f44336]"
            >
              {{ errors["minLandArea"].message }}
            </p>
          </div>
        </div>
        <div class="w-full flex flex-col justify-start items-start">
          <p class="text-sm">Máxima</p>
          <div class="w-full flex flex-row justify-start items-center">
            <input
              formControlName="total_m2"
              type="text"
              id="total_m2"
              [ngClass]="
                ruleObject.maxLandAreaNotApplied
                  ? 'input max-w-52 mr-2 opacity-40 pointer-events-none cursor-not-allowed'
                  : 'input max-w-52 mr-2'
              "
              [disabled]="ruleObject.maxLandAreaNotApplied"
              [value]="ruleObject.maxLandArea || ''"
              placeholder="Inserir"
              required=""
              (input)="handleObjectValue($event, 'maxLandArea')"
            />
            <input
              type="checkbox"
              name="not_applied4"
              id="not_applied4"
              [checked]="ruleObject.maxLandAreaNotApplied || false"
              (change)="handleObjectValue($event, 'maxLandAreaNotApplied')"
            />
            <label class="text-sm font-medium ml-2 mr-6" for="not_applied4"
              >Não se aplica</label
            >
          </div>
          <div class="w-full flex flex-row justify-start items-center mt-2">
            <p
              *ngIf="errors['maxLandArea']?.message"
              class="text-sm font-medium text-[#f44336]"
            >
              {{ errors["maxLandArea"].message }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div
      *ngIf="restrictByArea"
      class="w-full flex flex-row justify-start items-start border-b border-solid border-[#CACED7] py-4 mt-2"
    >
      <div class="w-full grid grid-cols-1 md:grid-cols-3 gap-4">
        <div class="w-full flex flex-row justify-start">
          <p class="text-xl">Área construída (m²)</p>
        </div>
        <div class="w-full flex flex-col justify-start items-start">
          <p class="text-sm">Mínima</p>
          <div class="w-full flex flex-row justify-start items-center">
            <input
              formControlName="total_m2"
              type="text"
              id="total_m2"
              [ngClass]="
                ruleObject.minBuildingAreaNotApplied
                  ? 'input max-w-52 mr-2 opacity-40 pointer-events-none cursor-not-allowed'
                  : 'input max-w-52 mr-2'
              "
              [disabled]="ruleObject.minBuildingAreaNotApplied"
              [value]="ruleObject.minBuildingArea || ''"
              placeholder="Inserir"
              required=""
              (input)="handleObjectValue($event, 'minBuildingArea')"
            />
            <input
              type="checkbox"
              name="not_applied5"
              id="not_applied5"
              [checked]="ruleObject.minBuildingAreaNotApplied || false"
              (change)="handleObjectValue($event, 'minBuildingAreaNotApplied')"
            />
            <label class="text-sm font-medium ml-2 mr-6" for="not_applied5"
              >Não se aplica</label
            >
          </div>
          <div class="w-full flex flex-row justify-start items-center mt-2">
            <p
              *ngIf="errors['minBuildingArea']?.message"
              class="text-sm font-medium text-[#f44336]"
            >
              {{ errors["minBuildingArea"].message }}
            </p>
          </div>
        </div>
        <div class="w-full flex flex-col justify-start items-start">
          <p class="text-sm">Máxima</p>
          <div class="w-full flex flex-row justify-start items-center">
            <input
              formControlName="total_m2"
              type="text"
              id="total_m2"
              [ngClass]="
                ruleObject.maxBuildingAreaNotApplied
                  ? 'input max-w-52 mr-2 opacity-40 pointer-events-none cursor-not-allowed'
                  : 'input max-w-52 mr-2'
              "
              [disabled]="ruleObject.maxBuildingAreaNotApplied"
              [value]="ruleObject.maxBuildingArea || ''"
              placeholder="Inserir"
              required=""
              (input)="handleObjectValue($event, 'maxBuildingArea')"
            />
            <input
              type="checkbox"
              name="not_applied6"
              id="not_applied6"
              [checked]="ruleObject.maxBuildingAreaNotApplied || false"
              (change)="handleObjectValue($event, 'maxBuildingAreaNotApplied')"
            />
            <label class="text-sm font-medium ml-2 mr-6" for="not_applied6"
              >Não se aplica</label
            >
          </div>
          <div class="w-full flex flex-row justify-start items-center mt-2">
            <p
              *ngIf="errors['maxBuildingArea']?.message"
              class="text-sm font-medium text-[#f44336]"
            >
              {{ errors["maxBuildingArea"].message }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div
      *ngIf="restrictByArea"
      class="w-full flex flex-row justify-start items-start border-b border-solid border-[#CACED7] py-4 mt-2"
    >
      <div class="w-full grid grid-cols-1 md:grid-cols-3 gap-4">
        <div class="w-full flex flex-row justify-start">
          <p class="text-xl">Área do estabelecimento (m²)</p>
        </div>
        <div class="w-full flex flex-col justify-start items-start">
          <p class="text-sm">Mínima</p>
          <div class="w-full flex flex-row justify-start items-center">
            <input
              formControlName="total_m2"
              type="text"
              id="total_m2"
              [ngClass]="
                ruleObject.minEstablishmentAreaNotApplied
                  ? 'input max-w-52 mr-2 opacity-40 pointer-events-none cursor-not-allowed'
                  : 'input max-w-52 mr-2'
              "
              [disabled]="ruleObject.minEstablishmentAreaNotApplied"
              [value]="ruleObject.minEstablishmentArea || ''"
              placeholder="Inserir"
              required=""
              (input)="handleObjectValue($event, 'minEstablishmentArea')"
            />
            <input
              type="checkbox"
              name="not_applied7"
              id="not_applied7"
              [checked]="ruleObject.minEstablishmentAreaNotApplied || false"
              (change)="
                handleObjectValue($event, 'minEstablishmentAreaNotApplied')
              "
            />
            <label class="text-sm font-medium ml-2 mr-6" for="not_applied7"
              >Não se aplica</label
            >
          </div>
          <div class="w-full flex flex-row justify-start items-center mt-2">
            <p
              *ngIf="errors['minEstablishmentArea']?.message"
              class="text-sm font-medium text-[#f44336]"
            >
              {{ errors["minEstablishmentArea"].message }}
            </p>
          </div>
        </div>
        <div class="w-full flex flex-col justify-start items-start">
          <p class="text-sm">Máxima</p>
          <div class="w-full flex flex-row justify-start items-center">
            <input
              formControlName="total_m2"
              type="text"
              id="total_m2"
              [ngClass]="
                ruleObject.maxEstablishmentAreaNotApplied
                  ? 'input max-w-52 mr-2 opacity-40 pointer-events-none cursor-not-allowed'
                  : 'input max-w-52 mr-2'
              "
              [disabled]="ruleObject.maxEstablishmentAreaNotApplied"
              [value]="ruleObject.maxEstablishmentArea || ''"
              placeholder="Inserir"
              required=""
              (input)="handleObjectValue($event, 'maxEstablishmentArea')"
            />
            <input
              type="checkbox"
              name="not_applied8"
              id="not_applied8"
              [checked]="ruleObject.maxEstablishmentAreaNotApplied || false"
              (change)="
                handleObjectValue($event, 'maxEstablishmentAreaNotApplied')
              "
            />
            <label class="text-sm font-medium ml-2 mr-6" for="not_applied8"
              >Não se aplica</label
            >
          </div>
          <div class="w-full flex flex-row justify-start items-center mt-2">
            <p
              *ngIf="errors['maxEstablishmentArea']?.message"
              class="text-sm font-medium text-[#f44336]"
            >
              {{ errors["maxEstablishmentArea"].message }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="w-full card flex flex-col justify-start items-start mt-4">
    <h1 class="text-2xl font-medium">Especificar parâmetros urbanísticos</h1>

    <div
      class="w-full flex flex-row justify-start items-start border-b border-solid border-[#CACED7] py-4 mt-4"
    >
      <div class="w-full grid grid-cols-1 md:grid-cols-5 gap-4">
        <div class="w-full flex flex-row justify-start">
          <p class="text-xl">Parâmetros de ocupação</p>
        </div>
        <div class="w-full flex flex-col justify-start items-start">
          <p class="text-sm">Taxa de ocupação (%)<font color="red">*</font></p>
          <input
            formControlName="total_m2"
            type="text"
            id="total_m2"
            class="input max-w-52 mr-2"
            [value]="ruleObject.ocupationRate || ''"
            placeholder="Inserir"
            required=""
            (input)="handleObjectValue($event, 'ocupationRate')"
          />
          <div class="w-full flex flex-row justify-start items-center mt-2">
            <p
              *ngIf="errors['ocupationRate']?.message"
              class="text-sm font-medium text-[#f44336]"
            >
              {{ errors["ocupationRate"].message }}
            </p>
          </div>
        </div>
        <div class="w-full flex flex-col justify-start items-start">
          <p class="text-sm">
            Índice de aproveitamento<font color="red">*</font>
          </p>
          <input
            formControlName="total_m2"
            type="text"
            id="total_m2"
            class="input max-w-52 mr-2"
            [value]="ruleObject.utilizationRate || ''"
            placeholder="Inserir"
            required=""
            (input)="handleObjectValue($event, 'utilizationRate')"
          />
          <div class="w-full flex flex-row justify-start items-center mt-2">
            <p
              *ngIf="errors['utilizationRate']?.message"
              class="text-sm font-medium text-[#f44336]"
            >
              {{ errors["utilizationRate"].message }}
            </p>
          </div>
        </div>
        <div class="w-full flex flex-col justify-start items-start">
          <p class="text-sm">
            Taxa de permeabilidade (%)<font color="red">*</font>
          </p>
          <input
            formControlName="total_m2"
            type="text"
            id="total_m2"
            class="input max-w-52 mr-2"
            [value]="ruleObject.permeabilityRate || ''"
            placeholder="Inserir"
            required=""
            (input)="handleObjectValue($event, 'permeabilityRate')"
          />
          <div class="w-full flex flex-row justify-start items-center mt-2">
            <p
              *ngIf="errors['permeabilityRate']?.message"
              class="text-sm font-medium text-[#f44336]"
            >
              {{ errors["permeabilityRate"].message }}
            </p>
          </div>
        </div>
        <div class="w-full flex flex-col justify-start items-start">
          <p class="text-sm">
            Área do Terreno Parcelável (m²)<font color="red">*</font>
          </p>
          <input
            formControlName="total_m2"
            type="text"
            id="total_m2"
            [ngClass]="
              ruleObject.parcelableLandAreaNotApplied
                ? 'input max-w-52 mr-2 opacity-40 pointer-events-none cursor-not-allowed'
                : 'input max-w-52 mr-2'
            "
            [disabled]="ruleObject.parcelableLandAreaNotApplied"
            [value]="ruleObject.parcelableLandArea || ''"
            placeholder="Inserir"
            required=""
            (input)="handleObjectValue($event, 'parcelableLandArea')"
          />
          <div class="w-full flex flex-row justify-start items-center mt-1">
            <input
              type="checkbox"
              [checked]="ruleObject.parcelableLandAreaNotApplied || false"
              (change)="
                handleObjectValue($event, 'parcelableLandAreaNotApplied')
              "
            />
            <p class="text-sm ml-2 font-medium">Não se aplica</p>
          </div>
          <div class="w-full flex flex-row justify-start items-center mt-2">
            <p
              *ngIf="errors['parcelableLandArea']?.message"
              class="text-sm font-medium text-[#f44336]"
            >
              {{ errors["parcelableLandArea"].message }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div
      class="w-full flex flex-row justify-start items-start border-b border-solid border-[#CACED7] py-4"
    >
      <div class="w-full grid grid-cols-1 md:grid-cols-5 gap-4">
        <div class="w-full flex flex-row justify-start">
          <p class="text-xl">Recuos do lote</p>
        </div>
        <div class="w-full flex flex-col justify-start items-start">
          <p class="text-sm">Recuo frontal (m)</p>
          <input
            formControlName="total_m2"
            type="text"
            id="total_m2"
            class="input max-w-52 mr-2"
            [value]="ruleObject.frontSetback || ''"
            placeholder="Inserir"
            required=""
            (input)="handleObjectValue($event, 'frontSetback')"
          />
        </div>
        <div class="w-full flex flex-col justify-start items-start">
          <p class="text-sm">Recuo de fundos (m)</p>
          <input
            formControlName="total_m2"
            type="text"
            id="total_m2"
            class="input max-w-52 mr-2"
            [value]="ruleObject.backSetback || ''"
            placeholder="Inserir"
            required=""
            (input)="handleObjectValue($event, 'backSetback')"
          />
        </div>
        <div class="w-full flex flex-col justify-start items-start">
          <p class="text-sm">Recuo lateral (m)</p>
          <input
            formControlName="total_m2"
            type="text"
            id="total_m2"
            class="input max-w-52 mr-2"
            [value]="ruleObject.sideSetback || ''"
            placeholder="Inserir"
            required=""
            (input)="handleObjectValue($event, 'sideSetback')"
          />
        </div>
      </div>
    </div>
    <div
      class="w-full flex flex-row justify-start items-start border-b border-solid border-[#CACED7] py-4 mt-2"
    >
      <div class="w-full grid grid-cols-1 md:grid-cols-5 gap-4">
        <div class="w-full flex flex-row justify-start">
          <p class="text-xl">Alturas da edificação</p>
        </div>
        <div class="w-full flex flex-col justify-start items-start">
          <p class="text-sm">Altura máxima da edificação (m)</p>
          <input
            formControlName="total_m2"
            type="text"
            id="total_m2"
            class="input max-w-52 mr-2"
            [value]="ruleObject.maxBuildingHeight || ''"
            placeholder="Inserir"
            required=""
            (input)="handleObjectValue($event, 'maxBuildingHeight')"
          />
        </div>
      </div>
    </div>
    <div
      class="w-full flex flex-row justify-start items-start border-b border-solid border-[#CACED7] py-4 mt-2"
    >
      <div class="w-full grid grid-cols-1 md:grid-cols-5 gap-4">
        <div class="w-full flex flex-row justify-start">
          <p class="text-xl">Áreas do lote</p>
        </div>
        <div class="w-full flex flex-col justify-start items-start">
          <p class="text-sm">Lote mínimo (m²)</p>
          <input
            formControlName="total_m2"
            type="text"
            id="total_m2"
            class="input max-w-52 mr-2"
            [value]="ruleObject.minLotSize || ''"
            placeholder="Inserir"
            required=""
            (input)="handleObjectValue($event, 'minLotSize')"
          />
        </div>
        <div class="w-full flex flex-col justify-start items-start">
          <p class="text-sm">Lote máximo (m²)</p>
          <input
            formControlName="total_m2"
            type="text"
            id="total_m2"
            class="input max-w-52 mr-2"
            placeholder="Inserir"
            [value]="ruleObject.maxLotSize || ''"
            placeholder="Inserir"
            required=""
            (input)="handleObjectValue($event, 'maxLotSize')"
          />
        </div>
        <div class="w-full flex flex-col justify-start items-start">
          <p class="text-sm">Testada mínima (m)</p>
          <input
            formControlName="total_m2"
            type="text"
            id="total_m2"
            class="input max-w-52 mr-2"
            placeholder="Inserir"
            [value]="ruleObject.minFrontage || ''"
            placeholder="Inserir"
            required=""
            (input)="handleObjectValue($event, 'minFrontage')"
          />
        </div>
        <div class="w-full flex flex-col justify-start items-start">
          <p class="text-sm">Profundidade mínima (m)</p>
          <input
            formControlName="total_m2"
            type="text"
            id="total_m2"
            class="input max-w-52 mr-2"
            placeholder="Inserir"
            [value]="ruleObject.minDepth || ''"
            placeholder="Inserir"
            required=""
            (input)="handleObjectValue($event, 'minDepth')"
          />
        </div>
      </div>
    </div>
    <div
      class="w-full flex flex-row justify-start items-start border-b border-solid border-[#CACED7] py-4 mt-2"
    >
      <div class="w-full grid grid-cols-1 md:grid-cols-5 gap-4">
        <div class="w-full flex flex-row justify-start">
          <p class="text-xl">Quadras</p>
        </div>
        <div class="w-full flex flex-col justify-start items-start">
          <p class="text-sm">Quadra mínima (m)</p>
          <input
            formControlName="total_m2"
            type="text"
            id="total_m2"
            class="input max-w-52 mr-2"
            placeholder="Inserir"
            [value]="ruleObject.minBlockSize || ''"
            placeholder="Inserir"
            required=""
            (input)="handleObjectValue($event, 'minBlockSize')"
          />
        </div>
        <div class="w-full flex flex-col justify-start items-start">
          <p class="text-sm">Quadra máxima (m)</p>
          <input
            formControlName="total_m2"
            type="text"
            id="total_m2"
            class="input max-w-52 mr-2"
            placeholder="Inserir"
            [value]="ruleObject.maxBlockSize || ''"
            placeholder="Inserir"
            required=""
            (input)="handleObjectValue($event, 'maxBlockSize')"
          />
        </div>
      </div>
    </div>
  </div>

  <div class="flex flex-row justify-end items-center mt-6 mb-4">
    <button class="btn btn-secondary text-sm" (click)="goBack()">Voltar</button>
    <button
      [ngClass]="
        loading
          ? 'btn btn-primary text-sm ml-2 opacity-40 cursor-not-allowed pointer-events-none'
          : 'btn btn-primary text-sm ml-2'
      "
      (click)="onSaveInformations()"
    >
      {{ loading ? "Salvando regras..." : "Salvar regras" }}
    </button>
  </div>
</div>
