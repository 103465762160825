import { CommonModule } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TableComponent } from '../../components/table/table.component';
import { FilterBoxComponent } from '../../components/filter-box/filter-box.component';
import { AlertService } from '../../services/alert.service';
import { AlertComponent } from '../../components/alert/alert.component';
import { EditIconComponent } from '../../icons/cnaes/edit-icon/edit-icon.component';
import { EyeIconComponent } from '../../icons/edit-zone/eye-icon/eye-icon.component';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import {
  matArrowBackIos,
  matArrowForwardIos,
  matSync,
} from '@ng-icons/material-icons/baseline';
import {
  BOLD_BUTTON,
  EditorConfig,
  ITALIC_BUTTON,
  NgxSimpleTextEditorModule,
  UNDERLINE_BUTTON,
} from 'ngx-simple-text-editor';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { OrientationProps } from '../../models/orientation.model';
import { OrientationService } from '../../services/orientation.service';
import { catchError, tap, throwError } from 'rxjs';
import { SingleZoneResponse } from '../../models/zone.models';
import { ZoneService } from '../../services/zone.service';
import { CreateZoneFeature } from '../../models/macrozone.models';
import { ModalService } from '../../services/modal.service';
import {
  BreadcrumbsComponent,
  IRoute,
} from '../../components/breadcrumbs/breadcrumbs.component';
import { MacrozoneService } from '../../services/macrozone.service';
import { RulesProps } from '../../models/rules.models';

@Component({
  selector: 'app-create-orientation',
  standalone: true,
  imports: [
    CommonModule,
    TableComponent,
    FilterBoxComponent,
    AlertComponent,
    EditIconComponent,
    EyeIconComponent,
    NgIconComponent,
    NgxSimpleTextEditorModule,
    ReactiveFormsModule,
    BreadcrumbsComponent,
  ],
  providers: [
    provideIcons({
      matSync,
      matArrowBackIos,
      matArrowForwardIos,
    }),
  ],
  templateUrl: './create-orientation.component.html',
  styleUrl: './create-orientation.component.scss',
})
export class CreateOrientationComponent implements OnInit {
  loading: boolean = false;
  orientationForm!: FormGroup;
  config: EditorConfig = {
    placeholder: 'Inserir texto...',
    buttons: [
      BOLD_BUTTON,
      ITALIC_BUTTON,
      UNDERLINE_BUTTON,
      // ORDERED_LIST_BUTTON,
      // UNORDERED_LIST_BUTTON,
    ], // ST_BUTTONS
  };

  routes: IRoute[] = [
    {
      label: 'Macrozonas',
      link: { route: `/macrozonas` },
    },
    {
      label: 'Zonas',
      link: {
        route: `/`,
      },
    },
    {
      label: 'Adicionar zona',
      link: {
        route: `/`,
      },
    },
    {
      label: 'Adicionar orientações',
      link: {
        route: `/`,
      },
    },
  ];

  orientationService = inject(OrientationService);

  macrozoneService = inject(MacrozoneService);
  zoneService = inject(ZoneService);

  getZoneName = (name: string) => {
    switch (name) {
      case 'environmental':
        return 'Ambiental';
      case 'special':
        return 'Especial';
      case 'urban':
        return 'Urbana';
      default:
        return 'Rural';
    }
  };

  zoneId: string | null = null;
  zone: SingleZoneResponse = {} as SingleZoneResponse;

  rule: RulesProps = {} as RulesProps;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private alertService: AlertService,
    private modalService: ModalService,
    private formBuilder: FormBuilder,
  ) {}

  ngOnInit(): void {
    this.orientationForm = this.formBuilder.group({
      environmentLicensing: [''],
      pgv: [''],
      parkingAndVehicles: [''],
      airCommand: [''],
      fireDepartment: [''],
      historicalHeritage: [''],
      healthLicensing: [''],
      others: [''],
    });

    this.route.params.subscribe((params) => {
      const name = params['name'];
      const acronym = params['acronym'];
      const description = params['description'];
      const hierarchy = params['hierarchy'];
      const macrozone = params['macrozone'];

      if (name && acronym && hierarchy) {
        const data: CreateZoneFeature[] = history.state.data;

        this.zone = {
          ...this.zone,
          name,
          acronym,
          description: description || '',
          hierarchy,
          macrozoneId: macrozone,
          features: data,
        };

        this.macrozoneService
          .getZonesById({ id: macrozone }, { page: 1, limit: 1 })
          .pipe(
            tap((response) => {
              this.zone.macrozone = response.zones[0].macrozone;

              this.routes = [
                {
                  label: 'Macrozonas',
                  link: { route: `/macrozonas` },
                },
                {
                  label: 'Zonas',
                  link: {
                    route: `/macrozonas/zonas/${this.getZoneName(
                      this.zone.macrozone.name,
                    ).toLowerCase()}/${this.zone.macrozoneId}`,
                  },
                },
                {
                  label: 'Adicionar zona',
                  link: {
                    route: `/macrozonas/zona/${this.zone.macrozoneId}/create`,
                    data: {
                      name: this.zone.name,
                      acronym: this.zone.acronym,
                      hierarchy: this.zone.hierarchy,
                      description: this.zone.description,
                    },
                  },
                },
                {
                  label: 'Adicionar orientações',
                  link: {
                    route: `/orientation/create/0;name=${this.zone.name};description=${this.zone.description};hierarchy=${this.zone.hierarchy};acronym=${this.zone.acronym};macrozone={${this.zone.macrozoneId}`,
                  },
                },
              ];
            }),
            catchError((error) => {
              console.error('Error fetching macrozones:', error);
              return throwError(() => error);
            }),
          )
          .subscribe();
      }

      const orientation: OrientationProps = history.state.orientation;
      const rule: RulesProps = history.state.rule;

      if (rule) this.rule = rule;

      if (orientation) {
        this.orientationForm.patchValue({
          environmentLicensing: orientation.environmentLicensing,
          pgv: orientation.pgv,
          parkingAndVehicles: orientation.parkingAndVehicles,
          airCommand: orientation.airCommand,
          fireDepartment: orientation.fireDepartment,
          historicalHeritage: orientation.historicalHeritage,
          healthLicensing: orientation.healthLicensing,
          others: orientation.others,
        });
      }
    });

    this.route.paramMap.subscribe((params) => {
      this.zoneId = params.get('zoneId');
      if (this.zoneId) {
        if (this.zoneId.length > 6) this.getZone();
      } else {
        this.router.navigate([`/`]);
      }
    });
  }

  getZone(): void {
    if (this.zoneId)
      this.zoneService
        .getZoneById(this.zoneId)
        .pipe(
          tap((response: SingleZoneResponse) => {
            this.zone = response;

            this.routes = [
              {
                label: 'Macrozonas',
                link: { route: `/macrozonas` },
              },
              {
                label: 'Zonas',
                link: {
                  route: `/macrozonas/zonas/${this.getZoneName(
                    this.zone.macrozone.name,
                  ).toLowerCase()}/${this.zone.macrozoneId}`,
                },
              },
              {
                label: 'Adicionar zona',
                link: {
                  route: `/macrozonas/zona/${this.zone.macrozoneId}/create`,
                  data: {
                    name: this.zone.name,
                    acronym: this.zone.acronym,
                    hierarchy: this.zone.hierarchy,
                    description: this.zone.description,
                  },
                },
              },
              {
                label: 'Adicionar orientações',
                link: {
                  route: `/orientation/create/0;name=${this.zone.name};description=${this.zone.description};hierarchy=${this.zone.hierarchy};acronym=${this.zone.acronym};macrozone={${this.zone.macrozoneId}`,
                },
              },
            ];
          }),
          catchError((error) => {
            console.error('Error fetching single zone:', error);
            return throwError(() => error);
          }),
        )
        .subscribe();
  }

  onSubmit() {
    this.loading = true;
    if (this.orientationForm.valid) {
      const orientationDto: OrientationProps = {
        zoneId: this.zoneId || '',
        environmentLicensing:
          this.orientationForm.controls['environmentLicensing'].value,
        pgv: this.orientationForm.controls['pgv'].value,
        parkingAndVehicles:
          this.orientationForm.controls['parkingAndVehicles'].value,
        airCommand: this.orientationForm.controls['airCommand'].value,
        fireDepartment: this.orientationForm.controls['fireDepartment'].value,
        historicalHeritage:
          this.orientationForm.controls['historicalHeritage'].value,
        healthLicensing: this.orientationForm.controls['healthLicensing'].value,
        others: this.orientationForm.controls['others'].value,
      };

      this.modalService.add({
        id: 'create-rule-success',
        icon: 'success',
        title: 'Sua orientação foi adicionada com sucesso.',
        actionLabel: 'Continuar',
      });
      this.router.navigate(
        [
          `/macrozonas/zona/${this.zone.macrozoneId}/create`,
          {
            name: this.zone.name,
            acronym: this.zone.acronym,
            hierarchy: this.zone.hierarchy,
            description: this.zone.description,
          },
        ],
        {
          state: {
            data: this.zone.features,
            orientation: orientationDto,
            rule: this.rule,
          },
        },
      );

      // this.orientationService
      //   .createOrientation(orientationDto)
      //   .pipe(
      //     tap((response) => {
      //       this.loading = false;
      //       this.alertService.add({
      //         id: 'create-orientation-success',
      //         type: 'success',
      //         message: 'Tivemos um problema ao tentar criar sua orientação.',
      //         top: 5,
      //         right: 1,
      //       });
      //     }),
      //     catchError((error) => {
      //       this.loading = false;
      //       console.error('Error creating orientation:', error);
      //       this.alertService.add({
      //         id: 'create-orientation-error',
      //         type: 'error',
      //         message: 'Tivemos um problema ao tentar criar sua pegunta.',
      //         top: 5,
      //         right: 1,
      //       });
      //       return throwError(() => error);
      //     })
      //   )
      //   .subscribe();
    } else {
      this.loading = false;
      this.markFormGroupTouched(this.orientationForm);
    }
  }

  public goBack = () => {
    const orientationDto: OrientationProps = {
      zoneId: this.zoneId || '',
      environmentLicensing:
        this.orientationForm.controls['environmentLicensing'].value,
      pgv: this.orientationForm.controls['pgv'].value,
      parkingAndVehicles:
        this.orientationForm.controls['parkingAndVehicles'].value,
      airCommand: this.orientationForm.controls['airCommand'].value,
      fireDepartment: this.orientationForm.controls['fireDepartment'].value,
      historicalHeritage:
        this.orientationForm.controls['historicalHeritage'].value,
      healthLicensing: this.orientationForm.controls['healthLicensing'].value,
      others: this.orientationForm.controls['others'].value,
    };

    this.router.navigate(
      [
        `/macrozonas/zona/${this.zone.macrozoneId}/create`,
        {
          name: this.zone.name,
          acronym: this.zone.acronym,
          hierarchy: this.zone.hierarchy,
          description: this.zone.description,
        },
      ],
      {
        state: {
          data: this.zone.features,
          rule: this.rule,
          orientation: orientationDto,
        },
      },
    );
  };

  markFormGroupTouched(formGroup: FormGroup) {
    Object.values(formGroup.controls).forEach((control) => {
      control.markAsTouched();

      if (control instanceof FormGroup) {
        this.markFormGroupTouched(control);
      }
    });
  }
}
