import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { FeasibilityService } from '../../../../services/feasibility.service';
import { ModalService } from '../../../../services/modal.service';

@Component({
  selector: 'app-feasibility-result',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatDividerModule,
    MatIconModule,
    NgxMaskDirective,
    NgxMaskPipe,
  ],
  templateUrl: './result.component.html',
  styleUrl: './result.component.scss',
  providers: [provideNgxMask()],
})
export class ResultFeasibilityComponent {
  @Input() feasibilityForm!: FormGroup;
  @Input() feasibilityId!: string;
  @Input() feasibilityResponse!: string;
  form!: FormGroup;
  @Output() emitForm = new EventEmitter<FormGroup>();
  @Input() result: boolean = false;

  loading = false;

  private feasibilityService = inject(FeasibilityService);

  constructor(
    private formBuilder: FormBuilder,
    private modalService: ModalService,
  ) {}

  downloadPdf() {
    this.loading = true;
    this.feasibilityService
      .downloadFeasibilityPdf(this.feasibilityId, 'is_original')
      .subscribe({
        next: (response) => {
          const blob = new Blob([response], { type: 'application/pdf' });
          const url = window.URL.createObjectURL(blob);
          window.open(url);
          this.loading = false;
        },
        error: (error) => {
          console.log(error);
        },
        complete: () => {
          this.loading = false;
        },
      });
  }
}
