import { CommonModule } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FeasibilityResponse } from '../../models/feasibility.model';
import { MatIcon } from '@angular/material/icon';
import { FilterBoxComponent } from '../../components/filter-box/filter-box.component';
import { NgIcon, provideIcons } from '@ng-icons/core';
import { FeasibilityService } from '../../services/feasibility.service';

import { AuthService } from '../../services/auth.service';
import {
  matArrowBackIos,
  matArrowForwardIos,
} from '@ng-icons/material-icons/baseline';
import { debounceTime, Subject } from 'rxjs';

@Component({
  selector: 'app-businesses',
  standalone: true,
  imports: [CommonModule, MatIcon, FilterBoxComponent, NgIcon],
  providers: [
    provideIcons({
      matArrowBackIos,
      matArrowForwardIos,
    }),
  ],
  templateUrl: './businesses.component.html',
  styleUrl: './businesses.component.scss',
})
export class BusinessesComponent implements OnInit {
  data: FeasibilityResponse[] = [];
  loading: boolean = true;

  page: number = 1;
  limit: number = 10;
  keyword: string = '';
  defaultOrderBy: string = 'desc';
  currentOrderField: string = '';

  pages: number[] = [];

  userDocument!: string | undefined;
  keywordSubject: Subject<string> = new Subject<string>();

  private feasibilityService = inject(FeasibilityService);
  private authService = inject(AuthService);

  constructor(private router: Router) {
    this.keywordSubject.pipe(debounceTime(1000)).subscribe(() => {
      this.loading = true;
      this.getFeasibilitys(
        this.page,
        this.limit,
        this.keyword,
        this.defaultOrderBy,
        this.currentOrderField,
      );
    });
  }

  ngOnInit(): void {
    this.userDocument = this.authService.getDecodedUser()?.cpf;

    if (this.userDocument)
      this.getFeasibilitys(
        this.page,
        this.limit,
        this.keyword,
        this.defaultOrderBy,
        this.currentOrderField,
      );
  }

  getFeasibilitys(
    page: number,
    limit: number,
    keyword: string,
    orderBy?: string,
    orderField?: string,
  ): void {
    if (this.userDocument)
      this.feasibilityService
        .getFeasibilityByUserDocument(
          this.userDocument,
          page,
          limit,
          keyword,
          orderBy,
          orderField,
        )
        .subscribe({
          next: (response) => {
            if (!response.result) {
              this.data = [];
              this.generatePagesByFounded(response.filter.founded);
              this.loading = false;
              return;
            }

            this.data = response.result;
            this.generatePagesByFounded(response.filter.founded);
            this.loading = false;
          },
          error: (err) => {
            console.error(err);
            this.loading = false;
          },
        });
  }

  handleChangeByResult(event: Event): void {
    const input = event.target as HTMLInputElement;
    this.keyword = input.value;
    this.loading = true;
    this.getFeasibilitys(
      this.page,
      this.limit,
      this.keyword,
      this.defaultOrderBy,
      this.currentOrderField,
    );
  }

  handleChangeSearch(event: Event): void {
    const input = event.target as HTMLInputElement;
    this.keyword = input.value;
    this.keywordSubject.next(this.keyword);
  }

  handleOrderBy(orderField: string): void {
    this.loading = true;
    this.defaultOrderBy = this.defaultOrderBy === 'asc' ? 'desc' : 'asc';
    this.currentOrderField = orderField;
    this.getFeasibilitys(
      this.page,
      this.limit,
      this.keyword,
      this.defaultOrderBy,
      orderField,
    );
  }

  getOrderIcon(field: string): string {
    if (this.currentOrderField && this.currentOrderField === field) {
      return this.defaultOrderBy === 'asc'
        ? 'keyboard_arrow_up'
        : 'keyboard_arrow_down'; // rotate 180deg
    } else {
      return 'unfold_more';
    }
  }

  getRisk(r: number | undefined): string {
    if (r !== undefined && r !== null) {
      switch (r) {
        case 0:
          return 'Requer Informações';
        case 1:
          return 'Risco I';
        case 2:
          return 'Risco II';
        case 3:
          return 'Risco III';
        default:
          return 'Competência Estadual';
      }
    } else return '-';
  }

  getStatus(s: string) {
    if (s === 'PENDING') return 'Em análise';

    switch (s) {
      case 'ANALYSIS':
        return 'Em análise';
      case 'PENDING':
        return 'Pendente';
      case 'PEN_RESPONSE':
        return 'Pendente de resposta';
      case 'APPROVED':
        return 'Aprovado';
      case 'REJECTED':
        return 'Recusado';
      case 'ARCHIVED':
        return 'Arquivado';
      case 'RETURNED':
        return 'Devolvido';
      default:
        return 'Em análise';
    }
  }

  getStatusColor(
    s: string,
    isBackground: boolean = false,
    isBorder: boolean = false,
  ) {
    switch (s) {
      case 'ANALYSIS':
        return isBackground
          ? 'bg-orange-status'
          : isBorder
            ? 'border-orange-status'
            : 'text-orange-status';
      case 'PENDING':
        return isBackground
          ? 'bg-orange-status'
          : isBorder
            ? 'border-orange-status'
            : 'text-orange-status';
      case 'PEN_RESPONSE':
        return isBackground
          ? 'bg-red-status'
          : isBorder
            ? 'border-red-status'
            : 'text-red-status';
      case 'APPROVED':
        return isBackground
          ? 'bg-approved-status'
          : isBorder
            ? 'border-approved-status'
            : 'text-approved-status';
      case 'REJECTED':
        return isBackground
          ? 'bg-rejected-status'
          : isBorder
            ? 'border-rejected-status'
            : 'text-rejected-status';
      case 'ARCHIVED':
        return isBackground
          ? 'bg-default-status'
          : isBorder
            ? 'border-default-status'
            : 'text-default-status';
      default:
        return isBackground
          ? 'bg-orange-status'
          : isBorder
            ? 'border-orange-status'
            : 'text-orange-status';
    }
  }

  generatePagesByFounded(total: number): void {
    const pages = Math.ceil(total / this.limit);
    this.pages = Array.from({ length: pages }, (_, i) => i + 1);
  }

  handleLimitChange(event: Event, key: string): void {
    const input = event.target as HTMLInputElement;

    if (key === 'limit') this.limit = Number(input.value);

    this.getFeasibilitys(
      this.page,
      this.limit,
      this.keyword,
      this.defaultOrderBy,
      this.currentOrderField,
    );
  }

  handleSetPage(page: number): void {
    this.page = page;
    this.getFeasibilitys(
      this.page,
      this.limit,
      this.keyword,
      this.defaultOrderBy,
      this.currentOrderField,
    );
  }

  handlePageChange(key: string): void {
    if (key === 'next') {
      this.page = this.page + 1;
    } else if (this.page > 1) {
      this.page = this.page - 1;
    }

    this.getFeasibilitys(
      this.page,
      this.limit,
      this.keyword,
      this.defaultOrderBy,
      this.currentOrderField,
    );
  }

  toReFeasibility(id?: string): void {
    this.router.navigate([`businesses/re-feasibility/${id}`]);
  }

  toDateString(date: string): string {
    return new Date(date).toLocaleDateString();
  }
}
