<div class="m-3 p-4">
  <h1 class="font-bold text-3xl">Meus empreendimentos</h1>
  <div class="card w-full flex flex-row items-start">
    <div class="w-1/4 flex flex-col justify-start items-start mr-4">
      <label [htmlFor]="" class="label">Status</label>
      <select class="w-full input" (change)="handleChangeByResult($event)">
        <option value="" selected>Selecione...</option>
        <option>Aprovado</option>
        <option>Recusado</option>
        <option>Requer Análise</option>
        <option>Projeto Especial</option>
      </select>
    </div>
    <div class="w-3/4 flex flex-col justify-start items-end">
      <div class="w-full flex flex-col justify-start items-start">
        <label [htmlFor]="" class="label">Pesquisar</label>
        <input
          class="w-full input mb-2"
          type="text"
          placeholder="Pesquisar por protocolo"
          (input)="handleChangeSearch($event)"
        />
      </div>
    </div>
  </div>

  <div class="card">
    <h1 class="font-bold text-2xl mb-4">Consultas de Viabilidade</h1>

    <table class="w-full">
      <thead>
        <tr>
          <th>
            <div class="w-full flex flex-row justify-center items-center p-2">
              <p class="text-sm font-medium">Protocolo da Consulta</p>
            </div>
          </th>
          <th>
            <div class="w-full flex flex-row justify-center items-center p-2">
              <p class="text-sm font-medium">Endereço do empreendimento</p>
            </div>
          </th>
          <th>
            <div class="w-full flex flex-row justify-center items-center p-2">
              <p class="text-sm font-medium">Data da consulta</p>
              <mat-icon
                class="text-sm ml-1 mt-1 text-black"
                [fontIcon]="getOrderIcon('createdAt')"
                (click)="handleOrderBy('createdAt')"
              ></mat-icon>
            </div>
          </th>
          <th>
            <div class="w-full flex flex-row justify-center items-center p-2">
              <p class="text-sm font-medium">Risco municipal</p>
              <mat-icon
                class="text-sm ml-1 mt-1 text-black"
                [fontIcon]="getOrderIcon('finalRisk')"
                (click)="handleOrderBy('finalRisk')"
              ></mat-icon>
            </div>
          </th>
          <th>
            <div class="w-full flex flex-row justify-center items-center p-2">
              <p class="text-sm font-medium">Resultado da consulta</p>
              <mat-icon
                class="text-sm ml-1 mt-1 text-black"
                [fontIcon]="getOrderIcon('feasibilityResult')"
                (click)="handleOrderBy('feasibilityResult')"
              ></mat-icon>
            </div>
          </th>
          <th>
            <div class="w-full flex flex-row justify-center items-center p-2">
              <p class="text-sm font-medium">Status</p>
              <mat-icon
                class="text-sm ml-1 mt-1 text-black"
                [fontIcon]="getOrderIcon('status')"
                (click)="handleOrderBy('status')"
              ></mat-icon>
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="let d of data"
          class="py-2 hover:bg-gray-100 hover:cursor-pointer"
          (click)="toReFeasibility(d._id)"
        >
          <td>
            <div class="w-full flex flex-row justify-center items-center p-2">
              <p class="text-sm font-normal uppercase">{{ d.protocol }}</p>
            </div>
          </td>
          <td>
            <div class="w-full flex flex-row justify-center items-center p-2">
              <p class="text-sm font-normal">
                {{ d.fullAddress }}
              </p>
            </div>
          </td>
          <td>
            <div class="w-full flex flex-row justify-center items-center p-2">
              <p class="text-sm font-normal">
                {{ d.createdAt ? toDateString(d.createdAt) : "-" }}
              </p>
            </div>
          </td>
          <td>
            <div class="w-full flex flex-row justify-center items-center p-2">
              <p class="text-sm font-normal">{{ getRisk(d.finalRisk) }}</p>
            </div>
          </td>
          <td>
            <div
              class="w-full flex flex-row justify-center items-center p-2 capitalize"
            >
              <p class="text-sm font-normal">{{ d.feasibilityResult }}</p>
            </div>
          </td>
          <td>
            <div class="w-full flex flex-row justify-center items-center p-2">
              <p class="text-sm font-normal">
                <button
                  [ngClass]="
                    d.status && d.status !== 'DEFAULT'
                      ? 'py-1 px-4 rounded-full ' +
                        getStatusColor(d.status, true) +
                        ' text-sm font-medium border border-solid ' +
                        getStatusColor(d.status, false, true) +
                        ' ' +
                        getStatusColor(d.status)
                      : d.feasibilityResult === 'aprovado' ||
                          d.feasibilityResult === 'recusado'
                        ? loading
                          ? 'text-slate-800 text-md font-medium cursor-not-allowed pointer-events-none underline'
                          : 'text-slate-800 hover:text-slate-700 text-md font-medium underline'
                        : loading
                          ? 'py-2 px-4 rounded-md bg-slate-800 text-white text-md font-medium cursor-not-allowed pointer-events-none'
                          : 'py-2 px-4 rounded-md bg-slate-800 text-white hover:bg-slate-700 text-md font-medium'
                  "
                >
                  {{
                    d.status && d.status !== "DEFAULT"
                      ? getStatus(d.status)
                      : d.feasibilityResult === "aprovado" ||
                          d.feasibilityResult === "recusado"
                        ? "Reanálise"
                        : "Solicitar reanálise"
                  }}
                </button>
              </p>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <div
      *ngIf="data.length === 0 && !loading"
      class="w-full flex flex-row justify-center py-4"
    >
      <div class="flex p-3 flex-row items-center">
        <p class="text-sm font-medium">Nenhum registro encontrado.</p>
      </div>
    </div>

    <div *ngIf="loading" class="w-full flex flex-row justify-center py-4">
      <div class="flex p-3 flex-row items-center">
        <ng-icon class="mr-3 animate-spin" name="matSync"></ng-icon>
        <p class="text-sm font-medium">Carregando dados...</p>
      </div>
    </div>

    <div *ngIf="!loading" class="flex flex-row justify-between mt-4">
      <select
        class="mt-3 p-1 rounded-md w-16"
        (input)="handleLimitChange($event, 'limit')"
      >
        <option value="10" [selected]="limit === 10">10</option>
        <option value="20" [selected]="limit === 20">20</option>
        <option value="50" [selected]="limit === 50">50</option>
      </select>

      <nav
        class="mt-3 flex flex-row justify-end"
        aria-label="Page navigation example"
      >
        <ul class="flex items-center -space-x-px h-10 text-base">
          <li>
            <button
              [ngClass]="
                page > 1
                  ? 'flex cursor-pointer items-center justify-center px-4 h-10 ms-0 leading-tight text-gray-500 bg-white border border-e-0 border-gray-300 rounded-s-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white'
                  : 'opacity-40 pointer-events-none cursor-not-allowed flex cursor-pointer items-center justify-center px-4 h-10 ms-0 leading-tight text-gray-500 bg-white border border-e-0 border-gray-300 rounded-s-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white'
              "
              (click)="handlePageChange('previous')"
            >
              <span class="sr-only">Previous</span>
              <ng-icon name="matArrowBackIos"></ng-icon>
            </button>
          </li>
          <button
            *ngFor="let p of pages"
            [ngClass]="
              page === p
                ? 'flex cursor-pointer items-center justify-center px-4 h-10 leading-tight border border-gray-300 bg-gray-200 text-gray-800 pointer-events-none'
                : 'flex cursor-pointer items-center justify-center px-4 h-10 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700'
            "
            (click)="handleSetPage(p)"
          >
            {{ p }}
          </button>
          <li>
            <button
              [ngClass]="
                pages.length > 1
                  ? 'flex cursor-pointer items-center justify-center px-4 h-10 ms-0 leading-tight text-gray-500 bg-white border border-e-0 border-gray-300 rounded-s-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white'
                  : 'opacity-40 pointer-events-none cursor-not-allowed flex cursor-pointer items-center justify-center px-4 h-10 ms-0 leading-tight text-gray-500 bg-white border border-e-0 border-gray-300 rounded-s-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white'
              "
              (click)="handlePageChange('next')"
            >
              <span class="sr-only">Next</span>
              <ng-icon name="matArrowForwardIos"></ng-icon>
            </button>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</div>
