import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import {
  QuestionProps,
  QuestionResponse,
  QuestionsUpdateBulk,
} from '../models/questions.models';
import { CnaeFilter } from '../models/cnae.models';

@Injectable({
  providedIn: 'root',
})
export class QuestionsService {
  private httpService = inject(HttpClient);
  private url = `${environment.urlApi}/question`;

  constructor() {}

  getQuestions(filter: CnaeFilter): Observable<QuestionResponse> {
    return this.httpService.get<QuestionResponse>(
      `${this.url}?page=${filter.page}&limit=${filter.limit}&keyword=${filter.keyword}`
    );
  }

  createQuestion(dto: QuestionProps[]): Observable<QuestionProps> {
    return this.httpService.post<QuestionProps>(`${this.url}`, {
      questions: dto,
    });
  }

  updateQuestion(id:string, data:QuestionProps): Observable<QuestionResponse> {
    return this.httpService.put<QuestionResponse>(`${this.url}/${id}`, data);
  }

  updateQuestionsBulk(
    dto: QuestionsUpdateBulk
  ): Observable<QuestionsUpdateBulk> {
    return this.httpService.put<QuestionsUpdateBulk>(
      `${this.url}/update-in-bulk`,
      dto
    );
  }

  deleteQuestion(id: string): Observable<void> {
    return this.httpService.delete<void>(`${this.url}/delete/${id}`);
  }
}
