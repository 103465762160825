import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Output } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';

@Component({
  selector: 'app-approve',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatDividerModule,
    MatIconModule,
    NgxMaskDirective,
    NgxMaskPipe,
  ],
  templateUrl: './approve.component.html',
  styleUrl: './approve.component.scss',
  providers: [provideNgxMask()],
})
export class ApproveComponent {
  @Output() justification: EventEmitter<string> = new EventEmitter<string>();

  constructor() {}

  handleJustificationChange(ev: Event): void {
    const target = ev.target as HTMLInputElement;
    this.justification.emit(target.value);
  }
}
