import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { MatDialogContent, MatDialogActions, MatDialogClose, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { WarningBigIconComponent } from '../../../icons/modal/warning-big-icon/warning-big-icon.component';

export interface DeleteModalComponentProps {
  title: string;
  subtitle?: string;
  content: string[];
}

@Component({
  selector: 'app-delete-modal',
  standalone: true,
  imports: [
    WarningBigIconComponent,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    CommonModule
  ],
  templateUrl: './delete-modal.component.html',
  styleUrl: './delete-modal.component.scss'
})
export class DeleteModalComponent {
  readonly data = inject<DeleteModalComponentProps>(MAT_DIALOG_DATA);

  confirm(): boolean {
    return true;
  }
}
