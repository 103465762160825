<app-alert></app-alert>
<section class="h-full bg-blue-950">
  <div
    class="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0"
  >
    <a
      href="#"
      class="flex items-center mb-6 text-2xl font-semibold text-white"
    >
      <div class="rounded-full mr-5 bg-white">
        <img class="w-16 h-16" src="assets/images/HUBlic.png" alt="logo" />
      </div>
      Hublic
    </a>
    <div
      class="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700"
    >
      <div class="w-full flex flex-col justify-start items-start p-6">
        <h1
          class="text-md font-bold leading-tight tracking-tight text-gray-900 dark:text-white"
        >
          Insira seu E-mail para recuperar seu acesso
        </h1>
        <form
          class="w-full mt-4"
          [formGroup]="forgetPasswordForm"
          (ngSubmit)="onSubmit()"
        >
          <div>
            <label
              for="email"
              class="text-sm font-medium text-gray-900 dark:text-white"
              >E-mail</label
            >
            <input
              formControlName="email"
              type="email"
              id="email"
              class="input-text"
              placeholder="example@mail.com"
              required=""
            />
            <div
              class="text-xs font-medium text-red-500 mt-1"
              *ngIf="
                (forgetPasswordForm.controls['email'].touched ||
                  forgetPasswordForm.controls['email'].dirty) &&
                forgetPasswordForm.controls['email'].errors &&
                forgetPasswordForm.controls['email'].errors['required']
              "
            >
              E-mail é obrigatório.
            </div>
            <div
              class="text-xs font-medium text-red-500 mt-1"
              *ngIf="
                (forgetPasswordForm.controls['email'].touched ||
                  forgetPasswordForm.controls['email'].dirty) &&
                forgetPasswordForm.controls['email'].errors &&
                forgetPasswordForm.controls['email'].errors['email']
              "
            >
              E-mail no formato inválido.
            </div>
          </div>
          <div
            class="w-full flex flex-col md:flex-row justify-end items-center mt-6"
          >
            <button type="button" class="btn-secondary" (click)="push('/')">
              VOLTAR
            </button>
            <button type="submit" class="btn">RECUPERAR</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</section>
