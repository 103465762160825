/* eslint-disable @angular-eslint/use-lifecycle-interface */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output, inject } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { BuildIconComponent } from '../../../../icons/cnaes/build-icon/build-icon.component';
import { MatIconModule } from '@angular/material/icon';
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';
import * as L from 'leaflet';
import { FeasibilityService } from '../../../../services/feasibility.service';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  tap,
  throwError,
} from 'rxjs';
import { Address } from '../../../../models/feasibility.model';
import { ViacepService } from '../../../../services/viacep.service';

@Component({
  selector: 'app-feasibility-address',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    BuildIconComponent,
    MatIconModule,
    NgxMaskDirective,
  ],
  providers: [provideNgxMask()],
  templateUrl: './address.component.html',
  styleUrl: './address.component.scss',
})
export class AddressFeasibilityComponent {
  @Input() feasibilityForm!: FormGroup;
  @Input() step!: number;
  @Output() setStep = new EventEmitter<number>();
  form!: FormGroup;
  @Output() emitForm = new EventEmitter<FormGroup>();

  public tab: number = 0;

  private map: L.Map | undefined;
  private drawControl: L.Control.Draw | undefined;
  private drawnItems: L.FeatureGroup | undefined;
  private highlightStyle: L.PathOptions = {};

  private _showMap = false;
  private mapInitialized = false;

  address: Address = {} as Address;
  loading: boolean = false;

  private feasibilityService = inject(FeasibilityService);

  handleChangeTab = (tab: number): number => {
    this.form.reset();

    this.tab = tab;
    if (tab === 2) this.showMap = true;
    else this.showMap = false;
    return this.tab;
  };

  get showMap(): boolean {
    return this._showMap;
  }

  set showMap(value: boolean) {
    this._showMap = value;
    if (value && !this.mapInitialized) {
      setTimeout(() => this.initMap(), 0);
      this.mapInitialized = true;
    } else {
      this.mapInitialized = false;
    }
  }

  constructor(
    private formBuilder: FormBuilder,
    private viaCepService: ViacepService,
  ) {}

  ngOnInit(): void {
    this.createForm();
    this.getForm();
    this.setupPostalcodeListener();
  }

  getForm(): void {
    const form = this.feasibilityForm.get('address');
    if (form) {
      this.form.patchValue(form.value);
    }
  }

  createForm(): void {
    this.form = this.formBuilder.group({
      postalcode: new FormControl(null, Validators.required),
      address: new FormControl(null, Validators.required),
      number: new FormControl(null, Validators.required),
      complement: new FormControl(null),
      neighborhood: new FormControl(null, Validators.required),
      latitude: new FormControl(null),
      longitude: new FormControl(null),
    });
  }

  setupPostalcodeListener(): void {
    this.form
      .get('postalcode')
      ?.valueChanges.pipe(debounceTime(1000), distinctUntilChanged())
      .subscribe((value) => {
        if (value && value.length === 9) {
          this.lookupCep(value);
        }
      });
  }

  lookupCep(cep: string): void {
    if (this.tab === 0)
      this.viaCepService.lookupCep(cep).subscribe({
        next: (data: any) => {
          if (data.erro) {
            console.error('CEP não encontrado');
            return;
          }
          this.form.patchValue({
            address: data.logradouro,
            neighborhood: data.bairro,
            latitude: null,
            longitude: null,
          });
        },
        error: (error) => {
          console.error('Erro ao consultar o CEP:', error);
        },
      });
  }

  private initMap(): void {
    L.drawLocal.draw = {
      toolbar: {
        actions: {
          title: 'Cancelar desenho',
          text: 'Cancelar',
        },
        finish: {
          title: 'Terminar desenho',
          text: 'Terminar',
        },
        undo: {
          title: 'Deletar último ponto desenhado',
          text: 'Deletar último ponto',
        },
        buttons: {
          polyline: 'Desenhar uma linha poligonal',
          polygon: 'Desenhar um polígono',
          rectangle: 'Desenhar um retângulo',
          circle: 'Desenhar um círculo',
          marker: 'Colocar um marcador',
          circlemarker: 'Colocar um marcador circular',
        },
      },
      handlers: {
        circle: {
          tooltip: {
            start: 'Clique e arraste para desenhar um círculo.',
          },
          radius: 'Raio',
        },
        marker: {
          tooltip: {
            start: 'Clique no mapa para colocar um marcador.',
          },
        },
        polygon: {
          tooltip: {
            start: 'Clique para começar a desenhar uma forma.',
            cont: 'Clique para continuar desenhando a forma.',
            end: 'Clique no primeiro ponto para fechar esta forma.',
          },
        },
        polyline: {
          error: '<strong>Erro:</strong> bordas da forma não podem se cruzar!',
          tooltip: {
            start: 'Clique para começar a desenhar uma linha.',
            cont: 'Clique para continuar a linha.',
            end: 'Clique no último ponto para terminar a linha.',
          },
        },
        rectangle: {
          tooltip: {
            start: 'Clique e arraste para desenhar um retângulo.',
          },
        },
        simpleshape: {
          tooltip: {
            end: 'Solte o mouse para terminar de desenhar.',
          },
        },
        circlemarker: {
          tooltip: {
            start: 'Clique no mapa para colocar um marcador circular.',
          },
        },
      },
    };

    L.drawLocal.edit = {
      toolbar: {
        actions: {
          save: {
            title: 'Salvar alterações',
            text: 'Salvar',
          },
          cancel: {
            title: 'Cancelar edição, descarta todas as alterações',
            text: 'Cancelar',
          },
          clearAll: {
            title: 'Limpar todas as marcações',
            text: 'Limpar tudo',
          },
        },
        buttons: {
          edit: 'Editar marcações',
          editDisabled: 'Não há marcações para editar',
          remove: 'Deletar marcações',
          removeDisabled: 'Não há marcações para deletar',
        },
      },
      handlers: {
        edit: {
          tooltip: {
            text: 'Arraste a marcação para editar.',
            subtext: 'Clique em cancelar para desfazer alterações.',
          },
        },
        remove: {
          tooltip: {
            text: 'Clique em um desenho para remover',
          },
        },
      },
    };

    this.map = L.map('mapa', {
      center: [-3.918639, -38.378295],
      zoom: 15,
    });

    const tiles = L.tileLayer(
      'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      {
        maxZoom: 18,
        minZoom: 3,
      },
    );

    tiles.addTo(this.map);

    this.highlightStyle = {
      color: '#9b1d41',
      weight: 3,
      opacity: 0.6,
      fillOpacity: 0.65,
      fillColor: '#9b1d41',
    };

    this.drawnItems = new L.FeatureGroup().setStyle(this.highlightStyle);
    this.map.addLayer(this.drawnItems);

    this.drawControl = new L.Control.Draw({
      draw: {
        circle: false,
        circlemarker: false,
        marker: {
          icon: L.icon({
            className: 'marker-icon',
            iconSize: [32, 32],
            iconAnchor: [16, 32],
            iconUrl: 'assets/images/map/pin.png',
          }),
          zIndexOffset: 1000,
        },
        polyline: false,
        rectangle: false,
        polygon: false,
      },
      edit: {
        featureGroup: this.drawnItems,
        remove: true,
      },
    });

    this.map.addControl(this.drawControl);

    this.map.on(L.Draw.Event.CREATED, (e: any) => {
      const type = e.layerType;
      const layer = e.layer;

      if (type === 'marker') {
        this.drawnItems?.clearLayers();
        this.drawnItems?.addLayer(layer);

        const lat = layer.getLatLng().lat;
        const lng = layer.getLatLng().lng;

        this.feasibilityService
          .getAddressByCoordinates({ lat, lng })
          .pipe(
            tap((response) => {
              this.form.patchValue({
                postalcode: response.address.postcode,
                address: response.address.road,
                neighborhood: response.address.suburb,
                latitude: Number(lat),
                longitude: Number(lng),
              });
            }),
            catchError((error) => {
              console.log(error);
              return throwError(() => error);
            }),
          )
          .subscribe();
      }
    });
  }

  formControl(control: string): AbstractControl | null {
    return this.form.get(control);
  }

  getFormValue(control: string): string | number {
    return this.formControl(control)?.value || '';
  }

  onSubmit() {
    this.loading = true;
    if (this.form.valid) {
      const lat = this.form.get('latitude')?.value;
      const lng = this.form.get('longitude')?.value;

      if (!lat && !lng && this.tab === 0) {
        const address = this.form.get('address')?.value;
        const number = this.form.get('number')?.value;
        const neighborhood = this.form.get('neighborhood')?.value;
        const complement = this.form.get('complement')?.value;
        const postalcode = this.form.get('postalcode')?.value;

        this.feasibilityService
          .getCoordinatesByAddress(
            `${address}, ${number}, ${neighborhood} ${
              complement ? complement + ',' : ''
            }, ${postalcode.replace(/[^0-9]/g, '')}`,
          )
          .pipe(
            tap((response) => {
              this.form.patchValue({
                latitude: Number(response[0].lat),
                longitude: Number(response[0].lon),
              });
              this.setStep.emit(4);
              this.emitForm.emit(this.form);
              this.loading = false;
            }),
            catchError((error) => {
              console.log(error);
              this.loading = false;
              return throwError(() => error);
            }),
          )
          .subscribe();
      } else {
        this.setStep.emit(4);
        this.emitForm.emit(this.form);
      }
    } else {
      this.loading = false;
      this.form.markAllAsTouched();
    }
  }
}
