<!-- eslint-disable @angular-eslint/template/label-has-associated-control -->
<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<!-- Questions-->
<div
  *ngIf="showQuestions"
  class="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50"
>
  <div
    class="relative top-20 mx-auto p-4 border w-[70%] max-h-[80%] overflow-y-auto max-w-screen-lg shadow-lg rounded bg-white flex flex-col justify-start items-start"
  >
    <h3 class="text-lg font-medium">Definir perguntas</h3>
    <p class="text-sm text-gray-700">
      Defina as perguntas que os usuários deverão responder para o cálculo de
      risco. <strong>Todas as respostas devem ser “Sim” ou “Não”.</strong>
    </p>
    <form
      *ngIf="isNewQuestion"
      class="w-full flex flex-col"
      [formGroup]="questionForm"
      (ngSubmit)="onSubmit()"
    >
      <div
        class="w-full flex flex-col justify-start items-start p-4 rounded bg-gray-100 mt-4"
      >
        <div class="w-full flex flex-col justify-center items-center">
          <p class="w-full text-sm font-normal text-left">Pergunta*</p>
          <input
            formControlName="description"
            class="appearance-none rounded w-full py-2 px-3 text-gray-700 leading-tight outline-none h-9"
            type="text"
            placeholder="Exemplo de pergunta inserida?"
            required
          />
          <div
            class="w-full flex flex-row justify-start text-xs font-medium text-red-500 mt-1"
            *ngIf="
              (questionForm.controls['description'].touched ||
                questionForm.controls['description'].dirty) &&
              questionForm.controls['description'].errors &&
              questionForm.controls['description'].errors['required']
            "
          >
            Pergunta é obrigatório.
          </div>
        </div>

        <div class="w-full grid grid-cols-3 gap-4 mt-6">
          <div class="w-full flex flex-col justify-start items-center">
            <p class="w-full text-sm font-normal text-left">
              Se SIM, definir risco como*
            </p>
            <select
              formControlName="riskIfYes"
              class="w-full text-sm text-left font-medium text-gray-900 dark:text-white rounded"
            >
              <option [ngValue]="null" selected disabled>
                Selecione uma opção
              </option>
              <option [ngValue]="0">Requer Informações</option>
              <option [ngValue]="1">Risco I</option>
              <option [ngValue]="2">Risco II</option>
              <option [ngValue]="3">Risco III</option>
              <option [ngValue]="4">Competência Estadual</option>
            </select>
            <div
              class="w-full flex flex-row justify-start text-xs font-medium text-red-500 mt-1"
              *ngIf="
                (questionForm.controls['riskIfYes'].touched ||
                  questionForm.controls['riskIfYes'].dirty) &&
                questionForm.controls['riskIfYes'].errors &&
                questionForm.controls['riskIfYes'].errors['required']
              "
            >
              Risco se SIM é obrigatório.
            </div>
          </div>
          <div class="w-full flex flex-col justify-start items-center">
            <p class="w-full text-sm font-normal text-left">
              Se NÃO, definir risco como*
            </p>
            <select
              formControlName="riskIfNo"
              class="w-full text-sm text-left font-medium text-gray-900 dark:text-white rounded"
            >
              <option [ngValue]="null" selected disabled>
                Selecione uma opção
              </option>
              <option [ngValue]="0">Requer Informações</option>
              <option [ngValue]="1">Risco I</option>
              <option [ngValue]="2">Risco II</option>
              <option [ngValue]="3">Risco III</option>
              <option [ngValue]="4">Competência Estadual</option>
            </select>
            <div
              class="w-full flex flex-row justify-start text-xs font-medium text-red-500 mt-1"
              *ngIf="
                (questionForm.controls['riskIfNo'].touched ||
                  questionForm.controls['riskIfNo'].dirty) &&
                questionForm.controls['riskIfNo'].errors &&
                questionForm.controls['riskIfNo'].errors['required']
              "
            >
              Risco se NÃO é obrigatório.
            </div>
          </div>
        </div>
      </div>

      <div class="w-full flex flex-row justify-end items-center mt-6">
        <button
          [ngClass]="
            loadingQuestion
              ? 'btn btn-secondary text-sm opacity-40 cursor-not-allowed pointer-events-none'
              : 'btn btn-secondary text-sm'
          "
          (click)="showQuestions = false"
        >
          Cancelar
        </button>
        <button
          type="submit"
          [ngClass]="
            loadingQuestion
              ? 'btn btn-primary text-sm ml-2 opacity-40 cursor-not-allowed pointer-events-none'
              : 'btn btn-primary text-sm ml-2'
          "
        >
          {{
            loadingQuestion ? "Salvando informações..." : "Salvar informações"
          }}
        </button>
      </div>
    </form>

    <ng-container *ngIf="!isNewQuestion">
      <form class="w-full">
        <div
          *ngFor="let d of toEditData.questions; let index = index"
          class="w-full flex flex-col justify-start items-start p-4 rounded bg-gray-100 mt-4"
        >
          <div class="w-full flex flex-col justify-center items-center">
            <p class="w-full text-sm font-normal text-left">Pergunta*</p>
            <input
              [ngClass]="
                d.isEditing
                  ? 'appearance-none rounded w-full py-2 px-3 text-gray-700 leading-tight outline-none h-9'
                  : 'appearance-none rounded w-full py-2 px-3 text-gray-700 leading-tight outline-none h-9 opacity-40 cursor-not-allowed pointer-events-none'
              "
              type="text"
              placeholder="Exemplo de pergunta inserida?"
              [value]="d.description"
              (input)="handleEditQuestion(index, 'description', $event)"
            />
          </div>
          <div
            class="w-full flex flex-row justify-start text-xs font-medium text-red-500 mt-1"
            *ngIf="titleError"
          >
            Pergunta é obrigatório.
          </div>

          <div class="w-full grid grid-cols-3 gap-4 mt-6">
            <div class="w-full flex flex-col justify-start items-center">
              <p class="w-full text-sm font-normal text-left">
                Se SIM, definir risco como*
              </p>
              <select
                [ngClass]="
                  d.isEditing
                    ? 'w-full text-sm text-left font-medium text-gray-900 dark:text-white rounded'
                    : 'w-full text-sm text-left font-medium text-gray-900 dark:text-white rounded opacity-40 cursor-not-allowed pointer-events-none'
                "
                (input)="handleEditQuestion(index, 'riskIfYes', $event)"
              >
                <option value="null" selected disabled>
                  Selecione uma opção
                </option>
                <option value="0" [selected]="d.riskIfYes === 0">
                  Requer Informações
                </option>
                <option value="1" [selected]="d.riskIfYes === 1">
                  Risco I
                </option>
                <option value="2" [selected]="d.riskIfYes === 2">
                  Risco II
                </option>
                <option value="3" [selected]="d.riskIfYes === 3">
                  Risco III
                </option>
                <option value="4" [selected]="d.riskIfYes === 4">
                  Competência Estadual
                </option>
              </select>

              <div
                class="w-full flex flex-row justify-start text-xs font-medium text-red-500 mt-1"
                *ngIf="riskYesError"
              >
                Risco se SIM é obrigatório.
              </div>
            </div>
            <div class="w-full flex flex-col justify-start items-center">
              <p class="w-full text-sm font-normal text-left">
                Se NÃO, definir risco como*
              </p>
              <select
                [ngClass]="
                  d.isEditing
                    ? 'w-full text-sm text-left font-medium text-gray-900 dark:text-white rounded'
                    : 'w-full text-sm text-left font-medium text-gray-900 dark:text-white rounded opacity-40 cursor-not-allowed pointer-events-none'
                "
                (input)="handleEditQuestion(index, 'riskIfNo', $event)"
              >
                <option value="null" selected disabled>
                  Selecione uma opção
                </option>
                <option value="0" [selected]="d.riskIfNo === 0">
                  Requer Informações
                </option>
                <option value="1" [selected]="d.riskIfNo === 1">Risco I</option>
                <option value="2" [selected]="d.riskIfNo === 2">
                  Risco II
                </option>
                <option value="3" [selected]="d.riskIfNo === 3">
                  Risco III
                </option>
                <option value="4" [selected]="d.riskIfNo === 4">
                  Competência Estadual
                </option>
              </select>

              <div
                class="w-full flex flex-row justify-start text-xs font-medium text-red-500 mt-1"
                *ngIf="riskNoError"
              >
                Risco se NÃO é obrigatório.
              </div>
            </div>
          </div>

          <div
            *ngIf="!isNewQuestion && !d.isEditing"
            class="w-full flex flex-row justify-end items-center mt-6"
          >
            <app-delete-icon
              [width]="20"
              [height]="20"
              [color]="'#E64D4D'"
            ></app-delete-icon>
            <p
              class="text-sm font-normal text-left ml-1 text-[#E64D4D] mr-6 hover:cursor-pointer"
              (click)="handleDeleteQuestion(index)"
            >
              Deletar
            </p>
            <!-- <app-edit-icon [width]="20" [height]="20"></app-edit-icon> -->
            <!-- <p
              class="text-sm font-normal text-left ml-1 hover:cursor-pointer"
              (click)="handleEditingQuestion(index)"
            >
              Editar
            </p> -->
          </div>

          <div
            *ngIf="!isNewQuestion && d.isEditing"
            class="w-full flex flex-row justify-end items-center mt-6"
          >
            <p
              class="text-sm font-normal text-left ml-1 text-[#E64D4D] mr-6 hover:cursor-pointer"
              (click)="handleEditingQuestion(index)"
            >
              Cancelar
            </p>
            <app-checked-icon [width]="20" [height]="20"></app-checked-icon>
            <p
              class="text-sm font-normal text-left ml-1 hover:cursor-pointer"
              (click)="handleSave(index)"
            >
              Salvar
            </p>
          </div>
        </div>
      </form>
    </ng-container>

    <div
      *ngIf="!isNewQuestion"
      class="w-full flex flex-col justify-center items-center p-4 rounded bg-gray-100 mt-4"
    >
      <button class="btn btn-primary text-sm" (click)="handleNewQuestion()">
        Nova pergunta
      </button>
      <div class="w-1/5 flex flex-row justify-center items-center mt-4 mb-4">
        <div class="w-full h-[1px] bg-gray-400 mr-2"></div>
        <p class="text-sm font-normal text-gray-400">OU</p>
        <div class="w-full h-[1px] bg-gray-400 ml-2"></div>
      </div>
      <p
        class="text-sm font-medium hover:cursor-pointer"
        (click)="handleShowQuestionsBank()"
      >
        Selecionar do Banco de Perguntas
      </p>
    </div>

    <div
      *ngIf="!isNewQuestion"
      class="w-full flex flex-row justify-end items-center mt-6"
    >
      <button class="btn btn-secondary text-sm" (click)="showQuestions = false">
        Cancelar
      </button>
      <button
        [ngClass]="
          loadingQuestion || titleError || riskYesError || riskNoError
            ? 'btn btn-primary text-sm ml-2 opacity-40 cursor-not-allowed pointer-events-none'
            : 'btn btn-primary text-sm ml-2'
        "
        (click)="handleSaveQuestions()"
      >
        {{ loadingQuestion ? "Salvando informações..." : "Salvar informações" }}
      </button>
    </div>
  </div>
</div>

<!-- Questions FIM -->

<!--
  Questions Bank
-->
<div
  *ngIf="showQuestionsBank"
  class="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50"
>
  <div
    class="relative top-20 mx-auto p-4 border w-[70%] max-w-screen-lg max-h-[80%] overflow-y-auto shadow-lg rounded bg-white flex flex-col justify-start items-start"
  >
    <h3 class="text-lg font-medium">Banco de perguntas</h3>
    <p class="text-sm text-gray-700">
      Selecione uma ou mais perguntas salvas para adicioná-las ao cálculo de
      risco da atividade (CNAE).
    </p>

    <div class="w-full">
      <app-filter-box
        [showInclude]="true"
        [placeholder]="'Pesquisar por código ou palavra-chave'"
        (filter)="filterQuestions($event)"
        [loading]="loading"
      >
        <div class="mb-3">
          <div class="flex flex-col">
            <label class="label">Pesquisar perguntas</label>
          </div>
        </div>
      </app-filter-box>
    </div>

    <table class="w-full">
      <thead>
        <tr>
          <th>
            <div
              class="w-full flex flex-row justify-center items-center p-2 hover:cursor-pointer"
            >
              <p class="text-sm font-medium">Seleção</p>
              <mat-icon
                class="text-sm ml-1 mt-1 text-black"
                fontIcon="unfold_more"
              ></mat-icon>
            </div>
          </th>
          <th>
            <div class="w-full flex flex-row justify-center items-center p-2">
              <p class="text-sm font-medium">Código</p>
              <mat-icon
                class="text-sm ml-1 mt-1 text-black"
                fontIcon="unfold_more"
              ></mat-icon>
            </div>
          </th>
          <th>
            <div class="w-full flex flex-row justify-center items-center p-2">
              <p class="text-sm font-medium">Pergunta*</p>
              <mat-icon
                class="text-sm ml-1 mt-1 text-black"
                fontIcon="unfold_more"
              ></mat-icon>
            </div>
          </th>
          <th>
            <div class="w-full flex flex-row justify-center items-center p-2">
              <p class="text-sm font-medium">Se SIM*</p>
              <mat-icon
                class="text-sm ml-1 mt-1 text-black"
                fontIcon="unfold_more"
              ></mat-icon>
            </div>
          </th>
          <th>
            <div class="w-full flex flex-row justify-center items-center p-2">
              <p class="text-sm font-medium">Se NÃO*</p>
              <mat-icon
                class="text-sm ml-1 mt-1 text-black"
                fontIcon="unfold_more"
              ></mat-icon>
            </div>
          </th>
        </tr>
      </thead>
      <tbody *ngIf="!loadingQuestions">
        <tr *ngFor="let d of questions; let index = index" class="py-2">
          <td>
            <div class="w-full flex flex-row justify-center items-center p-2">
              <input
                type="checkbox"
                [ngClass]="
                  d.exists
                    ? 'opacity-40 cursor-not-allowed pointer-events-none'
                    : ''
                "
                (change)="handleSelectQuestion(d.id, $event)"
                [checked]="d.exists"
              />
            </div>
          </td>
          <td>
            <div class="w-full flex flex-row justify-center items-center p-2">
              <p class="text-sm font-normal">{{ d.code }}</p>
            </div>
          </td>
          <td>
            <div class="w-full flex flex-row justify-center items-center p-2">
              <p class="text-sm font-normal uppercase">
                {{ d.description }}
              </p>
            </div>
          </td>
          <td>
            <div class="w-full flex flex-row justify-center items-center p-2">
              <p class="text-sm font-normal">{{ getRisk(d.riskIfYes) }}</p>
            </div>
          </td>
          <td>
            <div class="w-full flex flex-row justify-center items-center p-2">
              <p class="text-sm font-normal">{{ getRisk(d.riskIfNo) }}</p>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <div
      *ngIf="questions.length === 0 && !loadingQuestions"
      class="w-full flex flex-row justify-center py-4"
    >
      <div class="flex p-3 flex-row items-center">
        <p class="text-sm font-medium">Nenhum registro encontrado.</p>
      </div>
    </div>

    <div
      *ngIf="loadingQuestions"
      class="w-full flex flex-row justify-center py-4"
    >
      <div class="flex p-3 flex-row items-center">
        <ng-icon class="mr-3 animate-spin" name="matSync"></ng-icon>
        <p class="text-sm font-medium">Carregando dados...</p>
      </div>
    </div>

    <div
      *ngIf="!loadingQuestions"
      class="w-full flex flex-row justify-between mt-4"
    >
      <select
        class="mt-3 p-1 rounded-md w-16"
        (input)="handleLimitChange($event, 'limit', 'questions')"
      >
        <option value="10" [selected]="limitQuestions === 10">10</option>
        <option value="20" [selected]="limitQuestions === 20">20</option>
        <option value="50" [selected]="limitQuestions === 50">50</option>
      </select>

      <nav
        class="mt-3 flex flex-row justify-end"
        aria-label="Page navigation example"
      >
        <ul class="flex items-center -space-x-px h-10 text-base">
          <li>
            <div
              [ngClass]="
                pageQuestions > 1
                  ? 'flex cursor-pointer items-center justify-center px-4 h-10 ms-0 leading-tight text-gray-500 bg-white border border-e-0 border-gray-300 rounded-s-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white'
                  : 'opacity-40 pointer-events-none cursor-not-allowed flex cursor-pointer items-center justify-center px-4 h-10 ms-0 leading-tight text-gray-500 bg-white border border-e-0 border-gray-300 rounded-s-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white'
              "
              (click)="handlePageChange('previous', 'questions')"
            >
              <span class="sr-only">Previous</span>
              <ng-icon name="matArrowBackIos"></ng-icon>
            </div>
          </li>
          <div
            class="flex cursor-pointer items-center justify-center px-4 h-10 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
          >
            {{ page }}
          </div>
          <li>
            <div
              class="flex cursor-pointer items-center justify-center px-4 h-10 leading-tight text-gray-500 bg-white border border-gray-300 rounded-e-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
              (click)="handlePageChange('next', 'questions')"
            >
              <span class="sr-only">Next</span>
              <ng-icon name="matArrowForwardIos"></ng-icon>
            </div>
          </li>
        </ul>
      </nav>
    </div>

    <div class="w-full flex flex-row justify-end items-center mt-6">
      <button
        class="btn btn-secondary text-sm"
        (click)="handleShowQuestionsBank()"
      >
        Cancelar
      </button>
      <button
        [ngClass]="
          loading
            ? 'btn btn-primary text-sm ml-2 opacity-40 cursor-not-allowed pointer-events-none'
            : 'btn btn-primary text-sm ml-2'
        "
        (click)="handleAddSelecteds()"
      >
        {{ loading ? "Adicionando..." : "Adicionar selecionado(s)" }}
      </button>
    </div>
  </div>
</div>
<!--
  Questions Bank Fim
-->

<div class="m-3 p-4">
  <app-breadcrumbs [routes]="routes"></app-breadcrumbs>

  <h1 class="font-bold text-3xl">Atividades (CNAEs)</h1>

  <div class="w-full flex flex-row justify-center items-center mt-6">
    <div
      [ngClass]="
        tab === 0
          ? 'w-1/2 flex flex-col justify-center items-center border-b-4 border-slate-800 p-4'
          : 'w-1/2 flex flex-col justify-center items-center border-b border-slate-800 p-4'
      "
      (click)="handleChangeTab(0)"
    >
      <div
        [ngClass]="
          tab === 0
            ? 'mb-2 rounded-full py-1 px-6 bg-slate-800'
            : 'mb-2 rounded-full py-1 px-6 bg-transparent'
        "
      >
        <app-build-icon
          [width]="26"
          [height]="26"
          [color]="tab === 0 ? '#fff' : '#000'"
        ></app-build-icon>
      </div>
      <p class="text-md">Lista de atividades (CNAEs)</p>
    </div>
    <div
      [ngClass]="
        tab === 1
          ? 'w-1/2 flex flex-col justify-center items-center border-b-4 border-slate-800 p-4'
          : 'w-1/2 flex flex-col justify-center items-center border-b border-slate-800 p-4'
      "
      (click)="handleChangeTab(1)"
    >
      <div
        [ngClass]="
          tab === 1
            ? 'mb-2 rounded-full py-1 px-6 bg-slate-800'
            : 'mb-2 rounded-full py-1 px-6 bg-transparent'
        "
      >
        <app-question-icon
          [width]="26"
          [height]="26"
          [color]="tab === 1 ? '#fff' : '#000'"
        ></app-question-icon>
      </div>
      <p class="text-md">Banco de perguntas</p>
    </div>
  </div>

  <app-filter-box
    *ngIf="tab === 0"
    [showInclude]="true"
    [placeholder]="'Pesquisar atividades (CNAEs) por código ou palavra-chave'"
    (filter)="filterCnae($event)"
    [loading]="loading"
  >
    <div class="mb-3">
      <div class="flex flex-col">
        <label class="label">Pesquisar atividades (CNAEs)</label>
      </div>
    </div>
  </app-filter-box>

  <app-filter-box
    *ngIf="tab === 1"
    [showInclude]="true"
    [placeholder]="'Pesquise por código ou palavra-chave'"
    (filter)="filterQuestions($event)"
    [loading]="loadingQuestions"
  >
    <div class="mb-3">
      <div class="flex flex-col">
        <label class="label">Pesquisar pergunta(s)</label>
      </div>
    </div>
  </app-filter-box>

  <div class="card">
    <div
      *ngIf="tab === 0"
      class="w-full flex flex-row justify-end items-center mb-4"
    >
      <button
        *ngIf="!isEditingCnaes"
        class="btn btn-primary text-sm"
        (click)="handleEditingCnaes()"
      >
        Editar vários
      </button>
      <button
        *ngIf="isEditingCnaes"
        [ngClass]="
          loading
            ? 'btn btn-primary text-sm opacity-40 cursor-not-allowed pointer-events-none'
            : 'btn btn-primary text-sm'
        "
        (click)="handleUpdateBulkCnaes()"
      >
        Atualizar todos
      </button>
      <button
        *ngIf="isEditingCnaes"
        [ngClass]="
          loading
            ? 'btn btn-secondary text-sm ml-2 opacity-40 cursor-not-allowed pointer-events-none'
            : 'btn btn-secondary text-sm ml-2'
        "
        (click)="handleEditingCnaes()"
      >
        Cancelar
      </button>
    </div>
    <div
      *ngIf="tab === 1"
      class="w-full flex flex-row justify-end items-center mb-4"
    >
      <button
        *ngIf="!isEditingQuestions"
        class="btn btn-primary text-sm"
        (click)="handleEditingQuestions()"
      >
        Editar vários
      </button>
      <button
        *ngIf="isEditingQuestions"
        [ngClass]="
          loadingQuestion
            ? 'btn btn-primary text-sm opacity-40 cursor-not-allowed pointer-events-none'
            : 'btn btn-primary text-sm'
        "
        (click)="handleUpdateBulkQuestions()"
      >
        Atualizar todos
      </button>
      <button
        *ngIf="isEditingQuestions"
        [ngClass]="
          loadingQuestion
            ? 'btn btn-secondary text-sm ml-2 opacity-40 cursor-not-allowed pointer-events-none'
            : 'btn btn-secondary text-sm ml-2'
        "
        (click)="handleEditingQuestions()"
      >
        Cancelar
      </button>
      <button
        class="btn btn-primary text-sm ml-2"
        (click)="handleShowQuestions(true)"
      >
        Nova pergunta
      </button>
    </div>
    <table *ngIf="tab === 0" class="w-full">
      <thead>
        <tr>
          <th>
            <div class="w-full flex flex-row justify-start items-center p-2">
              <p class="text-sm font-medium">Seleção</p>
            </div>
          </th>
          <th>
            <div
              class="w-full flex flex-row justify-start items-center p-2 hover:cursor-pointer"
              (click)="
                setOrderBy({
                  orderby: 'code',
                  direction: this.currentOrderDirection,
                })
              "
            >
              <p class="text-sm font-medium">Código</p>
              <mat-icon
                [ngClass]="
                  'text-sm ml-1 mt-1 text-black ' +
                  getOrderClass({
                    orderby: 'code',
                    direction: this.currentOrderDirection,
                  })
                "
                [fontIcon]="
                  getOrderIcon({
                    orderby: 'code',
                    direction: this.currentOrderDirection,
                  })
                "
              ></mat-icon>
            </div>
          </th>
          <th>
            <div
              class="w-full flex flex-row justify-start items-center p-2 hover:cursor-pointer"
              (click)="
                setOrderBy({
                  orderby: 'name',
                  direction: this.currentOrderDirection,
                })
              "
            >
              <p class="text-sm font-medium">Denominação</p>
              <mat-icon
                [ngClass]="
                  'text-sm ml-1 mt-1 text-black ' +
                  getOrderClass({
                    orderby: 'name',
                    direction: this.currentOrderDirection,
                  })
                "
                [fontIcon]="
                  getOrderIcon({
                    orderby: 'name',
                    direction: this.currentOrderDirection,
                  })
                "
              ></mat-icon>
            </div>
          </th>
          <th>
            <div class="w-full flex flex-row justify-start items-center p-2">
              <p class="text-sm font-medium">Risco ambiental*</p>
            </div>
          </th>
          <th>
            <div class="w-full flex flex-row justify-start items-center p-2">
              <p class="text-sm font-medium">Risco sanitário*</p>
            </div>
          </th>
          <th>
            <div class="w-full flex flex-row justify-start items-center p-2">
              <p class="text-sm font-medium">PGV</p>
              <button
                mat-raised-button
                matTooltip="Os Polos Geradores de Viagens (PGVs) são locais ou instalações que atraem muitas pessoas e geram grande movimento de veículos."
                matTooltipPosition="above"
                class="text-sm ml-1"
              >
                ⓘ
              </button>
            </div>
          </th>
          <th>
            <div class="w-full flex flex-row justify-start items-center p-2">
              <p class="text-sm font-medium">Projeto Especial</p>
            </div>
          </th>
          <th>
            <div class="w-full flex flex-row justify-start items-center p-2">
              <p class="text-sm font-medium">Domicílio Fiscal</p>
            </div>
          </th>
          <th>
            <div class="w-full flex flex-row justify-start items-center p-2">
              <p class="text-sm font-medium">Avaliar área do terreno (m²)</p>
            </div>
          </th>
          <th>
            <div class="w-full flex flex-row justify-start items-center p-2">
              <p class="text-sm font-medium">Avaliar área construída (m²)</p>
            </div>
          </th>
          <th>
            <div class="w-full flex flex-row justify-start items-center p-2">
              <p class="text-sm font-medium">Risco final</p>
            </div>
          </th>
        </tr>
      </thead>
      <tbody *ngIf="!loading">
        <tr *ngFor="let cnae of cnaes; let index = index" class="py-2">
          <td>
            <div class="w-full flex flex-row justify-center items-center p-2">
              <input type="checkbox" />
            </div>
          </td>
          <td>
            <div class="w-full flex flex-row justify-start items-center p-2">
              <p class="text-sm font-normal">{{ cnae.code }}</p>
            </div>
          </td>
          <td>
            <div class="w-full flex flex-row justify-start items-center p-2">
              <p class="text-sm font-normal uppercase">
                {{ cnae.name }}
              </p>
            </div>
          </td>
          <td>
            <div class="w-full flex flex-col justify-start items-center p-2">
              <select
                [ngClass]="
                  isEditingCnaes
                    ? 'w-full text-sm text-left font-medium text-gray-900 dark:text-white rounded'
                    : 'w-full text-sm text-left font-medium text-gray-900 dark:text-white rounded opacity-40 cursor-not-allowed pointer-events-none'
                "
                (input)="handleEditCnae(index, 'environmentalRisk', $event)"
              >
                <option selected disabled>Selecione uma opção</option>
                <option value="0" [selected]="cnae.environmentalRisk === 0">
                  Requer Informações
                </option>
                <option value="1" [selected]="cnae.environmentalRisk === 1">
                  Risco I
                </option>
                <option value="2" [selected]="cnae.environmentalRisk === 2">
                  Risco II
                </option>
                <option value="3" [selected]="cnae.environmentalRisk === 3">
                  Risco III
                </option>
                <option value="4" [selected]="cnae.environmentalRisk === 4">
                  Competência Estadual
                </option>
              </select>
              <div
                *ngIf="validQuestions(cnae.questions)"
                [ngClass]="
                  validQuestions(cnae.questions)
                    ? 'w-full flex flex-row justify-center items-center mt-1 hover:cursor-pointer'
                    : 'w-full flex flex-row justify-center items-center mt-1 opacity-40 pointer-events-none cursor-not-allowed'
                "
                (click)="handleShowQuestions(false, cnae.code)"
              >
                <app-eye-icon
                  [color]="'#464646'"
                  [width]="16"
                  [height]="16"
                ></app-eye-icon>
                <p class="text-xs ml-1 text-[#464646]">Ver perguntas</p>
              </div>
              <div
                *ngIf="!validQuestions(cnae.questions)"
                class="w-full flex flex-row justify-center items-center mt-1 hover:cursor-pointer"
                (click)="handleShowQuestions(false, cnae.code)"
              >
                <p class="text-xs ml-1 text-[#464646]">+ Definir perguntas</p>
              </div>
            </div>
          </td>
          <td>
            <div class="w-full flex flex-col justify-start items-center p-2">
              <select
                [ngClass]="
                  isEditingCnaes
                    ? 'w-full text-sm text-left font-medium text-gray-900 dark:text-white rounded'
                    : 'w-full text-sm text-left font-medium text-gray-900 dark:text-white rounded opacity-40 cursor-not-allowed pointer-events-none'
                "
                (input)="handleEditCnae(index, 'healthRisk', $event)"
              >
                <option selected disabled>Selecione uma opção</option>
                <option value="0" [selected]="cnae.healthRisk === 0">
                  Requer Informações
                </option>
                <option value="1" [selected]="cnae.healthRisk === 1">
                  Risco I
                </option>
                <option value="2" [selected]="cnae.healthRisk === 2">
                  Risco II
                </option>
                <option value="3" [selected]="cnae.healthRisk === 3">
                  Risco III
                </option>
                <option value="4" [selected]="cnae.healthRisk === 4">
                  Competência Estadual
                </option>
              </select>
              <div
                *ngIf="validQuestions(cnae.questions)"
                [ngClass]="
                  validQuestions(cnae.questions)
                    ? 'w-full flex flex-row justify-center items-center mt-1 hover:cursor-pointer'
                    : 'w-full flex flex-row justify-center items-center mt-1 opacity-40 pointer-events-none cursor-not-allowed'
                "
                (click)="handleShowQuestions(false, cnae.code)"
              >
                <app-eye-icon
                  [color]="'#464646'"
                  [width]="16"
                  [height]="16"
                ></app-eye-icon>
                <p class="text-xs ml-1 text-[#464646]">Ver perguntas</p>
              </div>
              <div
                *ngIf="!validQuestions(cnae.questions)"
                class="w-full flex flex-row justify-center items-center mt-1 hover:cursor-pointer"
                (click)="handleShowQuestions(false, cnae.code)"
              >
                <p class="text-xs ml-1 text-[#464646]">+ Definir perguntas</p>
              </div>
            </div>
          </td>
          <td>
            <div
              [ngClass]="
                cnae.residenceSupervisor
                  ? 'w-full flex flex-row justify-center items-center p-2 opacity-40 pointer-events-none cursor-not-allowed'
                  : 'w-full flex flex-row justify-center items-center p-2'
              "
            >
              <input
                type="checkbox"
                [checked]="cnae.pgv"
                [ngClass]="
                  isEditingCnaes
                    ? ''
                    : 'opacity-40 cursor-not-allowed pointer-events-none'
                "
                (input)="handleEditCnae(index, 'pgv', $event)"
              />
            </div>
          </td>
          <td>
            <div
              [ngClass]="
                cnae.residenceSupervisor
                  ? 'w-full flex flex-row justify-center items-center p-2 opacity-40 pointer-events-none cursor-not-allowed'
                  : 'w-full flex flex-row justify-center items-center p-2'
              "
            >
              <input
                type="checkbox"
                [checked]="cnae.specialProject"
                [ngClass]="
                  isEditingCnaes
                    ? ''
                    : 'opacity-40 cursor-not-allowed pointer-events-none'
                "
                (input)="handleEditCnae(index, 'specialProject', $event)"
              />
            </div>
          </td>
          <td>
            <div
              [ngClass]="
                cnae.pgv || cnae.specialProject
                  ? 'w-full flex flex-row justify-center items-center p-2 opacity-40 pointer-events-none cursor-not-allowed'
                  : 'w-full flex flex-row justify-center items-center p-2'
              "
            >
              <input
                type="checkbox"
                [checked]="cnae.residenceSupervisor"
                [ngClass]="
                  isEditingCnaes
                    ? ''
                    : 'opacity-40 cursor-not-allowed pointer-events-none'
                "
                (input)="handleEditCnae(index, 'residenceSupervisor', $event)"
              />
            </div>
          </td>
          <td>
            <div class="w-full flex flex-col justify-start items-center p-2">
              <div class="w-full flex flex-row justify-center items-center">
                <p class="text-xs font-normal ml-2">Mín.</p>
                <input
                  type="text"
                  [value]="cnae.landAreaMin"
                  class="w-full"
                  [ngClass]="
                    isEditingCnaes
                      ? 'appearance-none rounded w-1/2 py-2 px-3 text-gray-700 leading-tight outline-none h-9 ml-2.5'
                      : 'appearance-none rounded w-1/2 py-2 px-3 text-gray-700 leading-tight outline-none h-9 ml-2.5 opacity-40 cursor-not-allowed pointer-events-none'
                  "
                  (change)="handleEditCnae(index, 'landAreaMin', $event)"
                />
              </div>
              <div
                class="w-full flex flex-row justify-center items-center mt-2"
              >
                <p class="text-xs font-normal ml-2">Máx.</p>
                <input
                  type="text"
                  [value]="cnae.landAreaMax"
                  class="w-full"
                  [ngClass]="
                    isEditingCnaes
                      ? 'appearance-none rounded w-1/2 py-2 px-3 text-gray-700 leading-tight outline-none h-9 ml-2'
                      : 'appearance-none rounded w-1/2 py-2 px-3 text-gray-700 leading-tight outline-none h-9 ml-2 opacity-40 cursor-not-allowed pointer-events-none'
                  "
                  (change)="handleEditCnae(index, 'landAreaMax', $event)"
                />
              </div>
            </div>
          </td>
          <td>
            <div class="w-full flex flex-col justify-start items-center p-2">
              <div class="w-full flex flex-row justify-center items-center">
                <p class="text-xs font-normal ml-2">Mín.</p>
                <input
                  type="text"
                  [value]="cnae.buildingAreaMin"
                  class="w-full"
                  [ngClass]="
                    isEditingCnaes
                      ? 'appearance-none rounded w-1/2 py-2 px-3 text-gray-700 leading-tight outline-none h-9 ml-2.5'
                      : 'appearance-none rounded w-1/2 py-2 px-3 text-gray-700 leading-tight outline-none h-9 ml-2.5 opacity-40 cursor-not-allowed pointer-events-none'
                  "
                  (change)="handleEditCnae(index, 'buildingAreaMin', $event)"
                />
              </div>
              <div
                class="w-full flex flex-row justify-center items-center mt-2"
              >
                <p class="text-xs font-normal ml-2">Máx.</p>
                <input
                  type="text"
                  [value]="cnae.buildingAreaMax"
                  class="w-full"
                  [ngClass]="
                    isEditingCnaes
                      ? 'appearance-none rounded w-1/2 py-2 px-3 text-gray-700 leading-tight outline-none h-9 ml-2'
                      : 'appearance-none rounded w-1/2 py-2 px-3 text-gray-700 leading-tight outline-none h-9 ml-2 opacity-40 cursor-not-allowed pointer-events-none'
                  "
                  (change)="handleEditCnae(index, 'buildingAreaMax', $event)"
                />
              </div>
            </div>
          </td>
          <td>
            <div class="w-full flex flex-row justify-start items-center p-2">
              <p class="text-sm font-medium">
                {{
                  getRisk(getFinalRisk(cnae.environmentalRisk, cnae.healthRisk))
                }}
              </p>
              <!-- <p class="text-sm font-normal text-orange-600 mr-2">ⓘ</p>
              <p class="text-sm font-normal text-orange-600">Insira dados</p> -->
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <table *ngIf="tab === 1" class="w-full">
      <thead>
        <tr>
          <th>
            <div class="w-full flex flex-row justify-start items-center p-2">
              <p class="text-sm font-medium">Seleção</p>
              <mat-icon
                class="text-sm ml-1 mt-1 text-black"
                fontIcon="unfold_more"
              ></mat-icon>
            </div>
          </th>
          <th>
            <div class="w-full flex flex-row justify-start items-center p-2">
              <p class="text-sm font-medium">Código</p>
              <mat-icon
                class="text-sm ml-1 mt-1 text-black"
                fontIcon="unfold_more"
              ></mat-icon>
            </div>
          </th>
          <th>
            <div class="w-full flex flex-row justify-start items-center p-2">
              <p class="text-sm font-medium">Pergunta*</p>
              <mat-icon
                class="text-sm ml-1 mt-1 text-black"
                fontIcon="unfold_more"
              ></mat-icon>
            </div>
          </th>
          <th>
            <div class="w-full flex flex-row justify-start items-center p-2">
              <p class="text-sm font-medium">Se SIM*</p>
              <mat-icon
                class="text-sm ml-1 mt-1 text-black"
                fontIcon="unfold_more"
              ></mat-icon>
            </div>
          </th>
          <th>
            <div class="w-full flex flex-row justify-start items-center p-2">
              <p class="text-sm font-medium">Se NÃO*</p>
              <mat-icon
                class="text-sm ml-1 mt-1 text-black"
                fontIcon="unfold_more"
              ></mat-icon>
            </div>
          </th>
          <th>
            <div class="w-full flex flex-row justify-start items-center p-2">
              <p class="text-sm font-medium ml-1">Cnaes</p>
            </div>
          </th>
          <th>
            <div class="w-full flex flex-row justify-start items-center p-2">
              <p class="text-sm font-medium ml-1">Ações</p>
            </div>
          </th>
        </tr>
      </thead>
      <tbody *ngIf="!loadingQuestions">
        <tr *ngFor="let q of questions; let index = index" class="py-2">
          <td>
            <div class="w-full flex flex-row justify-start items-center p-2">
              <input type="checkbox" />
            </div>
          </td>
          <td>
            <div class="w-full flex flex-row justify-start items-center p-2">
              <p class="text-sm font-normal">{{ q.code }}</p>
            </div>
          </td>
          <td class="w-1/2">
            <div class="w-full flex flex-row justify-start items-center p-2">
              <p class="text-sm font-normal">
                {{ q.description }}
              </p>
            </div>
          </td>
          <td>
            <div class="w-full flex flex-col justify-start items-center p-2">
              <p class="w-full text-sm font-normal text-left">Definir como</p>
              <select
                [ngClass]="
                  isEditingQuestions
                    ? 'w-full text-sm text-left font-medium text-gray-900 dark:text-white rounded'
                    : 'w-full text-sm text-left font-medium text-gray-900 dark:text-white rounded opacity-40 cursor-not-allowed pointer-events-none'
                "
                (input)="handleEditQuestions(index, 'riskIfYes', $event)"
              >
                <option selected disabled>Selecione uma opção</option>
                <option value="0" [selected]="q.riskIfYes === 0">
                  Requer Informações
                </option>
                <option value="1" [selected]="q.riskIfYes === 1">
                  Risco I
                </option>
                <option value="2" [selected]="q.riskIfYes === 2">
                  Risco II
                </option>
                <option value="3" [selected]="q.riskIfYes === 3">
                  Risco III
                </option>
                <option value="4" [selected]="q.riskIfYes === 4">
                  Competência Estadual
                </option>
              </select>
            </div>
          </td>
          <td>
            <div class="w-full flex flex-col justify-start items-center p-2">
              <p class="w-full text-sm font-normal text-left">Definir como</p>
              <select
                [ngClass]="
                  isEditingQuestions
                    ? 'w-full text-sm text-left font-medium text-gray-900 dark:text-white rounded'
                    : 'w-full text-sm text-left font-medium text-gray-900 dark:text-white rounded opacity-40 cursor-not-allowed pointer-events-none'
                "
                (input)="handleEditQuestions(index, 'riskIfNo', $event)"
              >
                <option selected disabled>Selecione uma opção</option>
                <option value="0" [selected]="q.riskIfNo === 0">
                  Requer Informações
                </option>
                <option value="1" [selected]="q.riskIfNo === 1">Risco I</option>
                <option value="2" [selected]="q.riskIfNo === 2">
                  Risco II
                </option>
                <option value="3" [selected]="q.riskIfNo === 3">
                  Risco III
                </option>
                <option value="4" [selected]="q.riskIfNo === 4">
                  Competência Estadual
                </option>
              </select>
            </div>
          </td>
          <td>
            <span class="w-full flex flex-row justify-center">
              <button
                *ngIf="q.cnaesQt > 0"
                (click)="openLinkedModal(q)"
                class="text-blue-500 hover:underline"
              >
                {{ q.cnaesQt }}
              </button>
              <span *ngIf="q.cnaesQt === 0">
                {{ q.cnaesQt }}
              </span>
            </span>
          </td>
          <td>
            <div class="w-full flex flex-row justify-center items-start">
              <button
                (click)="openDeleteModal(q)"
                [disabled]="!canDelete(q)"
                class="flex flex-row justify-start items-center p-2 mr-4"
                [ngClass]="{
                  'cursor-not-allowed opacity-50': !canDelete(q),
                  'hover:cursor-pointer': canDelete(q),
                }"
              >
                <app-delete-icon
                  [width]="20"
                  [height]="20"
                  [color]="'#E64D4D'"
                ></app-delete-icon>
                <p class="text-sm font-normal text-left ml-1 text-[#E64D4D]">
                  Deletar
                </p>
              </button>
              <button
                (click)="openEditModal(q)"
                [disabled]="!canEdit(q)"
                class="flex flex-row justify-start items-center p-2"
                [ngClass]="{
                  'cursor-not-allowed opacity-50': !canEdit(q),
                  'hover:cursor-pointer': canEdit(q),
                }"
              >
                <app-edit-icon [width]="20" [height]="20"></app-edit-icon>
                <p class="text-sm font-normal text-left ml-1">Editar</p>
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <div
      *ngIf="tab === 0 && cnaes.length === 0 && !loading"
      class="w-full flex flex-row justify-center py-4"
    >
      <div class="flex p-3 flex-row items-center">
        <p class="text-sm font-medium">Nenhum registro encontrado.</p>
      </div>
    </div>

    <div
      *ngIf="tab === 1 && questions.length === 0 && !loadingQuestions"
      class="w-full flex flex-row justify-center py-4"
    >
      <div class="flex p-3 flex-row items-center">
        <p class="text-sm font-medium">Nenhum registro encontrado.</p>
      </div>
    </div>

    <div
      *ngIf="tab === 0 && loading"
      class="w-full flex flex-row justify-center py-4"
    >
      <div class="flex p-3 flex-row items-center">
        <ng-icon class="mr-3 animate-spin" name="matSync"></ng-icon>
        <p class="text-sm font-medium">Carregando dados...</p>
      </div>
    </div>

    <div
      *ngIf="tab === 1 && loadingQuestions"
      class="w-full flex flex-row justify-center py-4"
    >
      <div class="flex p-3 flex-row items-center">
        <ng-icon class="mr-3 animate-spin" name="matSync"></ng-icon>
        <p class="text-sm font-medium">Carregando dados...</p>
      </div>
    </div>

    <div
      *ngIf="tab === 0 && !loading"
      class="flex flex-row justify-between mt-4"
    >
      <select
        class="mt-3 p-1 rounded-md w-16"
        (input)="handleLimitChange($event, 'limit', 'cnaes')"
      >
        <option value="10" [selected]="limit === 10">10</option>
        <option value="20" [selected]="limit === 20">20</option>
        <option value="50" [selected]="limit === 50">50</option>
      </select>

      <nav
        class="mt-3 flex flex-row justify-end"
        aria-label="Page navigation example"
      >
        <ul class="flex items-center -space-x-px h-10 text-base">
          <li>
            <div
              [ngClass]="
                page > 1
                  ? 'flex cursor-pointer items-center justify-center px-4 h-10 ms-0 leading-tight text-gray-500 bg-white border border-e-0 border-gray-300 rounded-s-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white'
                  : 'opacity-40 pointer-events-none cursor-not-allowed flex cursor-pointer items-center justify-center px-4 h-10 ms-0 leading-tight text-gray-500 bg-white border border-e-0 border-gray-300 rounded-s-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white'
              "
              (click)="handlePageChange('previous', 'cnaes')"
            >
              <span class="sr-only">Previous</span>
              <ng-icon name="matArrowBackIos"></ng-icon>
            </div>
          </li>
          <div
            class="flex cursor-pointer items-center justify-center px-4 h-10 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
          >
            {{ page }}
          </div>
          <li>
            <div
              class="flex cursor-pointer items-center justify-center px-4 h-10 leading-tight text-gray-500 bg-white border border-gray-300 rounded-e-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
              (click)="handlePageChange('next', 'cnaes')"
            >
              <span class="sr-only">Next</span>
              <ng-icon name="matArrowForwardIos"></ng-icon>
            </div>
          </li>
        </ul>
      </nav>
    </div>

    <div
      *ngIf="tab === 1 && !loadingQuestions"
      class="flex flex-row justify-between mt-4"
    >
      <select
        class="mt-3 p-1 rounded-md w-16"
        (input)="handleLimitChange($event, 'limit', 'questions')"
      >
        <option value="10" [selected]="limitQuestions === 10">10</option>
        <option value="20" [selected]="limitQuestions === 20">20</option>
        <option value="50" [selected]="limitQuestions === 50">50</option>
      </select>

      <nav
        class="mt-3 flex flex-row justify-end"
        aria-label="Page navigation example"
      >
        <ul class="flex items-center -space-x-px h-10 text-base">
          <li>
            <div
              [ngClass]="
                pageQuestions > 1
                  ? 'flex cursor-pointer items-center justify-center px-4 h-10 ms-0 leading-tight text-gray-500 bg-white border border-e-0 border-gray-300 rounded-s-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white'
                  : 'opacity-40 pointer-events-none cursor-not-allowed flex cursor-pointer items-center justify-center px-4 h-10 ms-0 leading-tight text-gray-500 bg-white border border-e-0 border-gray-300 rounded-s-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white'
              "
              (click)="handlePageChange('previous', 'questions')"
            >
              <span class="sr-only">Previous</span>
              <ng-icon name="matArrowBackIos"></ng-icon>
            </div>
          </li>
          <div
            class="flex cursor-pointer items-center justify-center px-4 h-10 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
          >
            {{ page }}
          </div>
          <li>
            <div
              class="flex cursor-pointer items-center justify-center px-4 h-10 leading-tight text-gray-500 bg-white border border-gray-300 rounded-e-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
              (click)="handlePageChange('next', 'questions')"
            >
              <span class="sr-only">Next</span>
              <ng-icon name="matArrowForwardIos"></ng-icon>
            </div>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</div>
