import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import {
  CnaeFilter,
  CnaeProps,
  CnaeQuestionProps,
  CnaeResponse,
  CnaeUpdateBulk,
} from '../models/cnae.models';
import { QuestionOnCnae } from '../models/questions.models';

@Injectable({
  providedIn: 'root',
})
export class CnaeService {
  private httpService = inject(HttpClient);
  private url = `${environment.urlApi}/cnae`;
  private questionUrl = `${environment.urlApi}/cnae-question`;

  constructor() {}

  getCnaes(filter: CnaeFilter): Observable<CnaeResponse[]> {
    return this.httpService.get<CnaeResponse[]>(
      `${this.url}?page=${filter.page}&limit=${filter.limit}&keyword=${filter.keyword}&orderBy=${filter.orderby}&direction=${filter.direction}&risk=${filter.risk || ""}`
    );
  }

  createQuestionOnCnae(dto: CnaeQuestionProps): Observable<CnaeQuestionProps> {
    return this.httpService.post<CnaeQuestionProps>(
      `${this.questionUrl}/attach-to-cnae`,
      dto
    );
  }

  updateCnae(): Observable<CnaeProps> {
    return this.httpService.get<CnaeProps>(`${this.url}/update`);
  }

  updateCnaeBulk(dto: CnaeUpdateBulk): Observable<CnaeUpdateBulk> {
    return this.httpService.put<CnaeUpdateBulk>(
      `${this.url}/update-in-bulk`,
      dto
    );
  }

  getQuestionsByCnaeCode(cnaeCode: number): Observable<QuestionOnCnae[]> {
    return this.httpService.get<QuestionOnCnae[]>(
      `${this.questionUrl}/${cnaeCode}`
    );
  }
}
