<svg
  [attr.width]="width + 'px'"
  [attr.height]="height + 'px'"
  viewBox="0 0 18 18"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M6.59631 11.9063L3.46881 8.77875L2.40381 9.83625L6.59631 14.0288L15.5963 5.02875L14.5388 3.97125L6.59631 11.9063Z"
    [attr.fill]="color"
  />
</svg>
