<div class="w-full flex flex-col justify-center items-center">
  <div class="w-full h-px bg-gray-300 mt-4 mb-4"></div>
  <h1 class="w-full text-left font-medium text-2xl">Aprovar reanálise</h1>
  <p class="text-sm w-full text-left">
    Ao enviar para revisão, você confirma que leu e aprovou as alterações
    realizadas no relatório da consulta de viabilidade.
  </p>

  <div class="w-full flex flex-col justify-start items-start mt-4">
    <label [htmlFor]="" class="label">Observações (Opcional)</label>
    <div class="w-full flex flex-row justify-center items-center">
      <textarea
        class="input w-full h-44"
        placeholder="Maximo de 500 caracteres"
        maxlength="500"
        (input)="handleJustificationChange($event)"
      ></textarea>
    </div>
  </div>
</div>
