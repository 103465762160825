<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<div class="m-3 p-4">
  <app-breadcrumbs [routes]="routes"></app-breadcrumbs>

  <h1 class="font-bold text-3xl">Macrozonas</h1>
  <div class="card">
    <table class="w-full">
      <thead>
        <tr>
          <th>
            <div class="w-full flex flex-row justify-start items-center p-2">
              <p class="text-sm font-medium">Nome</p>
              <mat-icon
                class="text-sm ml-1 mt-1 text-black"
                fontIcon="unfold_more"
              ></mat-icon>
            </div>
          </th>
          <th>
            <div class="w-full flex flex-row justify-start items-center p-2">
              <p class="text-sm font-medium">Hierarquia</p>
              <mat-icon
                class="text-sm ml-1 mt-1 text-black"
                fontIcon="unfold_more"
              ></mat-icon>
            </div>
          </th>
          <th>
            <div class="w-full flex flex-row justify-start items-center p-2">
              <p class="text-sm font-medium">Ações</p>
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let d of data" class="py-4">
          <td>
            <div class="w-full flex flex-row justify-start items-center p-2">
              <p class="text-sm font-normal">
                {{ "Macrozona " + getZoneName(d.name) }}
              </p>
            </div>
          </td>
          <td>
            <div class="w-full flex flex-row justify-start items-center p-2">
              <p class="text-sm font-normal">{{ getHirarchy(d.hierarchy) }}</p>
            </div>
          </td>
          <td>
            <div class="w-full flex flex-row justify-start items-center p-2">
              <!-- <div
                class="flex flex-row justify-center items-center mr-6 hover:cursor-pointer"
              >
                <app-eye-icon [width]="20" [height]="20"></app-eye-icon>
                <p class="text-sm font-normal text-left ml-1">Visualizar</p>
              </div> -->
              <div
                class="flex flex-row justify-center items-center mr-6 hover:cursor-pointer"
                (click)="toEdit(d)"
              >
                <app-eye-icon [width]="20" [height]="20"></app-eye-icon>
                <p class="text-sm font-normal text-left ml-1">Visualizar</p>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <div *ngIf="loading" class="w-full flex flex-row justify-center py-4">
      <div class="flex p-3 flex-row items-center">
        <ng-icon class="mr-3 animate-spin" name="matSync"></ng-icon>
        <p class="text-sm font-medium">Carregando dados...</p>
      </div>
    </div>
  </div>
</div>
