import {
  matSearch,
  matBlock,
  matAttachFile,
  matFileOpen,
  matLockOpen,
} from '@ng-icons/material-icons/baseline';
import { Component, OnInit } from '@angular/core';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import ApexCharts from 'apexcharts';

@Component({
  selector: 'app-dashboard',
  standalone: true,
  imports: [NgIconComponent],
  providers: [
    provideIcons({
      matSearch,
      matBlock,
      matAttachFile,
      matFileOpen,
      matLockOpen,
    }),
  ],
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.scss',
})
export class DashboardComponent implements OnInit {
  routes = [{ label: 'Dashboard', link: '/dashboard' }];

  constructor() {}

  options = {
    chart: {
      type: 'line',
    },
    series: [
      {
        name: 'sales',
        data: [30, 40, 35, 50, 49, 60, 70, 91, 125],
      },
    ],
    xaxis: {
      categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999],
    },
  };

  ngOnInit(): void {
    const chart1 = new ApexCharts(
      document.querySelector('#chart-1'),
      this.options,
    );
    chart1.render();

    const chart2 = new ApexCharts(
      document.querySelector('#chart-2'),
      this.options,
    );
    chart2.render();

    const chart3 = new ApexCharts(
      document.querySelector('#chart-3'),
      this.options,
    );
    chart3.render();

    const chart4 = new ApexCharts(
      document.querySelector('#chart-4'),
      this.options,
    );
    chart4.render();

    const chart5 = new ApexCharts(
      document.querySelector('#chart-5'),
      this.options,
    );
    chart5.render();
  }
}
