import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-new-layer',
  standalone: true,
  imports: [],
  templateUrl: './new-layer.component.html',
  styleUrl: './new-layer.component.scss'
})
export class NewLayerComponent {
  constructor(private router: Router) {}

  public goBack = () => {
    this.router.navigate(['/macrozonas']);
  }

  public save = () => {
    this.router.navigate(['/macrozonas']);
  }
}
