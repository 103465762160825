<app-alert></app-alert>
<section class="h-full bg-blue-950">
  <div
    class="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0"
  >
    <a
      href="#"
      class="flex items-center mb-6 text-2xl font-semibold text-white"
    >
      <div class="rounded-full mr-5 bg-white">
        <img class="w-16 h-16" src="assets/images/HUBlic.png" alt="logo" />
      </div>
      Hublic
    </a>
    <div
      class="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700"
    >
      <div class="p-6 space-y-4 md:space-y-6 sm:p-8">
        <h1
          class="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white"
        >
          Entre com sua conta
        </h1>
        <form
          class="space-y-4 md:space-y-6"
          [formGroup]="loginForm"
          (ngSubmit)="onSubmit()"
        >
          <div>
            <label
              for="username"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >E-mail</label
            >
            <input
              type="text"
              name="username"
              id="username"
              formControlName="username"
              class="input-email"
              placeholder="johndoe@company.com"
              required=""
            />
          </div>
          <div>
            <label
              for="password"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >Senha</label
            >
            <input
              type="password"
              name="password"
              id="password"
              formControlName="password"
              placeholder="••••••••"
              class="input-email"
              required=""
            />
          </div>
          <div class="flex items-center justify-between">
            <div class="flex items-start">
              <div class="flex items-center h-5">
                <input
                  id="remember"
                  aria-describedby="remember"
                  type="checkbox"
                  class="input-remember"
                  required=""
                />
              </div>
              <div class="ml-3 text-sm">
                <label for="remember" class="text-gray-500 dark:text-gray-300"
                  >Me mantenha conectado</label
                >
              </div>
            </div>
            <button class="forgot-password" (click)="push('/forgot-password')">
              Esqueci minha senha
            </button>
          </div>
          <button type="submit" class="sign-in">Entre</button>
          <p class="text-sm font-light text-gray-500 dark:text-gray-400">
            Não é possui cadastro?
            <button
              class="font-medium text-primary-600 hover:underline dark:text-primary-500 hover:cursor-pointer"
              (click)="push('register')"
            >
              Cadastre-se aqui!
            </button>
          </p>
        </form>
      </div>
    </div>
  </div>
</section>
