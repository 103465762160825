import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-layers-icon',
  standalone: true,
  templateUrl: './layers-icon.component.html',
  styleUrls: ['./layers-icon.component.scss'],
  imports: [CommonModule],
})
export class LayersIconComponent {
  @Input() color: string = '#000000';
  @Input() width: number = 24;
  @Input() height: number = 24;
}
