/* eslint-disable @typescript-eslint/no-explicit-any */
import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TableComponent } from '../../components/table/table.component';
import { FilterBoxComponent } from '../../components/filter-box/filter-box.component';
import { Store } from '@ngrx/store';
import { AppMacrozoneState } from '../../states/macrozone.state';
import {
  Macrozones,
  MacrozonesZonesResponse,
  ZoneFilter,
  ZoneResponse,
} from '../../models/macrozone.models';
import { MacrozoneService } from '../../services/macrozone.service';
import { catchError, tap, throwError } from 'rxjs';
import { ZoneService } from '../../services/zone.service';
import { HttpErrorResponse } from '@angular/common/http';
import { AlertService } from '../../services/alert.service';
import { AlertComponent } from '../../components/alert/alert.component';
import { EditIconComponent } from '../../icons/cnaes/edit-icon/edit-icon.component';
import { EyeIconComponent } from '../../icons/edit-zone/eye-icon/eye-icon.component';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import {
  matArrowBackIos,
  matArrowForwardIos,
  matSync,
} from '@ng-icons/material-icons/baseline';
import {
  faSolidSort,
  faSolidSortDown,
  faSolidSortUp,
} from '@ng-icons/font-awesome/solid';
import { OrderByColumn } from '../../services/filters/orderby.filter';
import { MatIcon } from '@angular/material/icon';
import {
  BreadcrumbsComponent,
  IRoute,
} from '../../components/breadcrumbs/breadcrumbs.component';
@Component({
  selector: 'app-zones',
  standalone: true,
  imports: [
    CommonModule,
    TableComponent,
    FilterBoxComponent,
    AlertComponent,
    EditIconComponent,
    EyeIconComponent,
    NgIconComponent,
    MatIcon,
    BreadcrumbsComponent,
  ],
  providers: [
    provideIcons({
      matSync,
      matArrowBackIos,
      matArrowForwardIos,
      faSolidSort,
      faSolidSortUp,
      faSolidSortDown,
    }),
  ],
  templateUrl: './zones.component.html',
  styleUrl: './zones.component.scss',
})
export class ZonesComponent {
  macrozoneId: string = '';
  macrozoneName: string = '';

  routes: IRoute[] = [
    {
      label: 'Macrozonas',
      link: {
        route: `/macrozonas`,
      },
    },
    {
      label: 'Zonas',
      link: {
        route: `/macrozonas/zonas/${this.macrozoneName
          .replace('Macrozona ', '')
          .toLowerCase()}/${this.macrozoneId}`,
      },
    },
  ];

  public isEditing: boolean = false;
  private store = inject(Store<AppMacrozoneState>);
  private macrozone: Macrozones = {} as Macrozones;
  macrozoneService = inject(MacrozoneService);
  zoneService = inject(ZoneService);
  loading: boolean = true;
  loadingEditing: boolean = false;
  selectOptions = [
    {
      label: 'Nível 1 - Muito baixo',
      value: 1,
    },
    {
      label: 'Nível 2 - Baixo',
      value: 2,
    },
    {
      label: 'Nível 3 - Médio',
      value: 3,
    },
    {
      label: 'Nível 4 - Alto',
      value: 4,
    },
    {
      label: 'Nível 5 - Muito alto',
      value: 5,
    },
  ];

  page: number = 1;
  limit: number = 10;
  keyword: string = '';

  currentOrderBy: 'name' | 'hierarchy' | 'description' | null = 'name';
  currentOrderDirection: 'ASC' | 'DESC' = 'ASC';

  public pages: number[] = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private alertService: AlertService
  ) {
    this.route.params.subscribe((params) => {
      this.macrozoneName = `Macrozona ${
        params['zoneName'].charAt(0).toUpperCase() +
        params['zoneName'].substring(1)
      }`;
      this.macrozoneId = params['id'];
    });

    this.getMacrozoneById({
      page: this.page,
      limit: this.limit,
      keyword: this.keyword,
    });
  }

  handlePageChange(key: string): void {
    if (key === 'next') {
      this.page = this.page + 1;
    } else if (this.page > 1) {
      this.page = this.page - 1;
    }

    this.getMacrozoneById({
      page: this.page,
      limit: this.limit,
      keyword: this.keyword,
    });
  }

  handleLimitChange(event: any, key: string): void {
    if (key === 'limit') this.limit = Number(event.target.value);

    this.getMacrozoneById({
      page: this.page,
      limit: this.limit,
      keyword: this.keyword,
    });
  }

  toEdit(d: ZoneResponse): void {
    this.router.navigate([`macrozonas/zona/${this.macrozoneId}/edit/${d.id}`]);
  }

  getHirarchy(h: number): string {
    switch (h) {
      case 1:
        return 'Nível 1 - Muito baixo';
      case 2:
        return 'Nível 2 - Baixo';
      case 3:
        return 'Nível 3 - Médio';
      case 4:
        return 'Nível 4 - Alto';
      default:
        return 'Nível 5 - Muito alto';
    }
  }

  data: ZoneResponse[] = [];

  handleEditing = () => (this.isEditing = !this.isEditing);

  handleEditHierarchy(id: string, event: any): void {
    this.data = this.data.map((d) => {
      if (d.id === id) {
        return {
          ...d,
          hierarchy: Number(event.target.value),
        };
      }

      return d;
    });
  }

  handleUpdateZones(): void {
    this.loadingEditing = true;
    this.zoneService.updateZones([...this.data]).subscribe({
      next: () => {
        this.handleEditing();
        this.loadingEditing = false;
        this.alertService.add({
          id: 'update-zones-success',
          type: 'success',
          message: 'Suas Zonas foram atualizadas com sucesso!',
          autoClose: true,
          top: 5,
          right: 1,
        });
      },
      error: (e: HttpErrorResponse) => {
        this.loadingEditing = false;
        console.error(e),
          this.alertService.add({
            id: 'update-zones-error',
            type: 'error',
            message:
              'Houve um erro ao tentar atualizar as Zonas, se o erro persistir contate o Suporte.',
            top: 5,
            right: 1,
          });
      },
    });
  }

  filterZone(f: string): void {
    this.loading = true;
    this.keyword = f;
    this.getMacrozoneById({
      page: this.page,
      limit: this.limit,
      name: this.keyword,
      orderby: this.currentOrderBy as string | undefined,
      direction: this.currentOrderDirection,
    });
  }

  getMacrozoneById({
    page,
    limit,
    name,
    orderby,
    direction,
  }: ZoneFilter): void {
    if (!orderby) this.loading = true;
    if (this.macrozoneId)
      this.macrozoneService
        .getZonesById(
          { id: String(this.macrozoneId) },
          {
            page,
            limit,
            keyword: name?.trim(),
            orderby,
            direction,
          }
        )
        .pipe(
          tap((response: MacrozonesZonesResponse) => {
            if (response.zones !== null && response.zones.length > 0) {
              this.data = response.zones.map((zone) => {
                return {
                  ...zone,
                  layer: zone.name,
                };
              });

              this.routes = [
                {
                  label: 'Macrozonas',
                  link: {
                    route: `/macrozonas`,
                  },
                },
                {
                  label: 'Zonas',
                  link: {
                    route: `/macrozonas/zonas/${this.macrozoneName
                      .replace('Macrozona ', '')
                      .toLowerCase()}/${this.macrozoneId}`,
                  },
                },
              ];

              this.macrozone = response.zones[0].macrozone;
              this.loading = false;
            } else {
              this.data = [];
              this.loading = false;
            }
          }),
          catchError((error) => {
            console.error('Error fetching macrozones:', error);
            return throwError(() => error);
          })
        )
        .subscribe();
  }

  public newZone = () => {
    this.router.navigate([`/macrozonas/zona/${this.macrozoneId}/create`]);
  };

  setOrderBy(column: OrderByColumn): void {
    const columnValue = column.orderby as
      | 'name'
      | 'hierarchy'
      | 'description'
      | null;
    if (this.currentOrderBy === column.orderby) {
      this.currentOrderDirection =
        this.currentOrderDirection === 'ASC' ? 'DESC' : 'ASC';
    } else {
      this.currentOrderBy = columnValue;
      this.currentOrderDirection = 'ASC';
    }

    this.getMacrozoneById({
      page: this.page,
      limit: this.limit,
      name: this.keyword,
      orderby: this.currentOrderBy as string | undefined,
      direction: this.currentOrderDirection,
    });
  }

  getOrderClass(column: OrderByColumn): string {
    if (this.currentOrderBy !== column.orderby) {
      return '';
    } else {
      return this.currentOrderDirection === 'ASC' ? '' : 'rotate-180 mt-0';
    }
  }

  getOrderIcon(column: OrderByColumn): string {
    if (this.currentOrderBy !== column.orderby) {
      return 'unfold_more';
    } else {
      return this.currentOrderDirection === 'ASC'
        ? 'keyboard_arrow_up'
        : 'keyboard_arrow_up'; // rotate 180deg
    }
  }
}
