import { CommonModule } from '@angular/common';
import { Component, inject, Input, OnInit } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { FeasibilityResponse } from '../../../models/feasibility.model';
import { FeasibilityService } from '../../../services/feasibility.service';
import { AuthService } from '../../../services/auth.service';

@Component({
  selector: 'app-status-history',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatDividerModule,
    MatIconModule,
    NgxMaskDirective,
    NgxMaskPipe,
  ],
  templateUrl: './status_history.component.html',
  styleUrl: './status_history.component.scss',
  providers: [provideNgxMask()],
})
export class StatusHistoryComponent implements OnInit {
  @Input() data!: FeasibilityResponse;

  loading = false;
  loadingDownloadFile: boolean = false;

  role!: string;
  userDocument!: string | undefined;
  userName!: string | undefined;

  private feasibilityService = inject(FeasibilityService);
  private authService = inject(AuthService);

  showJustification: boolean = false;

  constructor() {}

  ngOnInit(): void {
    this.role = this.authService.getDecodedUser()?.role ?? 'user';
    this.userDocument = this.authService.getDecodedUser()?.cpf ?? '';
    this.userName = this.authService.getDecodedUser()?.fullname ?? '';
  }

  downloadJustification(status?: string) {
    if (this.data.refeasibilityProtocol && this.data._id) {
      this.loadingDownloadFile = true;
      this.feasibilityService
        .downloadRefeasbilityJustificationPdf(
          this.data.refeasibilityProtocol,
          this.data._id,
          (this.role === 'analyst' && status && status === 'PEN_RESPONSE') ||
            (this.role === 'analyst' && status && status === 'REANALYSIS') ||
            (this.role === 'user' && status && status === 'PEN_RESPONSE')
            ? 'response'
            : this.role === 'analyst' && status && status === 'ANSWERED'
              ? 'notification'
              : 'justification',
        )
        .subscribe({
          next: (response) => {
            const blob = new Blob([response], { type: 'application/pdf' });
            const url = window.URL.createObjectURL(blob);
            window.open(url);
            this.loadingDownloadFile = false;
          },
          error: (error) => {
            console.log(error);
            this.loadingDownloadFile = false;
          },
        });
    }
  }

  getStatus(s: string) {
    if (this.role === 'user') {
      if (s === 'DEFAULT') return 'Justificativa enviada';
      if (s === 'PEN_RESPONSE') return 'Resposta enviada';
      if (s === 'PENDING') return 'Em análise';
    }

    if (this.role === 'analyst' || this.role === 'coordinator-manager') {
      if (s === 'ANSWERED') return 'Aguardando resposta';
      if (s === 'PEN_RESPONSE') return 'Resposta recebida';
    }

    switch (s) {
      case 'ANALYSIS':
        return 'Em análise';
      case 'PEN_REVIEW':
        return 'Pendente de revisão';
      case 'REVIEW':
        return 'Em 1ª revisão';
      case 'SECOND_REVIEW':
        return 'Em 2ª revisão';
      case 'PENDING':
        return 'Pendente';
      case 'PEN_ANALYSIS':
        return 'Pendente de análise';
      case 'PEN_RESPONSE':
        return 'Pendente de resposta';
      case 'ANSWERED':
        return 'Resposta recebida';
      case 'PEN_REANALYSIS':
        return 'Pendente de reanálise';
      case 'REANALYSIS':
        return 'Em reanálise';
      case 'APPROVED':
        return 'Aprovado';
      case 'REJECTED':
        return 'Recusado';
      case 'ARCHIVED':
        return 'Arquivado';
      case 'RETURNED':
        return 'Devolvido';
      default:
        return 'Justificativa recebida';
    }
  }

  getStatusColor(
    s: string,
    isBackground: boolean = false,
    isBorder: boolean = false,
  ) {
    if (this.role === 'user') {
      if (s === 'DEFAULT' || s === 'PEN_RESPONSE')
        return isBackground
          ? 'bg-blue-status'
          : isBorder
            ? 'border-blue-status'
            : 'text-blue-status';

      if (s === 'PENDING')
        return isBackground
          ? 'bg-orange-status'
          : isBorder
            ? 'border-orange-status'
            : 'text-orange-status';
    }

    if (this.role === 'analyst' || this.role === 'coordinator-manager') {
      if (s === 'PEN_RESPONSE')
        return isBackground
          ? 'bg-blue-status'
          : isBorder
            ? 'border-blue-status'
            : 'text-blue-status';
    }

    switch (s) {
      case 'ANALYSIS':
        return isBackground
          ? 'bg-orange-status'
          : isBorder
            ? 'border-orange-status'
            : 'text-orange-status';
      case 'PEN_REVIEW':
        return isBackground
          ? 'bg-green-status'
          : isBorder
            ? 'border-green-status'
            : 'text-green-status';
      case 'REVIEW':
        return isBackground
          ? 'bg-green-status'
          : isBorder
            ? 'border-green-status'
            : 'text-green-status';
      case 'SECOND_REVIEW':
        return isBackground
          ? 'bg-green-status'
          : isBorder
            ? 'border-green-status'
            : 'text-green-status';
      case 'PENDING':
        return isBackground
          ? 'bg-red-status'
          : isBorder
            ? 'border-red-status'
            : 'text-red-status';
      case 'PEN_ANALYSIS':
        return isBackground
          ? 'bg-red-status'
          : isBorder
            ? 'border-red-status'
            : 'text-red-status';
      case 'PEN_RESPONSE':
        return isBackground
          ? 'bg-red-status'
          : isBorder
            ? 'border-red-status'
            : 'text-red-status';
      case 'ANSWERED':
        return isBackground
          ? 'bg-blue-status'
          : isBorder
            ? 'border-blue-status'
            : 'text-blue-status';
      case 'PEN_REANALYSIS':
        return isBackground
          ? 'bg-orange-status'
          : isBorder
            ? 'border-orange-status'
            : 'text-orange-status';
      case 'REANALYSIS':
        return isBackground
          ? 'bg-orange-status'
          : isBorder
            ? 'border-orange-status'
            : 'text-orange-status';
      case 'APPROVED':
        return isBackground
          ? 'bg-approved-status'
          : isBorder
            ? 'border-approved-status'
            : 'text-approved-status';
      case 'REJECTED':
        return isBackground
          ? 'bg-rejected-status'
          : isBorder
            ? 'border-rejected-status'
            : 'text-rejected-status';
      case 'ARCHIVED':
        return isBackground
          ? 'bg-default-status'
          : isBorder
            ? 'border-default-status'
            : 'text-default-status';
      case 'RETURNED':
        return isBackground
          ? 'bg-red-status'
          : isBorder
            ? 'border-red-status'
            : 'text-red-status';
      default:
        return isBackground
          ? 'bg-blue-status'
          : isBorder
            ? 'border-blue-status'
            : 'text-blue-status';
    }
  }

  getStatusIcon(s: string) {
    if (this.role === 'user' && s === 'DEFAULT') {
      return 'chat_bubble';
    }

    switch (s) {
      case 'ANALYSIS':
        return 'hourglass_empty';
      case 'PEN_REVIEW':
        return 'hourglass_full';
      case 'REVIEW':
        return 'hourglass_full';
      case 'SECOND_REVIEW':
        return 'hourglass_full';
      case 'PENDING':
        return 'hourglass_empty';
      case 'PEN_ANALYSIS':
        return 'hourglass_empty';
      case 'PEN_RESPONSE':
        return 'feedback';
      case 'PEN_REANALYSIS':
        return 'hourglass_empty';
      case 'REANALYSIS':
        return 'hourglass_empty';
      case 'APPROVED':
        return 'check';
      case 'REJECTED':
        return 'close';
      case 'ARCHIVED':
        return 'archive';
      case 'RETURNED':
        return 'reply';
      default:
        return 'chat_bubble';
    }
  }

  getDateString(date: string | undefined): string {
    if (date) return new Date(date).toLocaleString();
    else return '-';
  }

  handleShowJustification(): void {
    this.showJustification = !this.showJustification;
  }

  getStatusByRole(status: string): boolean {
    const userStatus = [
      'ANALISYS',
      'PENDING',
      'REJECTED',
      'APPROVED',
      'ARCHIVED',
    ];

    if (this.role === 'user') {
      return userStatus.indexOf(status) >= 0;
    }

    return true;
  }
}
