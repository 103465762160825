<div class="m-3">
  <div class="card">
    <h1 class="text-2xl font-bold text-blue-700 mb-4">Fazer viabilidade</h1>
    <div class="p-3 mt-2">
      <app-viability-stepper [step]="step"></app-viability-stepper>
    </div>
    <div class="flex flex-col p-3 ml-5">
      <div *ngIf="step === 0">
        <h2 class="text-lg mt-3 font-bold">
          Você têm interesse em criar uma empresa?
        </h2>
        <p>
          Se sim, clique no botão abaixo para ser redirecionado para a Junta
          para criar o seu empreendimento.
        </p>
        <button (click)="createEnterprise()" class="btn btn-primary mt-3 mb-3">
          Criar empreendimento
        </button>
        <p>
          Se você já possui o seu empreendimento, pode só clicar em próximo.
        </p>
      </div>
      <div *ngIf="step === 1">
        <h2 class="text-lg mt-3 font-bold">Qual o seu responsável legal?</h2>
        <div class="mt-3 flex flex-row">
          <div class="pr-2 flex">
            <p class="label mr-3">Eu mesmo</p>
            <input
              [(ngModel)]="responsible"
              type="radio"
              [value]="true"
              name="responsible"
            />
          </div>
          <div class="pr-2 flex">
            <p class="label mr-3">Outra pessoa</p>
            <input
              [(ngModel)]="responsible"
              type="radio"
              [value]="false"
              name="responsible"
            />
          </div>
        </div>
        <div *ngIf="responsible === false" class="mt-3 w-1/3">
          <p class="label">Cpf do responsável</p>
          <input type="text" class="input" />
        </div>
      </div>
      <div *ngIf="step === 2">
        <h2 class="text-lg font-bold mt-3">MOTIVO</h2>
        <div>
          <h2 class="text-lg mt-3">Para o que é sua viabilidade?</h2>
          <div class="mt-3 flex flex-row">
            <button
              (click)="viabilityType = true"
              class="{{
                viabilityType === true ? 'border-blue-700 border-2 ' : ''
              }} flex flex-row border-md shadow-md  rounded-md p-3 text-xl items-center cursor-pointer"
            >
              <ng-icon
                name="matConstruction"
                class="mr-2 text-blue-700"
              ></ng-icon>
              <p>Funcionar</p>
            </button>
            <button
              (click)="viabilityType = false"
              class="{{
                viabilityType === false ? 'border-blue-700 border-2 ' : ''
              }} flex flex-row shadow-md  rounded-md p-3 text-xl items-center cursor-pointer ml-3"
            >
              <ng-icon name="matWork" class="mr-2 text-blue-700 ml-3"></ng-icon>
              <p>Construir</p>
            </button>
          </div>
        </div>
      </div>
      <div *ngIf="step === 3">
        <h2 class="mt-3 text-lg font-bold">TIPOLOGIA</h2>
        <div class="mt-3 flex flex-row flex-wrap">
          <div class="w-1/2 pr-2">
            <p class="label">Selecione a tipologia</p>
            <select [(ngModel)]="viability.tipology" class="input">
              <option [value]="1">Estabelecimento Físico</option>
              <option [value]="2">Domicílio Fiscal</option>
            </select>
          </div>
          <div *ngIf="viability.tipology === 2" class="w-1/2 pr-2">
            <p class="label">Tipo de Domicílio Fiscal</p>
            <select class="input">
              <option value="1">Virtual</option>
              <option value="2">Residencial</option>
              <option value="3">Espaço de Trabalho Coletivo</option>
            </select>
          </div>
        </div>
      </div>
      <div *ngIf="step === 4">
        <h2 class="mt-3 text-lg font-bold">ENDEREÇO</h2>
        <div
          class="text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:text-gray-400 dark:border-gray-700"
        >
          <ul class="flex flex-wrap -mb-px">
            <li class="me-2">
              <button
                (click)="changeTypeAddress('iptu')"
                href="#"
                class="{{
                  typeAddressInput === 'iptu' ? 'tab-active' : 'tab-inactive'
                }}"
              >
                Selecionar por Iptu
              </button>
            </li>
            <li class="me-2">
              <button
                (click)="changeTypeAddress('manual')"
                href="#"
                class="{{
                  typeAddressInput === 'manual' ? 'tab-active' : 'tab-inactive'
                }}"
              >
                Manual
              </button>
            </li>
            <li class="me-2">
              <button
                (click)="changeTypeAddress('map')"
                href="#"
                class="{{
                  typeAddressInput === 'map' ? 'tab-active' : 'tab-inactive'
                }}"
              >
                Pelo Mapa
              </button>
            </li>
          </ul>
        </div>
        <div *ngIf="typeAddressInput === 'map'">
          <app-map></app-map>
        </div>
        <div class="mt-3 flex flex-row flex-wrap">
          <div *ngIf="typeAddressInput === 'iptu'" class="w-full mb-4 pr-2">
            <p class="label">IPTU</p>
            <input type="text" class="input" />
          </div>
          <div class="w-1/2 pr-2">
            <p class="label">CEP</p>
            <input type="text" class="input" />
          </div>
          <div class="w-1/2 pr-2">
            <p class="label">Logradouro</p>
            <input type="text" class="input" />
          </div>
          <div class="w-1/2 pr-2 mt-2">
            <p class="label">Número</p>
            <input type="text" class="input" />
          </div>
          <div class="w-1/2 pr-2 mt-2">
            <p class="label">Complemento</p>
            <input type="text" class="input" />
          </div>
        </div>
      </div>
      <div *ngIf="step === 5">
        <h2 class="mt-3 text-lg font-bold">ÁREA DO TERRENO</h2>
        <div class="mt-3 flex flex-row flex-wrap">
          <div class="w-1/3 pr-2">
            <p class="label">Área Total</p>
            <input type="text" class="input" />
          </div>
          <div class="w-1/3 pr-2">
            <p class="label">Área Utilizada</p>
            <input type="text" class="input" />
          </div>
          <div class="w-1/3 pr-2">
            <p class="label">Área Remanescente</p>
            <input type="text" class="input" />
          </div>
        </div>
      </div>
      <div *ngIf="step === 6">
        <h2 class="mt-3 text-lg font-bold">ÁREA CONTRUÍDA</h2>
        <div class="mt-3 flex flex-row flex-wrap">
          <div class="w-1/3 pr-2 mt-2">
            <p class="label">Área do Estabelecimento</p>
            <input type="text" class="input" />
          </div>
          <div class="w-1/3 pr-2 mt-2">
            <p class="label">Área Construída Útil</p>
            <input type="text" class="input" />
          </div>
          <div class="w-1/3 pr-2 mt-2">
            <p class="label">Área Não-Utilizada</p>
            <input type="text" class="input" />
          </div>
        </div>
      </div>
      <div *ngIf="step === 7">
        <h2 class="mt-3 text-lg">
          Qual o seu CNAE de atividade <span class="font-bold">principal</span>?
        </h2>
        <div class="mt-3">
          <div class="flex flex-row w-full">
            <input
              type="text"
              placeholder="Selecione o CNAE"
              aria-label="Number"
              matInput
              class="w-full rounded-md"
              [formControl]="cnaeControl"
              [matAutocomplete]="auto"
            />
            <mat-autocomplete #auto="matAutocomplete">
              @for (option of filteredOptions | async; track option) {
                <mat-option [value]="option">{{ option }}</mat-option>
              }
            </mat-autocomplete>
            <button class="btn btn-primary ml-2">Adicionar</button>
          </div>
          <div>
            <app-table [columns]="columnsCnaes" [data]="cnaes" />
          </div>
        </div>

        <h2 class="mt-5 text-lg">
          Quais os seus CNAEs de atividades secundárias que são exercidos no
          local?
        </h2>
        <div class="mt-3">
          <div class="flex flex-row w-full">
            <input
              type="text"
              placeholder="Selecione o CNAE"
              aria-label="Number"
              matInput
              class="w-full rounded-md"
              [formControl]="cnaeControl"
              [matAutocomplete]="auto"
            />
            <mat-autocomplete #auto="matAutocomplete">
              @for (option of filteredOptions | async; track option) {
                <mat-option [value]="option">{{ option }}</mat-option>
              }
            </mat-autocomplete>
            <button class="btn btn-primary ml-2">Adicionar</button>
          </div>
          <div>
            <app-table [columns]="columnsCnaes" [data]="cnaes" />
          </div>
        </div>

        <h2 class="mt-5 text-lg">
          Quais os seus CNAEs de atividades secundárias que
          <span class="font-bold">não</span> são exercidos no local?
        </h2>
        <div class="mt-3">
          <div class="flex flex-row w-full">
            <input
              type="text"
              placeholder="Selecione o CNAE"
              aria-label="Number"
              matInput
              class="w-full rounded-md"
              [formControl]="cnaeControl"
              [matAutocomplete]="auto"
            />
            <mat-autocomplete #auto="matAutocomplete">
              @for (option of filteredOptions | async; track option) {
                <mat-option [value]="option">{{ option }}</mat-option>
              }
            </mat-autocomplete>
            <button class="btn btn-primary ml-2">Adicionar</button>
          </div>
          <div>
            <app-table [columns]="columnsCnaes" [data]="cnaes" />
          </div>
        </div>
      </div>
    </div>
    <div class="mt-5 flex justify-end">
      <button
        *ngIf="step !== 0"
        (click)="back()"
        class="btn btn-secondary mr-2"
      >
        Anterior
      </button>
      <button *ngIf="step !== 7" (click)="next()" class="btn btn-primary">
        Próximo
      </button>
      <button
        *ngIf="step === 7"
        (click)="doViability()"
        class="btn btn-primary"
      >
        Efetuar viabilidade
      </button>
    </div>
  </div>
</div>
