<div
  *ngFor="let alert of alerts"
  class="alert"
  [class]="classExpression"
  [ngClass]="{
    'alert-enter': alert.visible,
    'alert-exit': !alert.visible
  }"
  [style]="
    top && right
      ? 'top:' + top + 'rem; right:' + right + 'rem;'
      : top
      ? 'top:' + top + 'rem;'
      : right
      ? 'right:' + right + 'rem;'
      : ''
  "
>
  <span>{{ alert.message }}</span>
  <button type="button" (click)="close(alert.id)">Fechar</button>
</div>
