/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Component, inject, OnInit } from '@angular/core';
import { TableComponent } from '../../components/table/table.component';
import { FilterBoxComponent } from '../../components/filter-box/filter-box.component';
import { Router } from '@angular/router';
import { EmployeeService } from '../../services/employee.service';
import {
  EmployeeInterface,
  UpdateStatusEmployeesInputInterface,
  UpdateStatusEmployeesInterface,
} from '../../models/employee.model';
import { AlertService } from '../../services/alert.service';
import { HttpErrorResponse } from '@angular/common/http';
import { AlertComponent } from '../../components/alert/alert.component';
import { AuthService } from '../../services/auth.service';
import { LockedIconComponent } from '../../icons/employees/locked-icon/locked-icon.component';
import { UnlockedIconComponent } from '../../icons/employees/unlocked-icon/unlocked-icon.component';
import { EditIconComponent } from '../../icons/cnaes/edit-icon/edit-icon.component';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { CommonModule } from '@angular/common';
import {
  matArrowBackIos,
  matArrowForwardIos,
  matSync,
} from '@ng-icons/material-icons/baseline';
import { EyeIconComponent } from '../../icons/edit-zone/eye-icon/eye-icon.component';
import { catchError, tap, throwError } from 'rxjs';
import { MatIcon } from '@angular/material/icon';
import {
  BreadcrumbsComponent,
  IRoute,
} from '../../components/breadcrumbs/breadcrumbs.component';

@Component({
  selector: 'app-employees',
  standalone: true,
  imports: [
    TableComponent,
    FilterBoxComponent,
    AlertComponent,
    LockedIconComponent,
    UnlockedIconComponent,
    EditIconComponent,
    NgIconComponent,
    CommonModule,
    EyeIconComponent,
    MatIcon,
    BreadcrumbsComponent,
  ],
  providers: [
    provideIcons({
      matSync,
      matArrowBackIos,
      matArrowForwardIos,
    }),
  ],
  templateUrl: './employees.component.html',
  styleUrl: './employees.component.scss',
})
export class EmployeesComponent implements OnInit {
  routes: IRoute[] = [
    {
      label: 'Servidores',
      link: { route: `/servers` },
    },
  ];

  authService = inject(AuthService);

  loading: boolean = true;
  type: string | null = null;
  loadingStatus: boolean = false;

  page: number = 1;
  limit: number = 10;
  keyword: string = '';
  defaultOrderBy: string = 'desc';
  currentOrderField: string = '';

  pages: number[] = [];

  constructor(
    private router: Router,
    private employeeService: EmployeeService,
    private alertService: AlertService,
  ) {}

  data: EmployeeInterface[] = [];
  searchText: string = '';

  generatePagesByFounded(total: number): void {
    const pages = Math.ceil(total / this.limit);
    this.pages = Array.from({ length: pages }, (_, i) => i + 1);
  }

  handleGetEmployees = (): void => {
    this.type = this.authService.getDecodedUser()?.role ?? 'user';
    const userId = this.authService.getDecodedUser()?.ID ?? '';

    this.employeeService
      .findAll(
        this.page,
        this.limit,
        this.keyword,
        this.defaultOrderBy,
        this.currentOrderField,
      )
      .pipe(
        tap((response) => {
          if (response) {
            this.data = response.result
              .filter((emp) => emp.ID !== userId)
              .map((emp) => {
                return {
                  ...emp,
                  stringRole: this.getStringRole(emp.role),
                };
              });
            this.generatePagesByFounded(response.filter.founded);
          } else {
            this.data = [];
            this.generatePagesByFounded(0);
          }

          this.loading = false;
        }),
        catchError((error) => {
          console.error('Error fetching macrozones:', error);
          return throwError(() => error);
        }),
      )
      .subscribe();
  };

  ngOnInit(): void {
    this.handleGetEmployees();
  }

  handleLimitChange(event: Event, key: string): void {
    const input = event.target as HTMLInputElement;

    if (key === 'limit') this.limit = Number(input.value);

    this.handleGetEmployees();
  }

  handleSetPage(page: number): void {
    this.page = page;

    this.handleGetEmployees();
  }

  handlePageChange(key: string): void {
    if (key === 'next') {
      this.page = this.page + 1;
    } else if (this.page > 1) {
      this.page = this.page - 1;
    }

    this.handleGetEmployees();
  }

  handleStatus = (email: string) => {
    if (!this.loadingStatus) {
      this.loadingStatus = true;
      this.employeeService.updateStatusEmployee({ email }).subscribe({
        next: (response) => {
          this.loadingStatus = false;
          this.loading = true;
          this.handleGetEmployees();
          this.alertService.add({
            id: 'employee-field-success',
            type: 'success',
            message: 'Status do funcionário atualizado com sucesso.',
            top: 5,
            right: 1,
          });
        },
        error: (e: HttpErrorResponse) => {
          this.loadingStatus = false;
          console.error(e),
            this.alertService.add({
              id: 'employee-field-error',
              type: 'error',
              message: 'Houve um erro ao atualizar o status do  funcionário.',
              top: 5,
              right: 1,
            });
        },
      });
    }
  };

  public newEmployee = () => {
    this.router.navigate(['/novo-funcionario']);
  };

  getPermission = (user_role: string): boolean => {
    if (this.type === 'manager') {
      return true;
    } else if (
      this.type === 'mayor-secretary' &&
      (user_role === 'server' ||
        user_role === 'analyst' ||
        user_role === 'coordinator-manager')
    ) {
      return true;
    } else if (
      this.type === 'coordinator-manager' &&
      (user_role === 'server' || user_role === 'analyst')
    ) {
      return true;
    } else return false;
  };

  doFilter(eventData: any) {
    this.searchText = eventData;

    if (this.searchText) {
      const dto = {
        searchText: this.searchText, // Garanta que a chave esteja correta conforme a definição do backend
      };

      this.employeeService.findUsersByFilteredValue(dto).subscribe({
        next: (response) => {
          if (response) {
            this.data = response;
          }
        },
        error: (err) => {
          this.alertService.add({
            id: 'filter-field-error',
            type: 'error',
            message: 'Servidores não encontrados',
            top: 5,
            right: 1,
          });
        },
      });
    } else {
      this.alertService.add({
        id: 'filter-field-error',
        type: 'error',
        message: 'Campo pesquisa não pode ser vazio!',
        top: 5,
        right: 1,
      });
    }
  }

  getStringRole(role: string): string {
    switch (role) {
      case 'server':
        return 'Servidor';
      case 'analyst':
        return 'Analista';
      case 'coordinator-manager':
        return 'Coordenador/Gestor';
      case 'mayor-secretary':
        return 'Secretário/Prefeito';
      case 'user':
        return 'Cidadão';
      default:
        return 'Administrador';
    }
  }

  doClean(eventData: any) {
    this.searchText = eventData;

    if (eventData == '') {
      this.employeeService
        .findAll(
          this.page,
          this.limit,
          this.keyword,
          this.defaultOrderBy,
          this.currentOrderField,
        )
        .subscribe({
          next: (response) => {
            if (response) {
              this.data = response.result.map((emp) => {
                return {
                  ...emp,
                  stringRole: this.getStringRole(emp.role),
                };
              });
            }
          },
        });
    }
  }

  toEdit(id: string) {
    this.router.navigate([`/update-user/${id}`]);
  }
}
