<div>
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="card">
      <h1 class="font-medium text-2xl">
        Tipo de viabilidade<font color="red">*</font>
      </h1>
      <h3>Informe o tipo de viabilidade do empreendimento.</h3>
      <div class="flex flex-col md:flex-row gap-4 w-full h-full my-4">
        <mat-card
          class="bg-orange-400 hover:bg-orange-600 hover:cursor-pointer w-full text-white py-4"
          (click)="setTypeOfViability(0)"
        >
          <div class="flex flex-col items-center">
            <mat-icon
              class="mt-4"
              aria-hidden="false"
              fontIcon="home_work"
            ></mat-icon>
            <mat-card-content>Funcionamento</mat-card-content>
          </div>
        </mat-card>
        <mat-card
          class="bg-blue-700 hover:bg-blue-400 hover:cursor-not-allowed w-full text-white py-4"
        >
          <div class="flex flex-col items-center">
            <mat-icon aria-hidden="false" fontIcon="construction"></mat-icon>
            <mat-card-content>Construção</mat-card-content>
          </div>
        </mat-card>
      </div>
      <!-- <mat-divider class="my-4"></mat-divider>
      <h3>Deseja abrir o CNPJ do empreendimento?<font color="red">*</font></h3>
      <div class="flex flex-row gap-8 my-4">
        <button color="primary" mat-stroked-button disabled>
          <mat-icon fontIcon="exit_to_app"></mat-icon><span>Abrir CNPJ</span>
        </button>
        <div>
          <mat-checkbox
            class="mr-3"
            color="primary"
            formControlName="isToOpenCNPJ"
          >
            Não desejo abrir CNPJ
          </mat-checkbox>
        </div>
      </div> -->
    </div>
    <div *ngIf="this.formControl('typeOfViability')?.value === 0" class="card">
      <h1 class="font-medium text-2xl">
        Tipologia do empreendimento<font color="red">*</font>
      </h1>
      <h3>Preencha as informações abaixo para continuar sua solicitação.</h3>
      <div class="flex flex-col md:flex-row gap-4 my-4">
        <div class="flex flex-col">
          <label for="typeOfEnterprise" class="font-medium text-md">
            Tipologia do empreendimento<font color="red">*</font>
          </label>
          <select
            class="text-sm text-left font-normal text-gray-900 dark:text-white rounded"
            formControlName="typeOfEnterprise"
            (change)="changeTypeOfEnterprise($event)"
          >
            <option
              *ngFor="let opt of typeOfEnterpriseOptions"
              class="text-sm font-normal"
              [value]="opt.value"
              [selected]="opt.value === getFormValue('typeOfEnterprise')"
            >
              {{ opt.label }}
            </option>
          </select>
          <div
            class="w-full flex flex-row justify-start text-xs font-medium text-red-500 mt-1"
            *ngIf="
              (form.controls['typeOfEnterprise'].touched ||
                form.controls['typeOfEnterprise'].dirty) &&
              form.controls['typeOfEnterprise'].errors &&
              form.controls['typeOfEnterprise'].errors['required']
            "
          >
            Tipologia do empreendimento é obrigatório.
          </div>
        </div>
        <div
          *ngIf="this.form.get('typeOfEnterprise')?.value === '1'"
          class="flex flex-col"
        >
          <label for="typeOfEnterprise" class="font-medium text-md">
            Tipo de Domicílio Fiscal<font color="red">*</font>
          </label>
          <select
            class="text-sm text-left font-normal text-gray-900 dark:text-white rounded"
            formControlName="typeOfTaxDomicile"
          >
            <option
              *ngFor="let opt of typeOfTaxDomicileOptions"
              class="text-sm font-normal"
              [value]="opt.value"
              [selected]="opt.value === getFormValue('typeOfTaxDomicile')"
            >
              {{ opt.label }}
            </option>
          </select>

          <div
            class="w-full flex flex-row justify-start text-xs font-medium text-red-500 mt-1"
            *ngIf="
              (form.controls['typeOfTaxDomicile'].touched ||
                form.controls['typeOfTaxDomicile'].dirty) &&
              form.controls['typeOfTaxDomicile'].errors &&
              form.controls['typeOfTaxDomicile'].errors['required']
            "
          >
            Tipo de Domicílio Fiscal é obrigatório.
          </div>
        </div>
      </div>
    </div>
    <div class="w-full flex flex-row justify-end items-center mt-6">
      <button
        type="button"
        class="btn btn-secondary text-sm"
        (click)="this.setStep.emit(1)"
      >
        Voltar
      </button>
      <button
        class="btn btn-primary text-sm ml-2 disabled:bg-opacity-65"
        [disabled]="!form.valid"
        type="submit"
      >
        Próximo
      </button>
    </div>
  </form>
</div>
