<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<!-- Modal Secundária -->
<div
  *ngIf="showSecondarySelected"
  class="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50"
>
  <div
    class="relative top-20 mx-auto p-4 border w-[70%] max-h-[80%] overflow-y-auto max-w-screen-lg shadow-lg rounded bg-white flex flex-col justify-start items-start"
  >
    <h3 class="text-lg font-medium">Atividades Selecionadas</h3>
    <p class="text-sm text-gray-700">
      {{
        !showNotSecondarySelected
          ? "Atividades secundárias exercidas no endereço informado."
          : "Atividades secundárias NÃO exercidas no endereço informado."
      }}
    </p>

    <mat-divider></mat-divider>

    <table class="w-full mt-3">
      <thead>
        <tr>
          <th></th>
          <th>
            <div class="w-full flex flex-row justify-start items-center p-2">
              <p class="text-sm font-medium">Código</p>
              <mat-icon
                class="text-sm ml-1 mt-1 text-black"
                fontIcon="unfold_more"
              ></mat-icon>
            </div>
          </th>
          <th>
            <div class="w-full flex flex-row justify-start items-center p-2">
              <p class="text-sm font-medium">Denominação</p>
              <mat-icon
                class="text-sm ml-1 mt-1 text-black"
                fontIcon="unfold_more"
              ></mat-icon>
            </div>
          </th>
          <th>
            <div class="w-full flex flex-row justify-start items-center p-2">
              <p class="text-sm font-medium">Risco</p>
              <mat-icon
                class="text-sm ml-1 mt-1 text-black"
                fontIcon="unfold_more"
              ></mat-icon>
            </div>
          </th>
        </tr>
      </thead>
      <tbody
        *ngIf="
          showSecondarySelectedData && showSecondarySelectedData.length > 0
        "
        class="pointer-events-none cursor-not-allowed"
      >
        <tr
          *ngFor="
            let secondSelectedCnae of showSecondarySelectedData;
            let i = index
          "
        >
          <td>
            <div class="w-full flex flex-row justify-start items-center p-2">
              <input type="checkbox" checked />
            </div>
          </td>
          <td>
            <div class="w-full flex flex-row justify-start items-center p-2">
              <p class="text-sm font-normal">{{ secondSelectedCnae.code }}</p>
            </div>
          </td>
          <td>
            <div class="w-full flex flex-row justify-start items-center p-2">
              <p class="text-sm font-normal uppercase">
                {{ secondSelectedCnae.name }}
              </p>
            </div>
          </td>
          <td>
            <div class="w-full flex flex-row justify-start items-center p-2">
              <p class="text-sm font-normal capitalize">
                {{
                  getRisk(
                    getFinalRisk(
                      secondSelectedCnae.environmentalRisk,
                      secondSelectedCnae.healthRisk
                    )
                  )
                }}
              </p>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <div class="w-full flex flex-row justify-end items-center mt-6">
      <button
        type="button"
        class="btn btn-secondary text-sm"
        (click)="handleShowSecondarySelected('close')"
      >
        Fechar
      </button>
    </div>
  </div>
</div>
<!-- Fim Modal Secundária -->

<div>
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="card">
      <h1 class="font-medium text-2xl">Atividade principal</h1>
      <div *ngIf="!isReview" class="flex flex-col md:flex-row gap-4 mb-4 mt-3">
        <div class="w-full">
          <div class="flex flex-col w-full">
            <label for="keyword"
              >Selecione sua atividade (CNAE) principal<font color="red"
                >*</font
              >
            </label>
            <div class="relative flex flex-col sm:flex-row gap-4 w-full mt-1">
              <input
                id="keyword"
                name="keyword"
                type="search"
                placeholder="Pesquisar por palavra-chave ou código da atividade (CNAE)"
                class="w-full input"
                (input)="changeSearchField($event)"
                [value]="this.searchValue"
              />
              <div
                #suggestDiv
                *ngIf="showSuggestions"
                class="absolute left-0 top-full bg-white p-2 rounded border border-solid border-gray-300 shadow-md w-full z-10"
              >
                <div
                  *ngFor="let cnae of suggestMainCnaes; let i = index"
                  class="w-full flex flex-row justify-start items-center text-sm font-medium hover:bg-gray-300 bg-white p-2 hover:cursor-pointer"
                  (click)="handleSelectSuggestions(cnae)"
                >
                  <p class="mr-6">{{ cnae.code }}</p>
                  <p>{{ cnae.name }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <mat-divider *ngIf="!isReview"></mat-divider>

      <div class="w-full grid grid-cols-1 md:grid-cols-6 gap-4 mt-3">
        <div class="flex flex-col w-full md:col-span-1">
          <label for="code">Código</label>
          <div class="flex flex-col sm:flex-row gap-4 w-full mt-1">
            <input
              type="text"
              placeholder="000-0/00"
              mask="000-0/00"
              [ngClass]="
                isReview ? 'w-full input bg-slate-100' : 'w-full input'
              "
              readonly
              [value]="!isReview ? mainCnae?.code : data.mainActivity.code"
              [readOnly]="isReview"
            />
          </div>
        </div>
        <div class="flex flex-col w-full md:col-span-3">
          <label for="denomination">Denominação</label>
          <div class="flex flex-col sm:flex-row gap-4 w-full mt-1">
            <input
              type="text"
              placeholder="Denominação"
              [ngClass]="
                isReview ? 'w-full input bg-slate-100' : 'w-full input'
              "
              readonly
              [value]="!isReview ? mainCnae?.name : data.mainActivity.name"
              [readOnly]="isReview"
            />
          </div>
        </div>
        <div class="flex flex-col w-full md:col-span-2">
          <label for="risk">Risco da atividade</label>
          <div class="flex flex-col sm:flex-row gap-4 w-full mt-1">
            <input
              type="text"
              placeholder="Risco"
              [ngClass]="
                isReview ? 'w-full input bg-slate-100' : 'w-full input'
              "
              readonly
              [value]="
                getRisk(
                  !isReview ? mainCnae?.finalRisk : data.mainActivity.finalRisk
                )
              "
              [readOnly]="isReview"
            />
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="cnaeQuestions.length > 0" class="card">
      <div class="w-full flex flex-row justify-start items-center">
        <h1 class="font-medium text-2xl mr-4">
          Responda sobre sua atividade principal
        </h1>
        <mat-icon
          [ngClass]="
            showQuestions
              ? 'text-slate-800 rotate-[-90deg]'
              : 'text-slate-800 rotate-90'
          "
          fontIcon="arrow_forward_ios"
          (click)="handleShowQuestions()"
        ></mat-icon>
      </div>

      <div formGroupName="mainActivity">
        <div
          formArrayName="questions"
          *ngFor="let question of questionsFormArray.value; let i = index"
        >
          <div
            [formGroupName]="i"
            [ngClass]="
              showQuestions
                ? 'flex flex-col justify-start items-start mt-4'
                : 'hidden'
            "
          >
            <p class="text-md">
              {{ question.description }}
            </p>

            <div
              class="w-full md:w-1/2 flex flex-row justify-start items-center mt-2"
            >
              <div class="w-1/2 flex flex-col justify-start items-start mr-2">
                <label for="answer-{{ i }}" class="text-xs"
                  >Selecione sua resposta<font color="red">*</font></label
                >
                <select
                  id="answer-{{ i }}"
                  class="input"
                  formControlName="answer"
                  (input)="handleSelectAnswer($event, i)"
                >
                  <option selected disabled>Selecione</option>
                  <option value="yes">Sim</option>
                  <option value="no">Não</option>
                </select>
              </div>
              <div class="w-1/2 flex flex-col justify-start items-start">
                <label for="risk-{{ i }}" class="text-xs">Risco</label>
                <input
                  id="risk-{{ i }}"
                  name="risk"
                  class="input"
                  type="text"
                  [value]="getRisk(getQuestionRisk(question))"
                  placeholder="À calcular"
                  readonly
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <mat-divider class="mt-4 mb-4"></mat-divider>

      <div class="w-1/4 flex flex-col justify-start items-start">
        <label for="final_risk" class="text-xs"
          >Risco calculado para atividade principal</label
        >
        <input
          id="final_risk"
          name="final_risk"
          class="input"
          type="text"
          placeholder="À calcular"
          readonly
          [value]="getRisk(getFinalRiskByName('questions'))"
        />
      </div>
    </div>

    <div
      *ngIf="
        (isReview && secondaryCnaes && secondaryCnaes.length > 0) || !isReview
      "
      class="card"
    >
      <h1 class="font-medium text-2xl">
        Atividades secundárias exercidas no endereço informado
      </h1>

      <div *ngIf="!isReview" class="flex flex-col w-full mt-3">
        <label for="keyword"
          >Selecione suas atividades (CNAEs) secundárias.
        </label>
        <div class="flex flex-col sm:flex-row gap-4 w-full mt-1">
          <input
            id="keyword"
            name="keyword"
            type="search"
            placeholder="Pesquisar por palavra-chave ou código da atividade (CNAE)"
            class="w-full input"
            (input)="changeSecondarySearchField($event)"
          />
          <input
            class="btn btn-primary h-full disabled:opacity-65"
            type="button"
            (click)="
              handleSearchSecondaryCnaes(
                searchSecondaryValue,
                secondaryPage,
                secondaryLimit
              )
            "
            [disabled]="loadingSecondaryCnaes"
            value="Pesquisar"
          />
          <button
            type="button"
            [ngClass]="
              secondarySelectedCnaes.length > 0
                ? 'btn btn-primary h-full'
                : 'btn btn-primary h-full opacity-65 pointer-events-none cursor-not-allowed'
            "
            (click)="handleShowSecondarySelected('secondary')"
          >
            Visualizar
          </button>
        </div>
      </div>

      <table class="w-full mt-3">
        <thead>
          <tr>
            <th></th>
            <th>
              <div class="w-full flex flex-row justify-start items-center p-2">
                <p class="text-sm font-medium">Código</p>
                <mat-icon
                  class="text-sm ml-1 mt-1 text-black"
                  fontIcon="unfold_more"
                ></mat-icon>
              </div>
            </th>
            <th>
              <div class="w-full flex flex-row justify-start items-center p-2">
                <p class="text-sm font-medium">Denominação</p>
                <mat-icon
                  class="text-sm ml-1 mt-1 text-black"
                  fontIcon="unfold_more"
                ></mat-icon>
              </div>
            </th>
            <th>
              <div class="w-full flex flex-row justify-start items-center p-2">
                <p class="text-sm font-medium">Risco</p>
                <mat-icon
                  class="text-sm ml-1 mt-1 text-black"
                  fontIcon="unfold_more"
                ></mat-icon>
              </div>
            </th>
          </tr>
        </thead>
        <tbody
          *ngIf="secondaryCnaes && secondaryCnaes.length > 0 && !isReview"
          [ngClass]="
            loadingSecondarySelect
              ? 'opacity-40 cursor-not-allowed pointer-events-none'
              : ''
          "
        >
          <tr *ngFor="let secondCnae of secondaryCnaes; let i = index">
            <td>
              <div class="w-full flex flex-row justify-start items-center p-2">
                <input
                  type="checkbox"
                  (change)="handleSelectSecondaryCnae($event, secondCnae)"
                  [checked]="getSelected(secondCnae)"
                />
              </div>
            </td>
            <td>
              <div class="w-full flex flex-row justify-start items-center p-2">
                <p class="text-sm font-normal">{{ secondCnae.code }}</p>
              </div>
            </td>
            <td>
              <div class="w-full flex flex-row justify-start items-center p-2">
                <p class="text-sm font-normal uppercase">
                  {{ secondCnae.name }}
                </p>
              </div>
            </td>
            <td>
              <div class="w-full flex flex-row justify-start items-center p-2">
                <p class="text-sm font-normal capitalize">
                  {{
                    getRisk(
                      getFinalRisk(
                        secondCnae.environmentalRisk,
                        secondCnae.healthRisk
                      )
                    )
                  }}
                </p>
              </div>
            </td>
          </tr>
        </tbody>
        <tbody *ngIf="isReview">
          <tr
            *ngFor="
              let secondCnae of data.secondaryActivitiesCarriedOut;
              let i = index
            "
          >
            <td>
              <div class="w-full flex flex-row justify-start items-center p-2">
                <input type="checkbox" checked readonly />
              </div>
            </td>
            <td>
              <div class="w-full flex flex-row justify-start items-center p-2">
                <p class="text-sm font-normal">{{ secondCnae.code }}</p>
              </div>
            </td>
            <td>
              <div class="w-full flex flex-row justify-start items-center p-2">
                <p class="text-sm font-normal uppercase">
                  {{ secondCnae.name }}
                </p>
              </div>
            </td>
            <td>
              <div class="w-full flex flex-row justify-start items-center p-2">
                <p class="text-sm font-normal capitalize">
                  {{
                    getRisk(
                      getFinalRisk(
                        secondCnae.environmentalRisk,
                        secondCnae.healthRisk
                      )
                    )
                  }}
                </p>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div
        *ngIf="
          secondaryCnaes &&
          secondaryCnaes.length === 0 &&
          !loadingSecondaryCnaes
        "
        class="w-full flex flex-row justify-center py-4"
      >
        <div class="flex p-3 flex-row items-center">
          <p class="text-sm font-medium">Nenhum registro encontrado.</p>
        </div>
      </div>

      <div
        *ngIf="loadingSecondaryCnaes"
        class="w-full flex flex-row justify-center py-4"
      >
        <div class="flex p-3 flex-row items-center">
          <mat-icon
            class="animate-spin text-black mr-2"
            aria-hidden="false"
            fontIcon="refresh"
          ></mat-icon>
          <p class="text-sm font-medium">Carregando dados...</p>
        </div>
      </div>

      <div
        *ngIf="!loadingSecondaryCnaes && !isReview"
        class="flex flex-row justify-between mt-4"
      >
        <select
          class="w-16 mt-3 p-1 rounded-md"
          (input)="handleLimitChange($event, 'limit', 'secondary')"
        >
          <option value="10" [selected]="secondaryLimit === 10">10</option>
          <option value="20" [selected]="secondaryLimit === 20">20</option>
          <option value="50" [selected]="secondaryLimit === 50">50</option>
        </select>

        <nav
          class="mt-3 flex flex-row justify-end"
          aria-label="Page navigation example"
        >
          <ul class="flex items-center -space-x-px h-10 text-base">
            <li>
              <div
                [ngClass]="
                  secondaryPage > 1
                    ? 'flex cursor-pointer items-center justify-center px-4 h-10 ms-0 leading-tight text-gray-500 bg-white border border-e-0 border-gray-300 rounded-s-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white'
                    : 'opacity-40 pointer-events-none cursor-not-allowed flex cursor-pointer items-center justify-center px-4 h-10 ms-0 leading-tight text-gray-500 bg-white border border-e-0 border-gray-300 rounded-s-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white'
                "
                (click)="handlePageChange('previous', 'secondary')"
              >
                <span class="sr-only">Previous</span>
                <ng-icon name="matArrowBackIos"></ng-icon>
              </div>
            </li>
            <div
              class="flex cursor-pointer items-center justify-center px-4 h-10 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
            >
              {{ secondaryPage }}
            </div>
            <li>
              <div
                class="flex cursor-pointer items-center justify-center px-4 h-10 leading-tight text-gray-500 bg-white border border-gray-300 rounded-e-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                (click)="handlePageChange('next', 'secondary')"
              >
                <span class="sr-only">Next</span>
                <ng-icon name="matArrowForwardIos"></ng-icon>
              </div>
            </li>
          </ul>
        </nav>
      </div>
    </div>

    <mat-divider *ngIf="secondaryCnaeQuestions.length > 0"></mat-divider>

    <div *ngIf="secondaryCnaeQuestions.length > 0" class="card">
      <div class="w-full flex flex-row justify-start items-center">
        <h1 class="font-medium text-2xl mr-4">
          Responda sobre suas atividades secundárias
        </h1>
        <mat-icon
          [ngClass]="
            showSecondaryQuestions
              ? 'text-slate-800 rotate-[-90deg]'
              : 'text-slate-800 rotate-90'
          "
          fontIcon="arrow_forward_ios"
          (click)="handleShowSecondaryQuestions()"
        ></mat-icon>
      </div>

      <div>
        <div *ngFor="let question of secondaryCnaeQuestions; let i = index">
          <div
            [ngClass]="
              showSecondaryQuestions
                ? 'flex flex-col justify-start items-start mt-4'
                : 'hidden'
            "
          >
            <p class="text-md">
              {{ question.description }}
            </p>

            <div
              class="w-full md:w-1/2 flex flex-row justify-start items-center mt-2"
            >
              <div class="w-1/2 flex flex-col justify-start items-start mr-2">
                <label for="answer-{{ i }}" class="text-xs"
                  >Selecione sua resposta</label
                >
                <select
                  id="answer-{{ i }}"
                  class="input"
                  (input)="handleSelectAnswerSecondary($event, question)"
                >
                  <option selected disabled>Selecione</option>
                  <option value="yes" [selected]="question.answer === 'yes'">
                    Sim
                  </option>
                  <option value="no" [selected]="question.answer === 'no'">
                    Não
                  </option>
                </select>
              </div>
              <div class="w-1/2 flex flex-col justify-start items-start">
                <label for="risk-{{ i }}" class="text-xs">Risco</label>
                <input
                  id="risk-{{ i }}"
                  name="risk"
                  class="input"
                  type="text"
                  [value]="getRisk(getQuestionRisk(question))"
                  placeholder="À calcular"
                  readonly
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <mat-divider class="mt-4 mb-4"></mat-divider>

      <div class="w-1/4 flex flex-col justify-start items-start">
        <label for="final_risk" class="text-xs"
          >Risco calculado para atividades secundárias</label
        >
        <input
          id="final_risk"
          name="final_risk"
          class="input"
          type="text"
          placeholder="À calcular"
          readonly
          [value]="getRisk(getFinalRiskByName('secondary_questions'))"
        />
      </div>
    </div>

    <mat-divider></mat-divider>

    <div
      *ngIf="
        (isReview && notSecondaryCnaes && notSecondaryCnaes.length > 0) ||
        !isReview
      "
      class="card"
    >
      <h1 class="font-medium text-2xl">
        Atividades secundárias
        <strong><font color="red">NÃO</font></strong> exercidas no endereço
        informado
      </h1>

      <div *ngIf="!isReview" class="flex flex-col w-full mt-3">
        <label for="keyWord-2"
          >Selecione suas atividades (CNAEs) secundárias que
          <strong>NÃO</strong> são exercidas no endereço cadastrado do
          empreendimento.
        </label>
        <div class="flex flex-col sm:flex-row gap-4 w-full mt-1">
          <input
            id="keyWord-2"
            name="keyWord-2"
            type="search"
            placeholder="Pesquisar por palavra-chave ou código da atividade (CNAE)"
            class="w-full input"
            (input)="changeNotSecondarySearchField($event)"
          />
          <input
            class="btn btn-primary h-full disabled:opacity-65"
            type="button"
            (click)="
              handleSearchNotSecondaryCnaes(
                searchNotSecondaryValue,
                notSecondaryPage,
                notSecondaryLimit
              )
            "
            [disabled]="loadingSecondaryNotCnaes"
            value="Pesquisar"
          />
          <button
            type="button"
            [ngClass]="
              notSecondarySelectedCnaes.length > 0
                ? 'btn btn-primary h-full'
                : 'btn btn-primary h-full opacity-65 pointer-events-none cursor-not-allowed'
            "
            (click)="handleShowSecondarySelected('notSecondary')"
          >
            Visualizar
          </button>
        </div>
      </div>

      <table class="w-full mt-3">
        <thead>
          <tr>
            <th></th>
            <th>
              <div class="w-full flex flex-row justify-start items-center p-2">
                <p class="text-sm font-medium">Código</p>
                <mat-icon
                  class="text-sm ml-1 mt-1 text-black"
                  fontIcon="unfold_more"
                ></mat-icon>
              </div>
            </th>
            <th>
              <div class="w-full flex flex-row justify-start items-center p-2">
                <p class="text-sm font-medium">Denominação</p>
                <mat-icon
                  class="text-sm ml-1 mt-1 text-black"
                  fontIcon="unfold_more"
                ></mat-icon>
              </div>
            </th>
            <th>
              <div class="w-full flex flex-row justify-start items-center p-2">
                <p class="text-sm font-medium">Risco</p>
                <mat-icon
                  class="text-sm ml-1 mt-1 text-black"
                  fontIcon="unfold_more"
                ></mat-icon>
              </div>
            </th>
          </tr>
        </thead>
        <tbody
          *ngIf="notSecondaryCnaes && notSecondaryCnaes.length > 0 && !isReview"
          formArrayName="secondaryActivitiesNotCarriedOut"
        >
          <tr *ngFor="let notSecondCnae of notSecondaryCnaes; let i = index">
            <td>
              <div class="w-full flex flex-row justify-start items-center p-2">
                <input
                  type="checkbox"
                  (change)="handleSelectNotSecondaryCnae($event, notSecondCnae)"
                  [checked]="getSelectedNotSecondary(notSecondCnae)"
                />
              </div>
            </td>
            <td>
              <div class="w-full flex flex-row justify-start items-center p-2">
                <p class="text-sm font-normal">{{ notSecondCnae.code }}</p>
              </div>
            </td>
            <td>
              <div class="w-full flex flex-row justify-start items-center p-2">
                <p class="text-sm font-normal uppercase">
                  {{ notSecondCnae.name }}
                </p>
              </div>
            </td>
            <td>
              <div class="w-full flex flex-row justify-start items-center p-2">
                <p class="text-sm font-normal capitalize">
                  {{
                    getRisk(
                      getFinalRisk(
                        notSecondCnae.environmentalRisk,
                        notSecondCnae.healthRisk
                      )
                    )
                  }}
                </p>
              </div>
            </td>
          </tr>
        </tbody>
        <tbody *ngIf="isReview">
          <tr
            *ngFor="
              let secondCnae of data.secondaryActivitiesNotCarriedOut;
              let i = index
            "
          >
            <td>
              <div class="w-full flex flex-row justify-start items-center p-2">
                <input type="checkbox" checked readonly />
              </div>
            </td>
            <td>
              <div class="w-full flex flex-row justify-start items-center p-2">
                <p class="text-sm font-normal">{{ secondCnae.code }}</p>
              </div>
            </td>
            <td>
              <div class="w-full flex flex-row justify-start items-center p-2">
                <p class="text-sm font-normal uppercase">
                  {{ secondCnae.name }}
                </p>
              </div>
            </td>
            <td>
              <div class="w-full flex flex-row justify-start items-center p-2">
                <p class="text-sm font-normal capitalize">
                  {{
                    getRisk(
                      getFinalRisk(
                        secondCnae.environmentalRisk,
                        secondCnae.healthRisk
                      )
                    )
                  }}
                </p>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div
        *ngIf="
          notSecondaryCnaes &&
          notSecondaryCnaes.length === 0 &&
          !loadingSecondaryNotCnaes
        "
        class="w-full flex flex-row justify-center py-4"
      >
        <div class="flex p-3 flex-row items-center">
          <p class="text-sm font-medium">Nenhum registro encontrado.</p>
        </div>
      </div>

      <div
        *ngIf="loadingSecondaryNotCnaes"
        class="w-full flex flex-row justify-center py-4"
      >
        <div class="flex p-3 flex-row items-center">
          <mat-icon
            class="animate-spin text-black mr-2"
            aria-hidden="false"
            fontIcon="refresh"
          ></mat-icon>
          <p class="text-sm font-medium">Carregando dados...</p>
        </div>
      </div>

      <div
        *ngIf="!loadingSecondaryNotCnaes && !isReview"
        class="flex flex-row justify-between mt-4"
      >
        <select
          class="w-16 mt-3 p-1 rounded-md"
          (input)="handleLimitChange($event, 'limit', 'notSecondary')"
        >
          <option value="10" [selected]="notSecondaryLimit === 10">10</option>
          <option value="20" [selected]="notSecondaryLimit === 20">20</option>
          <option value="50" [selected]="notSecondaryLimit === 50">50</option>
        </select>

        <nav
          class="mt-3 flex flex-row justify-end"
          aria-label="Page navigation example"
        >
          <ul class="flex items-center -space-x-px h-10 text-base">
            <li>
              <div
                [ngClass]="
                  notSecondaryPage > 1
                    ? 'flex cursor-pointer items-center justify-center px-4 h-10 ms-0 leading-tight text-gray-500 bg-white border border-e-0 border-gray-300 rounded-s-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white'
                    : 'opacity-40 pointer-events-none cursor-not-allowed flex cursor-pointer items-center justify-center px-4 h-10 ms-0 leading-tight text-gray-500 bg-white border border-e-0 border-gray-300 rounded-s-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white'
                "
                (click)="handlePageChange('previous', 'notSecondary')"
              >
                <span class="sr-only">Previous</span>
                <ng-icon name="matArrowBackIos"></ng-icon>
              </div>
            </li>
            <div
              class="flex cursor-pointer items-center justify-center px-4 h-10 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
            >
              {{ notSecondaryPage }}
            </div>
            <li>
              <div
                class="flex cursor-pointer items-center justify-center px-4 h-10 leading-tight text-gray-500 bg-white border border-gray-300 rounded-e-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                (click)="handlePageChange('next', 'notSecondary')"
              >
                <span class="sr-only">Next</span>
                <ng-icon name="matArrowForwardIos"></ng-icon>
              </div>
            </li>
          </ul>
        </nav>
      </div>
    </div>

    <mat-divider></mat-divider>

    <div *ngIf="!isReview" class="card">
      <h1 class="font-medium text-2xl">Risco final do empreendimento</h1>
      <p class="mt-3">
        Este é o risco final calculado a partir das atividades adicionadas e
        respostas nos questionários.
      </p>
      <div class="flex flex-col w-full mt-2">
        <p>Risco final</p>
        <div class="flex flex-col sm:flex-row gap-4 w-full mt-1">
          <input
            type="text"
            placeholder="à calcular"
            class="w-full md:w-1/4 input"
            readonly
            [value]="getRisk(feasibilityFinalRisk)"
          />
        </div>
      </div>
    </div>

    <div
      *ngIf="!isReview"
      class="w-full flex flex-row justify-end items-center mt-6"
    >
      <button
        type="button"
        class="btn btn-secondary text-sm"
        (click)="this.setStep.emit(4)"
      >
        Voltar
      </button>
      <button
        [ngClass]="
          loading
            ? 'btn btn-primary text-sm ml-2 opacity-40 pointer-events-none cursor-not-allowed flex flex-row justify-center items-center'
            : 'btn btn-primary text-sm ml-2 flex flex-row justify-center items-center'
        "
        type="submit"
      >
        <mat-icon
          *ngIf="loading"
          class="animate-spin text-white mr-2"
          aria-hidden="false"
          fontIcon="refresh"
        ></mat-icon>
        {{ loading ? "Enviando..." : "Finalizar" }}
      </button>
    </div>
  </form>
</div>
