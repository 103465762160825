<div class="w-full flex flex-col justify-center items-center">
  <div class="w-full h-px bg-gray-300 mt-4 mb-4"></div>
  <h1 class="w-full text-left font-medium text-2xl">Recusar pedido</h1>
  <p class="text-sm w-full text-left">
    Ao recusar um pedido de reanálise, o processo será finalizado e seu status
    atualizado para “Recusado”.
  </p>
  <p class="text-sm mt-4 w-full text-left">
    Anexe abaixo a justificativa da recusa, em formato .PDF, que será
    disponibilizada ao cidadão.
  </p>
  <div class="w-full flex flex-col justify-start items-start mt-4">
    <label [htmlFor]="" class="label">Anexar justificativa</label>
    <button
      [ngClass]="
        file
          ? 'w-full p-4 rounded-md bg-green-100 border border-dashed border-green-300 hover:bg-green-200 hover:cursor-pointer'
          : 'w-full p-4 rounded-md bg-slate-100 border border-dashed border-slate-300 hover:bg-slate-200 hover:cursor-pointer'
      "
      (click)="handleUpload()"
    >
      <div class="w-full flex flex-row">
        <mat-icon
          class="text-black material-icons-outlined text-4xl w-auto h-auto mr-4"
          aria-hidden="true"
          >{{ file ? "picture_as_pdf" : "upload" }}</mat-icon
        >
        <div class="w-full flex flex-col justify-start items-start">
          <p class="text-sm font-medium text-gray-900">
            {{ file ? file.name : "Anexar justificativa" }}
          </p>
          <p class="text-xs font-medium text-gray-600">
            {{
              file ? getFileSize(file.size) : "Formato .PDF com menos de 10mb"
            }}
          </p>
        </div>
      </div>
    </button>
    <input
      #upload_refuse
      type="file"
      class="input hidden"
      (change)="onFileSelected()"
      accept="application/pdf"
    />
  </div>
</div>
