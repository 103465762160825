<h1 mat-dialog-title class="text-lg text-[#2F3980] mt-4">
  Atividades (CNAEs) vinculados
</h1>
<mat-dialog-content>
  <p class="text-sm text-gray-700">
    Lista de atividades vinculadas à(s) pergunta(s) selecionada(s). Ao
    desvincular uma atividade, a pergunta selecionada não será mais requerida
    para o cáluclo de viabilidade.
  </p>
  <div class="card">
    <div class="mb-3">
      <div class="flex flex-col mb-4">
        <label for="description" class="label">Pergunta selecionada</label>
        <input
          disabled
          name="description"
          [value]="selectedQuestion.description"
        />
      </div>
      <div class="flex flex-row gap-4">
        <div class="flex flex-col">
          <label for="riskIfYes" class="label"
            >Se SIM, definir risco como</label
          >
          <input
            disabled
            name="riskIfYes"
            disabled
            [value]="riskDictionary(selectedQuestion.riskIfYes)"
          />
        </div>
        <div class="flex flex-col">
          <label for="riskIfNo" class="label">Se NÂO, definir risco como</label>
          <input
            disabled
            name="riskIfNo"
            disabled
            [value]="riskDictionary(selectedQuestion.riskIfNo)"
          />
        </div>
      </div>
    </div>
  </div>

  <div class="card">
    <div *ngIf="cnaes.length === 0; else table">
      Nenhum registro encontrado.
    </div>
    <ng-template #table>
      <table>
        <thead>
          <tr>
            <th>
              <div class="w-full flex flex-row justify-center items-center p-2">
                <p class="text-sm font-medium">Seleção</p>
              </div>
            </th>
            <th>
              <div class="w-full flex flex-row justify-center items-center p-2">
                <p class="text-sm font-medium">Código</p>
                <mat-icon class="ml-2" fontIcon="unfold_more"></mat-icon>
              </div>
            </th>
            <th>
              <div class="w-full flex flex-row justify-center items-center p-2">
                <p class="text-sm font-medium">Atividade Vinculada</p>
                <mat-icon class="ml-2" fontIcon="unfold_more"></mat-icon>
              </div>
            </th>
            <th>
              <div class="w-full flex flex-row justify-center items-center p-2">
                <p class="text-sm font-medium">Risco Final</p>
                <mat-icon class="ml-2" fontIcon="unfold_more"></mat-icon>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let cnae of cnaes">
            <td>
              <div class="w-full flex flex-row justify-center items-center p-2">
                <mat-checkbox
                  color="primary"
                  (change)="toggleSelection(cnae.id, $event.checked)"
                  [checked]="selectedItems[cnae.id]"
                ></mat-checkbox>
              </div>
            </td>
            <td>
              <div class="w-full flex flex-row justify-center items-center p-2">
                <p>{{ cnae.code }}</p>
              </div>
            </td>
            <td>
              <div class="w-full flex flex-row justify-center items-center p-2">
                <p>{{ cnae.name }}</p>
              </div>
            </td>
            <td>
              <div class="w-full flex flex-row justify-center items-center p-2">
                <p>{{ riskDictionary(cnae.finalRisk) }}</p>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </ng-template>
    <div class="flex flex-row align-bottom justify-between">
      <select
        class="mt-3 p-1 rounded-md w-16"
        (input)="handleLimitChange($event)"
      >
        <option value="10" [selected]="pageable.limit === 10">10</option>
        <option value="20" [selected]="pageable.limit === 20">20</option>
        <option value="50" [selected]="pageable.limit === 50">50</option>
      </select>

      <nav
        class="mt-3 flex flex-row justify-end"
        aria-label="Page navigation example"
      >
        <ul class="flex items-center -space-x-px h-10 text-base">
          <li>
            <div
              [ngClass]="
                this.pageable.page > 0
                  ? 'flex cursor-pointer items-center justify-center px-4 h-10 ms-0 leading-tight text-gray-500 bg-white border border-e-0 border-gray-300 rounded-s-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white'
                  : 'opacity-40 pointer-events-none cursor-not-allowed flex items-center justify-center px-4 h-10 ms-0 leading-tight text-gray-500 bg-white border border-e-0 border-gray-300 rounded-s-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white'
              "
              (click)="handlePageChange('previous')"
              [attr.aria-hidden]="true"
            >
              <span class="sr-only">Previous</span>
              <ng-icon name="matArrowBackIos"></ng-icon>
            </div>
          </li>
          <div
            class="flex cursor-pointer items-center justify-center px-4 h-10 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
          >
            {{ this.pageable.page + 1 }}
          </div>
          <li>
            <div
              *ngIf="this.cnaes.length > 0"
              class="flex cursor-pointer items-center justify-center px-4 h-10 leading-tight text-gray-500 bg-white border border-gray-300 rounded-e-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
              (click)="handlePageChange('next')"
              [attr.aria-hidden]="true"
            >
              <span class="sr-only">Next</span>
              <ng-icon name="matArrowForwardIos"></ng-icon>
            </div>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions class="flex justify-end">
  <button mat-button matDialogClose>Voltar</button>
  <button
    mat-button
    [disabled]="getSelectedCnaes.length === 0"
    type="submit"
    (click)="openModal()"
  >
    Desvincular Selecionados
  </button>
</mat-dialog-actions>
