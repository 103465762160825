export default [
  {
    id: 101,
    description:
      'Membro do Poder Executivo (Presidente da República, Vice-Presidente da República, Ministro de Estado, Governador, Vice-Governador, Prefeito, Vice-Prefeito)',
  },
  {
    id: 102,
    description:
      'Membro do Poder Judiciário (Ministro, Juiz e Desembargador) e de Tribunal de Contas (Ministro e Conselheiro)',
  },
  {
    id: 103,
    description:
      'Membro do Poder Legislativo (Senador, Deputado Federal, Deputado Estadual e Vereador)',
  },
  {
    id: 104,
    description: 'Membro do Ministério Público (Procurador e Promotor)',
  },
  {
    id: 105,
    description:
      'Dirigente superior da administração pública (ocupante de cargo de direção, chefia, assessoria e de natureza especial), inclusive os das fundações públicas e autarquias',
  },
  {
    id: 106,
    description: 'Diplomata e afins',
  },
  {
    id: 107,
    description: 'Servidor das carreiras do Poder Legislativo',
  },
  {
    id: 108,
    description: 'Servidor das carreiras do Ministério Público',
  },
  {
    id: 109,
    description:
      'Servidor das carreiras do Poder Judiciário, Oficial de Justiça, Auxiliar, Assistente e Analista Judiciário',
  },
  {
    id: 110,
    description:
      'Advogado do setor público, Procurador da Fazenda, Consultor Jurídico, Procurador de autarquias e fundações públicas, Defensor Público',
  },
  {
    id: 111,
    description: 'Servidor das carreiras de auditoria fiscal e de fiscalização',
  },
  {
    id: 112,
    description: 'Servidor das carreiras do Banco Central, CVM e Susep',
  },
  {
    id: 113,
    description:
      'Delegado de Polícia e outros servidores das carreiras de polícia, exceto militar',
  },
  {
    id: 114,
    description:
      'Servidor das carreiras de gestão governamental, analista, gestor e técnico de planejamento',
  },
  {
    id: 115,
    description: 'Servidor das carreiras de ciência e tecnologia',
  },
  {
    id: 116,
    description:
      'Servidor das demais carreiras da administração pública direta, autárquica e fundacional',
  },
  {
    id: 117,
    description: 'Titular de Cartório',
  },
  {
    id: 118,
    description:
      'Dirigente ou administrador de partido político, organização patronal, sindical, filantrópica e religiosa',
  },
  {
    id: 120,
    description:
      'Dirigente, presidente e diretor de empresa industrial, comercial ou prestadora de serviços',
  },
  {
    id: 121,
    description:
      'Presidente e diretor de empresa pública e sociedade de economia mista',
  },
  {
    id: 130,
    description:
      'Gerente ou supervisor de empresa industrial, comercial ou prestadora de serviços',
  },
  {
    id: 131,
    description:
      'Gerente ou supervisor de empresa pública e sociedade de economia mista',
  },
  {
    id: 140,
    description:
      'Presidente, diretor, gerente e supervisor de organismo internacional e de organização não-governamental',
  },
  { id: 211, description: 'Matemático, estatístico, atuário e afins' },
  {
    id: 212,
    description:
      'Analista de sistemas, desenvolvedor de software, administrador de redes e bancos de dados e outros especialistas em informática (exceto técnico)',
  },
  {
    id: 213,
    description:
      'Físico, químico, meteorologista, geólogo, oceanógrafo e afins',
  },
  { id: 214, description: 'Engenheiro, arquiteto e afins' },
  {
    id: 215,
    description:
      'Piloto de aeronaves, comandante de embarcações e oficiais de máquinas',
  },
  { id: 221, description: 'Biólogo, biomédico e afins' },
  { id: 222, description: 'Agrônomo e afins' },
  {
    id: 224,
    description: 'Profissional da educação física (exceto professor)',
  },
  { id: 225, description: 'Médico' },
  { id: 226, description: 'Odontólogo' },
  {
    id: 227,
    description:
      'Enfermeiro de nível superior, nutricionista, farmacêutico e afins',
  },
  {
    id: 228,
    description: 'Veterinário, patologista (veterinário) e zootecnista',
  },
  {
    id: 229,
    description:
      'Fonoaudiólogo, fisioterapeuta, terapeuta ocupacional e afins (até o ano-calendário 2023)',
  },
  {
    id: 230,
    description: 'Fonoaudiólogo (a partir do ano-calendário 2024)',
  },
  {
    id: 231,
    description: 'Fisioterapeuta (a partir do ano-calendário 2024)',
  },
  {
    id: 232,
    description: 'Terapeuta ocupacional (a partir do ano-calendário 2024)',
  },
  { id: 241, description: 'Advogado' },
  { id: 250, description: 'Sociólogo e cientista político' },
  { id: 251, description: 'Antropólogo e arqueólogo' },
  {
    id: 252,
    description: 'Economista, administrador, contador, auditor e afins',
  },
  {
    id: 253,
    description:
      'Profissional de marketing, de publicidade e de comercialização',
  },
  { id: 254, description: 'Psicanalista' },
  { id: 255, description: 'Psicólogo' },
  { id: 256, description: 'Geógrafo' },
  { id: 257, description: 'Historiador' },
  { id: 258, description: 'Assistente social e economista doméstico' },
  { id: 259, description: 'Filósofo' },
  { id: 261, description: 'Jornalista e repórter' },
  {
    id: 263,
    description: 'Sacerdote ou membro de ordens ou seitas religiosas',
  },
  { id: 264, description: 'Tradutor, intérprete, filólogo' },
  {
    id: 265,
    description: 'Bibliotecário, documentalista, arquivólogo, museólogo',
  },
  { id: 266, description: 'Escritor, crítico, redator' },
  { id: 271, description: 'Locutor, comentarista' },
  { id: 272, description: 'Ator, diretor de espetáculos' },
  { id: 273, description: 'Cantor e compositor' },
  {
    id: 274,
    description: 'Músico, arranjador, regente de orquestra ou coral',
  },
  {
    id: 275,
    description:
      'Desenhista industrial (designer), escultor, pintor artístico e afins',
  },
  { id: 276, description: 'Cenógrafo, decorador de interiores' },
  { id: 277, description: 'Empresário e produtor de espetáculos' },
  {
    id: 279,
    description: 'Outros profissionais do espetáculo e das artes',
  },
  { id: 290, description: 'Professor na educação infantil' },
  { id: 291, description: 'Professor do ensino fundamental' },
  { id: 292, description: 'Professor do ensino médio' },
  { id: 293, description: 'Professor do ensino profissional' },
  { id: 294, description: 'Professor do ensino superior' },
  { id: 295, description: 'Instrutor e professor de escolas livres' },
  { id: 296, description: 'Pedagogo, orientador educacional' },
  { id: 311, description: 'Técnico em ciências físicas e químicas' },
  {
    id: 312,
    description:
      'Técnico em construção civil, de edificações e obras de infra-estrutura',
  },
  { id: 313, description: 'Técnico em eletro-eletrônica e fotônica' },
  { id: 314, description: 'Técnico em metalmecânica' },
  { id: 316, description: 'Técnico em mineralogia e geologia' },
  { id: 317, description: 'Técnico em informática' },
  { id: 318, description: 'Desenhista técnico e modelista' },
  {
    id: 319,
    description:
      'Outros técnicos de nível médio das ciências físicas, químicas, engenharia e afins',
  },
  { id: 320, description: 'Técnico em biologia' },
  { id: 321, description: 'Técnico da produção agropecuária' },
  { id: 322, description: 'Técnico da ciência da saúde humana' },
  { id: 323, description: 'Técnico da ciência da saúde animal' },
  {
    id: 324,
    description:
      'Técnico de laboratório, Raios-X e outros equipamentos e instrumentos de diagnóstico',
  },
  { id: 325, description: 'Técnico de bioquímica e da biotecnologia' },
  {
    id: 328,
    description: 'Técnico de conservação, dissecação e empalhamento de corpos',
  },
  {
    id: 351,
    description: 'Técnico das ciências administrativas e contábeis',
  },
  {
    id: 352,
    description:
      'Técnico de inspeção, fiscalização e coordenação administrativa',
  },
  {
    id: 353,
    description:
      'Agente de Bolsa de Valores, câmbio e outros serviços financeiros',
  },
  {
    id: 354,
    description:
      'Agente e representante comercial, corretor, leiloeiro e afins',
  },
  { id: 355, description: 'Corretor e Administrador de Imóveis' },
  { id: 371, description: 'Técnico de serviços culturais' },
  {
    id: 372,
    description:
      'Cinegrafista, fotógrafo e outros técnicos em operação de máquinas de tratamento de dados',
  },
  {
    id: 373,
    description: 'Técnico em operação de estações de rádio e televisão',
  },
  {
    id: 374,
    description:
      'Técnico em operação de aparelhos de sonorização, cenografia e projeção',
  },
  { id: 375, description: 'Decorador e vitrinista' },
  {
    id: 376,
    description: 'Apresentador, artistas de artes populares e modelos',
  },
  { id: 377, description: 'Atleta, desportista e afins' },
  { id: 391, description: 'Outros técnicos de nível médio' },
  {
    id: 410,
    description:
      'Bancário, economiário, escriturário, secretário, assistente e auxiliar administrativo',
  },
  {
    id: 420,
    description:
      'Trabalhador de atendimento ao público, caixa, despachante, recenseador e afins',
  },
  {
    id: 511,
    description:
      'Comissário de bordo, guia de turismo, agente de viagem e afins',
  },
  { id: 512, description: 'Trabalhador dos serviços domésticos em geral' },
  {
    id: 513,
    description: 'Trabalhador dos serviços de hotelaria e alimentação',
  },
  {
    id: 514,
    description:
      'Trabalhador dos serviços de administração, conservação e manutenção de edifícios',
  },
  { id: 515, description: 'Trabalhador dos serviços de saúde' },
  {
    id: 516,
    description:
      'Trabalhador dos serviços de embelezamento e cuidados pessoais',
  },
  {
    id: 517,
    description:
      'Trabalhador dos serviços de proteção e segurança (exceto militar)',
  },
  {
    id: 518,
    description:
      'Motorista e condutor do transporte de passageiros (motorista de taxi, ônibus, pequena embarcação etc)',
  },
  { id: 519, description: 'Outros trabalhadores de serviços diversos' },
  {
    id: 529,
    description:
      'Vendedor e prestador de serviços do comércio, ambulante, caixeiro-viajante e camelô',
  },
  { id: 610, description: 'Produtor na exploração agropecuária' },
  { id: 620, description: 'Trabalhador na exploração agropecuária' },
  { id: 630, description: 'Pescador, caçador e extrativista florestal' },
  { id: 640, description: 'Operador de máquina agropecuária e florestal' },
  {
    id: 710,
    description: 'Trabalhador da indústria extrativa e da construção civil',
  },
  {
    id: 720,
    description: 'Trabalhador da transformação de metais e compósitos',
  },
  {
    id: 730,
    description: 'Trabalhador da fabricação e instalação eletro-eletrônica',
  },
  {
    id: 740,
    description: 'Montador de aparelhos e instrumentos de precisão e musicais',
  },
  { id: 750, description: 'Joalheiro, vidreiro, ceramista e afins' },
  {
    id: 760,
    description:
      'Trabalhador das indústrias têxteis, do curtimento, do vestuário e das artes gráficas',
  },
  {
    id: 770,
    description: 'Trabalhador das indústrias de madeira e do mobiliário',
  },
  {
    id: 780,
    description:
      'Condutor e operador de robôs, veículos de equipamentos de movimentação de carga e afins',
  },
  {
    id: 810,
    description:
      'Trabalhador das indústrias química, petroquímica, borracha e plástico e afins',
  },
  {
    id: 820,
    description:
      'Trabalhador de instalações siderúrgicas e de materiais de construção',
  },
  {
    id: 830,
    description:
      'Trabalhador de instalações e máquinas de fabricação de celulose e papel',
  },
  {
    id: 840,
    description:
      'Trabalhador da fabricação de alimentos, bebidas, fumo e de agroindústrias',
  },
  {
    id: 860,
    description:
      'Operador de instalações de produção e distribuição de energia',
  },
  {
    id: 870,
    description: 'Trabalhador de outras instalações agroindustriais',
  },
  { id: 900, description: 'Trabalhador de reparação e manutenção' },
  { id: 10, description: 'Militar da Aeronáutica' },
  { id: 20, description: 'Militar do Exército' },
  { id: 30, description: 'Militar da Marinha' },
  { id: 40, description: 'Policial Militar' },
  { id: 50, description: 'Bombeiro Militar' },
  { id: 0, description: 'Sem ocupações' },
];
