<form [formGroup]="questionForm" (ngSubmit)="onSubmit()">
  <mat-dialog-content>
    <h1 mat-dialog-title class="text-lg text-[#2F3980] mt-4">Editar Questão</h1>
    <div class="w-full flex flex-col justify-start items-start p-4 rounded bg-gray-100 mt-4">
      <div class="w-full flex flex-col justify-center items-center">
        <p class="w-full text-sm font-normal text-left">Pergunta*</p>
        <input formControlName="description"
          class="appearance-none rounded w-full py-2 px-3 text-gray-700 leading-tight outline-none h-9" type="text"
          placeholder="Exemplo de pergunta inserida?" required />
        <div class="w-full flex flex-row justify-start text-xs font-medium text-red-500 mt-1" *ngIf="
                  (questionForm.controls['description'].touched ||
                    questionForm.controls['description'].dirty) &&
                  questionForm.controls['description'].errors &&
                  questionForm.controls['description'].errors['required']
                ">
          Pergunta é obrigatório.
        </div>
      </div>

      <div class="w-full grid grid-cols-3 gap-4 mt-6">
        <div class="w-full flex flex-col justify-start items-center">
          <p class="w-full text-sm font-normal text-left">
            Se SIM, definir risco como*
          </p>
          <select formControlName="riskIfYes"
            class="w-full text-sm text-left font-medium text-gray-900 dark:text-white rounded">
            <option [ngValue]="null" selected disabled>
              Selecione uma opção
            </option>
            <option [ngValue]="0">Requer Informações</option>
            <option [ngValue]="1">Risco I</option>
            <option [ngValue]="2">Risco II</option>
            <option [ngValue]="3">Risco III</option>
            <option [ngValue]="4">Competência Estadual</option>
          </select>
          <div class="w-full flex flex-row justify-start text-xs font-medium text-red-500 mt-1" *ngIf="
                    (questionForm.controls['riskIfYes'].touched ||
                      questionForm.controls['riskIfYes'].dirty) &&
                    questionForm.controls['riskIfYes'].errors &&
                    questionForm.controls['riskIfYes'].errors['required']
                  ">
            Risco se SIM é obrigatório.
          </div>
        </div>
        <div class="w-full flex flex-col justify-start items-center">
          <p class="w-full text-sm font-normal text-left">
            Se NÃO, definir risco como*
          </p>
          <select formControlName="riskIfNo"
            class="w-full text-sm text-left font-medium text-gray-900 dark:text-white rounded">
            <option [ngValue]="null" selected disabled>
              Selecione uma opção
            </option>
            <option [ngValue]="0">Requer Informações</option>
            <option [ngValue]="1">Risco I</option>
            <option [ngValue]="2">Risco II</option>
            <option [ngValue]="3">Risco III</option>
            <option [ngValue]="4">Competência Estadual</option>
          </select>
          <div class="w-full flex flex-row justify-start text-xs font-medium text-red-500 mt-1" *ngIf="
                    (questionForm.controls['riskIfNo'].touched ||
                      questionForm.controls['riskIfNo'].dirty) &&
                    questionForm.controls['riskIfNo'].errors &&
                    questionForm.controls['riskIfNo'].errors['required']
                  ">
            Risco se NÃO é obrigatório.
          </div>
        </div>
      </div>
    </div>
    <mat-dialog-actions class="flex justify-end gap-4 text-black">
      <button mat-button matDialogClose>Voltar</button>
      <button type="submit" mat-button >Atualizar</button>
    </mat-dialog-actions>
  </mat-dialog-content>

</form>
