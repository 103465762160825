/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import {
  GetZoneResponse,
  GetZonesByIdDTO,
  Macrozones,
  ZoneFilter,
} from '../models/macrozone.models';
import { clearParameters } from './filters/orderby.filter';

@Injectable({
  providedIn: 'root',
})
export class MacrozoneService {
  private httpService = inject(HttpClient);
  private url = `${environment.urlApi}/macrozone`;

  constructor() {}

  getMacrozones(): Observable<any> {
    return this.httpService.get(`${this.url}`);
  }

  updateMacrozones(dto: { macrozones: Macrozones[] }): Observable<any> {
    return this.httpService.put(`${this.url}`, dto);
  }

  getMacrozonesFilter(): Observable<any> {
    return this.httpService.get(`${this.url}/zones`);
  }

  getZonesById(
    dto: GetZonesByIdDTO,
    filter: ZoneFilter
  ): Observable<GetZoneResponse> {
    return this.httpService.get<GetZoneResponse>(
      `${this.url}/${String(dto.id)}/zones`,
      {
        params: {
          ...clearParameters(filter),
        },
      }
    );
  }
}
