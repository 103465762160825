<!-- eslint-disable @angular-eslint/template/label-has-associated-control -->
<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<div class="m-3 p-4">
  <app-breadcrumbs [routes]="routes"></app-breadcrumbs>

  <div class="w-full flex flex-row justify-between items-center">
    <h1 class="font-bold text-3xl">{{ macrozoneName || "Zonas" }}</h1>
    <button (click)="newZone()" class="btn btn-primary mr-2">Nova Zona</button>
  </div>

  <app-filter-box
    [showInclude]="true"
    [placeholder]="'Pesquisar Zonas por nome ou palavra-chave'"
    (filter)="filterZone($event)"
    [loading]="loading"
  >
    <div class="mb-3">
      <div class="flex flex-col">
        <label class="label">Pesquisar Zona</label>
      </div>
    </div>
  </app-filter-box>

  <div class="card">
    <div
      *ngIf="data.length > 0"
      class="w-full flex flex-row justify-end items center mb-4"
    >
      <button
        (click)="isEditing ? handleUpdateZones() : handleEditing()"
        [ngClass]="
          loadingEditing
            ? 'btn btn-primary mr-2 opacity-40 cursor-not-allowed pointer-events-none'
            : 'btn btn-primary mr-2'
        "
      >
        {{ isEditing ? (loadingEditing ? "Salvando..." : "Salvar") : "Editar" }}
      </button>
      <button
        (click)="handleEditing()"
        [ngClass]="isEditing ? 'btn btn-secondary' : 'hidden'"
      >
        Cancelar
      </button>
    </div>

    <table>
      <thead>
        <tr>
          <th>
            <div
              class="w-full flex flex-row justify-start items-center p-2 hover:cursor-pointer"
              (click)="
                setOrderBy({
                  orderby: 'name',
                  direction: this.currentOrderDirection,
                })
              "
            >
              <p class="text-sm font-medium">Nome</p>
              <mat-icon
                [ngClass]="
                  'text-sm ml-1 mt-1 text-black ' +
                  getOrderClass({
                    orderby: 'name',
                    direction: this.currentOrderDirection,
                  })
                "
                [fontIcon]="
                  getOrderIcon({
                    orderby: 'name',
                    direction: this.currentOrderDirection,
                  })
                "
              ></mat-icon>
            </div>
          </th>
          <th>
            <div
              class="w-full flex flex-row justify-start items-center p-2 hover:cursor-pointer"
              (click)="
                setOrderBy({
                  orderby: 'description',
                  direction: this.currentOrderDirection,
                })
              "
            >
              <p class="text-sm font-medium">Descrição</p>
              <mat-icon
                [ngClass]="
                  'text-sm ml-1 mt-1 text-black ' +
                  getOrderClass({
                    orderby: 'description',
                    direction: this.currentOrderDirection,
                  })
                "
                [fontIcon]="
                  getOrderIcon({
                    orderby: 'description',
                    direction: this.currentOrderDirection,
                  })
                "
              ></mat-icon>
            </div>
          </th>
          <th>
            <div
              class="w-full flex flex-row justify-start items-center p-2 hover:cursor-pointer"
              (click)="
                setOrderBy({
                  orderby: 'hierarchy',
                  direction: this.currentOrderDirection,
                })
              "
            >
              <p class="text-sm font-medium">Hierarquia</p>
              <mat-icon
                [ngClass]="
                  'text-sm ml-1 mt-1 text-black ' +
                  getOrderClass({
                    orderby: 'hierarchy',
                    direction: this.currentOrderDirection,
                  })
                "
                [fontIcon]="
                  getOrderIcon({
                    orderby: 'hierarchy',
                    direction: this.currentOrderDirection,
                  })
                "
              ></mat-icon>
            </div>
          </th>
          <th>
            <div class="w-full flex flex-row justify-start items-center p-2">
              <p class="text-sm font-medium">Ações</p>
            </div>
          </th>
        </tr>
      </thead>
      <tbody *ngIf="!loading">
        <tr *ngFor="let d of data" class="py-2">
          <td>
            <div class="w-full flex flex-row justify-start items-center p-2">
              <p class="text-sm font-normal">
                {{ d.name }}
              </p>
            </div>
          </td>
          <td>
            <div class="w-full flex flex-row justify-start items-center p-2">
              <p class="text-sm font-normal">{{ d.description }}</p>
            </div>
          </td>
          <td>
            <div class="w-full flex flex-row justify-start items-center p-2">
              <select
                [ngClass]="
                  isEditing
                    ? 'text-sm text-left font-normal text-gray-900 dark:text-white rounded'
                    : 'text-sm text-left font-normal text-gray-900 dark:text-white rounded border-slate-200 pointer-events-none cursor-not-allowed'
                "
                (change)="handleEditHierarchy(d.id, $event)"
              >
                <option
                  *ngFor="let opt of selectOptions"
                  class="text-sm font-normal"
                  [value]="opt.value"
                  [selected]="opt.value === d.hierarchy"
                >
                  {{ opt.label }}
                </option>
              </select>
            </div>
          </td>
          <td>
            <div class="w-full flex flex-row justify-start items-center p-2">
              <!-- <div
                class="flex flex-row justify-center items-center mr-6 hover:cursor-pointer"
              >
                <app-eye-icon [width]="20" [height]="20"></app-eye-icon>
                <p class="text-sm font-normal text-left ml-1">Visualizar</p>
              </div> -->
              <div
                class="flex flex-row justify-center items-center mr-6 hover:cursor-pointer"
                (click)="toEdit(d)"
              >
                <app-edit-icon [width]="20" [height]="20"></app-edit-icon>
                <p class="text-sm font-normal text-left ml-1">Editar</p>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <div
      *ngIf="data.length === 0 && !loading"
      class="w-full flex flex-row justify-center py-4"
    >
      <div class="flex p-3 flex-row items-center">
        <p class="text-sm font-medium">Nenhum registro encontrado.</p>
      </div>
    </div>

    <div *ngIf="loading" class="w-full flex flex-row justify-center py-4">
      <div class="flex p-3 flex-row items-center">
        <ng-icon class="mr-3 animate-spin" name="matSync"></ng-icon>
        <p class="text-sm font-medium">Carregando dados...</p>
      </div>
    </div>

    <div *ngIf="!loading" class="flex flex-row justify-between mt-4">
      <select
        class="mt-3 p-1 rounded-md w-16"
        (input)="handleLimitChange($event, 'limit')"
      >
        <option value="10" [selected]="limit === 10">10</option>
        <option value="20" [selected]="limit === 20">20</option>
        <option value="50" [selected]="limit === 50">50</option>
      </select>

      <nav
        class="mt-3 flex flex-row justify-end"
        aria-label="Page navigation example"
      >
        <ul class="flex items-center -space-x-px h-10 text-base">
          <li>
            <div
              [ngClass]="
                page > 1
                  ? 'flex cursor-pointer items-center justify-center px-4 h-10 ms-0 leading-tight text-gray-500 bg-white border border-e-0 border-gray-300 rounded-s-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white'
                  : 'opacity-40 pointer-events-none cursor-not-allowed flex cursor-pointer items-center justify-center px-4 h-10 ms-0 leading-tight text-gray-500 bg-white border border-e-0 border-gray-300 rounded-s-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white'
              "
              (click)="handlePageChange('previous')"
            >
              <span class="sr-only">Previous</span>
              <ng-icon name="matArrowBackIos"></ng-icon>
            </div>
          </li>
          <div
            class="flex cursor-pointer items-center justify-center px-4 h-10 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
          >
            {{ page }}
          </div>
          <li>
            <div
              class="flex cursor-pointer items-center justify-center px-4 h-10 leading-tight text-gray-500 bg-white border border-gray-300 rounded-e-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
              (click)="handlePageChange('next')"
            >
              <span class="sr-only">Next</span>
              <ng-icon name="matArrowForwardIos"></ng-icon>
            </div>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</div>
