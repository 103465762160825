import { Component } from '@angular/core';
import * as L from 'leaflet';
import 'leaflet-draw';

@Component({
  selector: 'app-map',
  standalone: true,
  imports: [],
  templateUrl: './map.component.html',
  styleUrl: './map.component.scss',
})
export class MapComponent {
  private map: L.Map | undefined;
  public activeMarker: {
    lat: number;
    lng: number;
    insidePolygon: boolean;
    address: string[];
    rules: string[];
  } = {
    lat: 0,
    lng: 0,
    insidePolygon: false,
    address: [],
    rules: [],
  };
  private drawControl: L.Control.Draw | undefined;
  public colorType = '#808080';

  AfterViewInit(): void {
    this.initMap();
  }

  private initMap(): void {
    this.map = L.map('mapa', {
      center: [-3.918639, -38.378295],
      zoom: 15,
    });

    const tiles = L.tileLayer(
      'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      {
        maxZoom: 18,
        minZoom: 3,
      },
    );

    tiles.addTo(this.map);
    const highlightStyle = {
      color: '#9b1d41',
      weight: 3,
      opacity: 0.6,
      fillOpacity: 0.65,
      fillColor: '#9b1d41',
    };

    const drawnItems = new L.FeatureGroup().setStyle(highlightStyle);
    this.map.addLayer(drawnItems);

    this.drawControl = new L.Control.Draw({
      draw: {
        circle: false,
        circlemarker: false,
        marker: false,
        polyline: false,
        rectangle: false,
        polygon: {
          shapeOptions: {
            color: this.colorType,
          },
        },
      },
    });
    this.map.addControl(this.drawControl);
  }
}
