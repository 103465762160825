import { matHomeOutline } from '@ng-icons/material-icons/outline';
import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { NgIconComponent, provideIcons } from '@ng-icons/core';

@Component({
  selector: 'app-viability-stepper',
  standalone: true,
  imports: [CommonModule, NgIconComponent],
  providers: [provideIcons({ matHomeOutline })],
  templateUrl: './viability-stepper.component.html',
  styleUrl: './viability-stepper.component.scss',
})
export class ViabilityStepperComponent {
  @Input() public step: number = 0;

  steps = [
    { index: 1, label: 'Criar empresa?' },
    { index: 2, label: 'Responsável Legal' },
    { index: 3, label: 'Motivo' },
    { index: 4, label: 'Tipologia' },
    { index: 5, label: 'Endereço' },
    { index: 6, label: 'Área do Terreno' },
    { index: 7, label: 'Área Construída' },
    { index: 8, label: 'CNAEs' },
  ];
}
