<div class="card">
  <div class="flex flex-row items-center justify-between">
    <div class="flex flex-col"></div>
    <!-- <div *ngIf="showInclude">
      <button (click)="callInclude()" class="btn btn-green ml-auto">Incluir</button>
    </div> -->
  </div>

  <!-- <div class="divider"></div> -->

  <ng-content></ng-content>

  <div class="mb-3">
    <div class="flex flex-col">
      <!-- <label class="label">Nome</label> -->
      <input
        type="text"
        class="input"
        id="name"
        [(ngModel)]="inputValue"
        [placeholder]="placeholder"
      />
    </div>
  </div>

  <div class="flex flex-row">
    <button
      (click)="callFilter()"
      [ngClass]="
        loading
          ? 'btn btn-primary mr-2 opacity-40 cursor-not-allowed pointer-events-none'
          : 'btn btn-primary mr-2'
      "
    >
      Pesquisar
    </button>
    <button
      (click)="callClean()"
      [ngClass]="
        loading
          ? 'btn btn-secondary opacity-40 cursor-not-allowed pointer-events-none'
          : 'btn btn-secondary'
      "
    >
      Limpar
    </button>
  </div>
</div>
