<div class="card">
  <h1 class="font-medium text-2xl">Resultado da consulta de viabilidade</h1>
  <div class="w-full flex flex-row justify-start items-center mt-4 px-4">
    <mat-icon
      *ngIf="result"
      class="text-green-500 material-icons-outlined text-5xl w-auto h-auto"
      aria-hidden="true"
      >check_circle</mat-icon
    >
    <mat-icon
      *ngIf="!result && !!!feasibilityResponse"
      class="text-red-500 material-icons-outlined text-5xl w-auto h-auto"
      aria-hidden="true"
      >cancel</mat-icon
    >
    <mat-icon
      *ngIf="!result && feasibilityResponse === 'requer análise'"
      class="text-amber-500 material-icons-outlined text-5xl w-auto h-auto"
      aria-hidden="true"
      >help</mat-icon
    >
    <mat-icon
      *ngIf="
        (!result && feasibilityResponse === 'projeto especial') ||
        (!result &&
          feasibilityResponse &&
          feasibilityResponse.indexOf('projeto especial') >= 0)
      "
      class="text-amber-500 material-icons-outlined text-5xl w-auto h-auto"
      aria-hidden="true"
      >error</mat-icon
    >
    <h1
      *ngIf="result"
      class="text-green-500 font-medium text-2xl ml-1 capitalize"
    >
      Aprovado
    </h1>
    <h1
      *ngIf="!result && !!!feasibilityResponse"
      class="text-red-500 font-medium text-2xl ml-1"
    >
      Recusado
    </h1>
    <h1
      *ngIf="!result && feasibilityResponse === 'requer análise'"
      class="text-amber-500 font-medium text-2xl ml-1"
    >
      Requer Análise
    </h1>
    <h1
      *ngIf="
        (!result && feasibilityResponse === 'projeto especial') ||
        (!result &&
          feasibilityResponse &&
          feasibilityResponse.indexOf('projeto especial') >= 0)
      "
      class="text-amber-500 font-medium text-2xl ml-1"
    >
      Projeto Especial
    </h1>
  </div>
  <mat-divider></mat-divider>
  <div>
    <button
      [ngClass]="
        loading
          ? 'btn btn-primary text-sm ml-2 disabled:bg-opacity-65 flex flex-row justify-center items-center mt-4 opacity-40 cursor-not-allowed pointer-events-none'
          : 'btn btn-primary text-sm ml-2 disabled:bg-opacity-65 flex flex-row justify-center items-center mt-4'
      "
      (click)="downloadPdf()"
    >
      <mat-icon *ngIf="!loading" fontIcon="download"></mat-icon>
      <mat-icon
        *ngIf="loading"
        class="animate-spin text-white mr-2"
        aria-hidden="false"
        fontIcon="refresh"
      ></mat-icon>
      <span class="ml-2">{{
        loading ? "Gerando arquivos..." : "Baixar relatório detalhado"
      }}</span>
    </button>
  </div>
</div>
