import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { CnaeResponse } from '../models/cnae.models';

@Injectable({
  providedIn: 'root',
})
export class CnaeQuestionService {
  private httpService = inject(HttpClient);
  private url = `${environment.urlApi}/cnae-question`;
  private questionUrl = `${environment.urlApi}/cnae-question`;

  constructor() {}

  getCnaesByQuestionId(
    questionId: string,
    filter: { page: number; limit: number }
  ): Observable<CnaeResponse[]> {
    return this.httpService.get<CnaeResponse[]>(
      `${this.url}/find-by-question-id/${questionId}?page=${filter.page}&limit=${filter.limit}`
    );
  }

  unlinkCnaes(questionId: string, cnaeIds: string[]): Observable<void> {
    return this.httpService.post<void>(`${this.url}/${questionId}/unlink`, {
      cnaeIds,
    });
  }
}
