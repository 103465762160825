<mat-dialog-content class="flex flex-col items-center gap-4 p-8">
  <warning-big-icon [color]="'#F29C1C'" [width]="62" [height]="62"></warning-big-icon>
  <h1 class="text-4xl text-[#2F3980]">Desvincular atividade(s)?</h1>

  <h4 class="text-gray-400">As análises de riscos das seguintes atividades (CNAEs) serão alteradas:</h4>

  <div *ngFor="let cnae of selectedCnaes">
    <div class="flex flex-col gap-4">
      <span class="text-md text-black">{{cnae.code}} - {{cnae.name}}</span>
    </div>
  </div>

</mat-dialog-content>

<mat-dialog-actions class="flex justify-end gap-4">
  <button mat-button matDialogClose>Voltar</button>
  <button mat-button [mat-dialog-close]="confirm()">Confirmar</button>
</mat-dialog-actions>
