import { Component, inject } from '@angular/core';
import { WarningBigIconComponent } from '../../../icons/modal/warning-big-icon/warning-big-icon.component';
import { CnaeResponse } from '../../../models/cnae.models';
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogClose, MatDialogContent } from '@angular/material/dialog';
import { CommonModule } from '@angular/common';


export interface ConfirmUnlinkCnaeModalComponentProps {
  selectedCnaes: CnaeResponse[];
}

@Component({
  selector: 'app-confirm-unlink-cnae-modal',
  standalone: true,
  imports: [
    WarningBigIconComponent,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    CommonModule],
  templateUrl: './confirm-unlink-cnae-modal.component.html',
  styleUrl: './confirm-unlink-cnae-modal.component.scss'
})
export class ConfirmUnlinkCnaeModalComponent {
  readonly data = inject<ConfirmUnlinkCnaeModalComponentProps>(MAT_DIALOG_DATA);
  selectedCnaes: CnaeResponse[] = this.data.selectedCnaes;

  confirm(): boolean {
    return true;
  }
}
