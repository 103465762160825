import {
  matClose,
  matCheck,
  matWork,
  matPersonPin,
} from '@ng-icons/material-icons/baseline';
import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { HttpErrorResponse } from '@angular/common/http';
import { AlertComponent } from '../../components/alert/alert.component';
import { AlertService } from '../../services/alert.service';
import { CreateEmployeeInterface } from '../../models/employee.model';
import { EmployeeService } from '../../services/employee.service';
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';

@Component({
  selector: 'app-register-employee',
  standalone: true,
  imports: [
    NgIconComponent,
    CommonModule,
    ReactiveFormsModule,
    AlertComponent,
    NgxMaskDirective,
  ],
  providers: [
    provideIcons({ matClose, matCheck, matPersonPin, matWork }),
    provideNgxMask(),
  ],
  templateUrl: './register.component.html',
  styleUrl: './register.component.scss',
})
export class RegisterEmployeeComponent implements OnInit {
  userForm!: FormGroup;
  contactTypes: string[][] = [
    ['Celular', 'Celular/WhatsApp', 'Telefone fixo'],
    ['Celular', 'Celular/WhatsApp', 'Telefone fixo'],
    ['Celular', 'Celular/WhatsApp', 'Telefone fixo'],
  ];

  public loading: boolean = false;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private userService: EmployeeService,
    private alertService: AlertService,
  ) {}

  log(event: Event, desc?: string) {
    const input = event.target as HTMLInputElement;

    desc ? console.log(desc, input.value) : console.log(input.value);
  }

  push(route: string): void {
    this.router.navigate([route]);
  }

  onContactTypeChange(event: Event, index: number) {
    const input = event.target as HTMLInputElement;

    const contactForm = (this.userForm.get('contacts') as FormArray).at(index);
    const newType = input.value;
    const numberControl = contactForm.get('number');

    if (newType === 'cellphone' || newType === 'whatsapp') {
      numberControl?.setValidators([
        Validators.required,
        Validators.pattern(/^\(\d{2}\) \d{5}-\d{4}$/),
      ]);
    } else {
      numberControl?.setValidators([
        Validators.required,
        Validators.pattern(/^\(\d{2}\) \d{4}-\d{4}$/),
      ]);
    }

    numberControl?.updateValueAndValidity();
  }

  addContact() {
    if ((this.userForm.get('contacts') as FormArray).length === 3) return;

    const contactForm = this.formBuilder.group({
      type: ['', Validators.required],
      number: ['', Validators.required],
    });
    this.contacts.push(contactForm);
  }

  removeContact(index: number) {
    this.contacts.removeAt(index);
  }

  ngOnInit(): void {
    this.userForm = this.formBuilder.group({
      fullname: ['', Validators.required],
      cpf: [
        '',
        [
          Validators.required,
          Validators.pattern(/^\d{3}\.\d{3}\.\d{3}-\d{2}$/),
        ],
      ],
      complement: [''],
      birthdate: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.pattern(/^.{8,}$/)]],
      confirm_password: ['', [Validators.required]],
      street: ['', Validators.required],
      neighborhood: ['', Validators.required],
      city: ['', Validators.required],
      state: ['', Validators.required],
      role: ['', Validators.required],
      jobTitle: ['', Validators.required],
      contacts: this.formBuilder.array(this.initContacts()),
    });
  }

  private initContacts(): FormGroup[] {
    const initialContacts = [{ type: '', number: '' }];
    return initialContacts.map((contact) =>
      this.formBuilder.group({
        type: [contact.type, Validators.required],
        number: [contact.number, Validators.required],
      }),
    );
  }

  get contacts(): FormArray {
    return this.userForm.get('contacts') as FormArray;
  }

  return() {
    this.router.navigate(['/login']);
  }

  mismatch() {
    const password = this.userForm.controls['password'].value;
    const confirmPassword = this.userForm.controls['confirm_password'].value;

    if (password !== confirmPassword) {
      this.userForm.controls['confirm_password'].setErrors({ not_match: true });
    } else {
      this.userForm.controls['confirm_password'].setErrors(null);
    }
  }

  onSubmit() {
    this.loading = true;
    this.mismatch();

    const contactsDto = this.contacts.controls.map((control) => {
      return {
        type: control.get('type')?.value,
        number: control.get('number')?.value.replace(/[^0-9]/g, ''),
      };
    });

    if (this.userForm.valid) {
      const userDto: CreateEmployeeInterface = {
        fullname: this.userForm.controls['fullname'].value,
        cpf: this.userForm.controls['cpf'].value.replace(/[^0-9]/g, ''),
        complement: this.userForm.controls['complement'].value,
        birthdate: this.userForm.controls['birthdate'].value,
        email: this.userForm.controls['email'].value,
        password: this.userForm.controls['password'].value,
        neighborhood: this.userForm.controls['neighborhood'].value,
        street: this.userForm.controls['street'].value,
        city: this.userForm.controls['city'].value,
        state: this.userForm.controls['state'].value,
        role: this.userForm.controls['role'].value,
        jobTitle: this.userForm.controls['jobTitle'].value,
        contacts: contactsDto,
      };

      this.userService.create(userDto).subscribe({
        next: () => {
          this.push('/login');
          this.alertService.add({
            id: 'register-field-success',
            type: 'success',
            message:
              'Seus dados foram enviados com sucesso, aguarde a aprovação do seu cadastro!',
          });
          this.loading = false;
        },
        error: (e: HttpErrorResponse) => {
          this.loading = false;
          console.error(e),
            this.alertService.add({
              id: 'register-field-error',
              type: 'error',
              message: e.error,
              top: 2,
              right: 2,
            });
        },
      });
    } else {
      this.loading = false;
      console.log(this.userForm.controls);
      this.userForm.markAllAsTouched();
    }
  }

  markFormGroupTouched(formGroup: FormGroup) {
    Object.values(formGroup.controls).forEach((control) => {
      control.markAsTouched();

      if (control instanceof FormGroup) {
        this.markFormGroupTouched(control);
      }
    });
  }
}
