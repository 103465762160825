<div class="flex flex-row h-full">
  <app-sidebar (isExpanded)="handleMenuToggle($event)"></app-sidebar>
  <div
    [ngClass]="
      !menuExpanded
        ? 'sm:ml-64 sm:mt-14 bg-slate-100 w-full h-full'
        : 'sm:ml-20 sm:mt-14 bg-slate-100 w-full h-full'
    "
  >
    <app-modal></app-modal>
    <app-alert></app-alert>
    <router-outlet></router-outlet>
  </div>
</div>
