<div [formGroup]="ruleForm" class="m-3 p-4">
  <h1 class="font-bold text-3xl">Revisão da Viabilidade</h1>
  <div class="card">
    <h1 class="font-medium text-2xl mb-4">Dados do empreendimento</h1>
    <div class="w-full grid grid-cols-4 gap-4">
      <div class="flex flex-col justify-start items-start">
        <label [htmlFor]="" class="label">Solicitante</label>
        <input
          type="text"
          class="input bg-slate-100"
          [value]="data.requester || ''"
          readonly
        />
      </div>
      <div class="flex flex-col justify-start items-start">
        <label [htmlFor]="" class="label">Responsável legal</label>
        <input
          type="text"
          class="input bg-slate-100"
          [value]="data.responsable || ''"
          readonly
        />
      </div>
      <div class="flex flex-col justify-start items-start">
        <label [htmlFor]="" class="label">Resultado da consulta</label>
        <input
          type="text"
          class="input bg-green-300 text-green-900 border-green-900 font-medium"
          [value]="getResult('aprovado')"
          readonly
        />
      </div>
      <div class="flex flex-col justify-start items-start">
        <label [htmlFor]="" class="label">Protocolo da consulta</label>
        <input
          type="text"
          class="input uppercase bg-slate-100"
          [value]="data.protocol || ''"
          readonly
        />
      </div>
    </div>
    <div class="w-full grid grid-cols-4 gap-4 mt-4">
      <div class="flex flex-col justify-start items-start col-span-2">
        <label [htmlFor]="" class="label">Endereço do empreendimento</label>
        <input
          type="text"
          class="input bg-slate-100"
          [value]="data.fullAddress || ''"
          readonly
        />
      </div>
      <div class="flex flex-col justify-start items-start">
        <label [htmlFor]="" class="label">Risco municipal</label>
        <input
          type="text"
          class="input bg-slate-100"
          [value]="getRisk(data.finalRisk)"
          readonly
        />
      </div>
      <div class="flex flex-col justify-start items-start">
        <label [htmlFor]="" class="label">Tipologia do empreendimento</label>
        <input
          type="text"
          class="input bg-slate-100"
          [value]="getTypeOfFeasibility(data.viabilityTipology)"
          readonly
        />
      </div>
    </div>

    <div class="w-full grid grid-cols-4 gap-4 mt-4">
      <div
        *ngIf="data.viabilityTipology !== 0"
        class="flex flex-col justify-start items-start"
      >
        <label [htmlFor]="" class="label">Tipo do domicílio fiscal</label>
        <input
          type="text"
          class="input bg-slate-100"
          [value]="
            data.viabilityTipology === 0
              ? '-'
              : getTaxDomicile(data.taxDomicile)
          "
          readonly
        />
      </div>
      <div class="flex flex-col justify-start items-start">
        <label [htmlFor]="" class="label">Zona</label>
        <input
          type="text"
          class="input bg-slate-100"
          [value]="getTypeOfFeasibility(data.viabilityTipology)"
          readonly
        />
      </div>
    </div>
  </div>

  <div class="card">
    <h1 class="font-medium text-2xl mb-4">Áreas do Terreno</h1>
    <div class="w-full grid grid-cols-4 gap-4">
      <div class="flex flex-col justify-start items-start">
        <label [htmlFor]="" class="label">Área do Terreno Total</label>
        <input
          type="text"
          class="input bg-slate-100"
          [value]="data.landAreaTotalArea + 'm' || '-'"
          readonly
        />
      </div>
      <div class="flex flex-col justify-start items-start">
        <label [htmlFor]="" class="label">Área do Terreno utilizada </label>
        <input
          type="text"
          class="input bg-slate-100"
          [value]="data.landAreaUsedArea + 'm' || '-'"
          readonly
        />
      </div>
      <div class="flex flex-col justify-start items-start">
        <label [htmlFor]="" class="label">Área do Terreno remanescente</label>
        <input
          type="text"
          class="input bg-slate-100"
          [value]="data.landAreaRemainingArea + 'm' || '-'"
          readonly
        />
      </div>
    </div>
  </div>
  <div class="card">
    <h1 class="font-medium text-2xl mb-4">Área construída</h1>
    <div class="w-full grid grid-cols-4 gap-4">
      <div class="flex flex-col justify-start items-start">
        <label [htmlFor]="" class="label">Área construída total</label>
        <input
          type="text"
          class="input bg-slate-100"
          [value]="data.buildingAreaTotalArea + 'm' || '-'"
          readonly
        />
      </div>
      <div class="flex flex-col justify-start items-start">
        <label [htmlFor]="" class="label">Área do estabelecimento</label>
        <input
          type="text"
          class="input bg-slate-100"
          [value]="data.buildingAreaUsedArea + 'm' || '-'"
          readonly
        />
      </div>
      <div class="flex flex-col justify-start items-start">
        <label [htmlFor]="" class="label">Área remanescente</label>
        <input
          type="text"
          class="input bg-slate-100"
          [value]="data.buildingAreaRemainingArea + 'm' || '-'"
          readonly
        />
      </div>
    </div>
  </div>

  <!-- Activiy -->
  <app-feasibility-activity
    [data]="data"
    [isReview]="true"
  ></app-feasibility-activity>

  <div class="w-full card flex flex-col justify-start items-start mt-4">
    <h1 class="text-2xl font-medium">Especificar parâmetros urbanísticos</h1>

    <div
      class="w-full flex flex-row justify-start items-start border-b border-solid border-[#CACED7] py-4 mt-4"
    >
      <div class="w-full grid grid-cols-1 md:grid-cols-5 gap-4">
        <div class="w-full flex flex-row justify-start">
          <p class="text-xl">Parâmetros de ocupação</p>
        </div>
        <div class="w-full flex flex-col justify-start items-start">
          <p class="text-sm">Taxa de ocupação (%)<font color="red">*</font></p>
          <input
            formControlName="ocupationRate"
            type="text"
            id="total_m2"
            class="input max-w-52 mr-2"
            [value]="data.ocupationRate || ''"
            placeholder="Inserir"
            required=""
            (input)="onChangeValues($event, 'ocupationRate')"
          />
          <div
            class="w-full flex flex-row justify-start items-center mt-2"
            *ngIf="
              (ruleForm.controls['ocupationRate'].touched ||
                ruleForm.controls['ocupationRate'].dirty) &&
              ruleForm.controls['ocupationRate'].errors &&
              ruleForm.controls['ocupationRate'].errors['required']
            "
          >
            <p class="text-sm font-medium text-[#f44336]">
              Taxa de ocupação é obrigatório.
            </p>
          </div>
        </div>
        <div class="w-full flex flex-col justify-start items-start">
          <p class="text-sm">
            Índice de aproveitamento<font color="red">*</font>
          </p>
          <input
            formControlName="utilizationRate"
            type="text"
            id="total_m2"
            class="input max-w-52 mr-2"
            [value]="data.utilizationRate || ''"
            placeholder="Inserir"
            required=""
            (input)="onChangeValues($event, 'utilizationRate')"
          />
          <div
            class="w-full flex flex-row justify-start items-center mt-2"
            *ngIf="
              (ruleForm.controls['utilizationRate'].touched ||
                ruleForm.controls['utilizationRate'].dirty) &&
              ruleForm.controls['utilizationRate'].errors &&
              ruleForm.controls['utilizationRate'].errors['required']
            "
          >
            <p class="text-sm font-medium text-[#f44336]">
              Índice de aproveitamento é obrigatório.
            </p>
          </div>
        </div>
        <div class="w-full flex flex-col justify-start items-start">
          <p class="text-sm">
            Taxa de permeabilidade (%)<font color="red">*</font>
          </p>
          <input
            formControlName="permeabilityRate"
            type="text"
            id="total_m2"
            class="input max-w-52 mr-2"
            [value]="data.permeabilityRate || ''"
            placeholder="Inserir"
            required=""
            (input)="onChangeValues($event, 'permeabilityRate')"
          />
          <div
            class="w-full flex flex-row justify-start items-center mt-2"
            *ngIf="
              (ruleForm.controls['permeabilityRate'].touched ||
                ruleForm.controls['permeabilityRate'].dirty) &&
              ruleForm.controls['permeabilityRate'].errors &&
              ruleForm.controls['permeabilityRate'].errors['required']
            "
          >
            <p class="text-sm font-medium text-[#f44336]">
              Taxa de permeabilidade é obrigatório.
            </p>
          </div>
        </div>
        <div class="w-full flex flex-col justify-start items-start">
          <p class="text-sm">
            Área do Terreno Parcelável (m²)<font color="red">*</font>
          </p>
          <input
            formControlName="parcelableLandArea"
            type="text"
            id="total_m2"
            [ngClass]="
              data.parcelableLandAreaNotApplied
                ? 'input max-w-52 mr-2 opacity-40 pointer-events-none cursor-not-allowed'
                : 'input max-w-52 mr-2'
            "
            [disabled]="data.parcelableLandAreaNotApplied"
            [value]="data.parcelableLandArea || ''"
            placeholder="Inserir"
            required=""
            (input)="onChangeValues($event, 'parcelableLandArea')"
          />
          <div class="w-full flex flex-row justify-start items-center mt-1">
            <input
              type="checkbox"
              [checked]="data.parcelableLandAreaNotApplied || false"
              (change)="onChangeValues($event, 'parcelableLandAreaNotApplied')"
            />
            <p class="text-sm ml-2 font-medium">Não se aplica</p>
          </div>
          <div
            class="w-full flex flex-row justify-start items-center mt-2"
            *ngIf="
              (ruleForm.controls['parcelableLandArea'].touched ||
                ruleForm.controls['parcelableLandArea'].dirty) &&
              ruleForm.controls['parcelableLandArea'].errors &&
              ruleForm.controls['parcelableLandArea'].errors['required']
            "
          >
            <p class="text-sm font-medium text-[#f44336]">
              Área do Terreno Parcelável é obrigatório.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div
      class="w-full flex flex-row justify-start items-start border-b border-solid border-[#CACED7] py-4"
    >
      <div class="w-full grid grid-cols-1 md:grid-cols-5 gap-4">
        <div class="w-full flex flex-row justify-start">
          <p class="text-xl">Recuos do lote</p>
        </div>
        <div class="w-full flex flex-col justify-start items-start">
          <p class="text-sm">Recuo frontal (m)</p>
          <input
            formControlName="frontSetback"
            type="text"
            id="total_m2"
            class="input max-w-52 mr-2"
            [value]="data.frontSetback || ''"
            placeholder="Inserir"
            required=""
            (input)="onChangeValues($event, 'frontSetback')"
          />
        </div>
        <div class="w-full flex flex-col justify-start items-start">
          <p class="text-sm">Recuo de fundos (m)</p>
          <input
            formControlName="backSetback"
            type="text"
            id="total_m2"
            class="input max-w-52 mr-2"
            [value]="data.backSetback || ''"
            placeholder="Inserir"
            required=""
            (input)="onChangeValues($event, 'backSetback')"
          />
        </div>
        <div class="w-full flex flex-col justify-start items-start">
          <p class="text-sm">Recuo lateral (m)</p>
          <input
            formControlName="sideSetback"
            type="text"
            id="total_m2"
            class="input max-w-52 mr-2"
            [value]="data.sideSetback || ''"
            placeholder="Inserir"
            required=""
            (input)="onChangeValues($event, 'sideSetback')"
          />
        </div>
      </div>
    </div>
    <div
      class="w-full flex flex-row justify-start items-start border-b border-solid border-[#CACED7] py-4 mt-2"
    >
      <div class="w-full grid grid-cols-1 md:grid-cols-5 gap-4">
        <div class="w-full flex flex-row justify-start">
          <p class="text-xl">Alturas da edificação</p>
        </div>
        <div class="w-full flex flex-col justify-start items-start">
          <p class="text-sm">Altura máxima da edificação (m)</p>
          <input
            formControlName="maxBuildingHeight"
            type="text"
            id="total_m2"
            class="input max-w-52 mr-2"
            [value]="data.maxBuildingHeight || ''"
            placeholder="Inserir"
            required=""
            (input)="onChangeValues($event, 'maxBuildingHeight')"
          />
        </div>
      </div>
    </div>
    <div
      class="w-full flex flex-row justify-start items-start border-b border-solid border-[#CACED7] py-4 mt-2"
    >
      <div class="w-full grid grid-cols-1 md:grid-cols-5 gap-4">
        <div class="w-full flex flex-row justify-start">
          <p class="text-xl">Áreas do lote</p>
        </div>
        <div class="w-full flex flex-col justify-start items-start">
          <p class="text-sm">Lote mínimo (m²)</p>
          <input
            formControlName="minLotSize"
            type="text"
            id="total_m2"
            class="input max-w-52 mr-2"
            [value]="data.minLotSize || ''"
            placeholder="Inserir"
            required=""
            (input)="onChangeValues($event, 'minLotSize')"
          />
        </div>
        <div class="w-full flex flex-col justify-start items-start">
          <p class="text-sm">Lote máximo (m²)</p>
          <input
            formControlName="maxLotSize"
            type="text"
            id="total_m2"
            class="input max-w-52 mr-2"
            placeholder="Inserir"
            [value]="data.maxLotSize || ''"
            placeholder="Inserir"
            required=""
            (input)="onChangeValues($event, 'maxLotSize')"
          />
        </div>
        <div class="w-full flex flex-col justify-start items-start">
          <p class="text-sm">Testada mínima (m)</p>
          <input
            formControlName="minFrontage"
            type="text"
            id="total_m2"
            class="input max-w-52 mr-2"
            placeholder="Inserir"
            [value]="data.minFrontage || ''"
            placeholder="Inserir"
            required=""
            (input)="onChangeValues($event, 'minFrontage')"
          />
        </div>
        <div class="w-full flex flex-col justify-start items-start">
          <p class="text-sm">Profundidade mínima (m)</p>
          <input
            formControlName="minDepth"
            type="text"
            id="total_m2"
            class="input max-w-52 mr-2"
            placeholder="Inserir"
            [value]="data.minDepth || ''"
            placeholder="Inserir"
            required=""
            (input)="onChangeValues($event, 'minDepth')"
          />
        </div>
      </div>
    </div>
    <div
      class="w-full flex flex-row justify-start items-start border-b border-solid border-[#CACED7] py-4 mt-2"
    >
      <div class="w-full grid grid-cols-1 md:grid-cols-5 gap-4">
        <div class="w-full flex flex-row justify-start">
          <p class="text-xl">Quadras</p>
        </div>
        <div class="w-full flex flex-col justify-start items-start">
          <p class="text-sm">Quadra mínima (m)</p>
          <input
            formControlName="minBlockSize"
            type="text"
            id="total_m2"
            class="input max-w-52 mr-2"
            placeholder="Inserir"
            [value]="data.minBlockSize || ''"
            placeholder="Inserir"
            required=""
            (input)="onChangeValues($event, 'minBlockSize')"
          />
        </div>
        <div class="w-full flex flex-col justify-start items-start">
          <p class="text-sm">Quadra máxima (m)</p>
          <input
            formControlName="maxBlockSize"
            type="text"
            id="total_m2"
            class="input max-w-52 mr-2"
            placeholder="Inserir"
            [value]="data.maxBlockSize || ''"
            placeholder="Inserir"
            required=""
            (input)="onChangeValues($event, 'maxBlockSize')"
          />
        </div>
      </div>
    </div>
  </div>

  <div class="card">
    <h1 class="font-medium text-2xl mb-4">Orientações</h1>
    <p class="font-medium text-xl mb-4">Licenciamento ambiental</p>
    <st-editor
      [ngModel]="data.environmentLicensing"
      [config]="config"
      (ngModelChange)="
        onChangeOrientationValues($event, 'environmentLicensing')
      "
    ></st-editor>
    <p class="font-medium text-xl mb-4 mt-6">Polo gerador de viagem (PGV)</p>
    <st-editor
      [ngModel]="data.pgv"
      [config]="config"
      (ngModelChange)="onChangeOrientationValues($event, 'pgv')"
    ></st-editor>
    <p class="font-medium text-xl mb-4 mt-6">
      Vagas, estacionamento, carga, descarga e acesso de veículos
    </p>
    <st-editor
      [ngModel]="data.parkingAndVehicles"
      [config]="config"
      (ngModelChange)="onChangeOrientationValues($event, 'parkingAndVehicles')"
    ></st-editor>
    <p class="font-medium text-xl mb-4 mt-6">Comando aéreo</p>
    <st-editor
      [ngModel]="data.airCommand"
      [config]="config"
      (ngModelChange)="onChangeOrientationValues($event, 'airCommand')"
    ></st-editor>
    <p class="font-medium text-xl mb-4 mt-6">Corpo de bombeiros</p>
    <st-editor
      [ngModel]="data.fireDepartment"
      [config]="config"
      (ngModelChange)="onChangeOrientationValues($event, 'fireDepartment')"
    ></st-editor>
    <p class="font-medium text-xl mb-4 mt-6">Patrimônio Histórico</p>
    <st-editor
      [ngModel]="data.historicalHeritage"
      [config]="config"
      (ngModelChange)="onChangeOrientationValues($event, 'historicalHeritage')"
    ></st-editor>
    <p class="font-medium text-xl mb-4 mt-6">Licenciamento Sanitário</p>
    <st-editor
      [ngModel]="data.healthLicensing"
      [config]="config"
      (ngModelChange)="onChangeOrientationValues($event, 'healthLicensing')"
    ></st-editor>
    <p class="font-medium text-xl mb-4 mt-6">Outras Orientações</p>
    <st-editor
      [ngModel]="data.others"
      [config]="config"
      (ngModelChange)="onChangeOrientationValues($event, 'others')"
    ></st-editor>
  </div>

  <div class="card">
    <h1 class="font-medium text-2xl mb-4">Justificativa da revisão</h1>
    <div class="flex flex-col justify-start items-start mb-4">
      <label [htmlFor]="" class="label"
        >Justificativa da revisão<font color="red">*</font></label
      >
      <textarea
        formControlName="justification"
        type="text"
        class="input h-20"
        maxlength="3200"
        (input)="onChangeValues($event, 'justification')"
      ></textarea>
      <div
        class="w-full flex flex-row justify-start items-center mt-2"
        *ngIf="
          (ruleForm.controls['justification'].touched ||
            ruleForm.controls['justification'].dirty) &&
          ruleForm.controls['justification'].errors &&
          ruleForm.controls['justification'].errors['required']
        "
      >
        <p class="text-sm font-medium text-[#f44336]">
          Uma justificativa é obrigatória.
        </p>
      </div>
    </div>
  </div>

  <div class="w-full flex flex-row justify-end items-center">
    <button class="btn btn-secondary mr-2" (click)="goBack()">Voltar</button>
    <button
      [ngClass]="
        !loading
          ? 'btn btn-primary'
          : 'btn btn-primary opacity-40 pointer-events-none cursor-not-allowed'
      "
      (click)="onSubmit()"
    >
      {{ loading ? "Enviando..." : "Enviar" }}
    </button>
  </div>
</div>
