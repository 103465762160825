import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-unlocked-icon',
  standalone: true,
  templateUrl: './unlocked-icon.component.html',
  styleUrls: ['./unlocked-icon.component.scss'],
  imports: [CommonModule],
})
export class UnlockedIconComponent {
  @Input() color: string = '#000000';
  @Input() width: number = 24;
  @Input() height: number = 24;
}
