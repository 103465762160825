/* eslint-disable @typescript-eslint/no-explicit-any */
import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { Router } from '@angular/router';

export interface IRoute {
  label: string;
  link: { route: string; data?: any };
}

@Component({
  selector: 'app-breadcrumbs',
  standalone: true,
  imports: [CommonModule, MatIcon],
  templateUrl: './breadcrumbs.component.html',
  styleUrl: './breadcrumbs.component.scss',
})
export class BreadcrumbsComponent {
  @Input() routes: IRoute[] = [];

  constructor(private router: Router) {}

  toRoute(route: string, state?: any): void {
    if (state) this.router.navigate([route], state);
    else this.router.navigate([route]);
  }
}
