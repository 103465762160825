import { AsyncPipe, CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { ViabilityStepperComponent } from './viability-stepper/viability-stepper.component';
import { TableComponent } from '../../components/table/table.component';
import { MapComponent } from '../../components/map/map.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { matConstruction, matWork } from '@ng-icons/material-icons/baseline';
import { TableColumnsProps } from '../../models/tables.models';

@Component({
  selector: 'app-do-viability',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    NgIconComponent,
    ReactiveFormsModule,
    ViabilityStepperComponent,
    TableComponent,
    MapComponent,
    MatFormFieldModule,
    MatAutocompleteModule,
    MatInputModule,
    AsyncPipe,
  ],
  providers: [provideIcons({ matConstruction, matWork })],
  templateUrl: './do-viability.component.html',
  styleUrl: './do-viability.component.scss',
})
export class DoViabilityComponent implements OnInit {
  constructor(private router: Router) {}

  step = 0;
  viabilityType: boolean | null = null;
  typeAddressInput = 'iptu';
  responsible = false;
  viability = {
    cnae: 1,
    cnpj: '123456789012',
    address: 'Rua 1, 123',
    tipology: 1,
  };

  columnsCnaes: TableColumnsProps[] = [
    { label: 'Código', value: 'code' },
    { label: 'Descrição', value: 'name' },
    {
      label: 'Risco',
      value: 'risco',
      customBody: (row) => {
        if ((row as unknown as { risco: number }).risco === 1) {
          return '<span class="p-1 pl-4 pr-4 rounded-xl bg-red-700 text-white text-sm">Alto Risco</span>';
        } else {
          return '<span class="p-1 pl-4 pr-4 rounded-xl bg-green-700 text-white text-sm">Baixo Risco</span>';
        }
      },
    },
  ];
  cnaes = [{ code: 1, name: '0001 - CNAE AX', risco: 0 }];

  cnaeControl = new FormControl();
  filteredOptions: Observable<string[]> | undefined;

  ngOnInit() {
    this.filteredOptions = this.cnaeControl.valueChanges.pipe(
      startWith(''),
      map((value) => {
        console.log('value', value);
        return this._filter(value);
      }),
    );
  }

  private _filter(value: string): string[] {
    console.log('filter', value);
    const filterValue = value.toLowerCase();
    return this.cnaes
      .filter((option) => option.name.toLowerCase().includes(filterValue))
      .map((option) => option.name);
  }

  public doViability = () => {
    const viabilities = localStorage.getItem('viabilities');
    if (viabilities) {
      localStorage.setItem(
        'viabilities',
        JSON.stringify([this.viability, ...JSON.parse(viabilities)]),
      );
    } else {
      localStorage.setItem('viabilities', JSON.stringify([this.viability]));
    }
    this.router.navigate(['/retorno-viabilidade']);
  };

  public next = () => {
    this.step++;
  };

  public back = () => {
    this.step--;
  };

  public changeTypeAddress = (type: string) => {
    this.typeAddressInput = type;
  };

  public createEnterprise = () => {
    setTimeout(() => {
      window.open('https://www.jucec.ce.gov.br/', '_blank');
    }, 1000);
  };
}
