import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-filter-box',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './filter-box.component.html',
  styleUrl: './filter-box.component.scss',
})
export class FilterBoxComponent {
  @Input() showInclude = false;
  @Input() content = null;
  @Input() placeholder: string = '';
  @Input() loading: boolean = false;

  @Output() include = new EventEmitter();
  @Output() filter = new EventEmitter();
  @Output() cleanner = new EventEmitter();

  inputValue: string = '';

  callInclude() {
    this.include.emit(this.inputValue);
  }

  callFilter = () => {
    this.filter.emit(this.inputValue);
  };

  callClean = () => {
    this.inputValue = '';
    this.cleanner.emit(this.inputValue);
    this.callFilter();
  };
}
