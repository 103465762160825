import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { compareValidator } from '../../../../validators/compare.validator';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';

@Component({
  selector: 'app-feasibility-enterprise',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatDividerModule,
    MatIconModule,
    NgxMaskDirective,
    NgxMaskPipe,
  ],
  templateUrl: './enterprise.component.html',
  styleUrl: './enterprise.component.scss',
  providers: [provideNgxMask()],
})
export class EnterpriseFeasibilityComponent implements OnInit {
  @Input() feasibilityForm!: FormGroup;
  @Input() step!: number;
  @Output() setStep = new EventEmitter<number>();
  form!: FormGroup;
  @Output() emitForm = new EventEmitter<FormGroup>();

  typeOfEnterprise: number = 0;

  constructor(private formBuilder: FormBuilder) {}

  ngOnInit(): void {
    this.createForm();
    this.getForm();
  }

  getForm(): void {
    const type = this.feasibilityForm.get('type')?.value;
    const typeOfEnterprise = Number(type?.typeOfEnterprise);
    this.typeOfEnterprise = typeOfEnterprise;
    const typeOfTaxDomicile = Number(type?.typeOfTaxDomicile);

    const form = this.feasibilityForm.get('enterprise') as FormGroup;

    const validateType = typeOfEnterprise === 1 && typeOfTaxDomicile === 0;

    // Se for true, eles podem ser iguais, se não, não podem
    const buildingAreaValidators = validateType
      ? { validators: compareValidator('totalArea', 'usedArea', 'equal') }
      : { validators: compareValidator('totalArea', 'usedArea', 'greater') };

    if (form)
      this.form = this.formBuilder.group({
        landArea: new FormGroup(
          {
            totalArea: new FormControl(
              form.get('landArea.totalArea')?.value,
              Validators.required,
            ),
            usedArea: new FormControl(
              form.get('landArea.usedArea')?.value,
              Validators.required,
            ),
            remainingArea: new FormControl(
              form.get('landArea.remainingArea')?.value,
              Validators.required,
            ),
          },
          { validators: compareValidator('totalArea', 'usedArea', 'greater') },
        ),

        buildingArea: new FormGroup(
          {
            totalArea: new FormControl(
              form.get('buildingArea.totalArea')?.value,
              [Validators.required],
            ),
            usedArea: new FormControl(
              form.get('buildingArea.usedArea')?.value,
              Validators.required,
            ),
            remainingArea: new FormControl(
              form.get('buildingArea.remainingArea')?.value,
              Validators.required,
            ),
          },
          buildingAreaValidators,
        ),
      });
  }

  createForm(): void {
    const type = this.feasibilityForm.get('type')?.value;
    const typeOfEnterprise = Number(type?.typeOfEnterprise);
    this.typeOfEnterprise = typeOfEnterprise;
    const typeOfTaxDomicile = Number(type?.typeOfTaxDomicile);

    const validateType = typeOfEnterprise === 1 && typeOfTaxDomicile === 0;

    // Se for true, eles podem ser iguais, se não, não podem
    const buildingAreaValidators = validateType
      ? { validators: compareValidator('totalArea', 'usedArea', 'equal') }
      : { validators: compareValidator('totalArea', 'usedArea', 'greater') };

    this.form = this.formBuilder.group({
      landArea: new FormGroup(
        {
          totalArea: new FormControl(null, [
            Validators.required,
            Validators.min(1),
          ]),
          usedArea: new FormControl(null, [
            Validators.required,
            Validators.min(1),
          ]),
          remainingArea: new FormControl(null, Validators.required),
        },
        { validators: compareValidator('totalArea', 'usedArea', 'greater') },
      ),

      buildingArea: new FormGroup(
        {
          totalArea: new FormControl(null, [
            Validators.required,
            Validators.min(1),
          ]),
          usedArea: new FormControl(null, [
            Validators.required,
            Validators.min(1),
          ]),
          remainingArea: new FormControl(null, Validators.required),
        },
        buildingAreaValidators,
      ),
    });
  }

  calculateRemainingArea(controlGroup: 'landArea' | 'buildingArea'): void {
    const totalArea = this.form.get(controlGroup)?.get('totalArea')?.value;
    const usedArea = this.form.get(controlGroup)?.get('usedArea')?.value;
    const remainingArea = totalArea - usedArea;

    if (usedArea > totalArea) {
      if (controlGroup === 'landArea') {
        this.form.get(controlGroup)?.get('remainingArea')?.setValue(0);
      } else {
        this.form.get(controlGroup)?.get('remainingArea')?.setValue(0);
      }
    } else {
      if (controlGroup === 'landArea') {
        this.form
          .get(controlGroup)
          ?.get('remainingArea')
          ?.setValue(remainingArea);
      } else {
        this.form
          .get(controlGroup)
          ?.get('remainingArea')
          ?.setValue(remainingArea);
      }
    }
  }

  calculateTotalArea(controlGroup: 'landArea' | 'buildingArea'): void {
    const remaining = this.form.get(controlGroup)?.get(`remainingArea`)?.value;
    const usedArea = this.form.get(controlGroup)?.get('usedArea')?.value;
    const totalArea = usedArea + remaining;

    this.form.get(controlGroup)?.get('totalArea')?.setValue(totalArea);
  }

  calculateBuildingArea(controlGroup: 'landArea' | 'buildingArea'): void {
    this.calculateTotalArea(controlGroup);

    const remaining = this.form.get(controlGroup)?.get(`remainingArea`)?.value;
    const totalArea = this.form.get(controlGroup)?.get('totalArea')?.value;
    const usedArea = this.form.get(controlGroup)?.get('usedArea')?.value;
    const remainingArea = usedArea - totalArea + remaining;

    if (remaining > totalArea) {
      this.form.get(controlGroup)?.get('usedArea')?.setValue(0);
    } else
      this.form
        .get(controlGroup)
        ?.get('usedArea')
        ?.setValue(usedArea - remainingArea);
  }

  onSubmit() {
    if (this.form.valid) {
      this.setStep.emit(5);
      this.emitForm.emit(this.form);
    } else {
      this.form.markAllAsTouched();
    }
  }

  formControl(control: string): AbstractControl | null {
    return this.form.get(control);
  }

  getFormValue(control: string): number {
    return Number(this.formControl(control)?.value) || 0;
  }
}
