<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->

<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="card">
    <h1 class="font-medium text-2xl">
      Endereço do empreendimento<font color="red">*</font>
    </h1>

    <div class="w-full flex flex-row justify-center items-center mt-6">
      <div
        [ngClass]="
          tab === 0
            ? 'w-1/2 flex flex-col justify-center items-center border-b-4 border-slate-800 p-4'
            : 'w-1/2 flex flex-col justify-center items-center border-b border-slate-800 p-4'
        "
        (click)="handleChangeTab(0)"
      >
        <div
          [ngClass]="
            tab === 0
              ? 'mb-2 rounded-full py-1 px-6 bg-slate-800 max-h-[34px]'
              : 'mb-2 rounded-full py-1 px-6 bg-transparent max-h-[34px]'
          "
        >
          <mat-icon
            aria-hidden="false"
            [ngClass]="tab === 0 ? 'text-white' : 'text-slate-800'"
            fontIcon="home"
          ></mat-icon>
        </div>
        <p class="text-md">Endereço</p>
      </div>
      <!-- <div
        [ngClass]="
          tab === 1
            ? 'w-1/2 flex flex-col justify-center items-center border-b-4 border-slate-800 p-4'
            : 'w-1/2 flex flex-col justify-center items-center border-b border-slate-800 p-4'
        "
        (click)="handleChangeTab(1)"
      >
        <div
          [ngClass]="
            tab === 1
              ? 'mb-2 rounded-full py-1 px-6 bg-slate-800 max-h-[34px]'
              : 'mb-2 rounded-full py-1 px-6 bg-transparent max-h-[34px]'
          "
        >
          <mat-icon
            aria-hidden="false"
            [ngClass]="tab === 1 ? 'text-white' : 'text-slate-800'"
            fontIcon="receipt_long"
          ></mat-icon>
        </div>
        <p class="text-md">IPTU</p>
      </div> -->
      <div
        [ngClass]="
          tab === 2
            ? 'w-1/2 flex flex-col justify-center items-center border-b-4 border-slate-800 p-4'
            : 'w-1/2 flex flex-col justify-center items-center border-b border-slate-800 p-4'
        "
        (click)="handleChangeTab(2)"
      >
        <div
          [ngClass]="
            tab === 2
              ? 'mb-2 rounded-full py-1 px-6 bg-slate-800 max-h-[34px]'
              : 'mb-2 rounded-full py-1 px-6 bg-transparent max-h-[34px]'
          "
        >
          <mat-icon
            aria-hidden="false"
            [ngClass]="tab === 2 ? 'text-white' : 'text-slate-800'"
            fontIcon="map"
          ></mat-icon>
        </div>
        <p class="text-md">Mapa</p>
      </div>
    </div>

    <div
      *ngIf="tab === 0"
      class="w-full flex flex-col md:flex-row justify-center items-center mt-2"
    >
      <div
        class="w-full md:w-1/2 grid grid-cols-1 md:grid-cols-3 gap-2 md:mr-4"
      >
        <div class="w-full md:col-span-1">
          <div class="w-full flex flex-col justify-center items-center">
            <p class="w-full text-sm font-normal text-left">
              CEP<font color="red">*</font>
            </p>
            <input
              class="input w-full"
              type="text"
              placeholder="00000-000"
              mask="00000-000"
              [dropSpecialCharacters]="false"
              formControlName="postalcode"
              required
            />
            <div
              class="w-full flex flex-row justify-start text-xs font-medium text-red-500 mt-1"
              *ngIf="
                (form.controls['postalcode'].touched ||
                  form.controls['postalcode'].dirty) &&
                form.controls['postalcode'].errors &&
                form.controls['postalcode'].errors['required']
              "
            >
              CEP é obrigatório.
            </div>
          </div>
        </div>
        <div class="w-full md:col-span-2">
          <div class="w-full flex flex-col justify-center items-center">
            <p class="w-full text-sm font-normal text-left">
              Endereço<font color="red">*</font>
            </p>
            <input
              class="input w-full"
              type="text"
              placeholder="Ex: Rua Ceara"
              formControlName="address"
              required
            />
            <div
              class="w-full flex flex-row justify-start text-xs font-medium text-red-500 mt-1"
              *ngIf="
                (form.controls['address'].touched ||
                  form.controls['address'].dirty) &&
                form.controls['address'].errors &&
                form.controls['address'].errors['required']
              "
            >
              Endereço é obrigatório.
            </div>
          </div>
        </div>
      </div>

      <div
        class="w-full md:w-1/2 grid grid-cols-1 md:grid-cols-6 gap-2 mt-2 md:mt-0"
      >
        <div class="w-full md:col-span-1">
          <div class="w-full flex flex-col justify-center items-center">
            <p class="w-full text-sm font-normal text-left">
              Número<font color="red">*</font>
            </p>
            <input
              class="input w-full"
              type="number"
              min="0"
              placeholder="Ex: 123"
              formControlName="number"
              required
              onkeydown="return event.key !== '-'"
            />
            <div
              class="w-full flex flex-row justify-start text-xs font-medium text-red-500 mt-1"
              *ngIf="
                (form.controls['number'].touched ||
                  form.controls['number'].dirty) &&
                form.controls['number'].errors &&
                form.controls['number'].errors['required']
              "
            >
              Número é obrigatório.
            </div>
          </div>
        </div>
        <div class="w-full md:col-span-2">
          <div class="w-full flex flex-col justify-center items-center">
            <p class="w-full text-sm font-normal text-left">Complemento</p>
            <input
              class="input w-full"
              type="text"
              placeholder="Ex: Bloco A, Apto 123"
              formControlName="complement"
            />
          </div>
        </div>
        <div class="w-full md:col-span-3">
          <div class="w-full flex flex-col justify-center items-center">
            <p class="w-full text-sm font-normal text-left">
              Bairro<font color="red">*</font>
            </p>
            <input
              class="input w-full"
              type="text"
              placeholder="Ex: Jardim das Flores"
              formControlName="neighborhood"
              required
            />
            <div
              class="w-full flex flex-row justify-start text-xs font-medium text-red-500 mt-1"
              *ngIf="
                (form.controls['neighborhood'].touched ||
                  form.controls['neighborhood'].dirty) &&
                form.controls['neighborhood'].errors &&
                form.controls['neighborhood'].errors['required']
              "
            >
              Bairro é obrigatório.
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <div
      *ngIf="tab === 1"
      class="w-full flex flex-col justify-center items-start mt-2"
    >
      <div class="max-w-44">
        <div class="w-full flex flex-col justify-center items-center">
          <p class="w-full text-sm font-normal text-left">
            IPTU<font color="red">*</font>
          </p>
          <input
            class="input w-full"
            type="text"
            placeholder="Informar"
            required
          />
        </div>
      </div>
      <div class="w-full h-px bg-stone-300 mt-4"></div>
      <div
        class="w-full flex flex-row justify-center items-center mt-2 opacity-40 pointer-events-none cursor-not-allowed"
      >
        <div class="w-1/2 grid grid-cols-3 gap-2 mr-4">
          <div class="col-span-1">
            <div class="w-full flex flex-col justify-center items-center">
              <p class="w-full text-sm font-normal text-left">
                CEP<font color="red">*</font>
              </p>
              <input
                class="input w-full"
                type="text"
                placeholder="00.000-000"
                mask="00000-000"
                [dropSpecialCharacters]="false"
                required
              />
            </div>
          </div>
          <div class="col-span-2">
            <div class="w-full flex flex-col justify-center items-center">
              <p class="w-full text-sm font-normal text-left">
                Endereço<font color="red">*</font>
              </p>
              <input
                class="input w-full"
                type="text"
                placeholder="Ex: Rua Ceara"
                required
              />
            </div>
          </div>
        </div>

        <div class="w-1/2 grid grid-cols-6 gap-2">
          <div class="col-span-1">
            <div class="w-full flex flex-col justify-center items-center">
              <p class="w-full text-sm font-normal text-left">
                Número<font color="red">*</font>
              </p>
              <input
                class="input w-full"
                type="text"
                mask="000000"
                [allowNegativeNumbers]="false"
                [dropSpecialCharacters]="false"
                placeholder="Ex: 123"
                required
              />
            </div>
          </div>
          <div class="col-span-2">
            <div class="w-full flex flex-col justify-center items-center">
              <p class="w-full text-sm font-normal text-left">Complemento</p>
              <input
                class="input w-full"
                type="text"
                placeholder="Ex: Bloco A, Apto 123"
              />
            </div>
          </div>
          <div class="col-span-3">
            <div class="w-full flex flex-col justify-center items-center">
              <p class="w-full text-sm font-normal text-left">
                Bairro<font color="red">*</font>
              </p>
              <input
                class="input w-full"
                type="text"
                placeholder="Ex: Jardim das Flores"
              />
            </div>
          </div>
        </div>
      </div>
    </div> -->

    <div *ngIf="tab === 2">
      <div class="mapa-container">
        <div class="mapa-frame">
          <div id="mapa"></div>
        </div>
      </div>

      <div class="w-full h-px bg-stone-300 mt-4"></div>
      <div
        class="w-full flex flex-col md:flex-row justify-center items-center mt-2 md:mt-0"
      >
        <div
          class="w-full md:w-1/2 grid grid-cols-1 md:grid-cols-3 gap-2 md:mr-4"
        >
          <div class="w-full md:col-span-1">
            <div class="w-full flex flex-col justify-center items-center">
              <p class="w-full text-sm font-normal text-left">
                CEP<font color="red">*</font>
              </p>
              <input
                class="input w-full"
                type="text"
                placeholder="00000-000"
                mask="00000-000"
                [dropSpecialCharacters]="false"
                required
                [defaultValue]="form.get('postalcode')?.value || ''"
                formControlName="postalcode"
                readonly
              />
              <div
                class="w-full flex flex-row justify-start text-xs font-medium text-red-500 mt-1"
                *ngIf="
                  (form.controls['postalcode'].touched ||
                    form.controls['postalcode'].dirty) &&
                  form.controls['postalcode'].errors &&
                  form.controls['postalcode'].errors['required']
                "
              >
                CEP é obrigatório.
              </div>
            </div>
          </div>
          <div class="w-full md:col-span-2">
            <div class="w-full flex flex-col justify-center items-center">
              <p class="w-full text-sm font-normal text-left">
                Endereço<font color="red">*</font>
              </p>
              <input
                class="input w-full"
                type="text"
                placeholder="Ex: Rua Ceara"
                [defaultValue]="form.get('address')?.value || ''"
                formControlName="address"
                required
              />
              <div
                class="w-full flex flex-row justify-start text-xs font-medium text-red-500 mt-1"
                *ngIf="
                  (form.controls['address'].touched ||
                    form.controls['address'].dirty) &&
                  form.controls['address'].errors &&
                  form.controls['address'].errors['required']
                "
              >
                Endereço é obrigatório.
              </div>
            </div>
          </div>
        </div>

        <div
          class="w-full md:w-1/2 grid grid-cols-1 md:grid-cols-6 gap-2 mt-2 md:mt-0"
        >
          <div class="w-full md:col-span-1">
            <div class="w-full flex flex-col justify-center items-center">
              <p class="w-full text-sm font-normal text-left">
                Número<font color="red">*</font>
              </p>
              <input
                class="input w-full"
                type="text"
                min="0"
                placeholder="Ex: 123"
                required
                formControlName="number"
                onkeydown="return event.key !== '-'"
              />
              <div
                class="w-full flex flex-row justify-start text-xs font-medium text-red-500 mt-1"
                *ngIf="
                  (form.controls['number'].touched ||
                    form.controls['number'].dirty) &&
                  form.controls['number'].errors &&
                  form.controls['number'].errors['required']
                "
              >
                Número é obrigatório.
              </div>
            </div>
          </div>
          <div class="w-full md:col-span-2">
            <div class="w-full flex flex-col justify-center items-center">
              <p class="w-full text-sm font-normal text-left">Complemento</p>
              <input
                class="input w-full"
                type="text"
                placeholder="Ex: Bloco A, Apto 123"
                formControlName="complement"
              />
            </div>
          </div>
          <div class="w-full md:col-span-3">
            <div class="w-full flex flex-col justify-center items-center">
              <p class="w-full text-sm font-normal text-left">
                Bairro<font color="red">*</font>
              </p>
              <input
                class="input w-full"
                type="text"
                placeholder="Ex: Jardim das Flores"
                [defaultValue]="form.get('neighborhood')?.value || ''"
                required
                formControlName="neighborhood"
              />
              <div
                class="w-full flex flex-row justify-start text-xs font-medium text-red-500 mt-1"
                *ngIf="
                  (form.controls['neighborhood'].touched ||
                    form.controls['neighborhood'].dirty) &&
                  form.controls['neighborhood'].errors &&
                  form.controls['neighborhood'].errors['required']
                "
              >
                Bairro é obrigatório.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="w-full flex flex-row justify-end items-center mt-6">
      <button
        type="button"
        class="btn btn-secondary text-sm"
        (click)="this.setStep.emit(2)"
      >
        Voltar
      </button>
      <button
        class="btn btn-primary text-sm ml-2 disabled:bg-opacity-65"
        [disabled]="!form.valid || loading"
        type="submit"
      >
        Próximo
      </button>
    </div>
  </div>
</form>
