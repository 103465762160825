import { v4 as uuidv4 } from 'uuid';
import { CommonModule } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import {
  FeasibilityResponse,
  FeasibilityResponseReview,
} from '../../models/feasibility.model';
import { MatIcon } from '@angular/material/icon';
import { FilterBoxComponent } from '../../components/filter-box/filter-box.component';
import { NgIcon } from '@ng-icons/core';
import { FeasibilityService } from '../../services/feasibility.service';
import { ModalService } from '../../services/modal.service';
import { MatDividerModule } from '@angular/material/divider';
import { ActivityFeasibilityComponent } from '../feasibility/views/activity/activity.component';
import {
  BOLD_BUTTON,
  EditorConfig,
  ITALIC_BUTTON,
  NgxSimpleTextEditorModule,
  UNDERLINE_BUTTON,
} from 'ngx-simple-text-editor';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';

@Component({
  selector: 'app-re-feasibility-review',
  standalone: true,
  imports: [
    FormsModule,
    CommonModule,
    MatDividerModule,
    MatIcon,
    FilterBoxComponent,
    NgIcon,
    ActivityFeasibilityComponent,
    NgxSimpleTextEditorModule,
    ReactiveFormsModule,
  ],
  templateUrl: './re-feasibility-review.component.html',
  styleUrl: './re-feasibility-review.component.scss',
})
export class ReFeasbilityReviewComponent implements OnInit {
  data: FeasibilityResponse & FeasibilityResponseReview =
    {} as FeasibilityResponse & FeasibilityResponseReview;
  loading: boolean = true;
  loadingDownload: boolean = false;

  feasibilityId!: string | undefined;
  documentId!: string | undefined;

  private feasibilityService = inject(FeasibilityService);

  ruleForm!: FormGroup;

  config: EditorConfig = {
    placeholder: 'Inserir texto...',
    buttons: [
      BOLD_BUTTON,
      ITALIC_BUTTON,
      UNDERLINE_BUTTON,
      // ORDERED_LIST_BUTTON,
      // UNORDERED_LIST_BUTTON,
    ],
  };

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private modalService: ModalService,
    private formBuilder: FormBuilder,
  ) {
    this.route.params.subscribe((params) => {
      const id = params['id'];
      if (id) this.documentId = id;
    });
  }

  ngOnInit(): void {
    this.getFeasibility();
    this.createRuleForm();

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        window.scrollTo(0, 0);
      }
    });
  }

  createRuleForm(): void {
    this.ruleForm = this.formBuilder.group({
      justification: this.formBuilder.control(null, [Validators.required]),
      ocupationRate: this.formBuilder.control(null, [
        Validators.required,
        Validators.min(0),
      ]),
      utilizationRate: this.formBuilder.control(null, [
        Validators.required,
        Validators.min(0),
      ]),
      permeabilityRate: this.formBuilder.control(null, [
        Validators.required,
        Validators.min(0),
      ]),
      parcelableLandArea: this.formBuilder.control(null, [
        Validators.required,
        Validators.min(0),
      ]),
    });
  }

  patchRuleForm(): void {
    this.ruleForm.patchValue({
      justification: this.data?.justification || null,
      ocupationRate: this.data?.ocupationRate || null,
      utilizationRate: this.data?.utilizationRate || null,
      permeabilityRate: this.data?.permeabilityRate || null,
      parcelableLandArea: this.data?.parcelableLandArea || null,
    });
  }

  getFeasibility(): void {
    if (this.documentId) {
      this.feasibilityService
        .getFeasibilityByIdAndUserDocument(this.documentId)
        .subscribe({
          next: (response) => {
            this.data = response as FeasibilityResponse &
              FeasibilityResponseReview;

            this.patchRuleForm();

            this.feasibilityId = response.id;
            this.loading = false;
          },
          error: (error) => {
            console.log(error);
            this.loading = false;
          },
        });
    }
  }

  getRisk(r: number | undefined): string {
    if (r !== undefined && r !== null) {
      switch (r) {
        case 0:
          return 'Requer Informações';
        case 1:
          return 'Risco I';
        case 2:
          return 'Risco II';
        case 3:
          return 'Risco III';
        default:
          return 'Competência Estadual';
      }
    } else return '-';
  }

  getFileSize(size: number): string {
    if (size < 1024) return `${size} B`;
    else if (size < 1024 * 1024) return `${(size / 1024).toFixed(2)} KB`;
    else return `${(size / 1024 / 1024).toFixed(2)} MB`;
  }

  goBack(): void {
    this.router.navigate([`/analysis`]);
  }

  downloadPdf() {
    if (this.feasibilityId) {
      this.loadingDownload = true;
      this.feasibilityService
        .downloadFeasibilityPdf(this.feasibilityId, 'is_original')
        .subscribe({
          next: (response) => {
            const blob = new Blob([response], { type: 'application/pdf' });
            const url = window.URL.createObjectURL(blob);
            window.open(url);
            this.loadingDownload = false;
          },
          error: (error) => {
            console.log(error);
          },
          complete: () => {
            this.loadingDownload = false;
          },
        });
    }
  }

  getDateString(date: string | undefined): string {
    if (date) return new Date(date).toLocaleString();
    else return '-';
  }

  getStatus(s: string) {
    switch (s) {
      case 'ANALYSIS':
        return 'Em análise';
      case 'PEN_REVIEW':
        return 'Pendente de revisão';
      case 'REVIEW':
        return 'Enviado para Revisão';
      case 'SECOND_REVIEW':
        return 'Enviado para 2ª revisão';
      case 'PENDING':
        return 'Pendente';
      case 'PEN_ANALYSIS':
        return 'Pendente de análise';
      case 'PEN_RESPONSE':
        return 'Pendente de resposta';
      case 'PEN_REANALYSIS':
        return 'Pendente de reanálise';
      case 'REANALYSIS':
        return 'Em reanálise';
      case 'APPROVED':
        return 'Aprovado';
      case 'REJECTED':
        return 'Recusado';
      case 'ARCHIVED':
        return 'Arquivado';
      case 'RETURNED':
        return 'Devolvido';
      default:
        return 'Pendente';
    }
  }

  getStatusColor(s: string) {
    switch (s) {
      case 'ANALYSIS':
        return 'yellow';
      case 'PEN_REVIEW':
        return 'blue';
      case 'REVIEW':
        return 'blue';
      case 'SECOND_REVIEW':
        return 'blue';
      case 'PENDING':
        return 'yellow';
      case 'PEN_ANALYSIS':
        return 'yellow';
      case 'PEN_RESPONSE':
        return 'yellow';
      case 'PEN_REANALYSIS':
        return 'yellow';
      case 'REANALYSIS':
        return 'yellow';
      case 'APPROVED':
        return 'green';
      case 'REJECTED':
        return 'red';
      case 'ARCHIVED':
        return 'gray';
      case 'RETURNED':
        return 'yellow';
      default:
        return 'yellow';
    }
  }

  getStatusIcon(s: string) {
    switch (s) {
      case 'ANALYSIS':
        return 'hourglass_empty';
      case 'PEN_REVIEW':
        return 'hourglass_full';
      case 'REVIEW':
        return 'hourglass_full';
      case 'SECOND_REVIEW':
        return 'hourglass_full';
      case 'PENDING':
        return 'hourglass_empty';
      case 'PEN_ANALYSIS':
        return 'hourglass_empty';
      case 'PEN_RESPONSE':
        return 'feedback';
      case 'PEN_REANALYSIS':
        return 'hourglass_empty';
      case 'REANALYSIS':
        return 'hourglass_empty';
      case 'APPROVED':
        return 'check';
      case 'REJECTED':
        return 'close';
      case 'ARCHIVED':
        return 'archive';
      case 'RETURNED':
        return 'reply';
      default:
        return 'hourglass_empty';
    }
  }

  getTypeOfFeasibility(t: number) {
    switch (t) {
      case 0:
        return 'Estabelecimento Físico';
      default:
        return 'Domicílio Fiscal';
    }
  }

  getTaxDomicile(t: number) {
    switch (t) {
      case 0:
        return 'Residencial';
      case 1:
        return 'Espaço de Trabalho Coletivo';
      default:
        return 'Outros';
    }
  }

  getResult(r: string): string {
    switch (r) {
      case 'aprovado':
        return 'Aprovado';
      case 'recusado':
        return 'Recusado';
      case 'requer análise':
        return 'Requer Análise';
      default:
        return 'Projeto Especial';
    }
  }

  onChangeValues(event: Event, type: string): void {
    const input =
      type === 'feasibilityResult'
        ? (event.target as HTMLSelectElement)
        : (event.target as HTMLInputElement);

    if (type.indexOf('NotApplied') >= 0) {
      this.data = {
        ...this.data,
        [type]: (input as HTMLInputElement).checked,
      };

      if ((input as HTMLInputElement).checked) {
        this.ruleForm.get('parcelableLandArea')?.clearValidators();
      } else {
        this.ruleForm
          .get('parcelableLandArea')
          ?.setValidators([Validators.required, Validators.min(0)]);
      }
    } else if (type.includes('landArea') || type.includes('buildingArea')) {
      if (input.value) {
        this.data[type] = Number(input.value);
      }
    } else {
      this.data[type] = input.value;
    }
  }

  onChangeOrientationValues(value: string, type: string): void {
    if (value) {
      this.data[type] = value;
    }
  }

  generateProtocolId(): string {
    const uuidWithHyphens = uuidv4();
    const shortUUID = uuidWithHyphens.substring(0, 12);
    return shortUUID;
  }

  onSubmit(): void {
    this.loading = true;

    this.ruleForm.markAllAsTouched();

    if (this.ruleForm.invalid) {
      this.loading = false;

      this.modalService.add({
        id: 'refeasibility-review-fields-error',
        icon: 'warning',
        title: 'Ops! Algo errado.',
        description:
          'Preencha todos os campos obrigatórios para encaminhar para revisão.',
        actionLabel: 'Continuar',
      });

      return;
    }

    // remove _id
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { _id, ...dto } = this.data;

    this.feasibilityService
      .updateFeasibilityStatus({
        ...dto,
        ocupationRate: Number(dto.ocupationRate),
        utilizationRate: Number(dto.utilizationRate),
        permeabilityRate: Number(dto.permeabilityRate),
        parcelableLandArea: dto.parcelableLandAreaNotApplied
          ? 0
          : Number(dto.parcelableLandArea),
        frontSetback: Number(dto.frontSetback),
        backSetback: Number(dto.backSetback),
        sideSetback: Number(dto.sideSetback),
        maxBuildingHeight: Number(dto.maxBuildingHeight),
        minLotSize: Number(dto.minLotSize),
        maxLotSize: Number(dto.maxLotSize),
        minFrontage: Number(dto.minFrontage),
        minDepth: Number(dto.minDepth),
        minBlockSize: Number(dto.minBlockSize),
        maxBlockSize: Number(dto.maxBlockSize),
        status: 'PEN_REVIEW',
        historyStatus: dto.historyStatus || [],
        flag: 'is_clone',
      })
      .subscribe({
        next: () => {
          this.modalService.add({
            id: 'refeasibility-review-success',
            icon: 'success',
            title: 'Tudo certo!',
            description: 'Encaminhado para revisão com sucesso.',
            actionLabel: 'Visualizar consulta',
            action: () => {
              this.router.navigate([`/analysis`]);
              this.modalService.remove('refeasibility-review-success');
            },
          });
          this.loading = false;
        },
        error: (error) => {
          console.log(error);
          this.modalService.add({
            id: 'refeasibility-review-error',
            icon: 'warning',
            title: 'Ops! Algo errado.',
            description:
              'Ocorreu um erro ao encaminhar para revisão. Tente novamente.',
            actionLabel: 'Continuar',
          });
          this.loading = false;
        },
      });
  }
}
