
<div class="m-4">
  <div class="m-4">
    <h2 class="card-title">Dashboard</h2>
    <div class="divider"></div>
    <div class="flex flex-row mt-6">
      <div class="w-1/3">
        <p>Quantidade de viabilidades</p>
        <div id="chart-1"></div>
      </div>
      <div class="w-1/3">
        <p>Viabilidades aceitas</p>
        <div id="chart-2"></div>
      </div>
      <div class="w-1/3">
        <p>Viabilidades recusadas</p>
        <div id="chart-3"></div>
      </div>
    </div>
    <div class="flex flex-row justify-center mt-6">
      <div class="w-1/2">
        <p>Viabilidades por tipo</p>
        <div id="chart-4"></div>
      </div>
      <div class="w-1/2">
        <p>Viabilidades por status</p>
        <div id="chart-5"></div>
      </div>
    </div>
  </div>
</div>
