import { Injectable, inject } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  CreateFeasibilityDto,
  FeasibilityResponse,
  FlagToUpdate,
  GetAddresInputDTO,
  GetAddresOutputDTO,
} from '../models/feasibility.model';
import { Paginate } from '../models/paginate.model';

@Injectable({
  providedIn: 'root',
})
export class FeasibilityService {
  private httpService = inject(HttpClient);
  private url = `${environment.urlApi}/feasibility-consultation`;
  private urlOpenStreet = environment.urlOpenStreet;

  constructor() {}

  findResponsible(cpf: string): Observable<{ initials: string[] }> {
    return this.httpService.get<{ initials: string[] }>(
      `${this.url}/responsible/${cpf}`,
    );
  }

  ValidateResponsible(dto: { cpf: string; initial: string }): Observable<void> {
    return this.httpService.post<void>(
      `${this.url}/responsible/${dto.cpf}`,
      dto,
    );
  }

  getAddressByCoordinates(
    dto: GetAddresInputDTO,
  ): Observable<GetAddresOutputDTO> {
    return this.httpService.get<GetAddresOutputDTO>(
      `${this.urlOpenStreet}/reverse`,
      {
        params: {
          format: 'json',
          lat: dto.lat.toString(),
          lon: dto.lng.toString(),
        },
      },
    );
  }

  getCoordinatesByAddress(address: string): Observable<GetAddresOutputDTO[]> {
    return this.httpService.get<GetAddresOutputDTO[]>(
      `${this.urlOpenStreet}/search`,
      {
        params: {
          format: 'json',
          q: address,
          addressdetails: '1',
          limit: '1',
        },
      },
    );
  }

  createFeasibility(
    dto: CreateFeasibilityDto,
  ): Observable<FeasibilityResponse> {
    return this.httpService.post<FeasibilityResponse>(`${this.url}`, dto);
  }

  downloadFeasibilityPdf(id: string, flag: string): Observable<Blob> {
    return this.httpService.get(
      `${this.url}/pdf?feasibilityId=${id}&flag=${flag}`,
      {
        responseType: 'blob',
      },
    );
  }

  getFeasibilityByUserDocument(
    document: string,
    page: number,
    limit: number,
    keyword?: string,
    orderBy?: string,
    orderField?: string,
  ): Observable<Paginate<FeasibilityResponse>> {
    return this.httpService.get<Paginate<FeasibilityResponse>>(
      `${this.url}/${document}`,
      {
        params: {
          page: page.toString(),
          limit: limit.toString(),
          search: keyword?.toLowerCase() || '',
          orderBy: orderBy || '',
          orderField: orderField || '',
        },
      },
    );
  }

  getFeasibilityByIdAndUserDocument(
    id: string,
    document?: string,
  ): Observable<FeasibilityResponse> {
    return this.httpService.get<FeasibilityResponse>(`${this.url}`, {
      params: {
        id: id,
        document: document || '',
      },
    });
  }

  sendRefeasbility(formData: FormData): Observable<void> {
    return this.httpService.post<void>(`${this.url}/refeasibility`, formData);
  }

  sendRefuseRefeasbility(formData: FormData, tag?: string): Observable<void> {
    if (tag)
      return this.httpService.post<void>(
        `${this.url}/refeasibility/response`,
        formData,
        {
          params: {
            tag,
          },
        },
      );

    return this.httpService.post<void>(
      `${this.url}/refeasibility/response`,
      formData,
    );
  }

  sendUserResponseRefeasbility(
    formData: FormData,
    tag: string,
  ): Observable<void> {
    return this.httpService.put<void>(
      `${this.url}/refeasibility/response`,
      formData,
      {
        params: {
          tag,
        },
      },
    );
  }

  getFeasibilitys(
    page: number,
    limit: number,
    search?: string,
    status?: string,
    analyst?: string,
    orderBy?: string,
    orderField?: string,
  ): Observable<Paginate<FeasibilityResponse>> {
    return this.httpService.get<Paginate<FeasibilityResponse>>(
      `${this.url}/analysis`,
      {
        params: {
          page: page.toString(),
          limit: limit.toString(),
          search: search || '',
          status: status || '',
          analyst: analyst || '',
          orderBy: orderBy || '',
          orderField: orderField || '',
        },
      },
    );
  }

  updateOpenedPdf(dto: FeasibilityResponse): Observable<void> {
    return this.httpService.put<void>(`${this.url}/opened-pdf`, dto);
  }

  updateFeasibilityStatus(
    dto: FeasibilityResponse & FlagToUpdate,
  ): Observable<FeasibilityResponse> {
    return this.httpService.put<FeasibilityResponse>(`${this.url}/pdf`, dto);
  }

  downloadRefeasbilityJustificationPdf(
    id: string,
    feasibilityId: string,
    tag: string,
  ): Observable<Blob> {
    return this.httpService.get(`${this.url}/refeasibility/${id}`, {
      params: {
        feasibilityId,
        tag,
      },
      responseType: 'blob',
    });
  }
}
