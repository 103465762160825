export interface OrderByColumn {
  orderby?: string;
  direction?: 'ASC' | 'DESC';
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function clearParameters<T extends Record<string, any>>(
  filter: T,
): Partial<T> {
  const filteredParams = Object.fromEntries(
    Object.entries(filter).filter(([, value]) => value != null && value !== ''),
  ) as Partial<T>;

  return filteredParams;
}
