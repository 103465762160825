import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { AlertService } from '../../services/alert.service';
import { CommonModule } from '@angular/common';
import { Alert } from '../../models/alert.models';
import { NavigationStart, Router } from '@angular/router';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
  imports: [CommonModule],
  standalone: true,
})
export class AlertComponent implements OnInit, OnDestroy {
  alerts: Alert[] = [];
  private alertSub: Subscription = new Subscription();
  public top: number | string;
  public right: number | string;

  classExpression: string = '';

  constructor(private alertService: AlertService, private router: Router) {
    this.top = 2;
    this.right = 2;
  }

  ngOnInit() {
    this.alertSub = this.alertService.alerts.subscribe((alerts) => {
      this.alerts = alerts;
      this.alerts.forEach((alert) => {
        this.classExpression = alert.type;

        if (alert.top) {
          this.top = alert.top;
          this.classExpression = this.classExpression + ` top-${alert.top}`;
        } else {
          this.classExpression = this.classExpression + ` top-${this.top}`;
        }

        if (alert.right) {
          this.right = alert.right;
          this.classExpression = this.classExpression + ` right-${alert.right}`;
        } else {
          this.classExpression = this.classExpression + ` right-${this.right}`;
        }

        if (alert.autoClose) {
          setTimeout(() => this.close(alert.id), 3000);
        }
      });
    });

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.alertService.clear();
      }
    });
  }

  ngOnDestroy() {
    this.alertSub.unsubscribe();
  }

  close(id: string) {
    this.alerts = this.alerts.map((alert) =>
      alert.id === id ? { ...alert, visible: false } : alert
    );

    setTimeout(() => {
      this.alerts = this.alerts.filter((alert) => alert.id !== id);
      this.alertService.remove(id);
    }, 250);
  }

  kill(id: string) {
    this.alertService.remove(id);
  }
}
