import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { Router } from '@angular/router';
import { AlertService } from '../../services/alert.service';
import { EmployeeService } from '../../services/employee.service';
import {
  EmployeeInterface,
  UpdateEmployeesInterfaceInput,
} from '../../models/employee.model';
import { ActivatedRoute } from '@angular/router';
import { AlertComponent } from '../../components/alert/alert.component';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-update-employee-form',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgMultiSelectDropDownModule,
    AlertComponent,
  ],
  templateUrl: './update-user.component.html',
  styleUrl: './update-user.component.css',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UpdateUserComponent implements OnInit {
  userForm!: FormGroup<{
    fullname: FormControl<string | null>;
    cpf: FormControl<string | null>;
    complement: FormControl<string | null>;
    birthdate: FormControl<string | null>;
    email: FormControl<string | null>;
    phone: FormControl<string | null>;
    street: FormControl<string | null>;
    neighborhood: FormControl<string | null>;
    city: FormControl<string | null>;
    state: FormControl<string | null>;
    role: FormControl<string | null>;
    jobTitle: FormControl<string | null>;
  }>;
  employee!: EmployeeInterface;
  employeeId: string;
  edit: boolean = false;
  role: string;

  constructor(
    private formBuilder: FormBuilder,
    private employeeService: EmployeeService,
    private route: ActivatedRoute,
    private alertService: AlertService,
    private router: Router,
  ) {
    this.employeeId = '';
    this.role = '';
  }

  ngOnInit(): void {
    this.getIdParamUrl();

    this.userForm = this.formBuilder.group({
      fullname: ['', Validators.required],
      cpf: [
        '',
        [
          Validators.required,
          Validators.pattern(/^\d{3}\.\d{3}\.\d{3}-\d{2}$/),
        ],
      ],
      complement: [''],
      birthdate: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phone: [
        '',
        [Validators.required, Validators.pattern(/^\(\d{2}\) \d{5}-\d{4}$/)],
      ],
      street: ['', Validators.required],
      neighborhood: ['', Validators.required],
      city: ['', Validators.required],
      state: ['', Validators.required],
      role: ['', Validators.required],
      jobTitle: ['', Validators.required],
    });

    this.findEmployeeById(this.employeeId);
  }

  getIdParamUrl() {
    this.route.params.subscribe((params: { [x: string]: string }) => {
      this.employeeId = params['id'];
    });
  }

  onItemSelect = (item: unknown) => {
    console.log(item);
  };

  onSelectAll = (items: unknown) => {
    console.log(items);
  };

  onSubmit() {
    if (this.userForm.valid) {
      const formData: UpdateEmployeesInterfaceInput = this.userForm
        .value as UpdateEmployeesInterfaceInput;

      this.employeeService.updateEmployee(formData).subscribe({
        next: (response) => {
          if (response) {
            return this.alertService.add({
              id: 'update-field-success',
              type: 'success',
              message: 'Funcionário atualizado com sucesso!.',
              top: 5,
              right: 1,
            });
          }
        },
        error: (e: HttpErrorResponse) => {
          console.error(e),
            this.alertService.add({
              id: 'update-field-error',
              type: 'error',
              message: 'Houve um erro ao atualizar funcionário.',
              top: 5,
              right: 1,
            });
        },
      });
    }
  }

  findEmployeeById(id: string) {
    this.employeeService.getById(id).subscribe({
      next: (response) => {
        if (response) {
          this.employee = response;

          this.userForm.patchValue({
            fullname: this.employee.fullname,

            cpf: this.employee.cpf,
            complement: this.employee.complement,
            birthdate: this.employee.birthdate,
            email: this.employee.email,
            phone: this.employee.phone,
            street: this.employee.street,
            neighborhood: this.employee.neighborhood,
            city: this.employee.city,
            state: this.employee.state,
            role: this.employee.role,
            jobTitle: this.employee.jobTitle,
          });

          this.role = this.employee.role;
        }
      },
      error: (e: HttpErrorResponse) => {
        console.error(e),
          this.alertService.add({
            id: 'update-field-error',
            type: 'error',
            message: 'Houve um erro ao buscar funcionário.',
            top: 5,
            right: 1,
          });
      },
    });
  }

  push(route: string): void {
    this.router.navigate([route]);
  }
}
