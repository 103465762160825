import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { provideRouter } from '@angular/router';
import { routes } from './app.routes';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { authInterceptor } from './auth/auth.interceptor';
import { refreshTokenInterceptor } from './auth/refreshtoken.interceptor';
import { provideStore } from '@ngrx/store';
import { UserReducer } from './reducers/user.reducer';
import { MacrozoneReducer } from './reducers/macrozone.reducer';
import { CnaeReducer } from './reducers/cnaes.reducer';
import { RuleReducer } from './reducers/rules.reducer';
import { IConfig, provideEnvironmentNgxMask } from 'ngx-mask';
import { MatNativeDateModule } from '@angular/material/core';

const maskConfigFunction: () => Partial<IConfig> = () => {
  return {
    validation: false,
  };
};

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideAnimationsAsync(),
    importProvidersFrom(MatNativeDateModule),
    provideHttpClient(
      withInterceptors([authInterceptor, refreshTokenInterceptor])
    ),
    provideStore({
      user: UserReducer,
      macrozones: MacrozoneReducer,
      cnaesToSave: CnaeReducer,
      rule: RuleReducer,
    }),
    provideEnvironmentNgxMask(maskConfigFunction),
  ],
};
