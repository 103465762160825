<div class="m-3">
  <div class="card">
    <h1 class="text-3xl font-bold mb-3">Nova Camada</h1>
    <div class="flex flex-col mb-2">
      <p class="label">Nome</p>
      <input type="text" class="input" placeholder="Nome da camada" />
    </div>
    <div class="flex flex-col">
      <p class="label">Descrição</p>
      <input type="text" class="input" />
    </div>
    <div class="flex flex-row">
      <button (click)="save()" class="btn btn-primary mt-3">Salvar</button>
      <button (click)="goBack()" class="btn btn-secondary mt-3 ml-3">Cancelar</button>
    </div>
  </div>
</div>
