<div
  *ngFor="let modal of modals"
  class="fixed inset-0 bg-black/20 overflow-y-auto h-screen w-full z-[1001] flex flex-row justify-center items-center"
>
  <div
    class="relative md:min-w-96 md:w-auto w-full flex flex-col justify-start items-center rounded bg-white p-4 max-w-3xl"
  >
    <warning-big-icon
      *ngIf="modal.icon === 'warning'"
      [color]="'#F29C1C'"
      [width]="62"
      [height]="62"
    ></warning-big-icon>
    <success-big-icon
      *ngIf="modal.icon === 'success'"
      [color]="'#56B73D'"
      [width]="62"
      [height]="62"
    ></success-big-icon>
    <p class="text-lg text-[#2F3980] mt-4">{{ modal.title }}</p>
    <p *ngIf="modal?.description" class="text-sm text-[#616372]">
      {{ modal.description }}
    </p>
    <div
      class="w-full flex flex-row justify-end items-center items-center mt-4"
    >
      <button
        *ngIf="modal?.cancelLabel"
        class="btn btn-secondary mr-5 capitalize"
        (click)="
          modal && modal.actionCancel ? modal.actionCancel() : close(modal.id)
        "
      >
        {{ modal.cancelLabel }}
      </button>
      <button
        *ngIf="modal?.actionLabel"
        class="btn btn-primary capitalize"
        (click)="modal && modal.action ? modal.action() : close(modal.id)"
      >
        {{ modal.actionLabel }}
      </button>
    </div>
  </div>
</div>
