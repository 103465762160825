/* eslint-disable @angular-eslint/component-selector */
import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'warning-big-icon',
  standalone: true,
  templateUrl: './warning-big-icon.component.html',
  styleUrls: ['./warning-big-icon.component.scss'],
  imports: [CommonModule],
})
export class WarningBigIconComponent {
  @Input() color: string = '#000000';
  @Input() width: number = 32;
  @Input() height: number = 32;
}
