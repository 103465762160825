import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Modal } from '../models/modal.models';

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  private modalsSubject = new BehaviorSubject<Modal[]>([]);
  public modals = this.modalsSubject.asObservable();

  constructor() {}

  add(modal: Modal) {
    const newModal = { ...modal, visible: true };
    const currentModals = this.modalsSubject.value;
    this.modalsSubject.next([...currentModals, newModal]);
  }

  remove(id: string) {
    const currentModals = this.modalsSubject.value.filter(
      (modal) => modal.id !== id
    );
    this.modalsSubject.next(currentModals);
  }

  clear(): void {
    this.modalsSubject.next([]);
  }
}
