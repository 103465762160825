<div
  *ngIf="showIf"
  class="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-10"
>
  <div
    class="relative top-20 mx-auto p-4 border w-[70%] max-h-[80%] overflow-y-auto max-w-screen-lg shadow-lg rounded bg-white flex flex-col justify-start items-start"
  >
    <h3 class="text-lg font-medium">Definir perguntas</h3>
    <p class="text-sm text-gray-700">
      Defina as perguntas que os usuários deverão responder para o cálculo de
      risco. <strong>Todas as respostas devem ser “Sim” ou “Não”.</strong>
    </p>

    <form
      *ngIf="isNewQuestion"
      class="w-full flex flex-col justify-start items-start p-4 rounded bg-gray-100 mt-4"
      [formGroup]="questionForm"
      (ngSubmit)="onSubmit()"
    >
      <div class="w-full flex flex-col justify-center items-center">
        <p class="w-full text-sm font-normal text-left">Pergunta*</p>
        <input
          formControlName="description"
          class="appearance-none rounded w-full py-2 px-3 text-gray-700 leading-tight outline-none h-9"
          type="text"
          placeholder="Exemplo de pergunta inserida?"
          required
        />
        <div
          class="w-full flex flex-row justify-start text-xs font-medium text-red-500 mt-1"
          *ngIf="
            (questionForm.controls['description'].touched ||
              questionForm.controls['description'].dirty) &&
            questionForm.controls['description'].errors &&
            questionForm.controls['description'].errors['required']
          "
        >
          Pergunta é obrigatório.
        </div>
      </div>

      <div class="w-full grid grid-cols-3 gap-4 mt-6">
        <div class="w-full flex flex-col justify-start items-center">
          <p class="w-full text-sm font-normal text-left">
            Se SIM, definir risco como*
          </p>
          <select
            formControlName="riskIfYes"
            class="w-full text-sm text-left font-medium text-gray-900 dark:text-white rounded"
          >
            <option [ngValue]="null" selected disabled>
              Selecione uma opção
            </option>
            <option [ngValue]="0">Requer Informações</option>
            <option [ngValue]="1">Risco I</option>
            <option [ngValue]="2">Risco II</option>
            <option [ngValue]="3">Risco III</option>
            <option [ngValue]="4">Competência Estadual</option>
          </select>
          <div
            class="w-full flex flex-row justify-start text-xs font-medium text-red-500 mt-1"
            *ngIf="
              (questionForm.controls['riskIfYes'].touched ||
                questionForm.controls['riskIfYes'].dirty) &&
              questionForm.controls['riskIfYes'].errors &&
              questionForm.controls['riskIfYes'].errors['required']
            "
          >
            Risco se SIM é obrigatório.
          </div>
        </div>
        <div class="w-full flex flex-col justify-start items-center">
          <p class="w-full text-sm font-normal text-left">
            Se NÃO, definir risco como*
          </p>
          <select
            formControlName="riskIfNo"
            class="w-full text-sm text-left font-medium text-gray-900 dark:text-white rounded"
          >
            <option [ngValue]="null" selected disabled>
              Selecione uma opção
            </option>
            <option [ngValue]="0">Requer Informações</option>
            <option [ngValue]="1">Risco I</option>
            <option [ngValue]="2">Risco II</option>
            <option [ngValue]="3">Risco III</option>
            <option [ngValue]="4">Competência Estadual</option>
          </select>
          <div
            class="w-full flex flex-row justify-start text-xs font-medium text-red-500 mt-1"
            *ngIf="
              (questionForm.controls['riskIfNo'].touched ||
                questionForm.controls['riskIfNo'].dirty) &&
              questionForm.controls['riskIfNo'].errors &&
              questionForm.controls['riskIfNo'].errors['required']
            "
          >
            Risco se SIM é obrigatório.
          </div>
        </div>
      </div>

      <div
        *ngIf="!isNewQuestion"
        class="w-full flex flex-row justify-end items-center mt-6"
      >
        <app-delete-icon
          [width]="20"
          [height]="20"
          [color]="'#E64D4D'"
        ></app-delete-icon>
        <p class="text-sm font-normal text-left ml-1 text-[#E64D4D] mr-6">
          Deletar
        </p>
        <app-edit-icon [width]="20" [height]="20"></app-edit-icon>
        <p class="text-sm font-normal text-left ml-1">Editar</p>
      </div>

      <div
        *ngIf="isNewQuestion"
        class="w-full flex flex-row justify-end items-center mt-6"
      >
        <button
          [ngClass]="
            loading
              ? 'btn btn-secondary text-sm opacity-40 cursor-not-allowed pointer-events-none'
              : 'btn btn-secondary text-sm'
          "
          (click)="handleCloseFunction(false)"
        >
          Cancelar
        </button>
        <button
          type="submit"
          [ngClass]="
            loading
              ? 'btn btn-primary text-sm ml-2 opacity-40 cursor-not-allowed pointer-events-none'
              : 'btn btn-primary text-sm ml-2'
          "
        >
          {{ loading ? "Salvando informações..." : "Salvar informações" }}
        </button>
      </div>
    </form>

    <ng-container *ngIf="!isNewQuestion">
      <form class="w-full">
        <div
          *ngFor="let d of data.questions; let index = index"
          class="w-full flex flex-col justify-start items-start p-4 rounded bg-gray-100 mt-4"
        >
          <div class="w-full flex flex-col justify-center items-center">
            <p class="w-full text-sm font-normal text-left">Pergunta*</p>
            <input
              [ngClass]="
                d.isEditing
                  ? 'appearance-none rounded w-full py-2 px-3 text-gray-700 leading-tight outline-none h-9'
                  : 'appearance-none rounded w-full py-2 px-3 text-gray-700 leading-tight outline-none h-9 opacity-40 cursor-not-allowed pointer-events-none'
              "
              type="text"
              placeholder="Exemplo de pergunta inserida?"
              [value]="d.description"
              (change)="handleEditQuestion(index, 'description', $event)"
            />
          </div>

          <div class="w-full grid grid-cols-3 gap-4 mt-6">
            <div class="w-full flex flex-col justify-start items-center">
              <p class="w-full text-sm font-normal text-left">
                Se SIM, definir risco como*
              </p>
              <select
                [ngClass]="
                  d.isEditing
                    ? 'w-full text-sm text-left font-medium text-gray-900 dark:text-white rounded'
                    : 'w-full text-sm text-left font-medium text-gray-900 dark:text-white rounded opacity-40 cursor-not-allowed pointer-events-none'
                "
                (change)="handleEditQuestion(index, 'riskIfYes', $event)"
              >
                <option value="null" selected disabled>
                  Selecione uma opção
                </option>
                <option value="0" [selected]="d.riskIfYes === 0">
                  Requer Informações
                </option>
                <option value="1" [selected]="d.riskIfYes === 1">
                  Risco I
                </option>
                <option value="2" [selected]="d.riskIfYes === 2">
                  Risco II
                </option>
                <option value="3" [selected]="d.riskIfYes === 3">
                  Risco III
                </option>
                <option value="4" [selected]="d.riskIfYes === 4">
                  Competência Estadual
                </option>
              </select>
            </div>
            <div class="w-full flex flex-col justify-start items-center">
              <p class="w-full text-sm font-normal text-left">
                Se NÃO, definir risco como*
              </p>
              <select
                [ngClass]="
                  d.isEditing
                    ? 'w-full text-sm text-left font-medium text-gray-900 dark:text-white rounded'
                    : 'w-full text-sm text-left font-medium text-gray-900 dark:text-white rounded opacity-40 cursor-not-allowed pointer-events-none'
                "
                (change)="handleEditQuestion(index, 'riskIfNo', $event)"
              >
                <option value="null" selected disabled>
                  Selecione uma opção
                </option>
                <option value="0" [selected]="d.riskIfNo === 0">
                  Requer Informações
                </option>
                <option value="1" [selected]="d.riskIfNo === 1">Risco I</option>
                <option value="2" [selected]="d.riskIfNo === 2">
                  Risco II
                </option>
                <option value="3" [selected]="d.riskIfNo === 3">
                  Risco III
                </option>
                <option value="4" [selected]="d.riskIfNo === 4">
                  Competência Estadual
                </option>
              </select>
            </div>
          </div>

          <div
            *ngIf="!isNewQuestion && !d.isEditing"
            class="w-full flex flex-row justify-end items-center mt-6"
          >
            <app-delete-icon
              [width]="20"
              [height]="20"
              [color]="'#E64D4D'"
            ></app-delete-icon>
            <p
              class="text-sm font-normal text-left ml-1 text-[#E64D4D] mr-6 hover:cursor-pointer"
            >
              Deletar
            </p>
            <app-edit-icon [width]="20" [height]="20"></app-edit-icon>
            <button
              class="text-sm font-normal text-left ml-1 hover:cursor-pointer"
              (click)="handleEditingQuestion(index)"
            >
              Editar
            </button>
          </div>

          <div
            *ngIf="!isNewQuestion && d.isEditing"
            class="w-full flex flex-row justify-end items-center mt-6"
          >
            <button
              class="text-sm font-normal text-left ml-1 text-[#E64D4D] mr-6 hover:cursor-pointer"
              (click)="handleEditingQuestion(index)"
            >
              Cancelar
            </button>
            <app-checked-icon [width]="20" [height]="20"></app-checked-icon>
            <button
              class="text-sm font-normal text-left ml-1 hover:cursor-pointer"
              (click)="handleSave(index)"
            >
              Salvar
            </button>
          </div>

          <div
            *ngIf="isNewQuestion"
            class="w-full flex flex-row justify-end items-center mt-6"
          >
            <button
              [ngClass]="
                loading
                  ? 'btn btn-secondary text-sm opacity-40 cursor-not-allowed pointer-events-none'
                  : 'btn btn-secondary text-sm'
              "
              (click)="handleCloseFunction(false)"
            >
              Cancelar
            </button>
            <button
              type="submit"
              [ngClass]="
                loading
                  ? 'btn btn-primary text-sm ml-2 opacity-40 cursor-not-allowed pointer-events-none'
                  : 'btn btn-primary text-sm ml-2'
              "
            >
              {{ loading ? "Salvando informações..." : "Salvar informações" }}
            </button>
          </div>
        </div>
      </form>
    </ng-container>

    <!-- <div
      class="w-full flex flex-col justify-start items-start p-4 rounded bg-gray-100 mt-4"
    >
      <div class="w-full flex flex-col justify-center items-center">
        <p class="w-full text-sm font-normal text-left">Pergunta*</p>
        <input
          class="appearance-none rounded w-full py-2 px-3 text-gray-700 leading-tight outline-none h-9"
          type="text"
          placeholder="Inserir pergunta"
        />
        <div class="w-full flex flex-row justify-end items-center mt-2">
          <p class="text-xs font-normal mr-2">“Exemplo de pergunta aqui I?”</p>
          <p class="text-xs font-normal mr-2">“Exemplo de pergunta aqui II?”</p>
          <p class="text-xs font-normal mr-2">
            “Exemplo de pergunta aqui III?”
          </p>
          <p class="text-xs font-medium ml-2">+ Ver mais</p>
        </div>
      </div>

      <div class="w-full grid grid-cols-3 gap-4 mt-6">
        <div class="w-full flex flex-col justify-start items-center">
          <p class="w-full text-sm font-normal text-left">
            Se SIM, definir risco como*
          </p>
          <select
            class="w-full text-sm text-left font-medium text-gray-900 dark:text-white rounded"
          >
            <option>Risco II</option>
          </select>
        </div>
        <div class="w-full flex flex-col justify-start items-center">
          <p class="w-full text-sm font-normal text-left">
            Se NÃO, definir risco como*
          </p>
          <select
            class="w-full text-sm text-left font-medium text-gray-900 dark:text-white rounded"
          >
            <option>Risco I</option>
          </select>
        </div>
      </div>

      <div class="w-full flex flex-row justify-end items-center mt-6">
        <p class="text-sm font-normal text-left ml-1 text-[#E64D4D] mr-6">
          Cancelar
        </p>
        <app-checked-icon [width]="20" [height]="20"></app-checked-icon>
        <p class="text-sm font-normal text-left ml-1">Salvar</p>
      </div>
    </div> -->

    <div
      *ngIf="!isNewQuestion"
      class="w-full flex flex-col justify-center items-center p-4 rounded bg-gray-100 mt-4"
    >
      <button class="btn btn-primary text-sm">Nova pergunta</button>
      <div class="w-1/5 flex flex-row justify-center items-center mt-4 mb-4">
        <div class="w-full h-[1px] bg-gray-400 mr-2"></div>
        <p class="text-sm font-normal text-gray-400">OU</p>
        <div class="w-full h-[1px] bg-gray-400 ml-2"></div>
      </div>
      <button
        class="text-sm font-medium hover:cursor-pointer"
        (click)="handleActionFunction()"
      >
        Selecionar do Banco de Perguntas
      </button>
    </div>

    <div
      *ngIf="!isNewQuestion"
      class="w-full flex flex-row justify-end items-center mt-6"
    >
      <button
        class="btn btn-secondary text-sm"
        (click)="handleCloseFunction(false)"
      >
        Cancelar
      </button>
      <button
        [ngClass]="
          loading
            ? 'btn btn-primary text-sm ml-2 opacity-40 cursor-not-allowed pointer-events-none'
            : 'btn btn-primary text-sm ml-2'
        "
      >
        {{ loading ? "Salvando informações..." : "Salvar informações" }}
      </button>
    </div>
  </div>
</div>
