<svg
  [attr.width]="width + 'px'"
  [attr.height]="height + 'px'"
  viewBox="0 0 25 24"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path d="M19.75 7H17.75V9H19.75V7Z" [attr.fill]="color" />
  <path d="M19.75 11H17.75V13H19.75V11Z" [attr.fill]="color" />
  <path d="M19.75 15H17.75V17H19.75V15Z" [attr.fill]="color" />
  <path
    d="M1.75 11V21H7.75V16H9.75V21H15.75V11L8.75 6L1.75 11ZM13.75 19H11.75V14H5.75V19H3.75V12L8.75 8.5L13.75 12V19Z"
    [attr.fill]="color"
  />
  <path
    d="M10.75 3V4.97L12.75 6.4V5H21.75V19H17.75V21H23.75V3H10.75Z"
    [attr.fill]="color"
  />
</svg>
