import { createReducer, on } from '@ngrx/store';
import { ActionRuleSuccess, clearRuleState } from '../actions/rules.actions';

export const initialState = {};

export const RuleReducer = createReducer(
  initialState,
  on(ActionRuleSuccess, (state, { response }) => ({
    rule: response,
  })),
  on(clearRuleState, (state) => ({
    ...state,
    rule: null,
  }))
);
