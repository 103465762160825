import { Component, inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FeasibilityResponse } from '../../models/feasibility.model';
import { debounceTime, Subject } from 'rxjs';
import { FeasibilityService } from '../../services/feasibility.service';
import { CommonModule } from '@angular/common';
import { MatIcon } from '@angular/material/icon';
import { FilterBoxComponent } from '../../components/filter-box/filter-box.component';
import { NgIcon, provideIcons } from '@ng-icons/core';
import {
  matArrowBackIos,
  matArrowForwardIos,
} from '@ng-icons/material-icons/baseline';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-analysis',
  standalone: true,
  imports: [CommonModule, MatIcon, FilterBoxComponent, NgIcon],
  providers: [
    provideIcons({
      matArrowBackIos,
      matArrowForwardIos,
    }),
  ],
  templateUrl: './analysis.component.html',
  styleUrl: './analysis.component.scss',
})
export class AnalysisComponent implements OnInit {
  data: FeasibilityResponse[] = [];
  loading: boolean = true;

  page: number = 1;
  limit: number = 10;
  keyword: string = '';
  defaultOrderBy: string = 'desc';
  currentOrderField: string = '';

  pages: number[] = [];

  keywordSubject: Subject<string> = new Subject<string>();

  private feasibilityService = inject(FeasibilityService);
  private authService = inject(AuthService);

  role!: string;
  userDocument!: string;

  constructor(private router: Router) {
    this.keywordSubject.pipe(debounceTime(1000)).subscribe(() => {
      this.loading = true;

      this.getFeasibilitys(
        this.page,
        this.limit,
        this.keyword,
        this.getStatusByRole(),
        this.getAnalysts(),
        this.defaultOrderBy,
        this.currentOrderField,
      );
    });
  }

  ngOnInit(): void {
    this.role = this.authService.getDecodedUser()?.role ?? 'user';
    this.userDocument = this.authService.getDecodedUser()?.cpf ?? '';

    if (this.role === 'user') {
      this.router.navigate(['/']);
    }

    this.getFeasibilitys(
      this.page,
      this.limit,
      this.keyword,
      this.getStatusByRole(),
      this.getAnalysts(),
      this.defaultOrderBy,
      this.currentOrderField,
    );
  }

  getStatusByRole(): string {
    if (this.role === 'server') return 'PENDING';
    if (this.role === 'analyst')
      return 'ANALYSIS|REVIEW|RESPONSE|ARCHIVED|RETURNED|REJECTED';
    if (this.role === 'coordinator-manager') return 'REVIEW|SECOND_REVIEW';
    if (this.role === 'mayor-secretary') return 'SECOND_REVIEW';
    return '';
  }

  getAnalysts(): string {
    if (this.role === 'analyst') return this.userDocument;
    return '';
  }

  getFeasibilitys(
    page: number,
    limit: number,
    keyword: string,
    status: string,
    analyst: string,
    orderBy?: string,
    orderField?: string,
  ): void {
    this.feasibilityService
      .getFeasibilitys(
        page,
        limit,
        keyword,
        status,
        analyst,
        orderBy,
        orderField,
      )
      .subscribe({
        next: (response) => {
          if (!response.result) {
            this.data = [];
            this.generatePagesByFounded(response.filter.founded);
            this.loading = false;
            return;
          }

          this.data = response.result;
          this.generatePagesByFounded(response.filter.founded);
          this.loading = false;
        },
        error: (err) => {
          console.error(err);
          this.loading = false;
        },
      });
  }

  handleChangeByResult(event: Event): void {
    const input = event.target as HTMLInputElement;
    this.keyword = input.value;
    this.loading = true;
    this.getFeasibilitys(
      this.page,
      this.limit,
      this.keyword,
      this.getStatusByRole(),
      this.getAnalysts(),
      this.defaultOrderBy,
      this.currentOrderField,
    );
  }

  handleChangeSearch(event: Event): void {
    const input = event.target as HTMLInputElement;
    this.keyword = input.value;
    this.keywordSubject.next(this.keyword);
  }

  handleOrderBy(orderField: string): void {
    this.loading = true;
    this.defaultOrderBy = this.defaultOrderBy === 'asc' ? 'desc' : 'asc';
    this.currentOrderField = orderField;
    this.getFeasibilitys(
      this.page,
      this.limit,
      this.keyword,
      this.getStatusByRole(),
      this.getAnalysts(),
      this.defaultOrderBy,
      orderField,
    );
  }

  getOrderIcon(field: string): string {
    if (this.currentOrderField && this.currentOrderField === field) {
      return this.defaultOrderBy === 'asc'
        ? 'keyboard_arrow_up'
        : 'keyboard_arrow_down';
    } else {
      return 'unfold_more';
    }
  }

  getRisk(r: number | undefined): string {
    if (r !== undefined && r !== null) {
      switch (r) {
        case 0:
          return 'Requer Informações';
        case 1:
          return 'Risco I';
        case 2:
          return 'Risco II';
        case 3:
          return 'Risco III';
        default:
          return 'Competência Estadual';
      }
    } else return '-';
  }

  getStatus(s: string) {
    if (this.role === 'analyst') {
      if (s === 'PEN_RESPONSE') return 'Aguardando resposta';
    }

    switch (s) {
      case 'ANALYSIS':
        return 'Em análise';
      case 'PEN_REVIEW':
        return 'Pendente de revisão';
      case 'REVIEW':
        return 'Enviado para Revisão';
      case 'SECOND_REVIEW':
        return 'Enviado para 2ª revisão';
      case 'PENDING':
        return 'Pendente';
      case 'PEN_ANALYSIS':
        return 'Pendente de análise';
      case 'PEN_RESPONSE':
        return 'Pendente de resposta';
      case 'PEN_REANALYSIS':
        return 'Pendente de reanálise';
      case 'REANALYSIS':
        return 'Em reanálise';
      case 'APPROVED':
        return 'Aprovado';
      case 'REJECTED':
        return 'Recusado';
      case 'ARCHIVED':
        return 'Arquivado';
      case 'RETURNED':
        return 'Devolvido';
      default:
        return 'Pendente';
    }
  }

  getStatusColor(
    s: string,
    isBackground: boolean = false,
    isBorder: boolean = false,
  ) {
    switch (s) {
      case 'ANALYSIS':
        return isBackground
          ? 'bg-orange-status'
          : isBorder
            ? 'border-orange-status'
            : 'text-orange-status';
      case 'PEN_REVIEW':
        return isBackground
          ? 'bg-green-status'
          : isBorder
            ? 'border-green-status'
            : 'text-green-status';
      case 'REVIEW':
        return isBackground
          ? 'bg-green-status'
          : isBorder
            ? 'border-green-status'
            : 'text-green-status';
      case 'SECOND_REVIEW':
        return isBackground
          ? 'bg-green-status'
          : isBorder
            ? 'border-green-status'
            : 'text-green-status';
      case 'PENDING':
        return isBackground
          ? 'bg-red-status'
          : isBorder
            ? 'border-red-status'
            : 'text-red-status';
      case 'PEN_ANALYSIS':
        return isBackground
          ? 'bg-red-status'
          : isBorder
            ? 'border-red-status'
            : 'text-red-status';
      case 'PEN_RESPONSE':
        return isBackground
          ? 'bg-red-status'
          : isBorder
            ? 'border-red-status'
            : 'text-red-status';
      case 'PEN_REANALYSIS':
        return isBackground
          ? 'bg-orange-status'
          : isBorder
            ? 'border-orange-status'
            : 'text-orange-status';
      case 'REANALYSIS':
        return isBackground
          ? 'bg-orange-status'
          : isBorder
            ? 'border-orange-status'
            : 'text-orange-status';
      case 'APPROVED':
        return isBackground
          ? 'bg-approved-status'
          : isBorder
            ? 'border-approved-status'
            : 'text-approved-status';
      case 'REJECTED':
        return isBackground
          ? 'bg-rejected-status'
          : isBorder
            ? 'border-rejected-status'
            : 'text-rejected-status';
      case 'ARCHIVED':
        return isBackground
          ? 'bg-default-status'
          : isBorder
            ? 'border-default-status'
            : 'text-default-status';
      case 'RETURNED':
        return isBackground
          ? 'bg-red-status'
          : isBorder
            ? 'border-red-status'
            : 'text-red-status';
      default:
        return isBackground
          ? 'bg-blue-status'
          : isBorder
            ? 'border-blue-status'
            : 'text-blue-status';
    }
  }

  generatePagesByFounded(total: number): void {
    const pages = Math.ceil(total / this.limit);
    this.pages = Array.from({ length: pages }, (_, i) => i + 1);
  }

  handleLimitChange(event: Event, key: string): void {
    const input = event.target as HTMLInputElement;

    if (key === 'limit') this.limit = Number(input.value);

    this.getFeasibilitys(
      this.page,
      this.limit,
      this.keyword,
      this.getStatusByRole(),
      this.getAnalysts(),
      this.defaultOrderBy,
      this.currentOrderField,
    );
  }

  handleSetPage(page: number): void {
    this.page = page;

    this.getFeasibilitys(
      this.page,
      this.limit,
      this.keyword,
      this.getStatusByRole(),
      this.getAnalysts(),
      this.defaultOrderBy,
      this.currentOrderField,
    );
  }

  handlePageChange(key: string): void {
    if (key === 'next') {
      this.page = this.page + 1;
    } else if (this.page > 1) {
      this.page = this.page - 1;
    }

    this.getFeasibilitys(
      this.page,
      this.limit,
      this.keyword,
      this.getStatusByRole(),
      this.getAnalysts(),
      this.defaultOrderBy,
      this.currentOrderField,
    );
  }

  toReFeasibility(id?: string): void {
    this.router.navigate([`businesses/re-feasibility/${id}`]);
  }

  toDateString(date: string): string {
    return new Date(date).toLocaleDateString();
  }
}
