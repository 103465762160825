<div class="m-3">
  <div class="card">
    <h1 class="text-3xl font-bold">Serviços</h1>

    <div class="flex flex-row">
      <div class="card w-1/2">
        <p class="font-bold text-lg">Viabilidade</p>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi faucibus malesuada facilisis. Phasellus faucibus mi nec nisl luctus, eget elementum turpis bibendum. </p>
        <button class="btn btn-primary mt-3">Efetuar Viabilidade</button>
      </div>
      <div class="card w-1/2">
        <p class="font-bold text-lg">Cadastro Técnico</p>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi faucibus malesuada facilisis. Phasellus faucibus mi nec nisl luctus, eget elementum turpis bibendum. </p>
        <button class="btn btn-primary mt-3">Cadastre</button>
      </div>
    </div>
  </div>
</div>
