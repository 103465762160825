<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->

<div class="w-full relative p-2 flex flex-col justify-center items-center mt-4">
  <div class="w-full grid grid-cols-6">
    <div
      *ngFor="let s of steps; let index = index"
      class="w-full flex flex-row justify-start items-center"
    >
      <div
        [ngClass]="
          index === 0
            ? 'w-full h-1 bg-transparent mr-2'
            : index === steps.length - 1
              ? s.step === step
                ? 'w-full h-1 bg-[#408E2B] mr-2 rounded-br-full rounded-tr-full'
                : 'w-full h-1 bg-[#83A0F6] mr-2 rounded-br-full rounded-tr-full'
              : s.step === step || step > s.step
                ? 'w-full h-1 bg-[#408E2B] mr-2'
                : 'w-full h-1 bg-[#83A0F6] mr-2'
        "
      ></div>
      <div
        [ngClass]="
          s.step === step
            ? index === steps.length - 1 && step === s.step
              ? 'relative w-6 h-6 rounded-full border border-[#408E2B] p-4 bg-[#408E2B] hover:cursor-pointer'
              : 'relative w-6 h-6 rounded-full border border-[#4959E2] p-4 bg-[#4959E2] hover:cursor-pointer'
            : step > s.step
              ? 'relative w-6 h-6 rounded-full border border-[#408E2B] p-4 bg-[#408E2B] hover:cursor-pointer'
              : 'relative w-6 h-6 rounded-full border border-[#b5b5b5] p-4 bg-white hover:cursor-pointer'
        "
      >
        <p
          [ngClass]="
            s.step === step || (index === steps.length - 1 && step === s.step)
              ? 'absolute text-xs top-[50%] left-[50%] translate-y-[-50%] translate-x-[-50%] text-white font-medium'
              : 'absolute text-xs top-[50%] left-[50%] translate-y-[-50%] translate-x-[-50%]'
          "
        >
          {{
            index === steps.length - 1 && step === s.step
              ? ""
              : step <= s.step
                ? s.step
                : ""
          }}
          <app-checked-icon
            *ngIf="
              step > s.step || (index === steps.length - 1 && step === s.step)
            "
            [color]="'#ffffff'"
            [width]="24"
            [height]="24"
          ></app-checked-icon>
        </p>
      </div>
      <div
        [ngClass]="
          index === 0
            ? step > 1
              ? 'w-full h-1 bg-[#408E2B] ml-2 rounded-bl-full rounded-tl-full'
              : 'w-full h-1 bg-[#4959E2] ml-2 rounded-bl-full rounded-tl-full'
            : index === steps.length - 1
              ? 'w-full h-1 bg-transparent ml-2'
              : step > s.step
                ? 'w-full h-1 bg-[#408E2B] ml-2'
                : 'w-full h-1 bg-[#83A0F6] ml-2'
        "
      ></div>
    </div>
  </div>

  <div class="hidden md:flex w-full flex-row justify-between items-center mt-2">
    <div
      *ngFor="let s of steps; let index = index"
      class="w-full flex flex-col justify-center items-center"
    >
      <p
        [ngClass]="
          s.step === step
            ? 'text-sm text-center font-medium'
            : 'text-sm text-center'
        "
      >
        {{ s.description }}
      </p>
    </div>
  </div>
</div>
