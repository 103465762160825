import { createReducer, on } from '@ngrx/store';
import { ActionLoginSuccess, ActionLogoutUser } from '../actions/user.actions';

export const UserReducer = createReducer(
  {},
  on(ActionLoginSuccess, (state, { response }) => ({
    ...state,
    fullname: response.fullname,
    email: response.email,
    role: response.role,
    cpf: response.cpf,
  })),
  on(ActionLogoutUser, () => ({}))
);
