import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { TableComponent } from '../../components/table/table.component';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-new-cnae',
  standalone: true,
  imports: [
    FormsModule,
    CommonModule,
    TableComponent
  ],
  templateUrl: './new-cnae.component.html',
  styleUrl: './new-cnae.component.scss'
})
export class NewCnaeComponent {
  risk = '1';

  constructor(private router: Router) {}

  questions = [
    {
      question: 'Lorem isplum?',
      yes: '1',
      no: '2'
    }
  ];
  columns = [
    { label: 'Pergunta', value: 'question' },
    { label: 'Se Sim?', value: 'yes' },
    { label: 'Se Não?', value: 'no' }
  ];

  rules = [
    {
      zone: 'Zona 1',
      limit: 'Limite máx de 2000 m² de área útil',
      risk: '1'
    }
  ];
  columnsRules = [
    { label: 'Zona', value: 'zone' },
    { label: 'Limite', value: 'limit' },
    { label: 'Risco', value: 'risk' }
  ];

  public goBack = () => {
    this.router.navigate(['/cnaes']);
  }

  public save = () => {
    this.router.navigate(['/cnaes']);
  }

  public newRule = () => {
    this.router.navigate(['/rule/create']);
  }
}
