import { CommonModule } from '@angular/common';
import {
  Component,
  ElementRef,
  inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FeasibilityResponse } from '../../models/feasibility.model';
import { MatIcon } from '@angular/material/icon';
import { FilterBoxComponent } from '../../components/filter-box/filter-box.component';
import { NgIcon } from '@ng-icons/core';
import { FeasibilityService } from '../../services/feasibility.service';
import { AuthService } from '../../services/auth.service';
import { ModalService } from '../../services/modal.service';
import { MatDividerModule } from '@angular/material/divider';
import { UserResponse } from '../../models/token.model';
import { UserService } from '../../services/user.service';
import { StatusHistoryComponent } from './status_history/status_history.component';
import {
  BreadcrumbsComponent,
  IRoute,
} from '../../components/breadcrumbs/breadcrumbs.component';
import { CpfMask } from '../../../utils/mask/CpfMask';
import { RefuseComponent } from './refuse/refuse.component';
import { NotificationComponent } from './notification/notification.component';
import { ApproveComponent } from './mayor-secretary/approve/approve.component';
import { ReturnedComponent } from './mayor-secretary/returned/returned.component';
import { SecondReviewComponent } from './coordinator-manager/second-review/second-review.component';

@Component({
  selector: 'app-re-feasibility',
  standalone: true,
  imports: [
    CommonModule,
    MatDividerModule,
    MatIcon,
    FilterBoxComponent,
    NgIcon,
    StatusHistoryComponent,
    BreadcrumbsComponent,
    RefuseComponent,
    NotificationComponent,
    ApproveComponent,
    ReturnedComponent,
    SecondReviewComponent,
  ],
  templateUrl: './re-feasibility.component.html',
  styleUrl: './re-feasibility.component.scss',
})
export class ReFeasbilityComponent implements OnInit {
  @ViewChild('upload') upload!: ElementRef<HTMLInputElement>;
  @ViewChild('upload_refuse') upload_refuse!: ElementRef<HTMLInputElement>;
  @ViewChild('upload_notification')
  upload_notification!: ElementRef<HTMLInputElement>;
  @ViewChild('upload_response')
  upload_response!: ElementRef<HTMLInputElement>;

  data: FeasibilityResponse = {} as FeasibilityResponse;
  analystList: UserResponse[] = [];
  loading: boolean = true;
  loadingDownload: boolean = false;
  loadingDownloadFile: boolean = false;

  userDocument!: string | undefined;
  userName!: string | undefined;
  documentId!: string | undefined;
  feasibilityId!: string | undefined;

  page: number = 1;
  limit: number = 10;
  keyword: string = '';
  defaultOrderBy: string = 'desc';
  currentOrderField: string = '';

  pages: number[] = [];

  private feasibilityService = inject(FeasibilityService);
  private authService = inject(AuthService);
  private userService = inject(UserService);

  file!: File;
  fileRefuse!: File;
  fileNotification!: File;
  fileResponse!: File;
  role!: string;
  analyst!: string;
  analyst_response!: string;
  final_analyst_response!: string;
  final_secretary_response!: string;
  to_review!: string;
  deadline!: number;

  showAnalystResponse: boolean = false;
  showCoordinatorResponse: boolean = false;
  showSecretaryResponse: boolean = false;
  showJustification: boolean = false;

  coordinatorJustification!: string;
  secretaryJustification!: string;

  routes: IRoute[] = [
    {
      label: 'Análises',
      link: {
        route: '/analysis',
      },
    },
    {
      label:
        this.role === 'server' ? 'Encaminhar Pedido' : 'Detalhes da Reanálise',
      link: {
        route: `/businesses/re-feasibility/${this.documentId}`,
      },
    },
  ];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private modalService: ModalService,
  ) {}

  ngOnInit(): void {
    this.role = this.authService.getDecodedUser()?.role ?? 'user';

    this.userDocument = this.authService.getDecodedUser()?.cpf;
    this.userName = this.authService.getDecodedUser()?.fullname;

    this.route.params.subscribe((params) => {
      const id = params['id'];
      if (id) this.documentId = id;
    });

    this.getFeasibility();
    if (this.role && this.role === 'server') this.getAnalysts();
  }

  toHistory(): FeasibilityResponse {
    return {
      ...this.data,
      historyStatus:
        this.data.historyStatus?.sort((a, b) => {
          const dateDifference =
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();

          if (dateDifference !== 0) {
            return dateDifference;
          }

          return a.status === 'DEFAULT' ? 1 : -1;
        }) || [],
    };
  }

  handleChooseToReview(ev: Event): void {
    const input = ev.target as HTMLInputElement;
    this.to_review = input.value;
  }

  useMask(cpf: string): string {
    return CpfMask(cpf);
  }

  getFeasibility(): void {
    if (this.userDocument && this.documentId && this.role === 'user') {
      this.feasibilityService
        .getFeasibilityByIdAndUserDocument(this.documentId, this.userDocument)
        .subscribe({
          next: (response) => {
            if (!response) {
              this.data = {} as FeasibilityResponse;
              this.loading = false;
              return;
            }

            this.feasibilityId = response.feasibilityId;
            this.data = response;
            this.loading = false;
          },
          error: (err) => {
            console.error(err);
            this.loading = false;
          },
        });
    } else if (
      this.documentId &&
      this.userDocument &&
      this.role === 'analyst'
    ) {
      this.feasibilityService
        .getFeasibilityByIdAndUserDocument(this.documentId)
        .subscribe({
          next: (response) => {
            if (!response) {
              this.data = {} as FeasibilityResponse;
              this.loading = false;
              return;
            }

            this.feasibilityId = response.feasibilityId;
            this.data = response;

            if (
              this.role &&
              this.role === 'analyst' &&
              !response.openedAt &&
              this.data.status === 'PEN_REANALYSIS'
            ) {
              const date = new Date();
              const timeZoneOffset = -3 * 60 * 60000;

              const localDate = new Date(date.getTime() + timeZoneOffset);

              const formattedDate = localDate
                .toISOString()
                .replace('Z', '-03:00');

              const dto: FeasibilityResponse = {
                ...response,
                status: 'REANALYSIS',
                openedAt: formattedDate,
                updatedAt: formattedDate,
              };

              this.feasibilityService.updateOpenedPdf(dto).subscribe({
                next: () => {
                  this.data.status = 'REANALYSIS';
                  this.data.updatedAt = formattedDate;
                  this.data.historyStatus?.push({
                    status: 'PEN_REANALYSIS',
                    currentEditor: this.userDocument || '',
                    currentEditorName: this.userName || '',
                    role: 'analyst',
                    createdAt: formattedDate,
                  });
                },
                error: (err) => {
                  console.error(err);
                },
              });
            } else if (
              this.role &&
              this.role === 'analyst' &&
              !response.openedAt &&
              this.data.status === 'PEN_ANALYSIS'
            ) {
              const date = new Date();
              const timeZoneOffset = -3 * 60 * 60000;

              const localDate = new Date(date.getTime() + timeZoneOffset);

              const formattedDate = localDate
                .toISOString()
                .replace('Z', '-03:00');

              const dto: FeasibilityResponse = {
                ...response,
                status: 'ANALYSIS',
                openedAt: formattedDate,
                updatedAt: formattedDate,
              };

              this.feasibilityService.updateOpenedPdf(dto).subscribe({
                next: () => {
                  this.data.status = 'ANALYSIS';
                  this.data.updatedAt = formattedDate;
                  this.data.historyStatus?.push({
                    status: 'PEN_ANALYSIS',
                    currentEditor: this.userDocument || '',
                    currentEditorName: this.userName || '',
                    role: 'analyst',
                    createdAt: formattedDate,
                  });
                },
                error: (err) => {
                  console.error(err);
                },
              });
            }

            this.loading = false;
          },
          error: (err) => {
            console.error(err);
            this.loading = false;
          },
        });
    } else if (
      this.documentId &&
      this.userDocument &&
      this.role === 'coordinator-manager'
    ) {
      this.feasibilityService
        .getFeasibilityByIdAndUserDocument(this.documentId)
        .subscribe({
          next: (response) => {
            if (!response) {
              this.data = {} as FeasibilityResponse;
              this.loading = false;
              return;
            }

            this.feasibilityId = response.feasibilityId;
            this.data = response;

            if (
              this.role &&
              this.role === 'coordinator-manager' &&
              !response.openedAt
            ) {
              const date = new Date();
              const timeZoneOffset = -3 * 60 * 60000;

              const localDate = new Date(date.getTime() + timeZoneOffset);

              const formattedDate = localDate
                .toISOString()
                .replace('Z', '-03:00');

              const dto: FeasibilityResponse = {
                ...response,
                status: 'REVIEW',
                openedAt: formattedDate,
                updatedAt: formattedDate,
              };

              this.feasibilityService.updateOpenedPdf(dto).subscribe({
                next: () => {
                  this.data.status = 'REVIEW';
                  this.data.updatedAt = formattedDate;
                  this.data.historyStatus?.push({
                    status: 'PEN_REVIEW',
                    currentEditor: this.userDocument || '',
                    currentEditorName: this.userName || '',
                    role: 'coordinator-manager',
                    createdAt: formattedDate,
                  });
                },
                error: (err) => {
                  console.error(err);
                },
              });
            }

            this.loading = false;
          },
          error: (err) => {
            console.error(err);
            this.loading = false;
          },
        });
    } else if (this.documentId) {
      {
        this.feasibilityService
          .getFeasibilityByIdAndUserDocument(this.documentId)
          .subscribe({
            next: (response) => {
              if (!response) {
                this.data = {} as FeasibilityResponse;
                this.loading = false;
                return;
              }

              this.feasibilityId = response.feasibilityId;
              this.data = response;
              this.loading = false;
            },
            error: (err) => {
              console.error(err);
              this.loading = false;
            },
          });
      }
    }
  }

  getAnalysts(): void {
    this.loading = true;
    this.userService
      .getUsers(
        this.page,
        this.limit,
        'analyst',
        this.defaultOrderBy,
        this.currentOrderField,
      )
      .subscribe({
        next: (response) => {
          this.analystList = response.result;
          this.loading = false;
        },
        error: (err) => {
          console.error(err);
          this.loading = false;
        },
      });
  }

  getRisk(r: number | undefined): string {
    if (r !== undefined && r !== null) {
      switch (r) {
        case 0:
          return 'Requer Informações';
        case 1:
          return 'Risco I';
        case 2:
          return 'Risco II';
        case 3:
          return 'Risco III';
        default:
          return 'Competência Estadual';
      }
    } else return '-';
  }

  handleSelectAnalyst(ev: Event): void {
    const input = ev.target as HTMLInputElement;
    this.analyst = input.value;
  }

  handleUpload(): void {
    this.upload.nativeElement.click();
  }

  onFileSelected(): void {
    const files = this.upload.nativeElement.files;
    if (files && files.length > 0) {
      this.file = files[0];
    }
  }

  handleUploadNotification(): void {
    this.upload_notification.nativeElement.click();
  }

  handleUploadResponse(): void {
    this.upload_response.nativeElement.click();
  }

  onFileSelectedRefuse(file: File): void {
    this.fileRefuse = file;
  }

  onFileSelectedNotification(file: File): void {
    this.fileNotification = file;
  }

  onFileSelectedResponse(): void {
    const files = this.upload_response.nativeElement.files;
    if (files && files.length > 0) {
      this.fileResponse = files[0];
    }
  }

  handleDeadline(n: number): void {
    this.deadline = n;
  }

  handleShowAnalystResponse(): void {
    if (this.role === 'analyst')
      this.showAnalystResponse = !this.showAnalystResponse;
    else this.showCoordinatorResponse = !this.showCoordinatorResponse;

    this.final_analyst_response = '';
  }

  handleShowSecretaryResponse(): void {
    this.showSecretaryResponse = !this.showSecretaryResponse;
    this.final_secretary_response = '';
  }

  handleShowJustification(): void {
    this.showJustification = !this.showJustification;
  }

  onSelectAnalystResponse(ev: Event): void {
    const input = ev.target as HTMLInputElement;
    this.final_analyst_response = input.value;
  }

  onSelectSecretaryResponse(ev: Event): void {
    const input = ev.target as HTMLInputElement;
    this.final_secretary_response = input.value;
  }

  onSecretaryApprove(event: string): void {
    this.secretaryJustification = event;
  }

  onCoordinatorJustification(event: string): void {
    this.coordinatorJustification = event;
  }

  getFileSize(size: number): string {
    if (size < 1024) return `${size} B`;
    else if (size < 1024 * 1024) return `${(size / 1024).toFixed(2)} KB`;
    else return `${(size / 1024 / 1024).toFixed(2)} MB`;
  }

  goBack(): void {
    this.role === 'user'
      ? this.router.navigate(['/businesses'])
      : this.router.navigate(['/analysis']);
  }

  downloadPdf() {
    if (this.feasibilityId) {
      this.loadingDownload = true;
      this.feasibilityService
        .downloadFeasibilityPdf(this.feasibilityId, 'is_original')
        .subscribe({
          next: (response) => {
            const blob = new Blob([response], { type: 'application/pdf' });
            const url = window.URL.createObjectURL(blob);
            window.open(url);
            this.loadingDownload = false;
          },
          error: (error) => {
            console.log(error);
          },
          complete: () => {
            this.loadingDownload = false;
          },
        });
    }
  }

  downloadJustification(status?: string) {
    if (this.data.refeasibilityProtocol && this.data._id) {
      this.loadingDownloadFile = true;

      if (
        (this.role === 'user' && status && status === 'APPROVED') ||
        (this.role === 'user' && status && status === 'REJECTED') ||
        (this.role === 'user' && status && status === 'ARCHIVED')
      ) {
        if (this.feasibilityId) {
          this.feasibilityService
            .downloadFeasibilityPdf(this.feasibilityId, 'is_clone')
            .subscribe({
              next: (response) => {
                const blob = new Blob([response], { type: 'application/pdf' });
                const url = window.URL.createObjectURL(blob);
                window.open(url);
                this.loadingDownloadFile = false;
              },
              error: (error) => {
                console.log(error);
              },
              complete: () => {
                this.loadingDownloadFile = false;
              },
            });
        }
      } else
        this.feasibilityService
          .downloadRefeasbilityJustificationPdf(
            this.data.refeasibilityProtocol,
            this.data._id,
            (this.role === 'user' && status && status === 'PEN_RESPONSE') ||
              (this.role === 'analyst' && status && status === 'PEN_RESPONSE')
              ? 'notification'
              : this.role === 'user' && status && status === 'REJECTED'
                ? 'rejected'
                : this.role === 'analyst' && status && status === 'REANALYSIS'
                  ? 'response'
                  : 'justification',
          )
          .subscribe({
            next: (response) => {
              const blob = new Blob([response], { type: 'application/pdf' });
              const url = window.URL.createObjectURL(blob);
              window.open(url);
              this.loadingDownloadFile = false;
            },
            error: (error) => {
              console.log(error);
              this.loadingDownloadFile = false;
            },
          });
    }
  }

  getDateString(date: string | undefined): string {
    if (date) return new Date(date).toLocaleString();
    else return '-';
  }

  getResult(r: string): string {
    switch (r) {
      case 'aprovado':
        return 'Aprovado';
      case 'recusado':
        return 'Recusado';
      case 'requer análise':
        return 'Requer Análise';
      default:
        return 'Projeto Especial';
    }
  }

  getStatus(s: string) {
    if (this.role === 'user') {
      switch (s) {
        case 'ANALYSIS':
          return 'Em análise';
        case 'PENDING':
          return 'Em análise';
        case 'PEN_RESPONSE':
          return 'Pendente de resposta';
        case 'APPROVED':
          return 'Aprovado';
        case 'REJECTED':
          return 'Recusado';
        case 'ARCHIVED':
          return 'Arquivado';
        case 'RETURNED':
          return 'Devolvido';
        default:
          return 'Em análise';
      }
    }

    if (this.role === 'analyst') {
      if (s === 'PEN_RESPONSE') return 'Aguardando resposta';
    }

    if (this.role === 'coordinator-manager') {
      if (s === 'REVIEW') return 'Em revisão';
    }

    switch (s) {
      case 'ANALYSIS':
        return 'Em análise';
      case 'PEN_REVIEW':
        return 'Pendente de revisão';
      case 'REVIEW':
        return 'Em 1ª revisão';
      case 'SECOND_REVIEW':
        return 'Em 2ª revisão';
      case 'PENDING':
        return 'Pendente';
      case 'PEN_ANALYSIS':
        return 'Pendente de análise';
      case 'PEN_RESPONSE':
        return 'Pendente de resposta';
      case 'PEN_REANALYSIS':
        return 'Pendente de reanálise';
      case 'REANALYSIS':
        return 'Em reanálise';
      case 'APPROVED':
        return 'Aprovado';
      case 'REJECTED':
        return 'Recusado';
      case 'ARCHIVED':
        return 'Arquivado';
      case 'RETURNED':
        return 'Devolvido';
      default:
        return 'Justificativa recebida';
    }
  }

  getStatusColor(
    s: string,
    isBackground: boolean = false,
    isBorder: boolean = false,
  ) {
    if (this.role === 'user') {
      if (s === 'PENDING')
        return isBackground
          ? 'bg-orange-status'
          : isBorder
            ? 'border-orange-status'
            : 'text-orange-status';
    }

    switch (s) {
      case 'ANALYSIS':
        return isBackground
          ? 'bg-orange-status'
          : isBorder
            ? 'border-orange-status'
            : 'text-orange-status';
      case 'PEN_REVIEW':
        return isBackground
          ? 'bg-green-status'
          : isBorder
            ? 'border-green-status'
            : 'text-green-status';
      case 'REVIEW':
        return isBackground
          ? 'bg-green-status'
          : isBorder
            ? 'border-green-status'
            : 'text-green-status';
      case 'SECOND_REVIEW':
        return isBackground
          ? 'bg-green-status'
          : isBorder
            ? 'border-green-status'
            : 'text-green-status';
      case 'PENDING':
        return isBackground
          ? 'bg-red-status'
          : isBorder
            ? 'border-red-status'
            : 'text-red-status';
      case 'PEN_ANALYSIS':
        return isBackground
          ? 'bg-red-status'
          : isBorder
            ? 'border-red-status'
            : 'text-red-status';
      case 'PEN_RESPONSE':
        return isBackground
          ? 'bg-red-status'
          : isBorder
            ? 'border-red-status'
            : 'text-red-status';
      case 'PEN_REANALYSIS':
        return isBackground
          ? 'bg-orange-status'
          : isBorder
            ? 'border-orange-status'
            : 'text-orange-status';
      case 'REANALYSIS':
        return isBackground
          ? 'bg-orange-status'
          : isBorder
            ? 'border-orange-status'
            : 'text-orange-status';
      case 'APPROVED':
        return isBackground
          ? 'bg-approved-status'
          : isBorder
            ? 'border-approved-status'
            : 'text-approved-status';
      case 'REJECTED':
        return isBackground
          ? 'bg-rejected-status'
          : isBorder
            ? 'border-rejected-status'
            : 'text-rejected-status';
      case 'ARCHIVED':
        return isBackground
          ? 'bg-default-status'
          : isBorder
            ? 'border-default-status'
            : 'text-default-status';
      case 'RETURNED':
        return isBackground
          ? 'bg-red-status'
          : isBorder
            ? 'border-red-status'
            : 'text-red-status';
      default:
        return isBackground
          ? 'bg-blue-status'
          : isBorder
            ? 'border-blue-status'
            : 'text-blue-status';
    }
  }

  getStatusIcon(s: string) {
    if (this.role === 'user' && s === 'PENDING') {
      return 'chat_bubble';
    }

    switch (s) {
      case 'ANALYSIS':
        return 'hourglass_empty';
      case 'PEN_REVIEW':
        return 'hourglass_full';
      case 'REVIEW':
        return 'hourglass_full';
      case 'SECOND_REVIEW':
        return 'hourglass_full';
      case 'PENDING':
        return 'hourglass_empty';
      case 'PEN_ANALYSIS':
        return 'hourglass_empty';
      case 'PEN_RESPONSE':
        return 'feedback';
      case 'PEN_REANALYSIS':
        return 'hourglass_empty';
      case 'REANALYSIS':
        return 'hourglass_empty';
      case 'APPROVED':
        return 'check';
      case 'REJECTED':
        return 'close';
      case 'ARCHIVED':
        return 'archive';
      case 'RETURNED':
        return 'reply';
      default:
        return 'chat_bubble';
    }
  }

  sendRefeasbility(): void {
    this.loading = true;
    // remove _id
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { _id, ...dto } = this.data;

    if (this.role === 'server') {
      this.feasibilityService
        .updateFeasibilityStatus({
          ...dto,
          status: 'PEN_ANALYSIS',
          historyStatus: dto.historyStatus || [],
          analyst: this.analyst,
        })
        .subscribe({
          next: () => {
            this.modalService.add({
              id: 'refeasibility-success',
              icon: 'success',
              title: 'Tudo certo!',
              description: 'Encaminhado para análise com sucesso.',
              actionLabel: 'Fechar',
              action: () => {
                this.router.navigate(['/analysis']);
                this.modalService.remove('refeasibility-success');
              },
            });
            this.loading = false;
          },
          error: (err) => {
            console.error(err);
            this.modalService.add({
              id: 'refeasibility-error',
              icon: 'warning',
              title: 'Ops! Algo errado.',
              description:
                'Ocorreu um erro ao encaminhar para análise. Tente novamente.',
              actionLabel: 'Continuar',
            });
            this.loading = false;
          },
        });
    } else if (this.role === 'user') {
      if (this.file && this.feasibilityId) {
        const formData = new FormData();
        formData.append('file', this.file);

        formData.append('dto', JSON.stringify({ ...dto, status: 'PENDING' }));

        this.feasibilityService.sendRefeasbility(formData).subscribe({
          next: () => {
            this.modalService.add({
              id: 'refeasibility-success',
              icon: 'success',
              title: 'Tudo certo!',
              description:
                'Sua justificativa foi enviada com sucesso. Aguarde a análise.',
              actionLabel: 'Entendi',
              action: () => {
                this.router.navigate(['/businesses']);
                this.modalService.remove('refeasibility-success');
              },
            });
            this.loading = false;
          },
          error: (err) => {
            console.error(err);
            this.modalService.add({
              id: 'refeasibility-error',
              icon: 'warning',
              title: 'Ops! Algo errado.',
              description:
                'Ocorreu um erro ao enviar sua justificativa. Tente novamente.',
              actionLabel: 'Continuar',
            });
            this.loading = false;
          },
        });
      }

      if (this.fileResponse && this.feasibilityId) {
        const formData = new FormData();
        formData.append('file', this.fileResponse);

        formData.append(
          'dto',
          JSON.stringify({ ...dto, status: 'PEN_REANALYSIS', openedAt: '' }),
        );

        this.feasibilityService
          .sendUserResponseRefeasbility(formData, 'response')
          .subscribe({
            next: () => {
              this.modalService.add({
                id: 'refeasibility-success',
                icon: 'success',
                title: 'Tudo certo!',
                description:
                  'Sua resposta foi enviada com sucesso. Aguarde a análise.',
                actionLabel: 'Entendi',
                action: () => {
                  this.router.navigate(['/businesses']);
                  this.modalService.remove('refeasibility-success');
                },
              });
              this.loading = false;
            },
            error: (err) => {
              console.error(err);
              this.modalService.add({
                id: 'refeasibility-error',
                icon: 'warning',
                title: 'Ops! Algo errado.',
                description:
                  'Ocorreu um erro ao enviar sua resposta. Tente novamente.',
                actionLabel: 'Continuar',
              });
              this.loading = false;
            },
          });
      }
    }
  }

  isNotValidToNotify(): boolean {
    return (
      this.data &&
      this.data.historyStatus?.filter((f) => f.status === 'PEN_RESPONSE')
        .length === 3
    );
  }

  onChangeJustification(ev: Event): void {
    const input = ev.target as HTMLInputElement;
    this.data.coordinatorJustification = input.value;
  }

  onSendSecretaryResponse(): void {
    this.loading = true;
    // remove _id
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { _id, ...dto } = this.data;

    if (this.final_secretary_response === 'approve' && this.feasibilityId) {
      this.feasibilityService
        .updateFeasibilityStatus({
          ...dto,
          status: 'APPROVED',
          secretariatJustification: this.secretaryJustification,
          deadline: 0,
        })
        .subscribe({
          next: () => {
            this.modalService.add({
              id: 'refeasibility-success',
              icon: 'success',
              title: 'Tudo certo!',
              description:
                'A reanálise foi aprovada com sucesso. O solicitante será notificado.',
              actionLabel: 'Entendi',
              action: () => {
                this.router.navigate(['/analysis']);
                this.modalService.remove('refeasibility-success');
              },
            });
            this.loading = false;
          },
          error: (err) => {
            console.error(err);
            this.modalService.add({
              id: 'refeasibility-error',
              icon: 'warning',
              title: 'Ops! Algo errado.',
              description:
                'Ocorreu um erro ao aprovar a reanálise. Tente novamente.',
              actionLabel: 'Continuar',
            });
            this.loading = false;
          },
        });
    } else if (
      this.final_secretary_response === 'returned' &&
      this.secretaryJustification &&
      this.feasibilityId
    ) {
      this.feasibilityService
        .updateFeasibilityStatus({
          ...dto,
          status: 'RETURNED',
          secretariatJustification: this.secretaryJustification,
          deadline: 0,
        })
        .subscribe({
          next: () => {
            this.modalService.add({
              id: 'refeasibility-success',
              icon: 'success',
              title: 'Tudo certo!',
              description:
                'A reanálise foi devolvida para o analista. Aguarde a resposta.',
              actionLabel: 'Entendi',
              action: () => {
                this.router.navigate(['/analysis']);
                this.modalService.remove('refeasibility-success');
              },
            });
            this.loading = false;
          },
          error: (err) => {
            console.error(err);
            this.modalService.add({
              id: 'refeasibility-error',
              icon: 'warning',
              title: 'Ops! Algo errado.',
              description:
                'Ocorreu um erro ao devolver a reanálise. Tente novamente.',
              actionLabel: 'Continuar',
            });
            this.loading = false;
          },
        });
    }
  }

  onSendAnalystResponse(): void {
    this.loading = true;
    // remove _id
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { _id, ...dto } = this.data;

    if (
      this.final_analyst_response === 'refuse' &&
      this.fileRefuse &&
      this.feasibilityId
    ) {
      const formData = new FormData();
      formData.append('file', this.fileRefuse);

      formData.append('dto', JSON.stringify({ ...dto, status: 'REJECTED' }));

      this.feasibilityService
        .sendRefuseRefeasbility(formData, 'rejected')
        .subscribe({
          next: () => {
            this.modalService.add({
              id: 'refeasibility-success',
              icon: 'success',
              title: 'Tudo certo!',
              description:
                'Sua justificativa foi enviada com sucesso. Aguarde a análise.',
              actionLabel: 'Entendi',
              action: () => {
                this.router.navigate(['/analysis']);
                this.modalService.remove('refeasibility-success');
              },
            });
            this.loading = false;
          },
          error: (err) => {
            console.error(err);
            this.modalService.add({
              id: 'refeasibility-error',
              icon: 'warning',
              title: 'Ops! Algo errado.',
              description:
                'Ocorreu um erro ao enviar sua justificativa. Tente novamente.',
              actionLabel: 'Continuar',
            });
            this.loading = false;
          },
        });
    } else if (
      this.final_analyst_response === 'sending_notification' &&
      this.fileNotification &&
      this.feasibilityId &&
      this.deadline
    ) {
      const formData = new FormData();
      formData.append('file', this.fileNotification);

      formData.append(
        'dto',
        JSON.stringify({
          ...dto,
          status: 'PEN_RESPONSE',
          deadline: this.deadline,
        }),
      );

      this.feasibilityService
        .sendRefuseRefeasbility(formData, 'notification')
        .subscribe({
          next: () => {
            this.modalService.add({
              id: 'refeasibility-success',
              icon: 'success',
              title: 'Tudo certo!',
              description:
                'Sua resposta foi enviado com sucesso para o solicitante. Aguarde a resposta dentro do prazo.',
              actionLabel: 'Entendi',
              action: () => {
                this.router.navigate(['/analysis']);
                this.modalService.remove('refeasibility-success');
              },
            });
            this.loading = false;
          },
          error: (err) => {
            console.error(err);
            this.modalService.add({
              id: 'refeasibility-error',
              icon: 'warning',
              title: 'Ops! Algo errado.',
              description:
                'Ocorreu um erro ao enviar sua resposta. Tente novamente.',
              actionLabel: 'Continuar',
            });
            this.loading = false;
          },
        });
    } else if (this.final_analyst_response === 'sending_refeasibility') {
      this.handleShowAnalystResponse();
      this.router.navigate([
        `/businesses/re-feasibility-review/${this.documentId}`,
      ]);
    } else if (this.final_analyst_response === 'archived') {
      this.feasibilityService
        .updateFeasibilityStatus({
          ...dto,
          status: 'ARCHIVED',
          deadline: 0,
        })
        .subscribe({
          next: () => {
            this.modalService.add({
              id: 'refeasibility-success',
              icon: 'success',
              title: 'Tudo certo!',
              description:
                'Sua resposta foi enviado com sucesso para o solicitante. Aguarde a resposta dentro do prazo.',
              actionLabel: 'Entendi',
              action: () => {
                this.router.navigate(['/analysis']);
                this.modalService.remove('refeasibility-success');
              },
            });
            this.loading = false;
          },
          error: (err) => {
            console.error(err);
            this.modalService.add({
              id: 'refeasibility-error',
              icon: 'warning',
              title: 'Ops! Algo errado.',
              description:
                'Ocorreu um erro ao enviar sua resposta. Tente novamente.',
              actionLabel: 'Continuar',
            });
            this.loading = false;
          },
        });
    }
  }

  onSendCoordinatorResponse(): void {
    this.loading = true;

    // remove _id
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { _id, ...dto } = this.data;

    if (this.final_analyst_response === 'second_review') {
      this.feasibilityService
        .updateFeasibilityStatus({
          ...dto,
          status: 'SECOND_REVIEW',
          coordinatorJustification: this.coordinatorJustification,
        })
        .subscribe({
          next: () => {
            this.modalService.add({
              id: 'refeasibility-coordinator-success',
              icon: 'success',
              title: 'Tudo certo!',
              description: 'Enviado para 2ª revisão com sucesso.',
              actionLabel: 'Entendi',
              action: () => {
                this.router.navigate(['/analysis']);
                this.modalService.remove('refeasibility-coordinator-success');
              },
            });
            this.loading = false;
          },
          error: (err) => {
            console.error(err);
            this.modalService.add({
              id: 'refeasibility-coordinator-error',
              icon: 'warning',
              title: 'Ops! Algo errado.',
              description:
                'Ocorreu um erro ao enviar sua resposta. Tente novamente.',
              actionLabel: 'Continuar',
            });
            this.loading = false;
          },
        });
    } else {
      this.feasibilityService
        .updateFeasibilityStatus({
          ...dto,
          status: 'RETURNED',
          coordinatorJustification: this.coordinatorJustification,
        })
        .subscribe({
          next: () => {
            this.modalService.add({
              id: 'refeasibility-coordinator-success',
              icon: 'success',
              title: 'Tudo certo!',
              description:
                'Enviado para o Analista com sucesso. Aguarde uma resposta.',
              actionLabel: 'Entendi',
              action: () => {
                this.router.navigate(['/analysis']);
                this.modalService.remove('refeasibility-coordinator-success');
              },
            });
            this.loading = false;
          },
          error: (err) => {
            console.error(err);
            this.modalService.add({
              id: 'refeasibility-coordinator-error',
              icon: 'warning',
              title: 'Ops! Algo errado.',
              description:
                'Ocorreu um erro ao enviar sua resposta. Tente novamente.',
              actionLabel: 'Continuar',
            });
            this.loading = false;
          },
        });
    }
  }
}
