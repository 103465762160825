<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<nav class="flex mb-4" aria-label="Breadcrumb">
  <ol
    class="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse"
  >
    <li class="inline-flex items-center">
      <p
        (click)="toRoute('/')"
        class="flex flex-row justify-start items-center text-sm font-medium text-gray-700 hover:text-slate-800"
      >
        <mat-icon
          class="flex flex-col justify-center items-center text-sm"
          fontIcon="home"
        ></mat-icon>
        Início
      </p>
    </li>
    <li *ngFor="let route of routes">
      <div class="flex items-center">
        <mat-icon
          class="flex flex-col justify-center items-center text-md text-zinc-400"
          fontIcon="chevron_right"
        ></mat-icon>
        <p
          (click)="toRoute(route.link.route, route.link.data)"
          class="ms-1 text-sm font-medium text-gray-700 hover:text-slate-800 md:ms-2 hover:cursor-pointer"
        >
          {{ route.label }}
        </p>
      </div>
    </li>
    <!-- <li aria-current="page">
      <div class="flex items-center">
        <svg class="rtl:rotate-180 w-3 h-3 text-gray-400 mx-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4"/>
        </svg>
        <span class="ms-1 text-sm font-medium text-gray-500 md:ms-2 dark:text-gray-400">Flowbite</span>
      </div>
    </li> -->
  </ol>
</nav>
