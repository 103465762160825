<mat-dialog-content class="flex flex-col items-center gap-4 p-8">
  <warning-big-icon [color]="'#F29C1C'" [width]="62" [height]="62"></warning-big-icon>
  <h1 class="text-4xl text-[#2F3980]">{{data.title}}</h1>

  <h4 *ngIf="data.subtitle" class="text-gray-400">{{data.subtitle}}</h4>

  <div *ngIf="data.content.length > 0;else default">
    <div *ngFor="let c of data.content">
      <div class="flex flex-col gap-4">
        <span class="text-md text-black">{{c}}</span>
      </div>
    </div>
  </div>
  <ng-template #default>
    <div class="flex flex-col gap-4">
      <span class="text-md text-black">Tem certeza que deseja prosseguir?</span>
      <span class="text-md text-black">Essa ação não poderá ser desfeita</span>
    </div>
  </ng-template>


</mat-dialog-content>

<mat-dialog-actions class="flex justify-end gap-4">
  <button mat-button matDialogClose>Voltar</button>
  <button mat-button [mat-dialog-close]="confirm()">Confirmar</button>
</mat-dialog-actions>
