import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { OrientationProps } from '../models/orientation.model';

@Injectable({
  providedIn: 'root',
})
export class OrientationService {
  private httpService = inject(HttpClient);
  private url = `${environment.urlApi}/orientation`;

  constructor() {}

  createOrientation(dto: OrientationProps): Observable<OrientationProps> {
    return this.httpService.post<OrientationProps>(`${this.url}`, dto);
  }

  getOrientationByZoneId(id: string): Observable<OrientationProps> {
    return this.httpService.get<OrientationProps>(`${this.url}/zone/${id}`);
  }

  updateOrientationById(
    id: string,
    dto: OrientationProps
  ): Observable<OrientationProps> {
    return this.httpService.put<OrientationProps>(
      `${this.url}/${id}`,
      dto
    );
  }
}
