<svg
  [attr.width]="width + 'px'"
  [attr.height]="height + 'px'"
  viewBox="0 0 120 120"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M22.35 102.519H97.65C105.35 102.519 110.15 94.1687 106.3 87.5187L68.65 22.4687C64.8 15.8187 55.2 15.8187 51.35 22.4687L13.7 87.5187C9.85 94.1687 14.65 102.519 22.35 102.519ZM60 67.5187C57.25 67.5187 55 65.2687 55 62.5187V52.5187C55 49.7687 57.25 47.5187 60 47.5187C62.75 47.5187 65 49.7687 65 52.5187V62.5187C65 65.2687 62.75 67.5187 60 67.5187ZM65 87.5187H55V77.5187H65V87.5187Z"
    [attr.fill]="color"
  />
</svg>
