import {
  matSearch,
  matSync,
  matArrowBackIos,
  matArrowForwardIos,
  matClose,
  matCheck,
  matBlock,
  matLockOpen,
  matEdit,
} from '@ng-icons/material-icons/baseline';
import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { FormsModule } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { TableColumnsProps } from '../../models/tables.models';

@Component({
  selector: 'app-table',
  standalone: true,
  imports: [CommonModule, NgIconComponent, FormsModule],
  providers: [
    provideIcons({
      matSearch,
      matSync,
      matArrowBackIos,
      matArrowForwardIos,
      matCheck,
      matClose,
      matBlock,
      matLockOpen,
      matEdit,
    }),
  ],
  templateUrl: './table.component.html',
  styleUrl: './table.component.scss',
})
export class TableComponent implements OnChanges {
  @Input() columns = <TableColumnsProps[]>[];
  @Input() data = <unknown[]>[];
  @Input() loading = false;
  @Input() disablePaginate = false;
  @Input() disableBlock = false;
  @Input() disableEdit = false;
  @Input() isEditing = false;
  @Input() pageCount = 1;
  @Input() page = 1;
  @Input() perPage: number = 10;
  @Output() pageChange = new EventEmitter();
  @Output() perPageChange = new EventEmitter();

  pages: number[] = [];
  status: BehaviorSubject<boolean[]> = new BehaviorSubject<boolean[]>([false]);

  constructor() {}
  ngOnChanges() {
    if (this.pageCount > 0) {
      this.pageCount = Math.ceil(this.pageCount);

      if (this.page > this.pageCount) {
        this.page = this.pageCount;
      }

      if (this.page < 1) {
        this.page = 1;
      }

      if (this.pageCount < 1) {
        this.pageCount = 1;
      }

      if (this.pageCount > 5) {
        if (this.page < 3) {
          this.pages = [1, 2, 3, 4, 5];
        } else if (this.page > this.pageCount - 2) {
          this.pages = [
            this.pageCount - 4,
            this.pageCount - 3,
            this.pageCount - 2,
            this.pageCount - 1,
            this.pageCount,
          ];
        } else {
          this.pages = [
            this.page - 2,
            this.page - 1,
            this.page,
            this.page + 1,
            this.page + 2,
          ];
        }
      } else {
        this.pages = Array(this.pageCount)
          .fill(0)
          .map((x, i) => i + 1);
      }
    }
  }

  getValue(column: TableColumnsProps, row: string[] | number[]) {
    if (column.customBody) {
      return column.customBody(row);
    }

    return row[Number(column.value)];
  }

  changePage(page: number) {
    this.page = page;
    this.pageChange.emit(page);
  }

  changePerPage(perPage: number) {
    this.perPage = perPage;
    this.perPageChange.emit(perPage);
  }

  previousPage() {
    if (this.page > 1) {
      this.changePage(this.page - 1);
    }
  }

  nextPage() {
    if (this.page < this.pageCount) {
      this.changePage(this.page + 1);
    }
  }
}
