<ol
  class="items-center w-full space-y-2 sm:flex sm:space-x-2 sm:space-y-0 rtl:space-x-reverse"
>
  <li
    *ngFor="let item of steps"
    class="{{ item.index - 1 === step ? 'current-step' : 'other-step' }}"
  >
    <span
      class="flex items-center justify-center w-8 h-8 border border-gray-500 rounded-full shrink-0 dark:border-gray-400"
    >
      {{ item.index }}
    </span>
    <span>
      <h3 class="font-medium leading-tight text-sm">{{ item.label }}</h3>
    </span>
  </li>
</ol>
