import { CommonModule, Location } from '@angular/common';
import { AfterViewInit, Component, inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TableComponent } from '../../components/table/table.component';
import { FilterBoxComponent } from '../../components/filter-box/filter-box.component';
import { AlertService } from '../../services/alert.service';
import { AlertComponent } from '../../components/alert/alert.component';
import { EditIconComponent } from '../../icons/cnaes/edit-icon/edit-icon.component';
import { EyeIconComponent } from '../../icons/edit-zone/eye-icon/eye-icon.component';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import {
  matArrowBackIos,
  matArrowForwardIos,
  matSync,
} from '@ng-icons/material-icons/baseline';
import {
  BOLD_BUTTON,
  EditorConfig,
  ITALIC_BUTTON,
  NgxSimpleTextEditorModule,
  UNDERLINE_BUTTON,
} from 'ngx-simple-text-editor';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { OrientationProps } from '../../models/orientation.model';
import { OrientationService } from '../../services/orientation.service';
import { catchError, tap, throwError } from 'rxjs';
import { SingleZoneResponse } from '../../models/zone.models';
import { ZoneService } from '../../services/zone.service';
import { CreateZoneFeature } from '../../models/macrozone.models';
import { ModalService } from '../../services/modal.service';
import {
  BreadcrumbsComponent,
  IRoute,
} from '../../components/breadcrumbs/breadcrumbs.component';

@Component({
  selector: 'app-edit-orientation',
  standalone: true,
  imports: [
    CommonModule,
    TableComponent,
    FilterBoxComponent,
    AlertComponent,
    EditIconComponent,
    EyeIconComponent,
    NgIconComponent,
    NgxSimpleTextEditorModule,
    ReactiveFormsModule,
    BreadcrumbsComponent,
  ],
  providers: [
    provideIcons({
      matSync,
      matArrowBackIos,
      matArrowForwardIos,
    }),
  ],
  templateUrl: './edit-orientation.component.html',
  styleUrl: './edit-orientation.component.scss',
})
export class EditOrientationComponent implements OnInit, AfterViewInit {
  routes: IRoute[] = [
    {
      label: 'Macrozonas',
      link: { route: `/macrozonas` },
    },
    {
      label: 'Zonas',
      link: {
        route: `/`,
      },
    },
    {
      label: 'Editar zona',
      link: {
        route: `/`,
      },
    },
    {
      label: 'Editar orientações',
      link: {
        route: `/`,
      },
    },
  ];

  loading: boolean = false;
  orientationForm!: FormGroup;
  config: EditorConfig = {
    placeholder: 'Inserir texto...',
    buttons: [
      BOLD_BUTTON,
      ITALIC_BUTTON,
      UNDERLINE_BUTTON,
      // ORDERED_LIST_BUTTON,
      // UNORDERED_LIST_BUTTON,
    ], // ST_BUTTONS
  };

  orientationService = inject(OrientationService);

  zoneService = inject(ZoneService);

  zoneId: string | null = null;
  zone: SingleZoneResponse = {} as SingleZoneResponse;

  orientation: OrientationProps = {} as OrientationProps;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private alertService: AlertService,
    private modalService: ModalService,
    private formBuilder: FormBuilder,
    private location: Location,
  ) {}

  getZoneName = (name: string) => {
    switch (name) {
      case 'environmental':
        return 'Ambiental';
      case 'special':
        return 'Especial';
      case 'urban':
        return 'Urbana';
      default:
        return 'Rural';
    }
  };

  ngOnInit(): void {
    this.orientationForm = this.formBuilder.group({
      environmentLicensing: [''],
      pgv: [''],
      parkingAndVehicles: [''],
      airCommand: [''],
      fireDepartment: [''],
      historicalHeritage: [''],
      healthLicensing: [''],
      others: [''],
    });

    this.route.params.subscribe((params) => {
      const name = params['name'];
      const acronym = params['acronym'];
      const description = params['description'];
      const hierarchy = params['hierarchy'];
      const macrozone = params['macrozone'];

      if (name && acronym && description && hierarchy) {
        const data: CreateZoneFeature[] = history.state.data;

        this.zone = {
          ...this.zone,
          name,
          acronym,
          description,
          hierarchy,
          macrozoneId: macrozone,
          features: data,
        };

        this.routes = [
          {
            label: 'Macrozonas',
            link: { route: `/macrozonas` },
          },
          {
            label: 'Zonas',
            link: {
              route: `/macrozonas/zonas/${this.getZoneName(
                this.zone.macrozone.name,
              ).toLowerCase()}/${this.zone.macrozoneId}`,
            },
          },
          {
            label: 'Editar zona',
            link: {
              route: `/macrozonas/zona/${this.zone.macrozoneId}/edit/${this.zone.id}`,
              data: {
                name: this.zone.name,
                acronym: this.zone.acronym,
                hierarchy: this.zone.hierarchy,
                description: this.zone.description,
              },
            },
          },
          {
            label: 'Editar orientações',
            link: {
              route: `/orientation/edit/${this.zone.id}/${this.orientation.id}`,
            },
          },
        ];
      }

      const orientation: OrientationProps = history.state.orientation;

      if (orientation) {
        this.orientationForm.patchValue({
          environmentLicensing: orientation.environmentLicensing,
          pgv: orientation.pgv,
          parkingAndVehicles: orientation.parkingAndVehicles,
          airCommand: orientation.airCommand,
          fireDepartment: orientation.fireDepartment,
          historicalHeritage: orientation.historicalHeritage,
          healthLicensing: orientation.healthLicensing,
          others: orientation.others,
        });
      }
    });

    this.route.paramMap.subscribe((params) => {
      this.zoneId = params.get('zoneId');
      if (this.zoneId) {
        if (this.zoneId.length > 6) this.getZone();
        if (!this.orientation.id) this.getOrientation();
      } else {
        this.router.navigate([`/`]);
      }
    });
  }

  ngAfterViewInit() {
    const toobar = document.querySelectorAll('.st-toolbar');
    if (toobar) {
      toobar.forEach((element) => {
        const buttons = element.getElementsByTagName(
          'button',
        ) as HTMLCollection;

        const buttonsArr = Array.from(buttons);
        buttonsArr.forEach((button) => button.setAttribute('type', 'button'));
      });
    }
  }

  getOrientation(): void {
    if (this.zoneId)
      this.orientationService
        .getOrientationByZoneId(this.zoneId)
        .pipe(
          tap((response) => {
            this.orientation = response;

            this.routes = [
              {
                label: 'Macrozonas',
                link: { route: `/macrozonas` },
              },
              {
                label: 'Zonas',
                link: {
                  route: `/macrozonas/zonas/${this.getZoneName(
                    this.zone.macrozone.name,
                  ).toLowerCase()}/${this.zone.macrozoneId}`,
                },
              },
              {
                label: 'Editar zona',
                link: {
                  route: `/macrozonas/zona/${this.zone.macrozoneId}/edit/${this.zone.id}`,
                  data: {
                    name: this.zone.name,
                    acronym: this.zone.acronym,
                    hierarchy: this.zone.hierarchy,
                    description: this.zone.description,
                  },
                },
              },
              {
                label: 'Editar orientações',
                link: {
                  route: `/orientation/edit/${this.zone.id}/${this.orientation.id}`,
                },
              },
            ];
          }),
          catchError((error) => {
            console.error('Error fetching single orientation:', error);
            return throwError(() => error);
          }),
        )
        .subscribe();
  }

  getZone(): void {
    if (this.zoneId)
      this.zoneService
        .getZoneById(this.zoneId)
        .pipe(
          tap((response: SingleZoneResponse) => {
            this.zone = response;

            this.routes = [
              {
                label: 'Macrozonas',
                link: { route: `/macrozonas` },
              },
              {
                label: 'Zonas',
                link: {
                  route: `/macrozonas/zonas/${this.getZoneName(
                    this.zone.macrozone.name,
                  ).toLowerCase()}/${this.zone.macrozoneId}`,
                },
              },
              {
                label: 'Editar zona',
                link: {
                  route: `/macrozonas/zona/${this.zone.macrozoneId}/edit/${this.zone.id}`,
                  data: {
                    name: this.zone.name,
                    acronym: this.zone.acronym,
                    hierarchy: this.zone.hierarchy,
                    description: this.zone.description,
                  },
                },
              },
              {
                label: 'Editar orientações',
                link: {
                  route: `/orientation/edit/${this.zone.id}/${this.orientation.id}`,
                },
              },
            ];
          }),
          catchError((error) => {
            console.error('Error fetching single zone:', error);
            return throwError(() => error);
          }),
        )
        .subscribe();
  }

  public goBack = () => {
    this.location.back();
  };

  onSubmit() {
    this.loading = true;
    if (this.orientationForm.valid) {
      const orientationDto: OrientationProps = {
        zoneId: this.zoneId || this.orientation.zoneId,
        environmentLicensing:
          this.orientationForm.controls['environmentLicensing'].value ||
          this.orientation.environmentLicensing,
        pgv: this.orientationForm.controls['pgv'].value || this.orientation.pgv,
        parkingAndVehicles:
          this.orientationForm.controls['parkingAndVehicles'].value ||
          this.orientation.parkingAndVehicles,
        airCommand:
          this.orientationForm.controls['airCommand'].value ||
          this.orientation.airCommand,
        fireDepartment:
          this.orientationForm.controls['fireDepartment'].value ||
          this.orientation.fireDepartment,
        historicalHeritage:
          this.orientationForm.controls['historicalHeritage'].value ||
          this.orientation.historicalHeritage,
        healthLicensing:
          this.orientationForm.controls['healthLicensing'].value ||
          this.orientation.healthLicensing,
        others:
          this.orientationForm.controls['others'].value ||
          this.orientation.others,
      };

      if (this.orientation.id)
        this.orientationService
          .updateOrientationById(this.orientation.id, orientationDto)
          .pipe(
            tap(() => {
              this.loading = false;
              this.goBack();
              this.modalService.add({
                id: 'create-rule-success',
                icon: 'success',
                title: 'Sua orientação foi atualizada com sucesso.',
                actionLabel: 'Continuar',
              });
            }),
            catchError((error) => {
              this.loading = false;
              console.error('Error creating orientation:', error);
              this.alertService.add({
                id: 'create-orientation-error',
                type: 'error',
                message: 'Tivemos um problema ao tentar criar sua pegunta.',
                top: 5,
                right: 1,
              });
              return throwError(() => error);
            }),
          )
          .subscribe();
    } else {
      this.loading = false;
      this.markFormGroupTouched(this.orientationForm);
    }
  }

  markFormGroupTouched(formGroup: FormGroup) {
    Object.values(formGroup.controls).forEach((control) => {
      control.markAsTouched();

      if (control instanceof FormGroup) {
        this.markFormGroupTouched(control);
      }
    });
  }
}
