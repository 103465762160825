import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Modal } from '../../models/modal.models';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { ModalService } from '../../services/modal.service';
import { WarningBigIconComponent } from '../../icons/modal/warning-big-icon/warning-big-icon.component';
import { SuccessBigIconComponent } from '../../icons/modal/success-big-icon/success-big-icon.component';

@Component({
  selector: 'app-modal',
  standalone: true,
  imports: [CommonModule, WarningBigIconComponent, SuccessBigIconComponent],
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent implements OnInit, OnDestroy {
  modals: Modal[] = [];
  private modalSub: Subscription = new Subscription();

  constructor(
    private modalService: ModalService,
    private router: Router,
  ) {}

  ngOnInit() {
    this.modalSub = this.modalService.modals.subscribe((modals) => {
      this.modals = modals;
      this.modals.forEach((modal) => {
        if (modal.autoClose) {
          setTimeout(() => this.close(modal.id), 3000);
        }
      });
    });
  }

  ngOnDestroy() {
    this.modalSub.unsubscribe();
  }

  close(id: string) {
    this.modals = this.modals.map((alert) =>
      alert.id === id ? { ...alert, visible: false } : alert,
    );

    setTimeout(() => {
      this.modals = this.modals.filter((alert) => alert.id !== id);
      this.modalService.remove(id);
    }, 250);
  }

  kill(id: string) {
    this.modalService.remove(id);
  }
}
