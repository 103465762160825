/* eslint-disable no-useless-escape */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  matClose,
  matCheck,
  matWork,
  matPersonPin,
} from '@ng-icons/material-icons/baseline';
import { CommonModule } from '@angular/common';
import { Component, OnInit, } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { NgIconComponent, provideIcons } from '@ng-icons/core';

import { HttpErrorResponse } from '@angular/common/http';
import { UserService } from '../../services/user.service';
import { CreateUserInterface } from '../../models/user.model';
import { AlertComponent } from '../../components/alert/alert.component';
import { AlertService } from '../../services/alert.service';
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';
import OccupationsJson from '../../../utils/json/occupations';

@Component({
  selector: 'app-register',
  standalone: true,
  imports: [
    NgIconComponent,
    CommonModule,
    ReactiveFormsModule,
    AlertComponent, 
    NgxMaskDirective,
  ],
  providers: [
    provideIcons({ matClose, matCheck, matPersonPin, matWork }),
    provideNgxMask(),
  ],
  templateUrl: './register.component.html',
  styleUrl: './register.component.scss',
})
export class RegisterComponent implements OnInit {
  userForm!: FormGroup;
  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private userService: UserService,
    private alertService: AlertService
  ) {}

  occupations = OccupationsJson;

  log(event: any, desc?: string) {
    desc
      ? console.log(desc, event.target.value)
      : console.log(event.target.value);
  }

  push(route: string): void {
    this.router.navigate([route]);
  }

  ngOnInit(): void {
    this.watchIsTechnicalProfessional();

    this.userForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      street: ['', Validators.required],
      cpf: [
        '',
        [
          Validators.required,
          Validators.pattern(/^\d{3}\.\d{3}\.\d{3}\-\d{2}$/),
        ],
      ],
      complement: [''],
      birthdate: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      neighborhood: ['', Validators.required],
      password: ['', [Validators.required, Validators.pattern(/^.{8,}$/)]],
      confirm_password: ['', [Validators.required]],
      city: ['', Validators.required],
      uf: ['', Validators.required],
      isTechnicalProfessional: [false],
      occupation: [''],
      register: [''],
    });
  }

  typeUser: string | null = null;
  technical: boolean = false;

  return() {
    this.router.navigate(['/login']);
  }

  mismatch() {
    const password = this.userForm.controls['password'].value;
    const confirmPassword = this.userForm.controls['confirm_password'].value;

    if (password !== confirmPassword) {
      this.userForm.controls['confirm_password'].setErrors({ not_match: true });
    } else {
      this.userForm.controls['confirm_password'].setErrors(null);
    }
  }

  watchIsTechnicalProfessional(): void {
    if (this.userForm)
      this.userForm.controls['isTechnicalProfessional'].valueChanges.subscribe(
        (isTechnical) => {
          if (isTechnical) {
            this.userForm.controls['occupation'].setValidators([
              Validators.required,
            ]);
            this.userForm.controls['register'].setValidators([
              Validators.required,
            ]);
          } else {
            this.userForm.controls['occupation'].setValidators(null);
            this.userForm.controls['register'].setValidators(null);
          }
          this.userForm.controls['occupation'].updateValueAndValidity();
          this.userForm.controls['register'].updateValueAndValidity();
        }
      );
  }

  onSubmit() {
    this.mismatch();

    if (this.userForm.valid) {
      const userDto: CreateUserInterface = {
        firstName: this.userForm.controls['firstName'].value,
        lastName: this.userForm.controls['lastName'].value,
        street: this.userForm.controls['street'].value,
        cpf: this.userForm.controls['cpf'].value.replace(/[^0-9]/g, ''),
        complement: this.userForm.controls['complement'].value,
        birthDate: this.userForm.controls['birthdate'].value,
        email: this.userForm.controls['email'].value,
        neighborhood: this.userForm.controls['neighborhood'].value,
        password: this.userForm.controls['password'].value,
        city: this.userForm.controls['city'].value,
        state: this.userForm.controls['uf'].value,
        isTechnicalProfessional:
          this.userForm.controls['isTechnicalProfessional'].value,
        occupation: Number(
          this.userForm.controls['isTechnicalProfessional'].value === true
            ? this.userForm.controls['occupation'].value
            : 0
        ),
        register:
          this.userForm.controls['isTechnicalProfessional'].value === true
            ? this.userForm.controls['register'].value
            : '',
        role: 'user',
      };

      this.userService.create(userDto).subscribe({
        next: () => {
          this.router.navigate(['/login']);
          this.alertService.add({
            id: 'register-field-success',
            type: 'success',
            message:
              'Sua conta foi cadastrada com sucesso! Use os dados registrados para entrar na plataforma.',
          });
        },
        error: (e: HttpErrorResponse) => {
          console.error(e);
        },
      });
    } else {
      this.markFormGroupTouched(this.userForm);
    }
  }

  markFormGroupTouched(formGroup: FormGroup) {
    Object.values(formGroup.controls).forEach((control) => {
      control.markAsTouched();

      if (control instanceof FormGroup) {
        this.markFormGroupTouched(control);
      }
    });
  }
}
