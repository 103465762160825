<app-alert></app-alert>
<section class="h-full bg-blue-950 dark:bg-gray-900">
  <div
    class="flex flex-col items-center justify-center px-6 py-8 mx-auto mb-20 lg:py-0"
  >
    <a
      href="#"
      class="flex items-center mb-6 text-2xl font-semibold text-white"
    >
      <div class="rounded-full mr-5 bg-white">
        <img class="w-16 h-16" src="assets/images/HUBlic.png" alt="logo" />
      </div>
      Hublic
    </a>
    <div
      class="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-4xl xl:p-0 dark:bg-gray-800 dark:border-gray-700"
    >
      <div class="p-6 space-y-4 md:space-y-6 sm:p-8">
        <h1
          class="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white"
        >
          Cadastro de Cidadão
        </h1>
        <form
          class="space-y-4 md:space-y-6"
          [formGroup]="userForm"
          (ngSubmit)="onSubmit()"
        >
          <div>
            <h2
              class="text-md font-medium leading-tight tracking-tight text-gray-900 md:text-xl dark:text-white mb-2"
            >
              Informações Pessoais
            </h2>
            <div class="flex flex-col">
              <div class="grid grid-cols-1 md:grid-cols-4 gap-2.5 mb-2">
                <div>
                  <label
                    for="firstName"
                    class="mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >Nome</label 
                  >
                  <input
                    formControlName="firstName"
                    type="text"
                    id="firstName"
                    class="input-text"
                    placeholder="John"
                    required=""
                  />

                  <div
                    class="text-xs font-medium text-red-500 mt-1"
                    *ngIf="
                      (userForm.controls['firstName'].touched ||
                        userForm.controls['firstName'].dirty) &&
                      userForm.controls['firstName'].errors &&
                      userForm.controls['firstName'].errors['required']
                    "
                  >
                    Nome é obrigatório.
                  </div>
                </div>
                <div>
                  <label
                    for="lastName"
                    class="mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >Sobrenome</label
                  >
                  <input
                    formControlName="lastName"
                    type="text"
                    id="lastName"
                    class="input-text"
                    placeholder="Doe"
                    required=""
                  />

                  <div
                    class="text-xs font-medium text-red-500 mt-1"
                    *ngIf="
                      (userForm.controls['lastName'].touched ||
                        userForm.controls['lastName'].dirty) &&
                      userForm.controls['lastName'].errors &&
                      userForm.controls['lastName'].errors['required']
                    "
                  >
                    Sobrenome é obrigatório.
                  </div>
                </div>
                <div>
                  <label
                    for="cpf"
                    class="mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >CPF</label
                  >
                  <input
                    formControlName="cpf"
                    type="text"
                    id="cpf"
                    class="input-text"
                    placeholder="000.000.000-00"
                    mask="000.000.000-00"
                    [dropSpecialCharacters]="false"
                    required=""
                  />

                  <div
                    class="text-xs font-medium text-red-500 mt-1"
                    *ngIf="
                      (userForm.controls['cpf'].touched ||
                        userForm.controls['cpf'].dirty) &&
                      userForm.controls['cpf'].errors &&
                      userForm.controls['cpf'].errors['required']
                    "
                  >
                    CPF é obrigatório.
                  </div>
                  <div
                    class="text-xs font-medium text-red-500 mt-1"
                    *ngIf="
                      (userForm.controls['cpf'].touched ||
                        userForm.controls['cpf'].dirty) &&
                      userForm.controls['cpf'].errors &&
                      userForm.controls['cpf'].errors['pattern']
                    "
                  >
                    CPF com Formato invalido.
                  </div>
                </div>
                <div>
                  <label
                    for="birthdate"
                    class="mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >Data de nascimento</label
                  >
                  <input
                    formControlName="birthdate"
                    type="date"
                    id="birthdate"
                    class="input-text"
                    placeholder="00/00/0000"
                    required=""
                  />
                  <div
                    class="text-xs font-medium text-red-500 mt-1"
                    *ngIf="
                      (userForm.controls['birthdate'].touched ||
                        userForm.controls['birthdate'].dirty) &&
                      userForm.controls['birthdate'].errors &&
                      userForm.controls['birthdate'].errors['required']
                    "
                  >
                    Data de nascimento é obrigatória.
                  </div>
                </div>
              </div>
              <div class="grid grid-cols-1 md:grid-cols-2 gap-2.5 mb-2">
                <div>
                  <label
                    for="email"
                    class="text-sm font-medium text-gray-900 dark:text-white"
                    >E-mail</label
                  >
                  <input
                    formControlName="email"
                    type="email"
                    id="email"
                    class="input-text"
                    placeholder="email@company.com"
                    autocomplete="new-email"
                    required=""
                  />
                  <div
                    class="text-xs font-medium text-red-500 mt-1"
                    *ngIf="
                      (userForm.controls['email'].touched ||
                        userForm.controls['email'].dirty) &&
                      userForm.controls['email'].errors &&
                      userForm.controls['email'].errors['required']
                    "
                  >
                    E-mail é obrigatório.
                  </div>
                  <div
                    class="text-xs font-medium text-red-500 mt-1"
                    *ngIf="
                      (userForm.controls['email'].touched ||
                        userForm.controls['email'].dirty) &&
                      userForm.controls['email'].errors &&
                      userForm.controls['email'].errors['email']
                    "
                  >
                    E-mail no formato inválido.
                  </div>
                </div>
              </div>

              <h2
                class="text-md font-medium leading-tight tracking-tight text-gray-900 md:text-xl dark:text-white mb-2"
              >
                Endereço
              </h2>
              <div class="grid grid-cols-1 md:grid-cols-3 gap-2.5 mb-4">
                <div>
                  <label
                    for="street"
                    class="mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >Rua/Avenida</label
                  >
                  <input
                    formControlName="street"
                    type="text"
                    id="street"
                    class="input-text"
                    placeholder="Exemplo: Av. Paulista"
                    required=""
                  />

                  <div
                    class="text-xs font-medium text-red-500 mt-1"
                    *ngIf="
                      (userForm.controls['street'].touched ||
                        userForm.controls['street'].dirty) &&
                      userForm.controls['street'].errors &&
                      userForm.controls['street'].errors['required']
                    "
                  >
                    Rua/Avenida é obrigatório.
                  </div>
                </div>
                <div>
                  <label
                    for="complement"
                    class="mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >Complemento</label
                  >
                  <input
                    formControlName="complement"
                    type="text"
                    id="complement"
                    class="input-text"
                    placeholder="Apto, sala, andar, etc. (Opcional)"
                  />
                </div>
                <div>
                  <label
                    for="neighborhood"
                    class="mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >Bairro</label
                  >
                  <input
                    formControlName="neighborhood"
                    type="text"
                    id="neighborhood"
                    class="input-text"
                    placeholder="Exemplo: Vila Mariana"
                    required=""
                  />
                  <div
                    class="text-xs font-medium text-red-500 mt-1"
                    *ngIf="
                      (userForm.controls['neighborhood'].touched ||
                        userForm.controls['neighborhood'].dirty) &&
                      userForm.controls['neighborhood'].errors &&
                      userForm.controls['neighborhood'].errors['required']
                    "
                  >
                    Bairro é obrigatório.
                  </div>
                </div>
                <div class="md:col-span-1">
                  <label
                    for="city"
                    class="mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >Cidade</label
                  >
                  <input
                    formControlName="city"
                    type="text"
                    id="city"
                    class="input-text"
                    placeholder="Exemplo: São Paulo"
                    required=""
                  />

                  <div
                    class="text-xs font-medium text-red-500 mt-1"
                    *ngIf="
                      (userForm.controls['city'].touched ||
                        userForm.controls['city'].dirty) &&
                      userForm.controls['city'].errors &&
                      userForm.controls['city'].errors['required']
                    "
                  >
                    Cidade é obrigatória.
                  </div>
                </div>
                <div class="md:col-span-1">
                  <label
                    for="uf"
                    class="mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >Estado (UF)</label
                  >
                  <input
                    formControlName="uf"
                    type="text"
                    id="uf"
                    class="input-text"
                    placeholder="Exemplo: São Paulo"
                    required=""
                  />

                  <div
                    class="text-xs font-medium text-red-500 mt-1"
                    *ngIf="
                      (userForm.controls['uf'].touched ||
                        userForm.controls['uf'].dirty) &&
                      userForm.controls['uf'].errors &&
                      userForm.controls['uf'].errors['required']
                    "
                  >
                    Estado (UF) é obrigatório.
                  </div>
                </div>
              </div>
            </div>

            <h2
              class="text-md font-medium leading-tight tracking-tight text-gray-900 md:text-xl dark:text-white mb-2"
            >
              Informações Profissionais
            </h2>
            <div class="flex flex-col justify-start items-start mb-4">
              <div>
                <h2 class="text-md font-medium text-gray-900 dark:text-white">
                  Você é um profissional técnico?
                </h2>
                <div class="mt-3 flex flex-row">
                  <div class="pr-2 flex">
                    <p class="label mr-3">Sim</p>
                    <input
                      formControlName="isTechnicalProfessional"
                      type="radio"
                      [value]="true"
                      name="isTechnicalProfessional"
                    />
                  </div>
                  <div class="pr-2 flex">
                    <p class="label mr-3">Não</p>
                    <input
                      formControlName="isTechnicalProfessional"
                      type="radio"
                      [value]="false"
                      name="isTechnicalProfessional"
                    />
                  </div>
                </div>
              </div>
              <div
                *ngIf="userForm.get('isTechnicalProfessional')?.value === true"
                class="grid grid-cols-1 md:grid-cols-2 gap-2.5 mt-2"
              >
                <div>
                  <label
                    for="occupation"
                    class="text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Profissão</label
                  >
                  <select
                    class="input-text"
                    formControlName="occupation"
                    id="occupation"
                    required=""
                  >
                    <option value="" disabled selected>
                      Selecione sua profissão
                    </option>
                    <option
                      *ngFor="let occupation of occupations"
                      [value]="occupation.id"
                    >
                      {{ occupation.description }}
                    </option>
                  </select>
                  <div
                    class="text-xs font-medium text-red-500 mt-1"
                    *ngIf="
                      (userForm.controls['occupation'].touched ||
                        userForm.controls['occupation'].dirty) &&
                      userForm.controls['occupation'].errors &&
                      userForm.controls['occupation'].errors['required']
                    "
                  >
                    Profissão é obrigatório.
                  </div>
                </div>
                <div>
                  <label
                    for="register"
                    class="text-sm font-medium text-gray-900 dark:text-white"
                    >Registro Profissional</label
                  >
                  <input
                    formControlName="register"
                    type="text"
                    id="register"
                    class="input-text"
                    placeholder="Insira a inscrição do Conselho de Classe Profissional"
                    required=""
                  />
                  <div
                    class="text-xs font-medium text-red-500 mt-1"
                    *ngIf="
                      (userForm.controls['register'].touched ||
                        userForm.controls['register'].dirty) &&
                      userForm.controls['register'].errors &&
                      userForm.controls['register'].errors['required']
                    "
                  >
                    Registro Profissional é obrigatório.
                  </div>
                </div>
              </div>
            </div>

            <h2
              class="text-md font-medium leading-tight tracking-tight text-gray-900 md:text-xl dark:text-white mb-2"
            >
              Acesso
            </h2>
            <div class="grid grid-cols-1 md:grid-cols-3 gap-2.5 mb-4">
              <div>
                <label
                  for="password"
                  class="text-sm font-medium text-gray-900 dark:text-white"
                  >Senha</label
                >
                <input
                  formControlName="password"
                  type="password"
                  id="password"
                  class="input-text"
                  placeholder="***************"
                  autocomplete="new-password"
                  required=""
                />
                <div
                  class="text-xs font-medium text-red-500 mt-1"
                  *ngIf="
                    (userForm.controls['password'].touched ||
                      userForm.controls['password'].dirty) &&
                    userForm.controls['password'].errors &&
                    userForm.controls['password'].errors['required']
                  "
                >
                  Senha é obrigatória.
                </div>
                <div
                  class="text-xs font-medium text-red-500 mt-1"
                  *ngIf="
                    (userForm.controls['password'].touched ||
                      userForm.controls['password'].dirty) &&
                    userForm.controls['password'].errors &&
                    userForm.controls['password'].errors['pattern']
                  "
                >
                  Necessário ter pelo menos 8 caracteres.
                </div>
              </div>
              <div>
                <label
                  for="confirm_password"
                  class="text-sm font-medium text-gray-900 dark:text-white"
                  >Confirmar senha</label
                >
                <input
                  formControlName="confirm_password"
                  type="password"
                  id="confirm-password"
                  class="input-text"
                  placeholder="***************"
                  required=""
                />
                <div
                  class="text-xs font-medium text-red-500 mt-1"
                  *ngIf="
                    (userForm.controls['confirm_password'].touched ||
                      userForm.controls['confirm_password'].dirty) &&
                    userForm.controls['confirm_password'].errors &&
                    userForm.controls['confirm_password'].errors['required']
                  "
                >
                  Confirmar senha é obrigatória.
                </div>
                <div
                  class="text-xs font-medium text-red-500 mt-1"
                  *ngIf="
                    (userForm.controls['confirm_password'].touched ||
                      userForm.controls['confirm_password'].dirty) &&
                    userForm.controls['confirm_password'].errors &&
                    userForm.controls['confirm_password'].errors['not_match']
                  "
                >
                  As senhas devem ser iguais.
                </div>
              </div>
            </div>
          </div>

          <div class="flex flex-col md:flex-row justify-end items-center">
            <button type="button" class="btn-secondary" (click)="push('/')">
              VOLTAR
            </button>
            <button type="submit" class="btn">CADASTRAR CONTA</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</section>
