/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { matHomeOutline } from '@ng-icons/material-icons/outline';
import { Component, EventEmitter, OnInit, Output, inject } from '@angular/core';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import {
  matKeyboardArrowDown,
  matDashboard,
  matAttachMoney,
  matInsertDriveFile,
  matLibraryAdd,
  matAssessment,
  matMoveToInbox,
  matSearch,
  matSync,
  matSettingsPower,
  matManageAccounts,
  matArrowBackIos,
  matArrowForwardIos,
  matNotificationsActive,
  matCompareArrows,
  matSdCardAlert,
  matEdit,
  matRule,
  matMiscellaneousServices,
  matBusinessCenter,
} from '@ng-icons/material-icons/baseline';
import { CommonModule } from '@angular/common';
import { Router, RouterModule } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { AppState } from '../../states/user.state';

@Component({
  selector: 'app-sidebar',
  standalone: true,
  imports: [NgIconComponent, CommonModule, RouterModule],
  providers: [
    provideIcons({
      matHomeOutline,
      matKeyboardArrowDown,
      matAttachMoney,
      matDashboard,
      matInsertDriveFile,
      matLibraryAdd,
      matAssessment,
      matMoveToInbox,
      matSearch,
      matSync,
      matSettingsPower,
      matManageAccounts,
      matArrowBackIos,
      matArrowForwardIos,
      matNotificationsActive,
      matCompareArrows,
      matSdCardAlert,
      matEdit,
      matRule,
      matMiscellaneousServices,
      matBusinessCenter,
    }),
  ],
  templateUrl: './sidebar.component.html',
  styleUrl: './sidebar.component.scss',
})
export class SidebarComponent implements OnInit {
  @Output() isExpanded = new EventEmitter<boolean>(false);

  authService = inject(AuthService);
  private store = inject(Store<AppState>);

  constructor(private router: Router) {}

  type: string | null = null;
  name: string | null = null;
  topMenuOpen = false;
  collapsed = false;

  getUserName = (): void => {
    this.store
      .select((state) => state.user)
      .subscribe((userState) => {
        this.name = userState.fullname || '';
      });
  };

  logout = () => {
    this.authService.logout().subscribe({
      next: () => this.router.navigate(['/login']),
      error: (e: HttpErrorResponse) => {
        // console.error(e), this.alertService.error(e.message);
      },
    });
  };

  menu: any[] = [];

  ngOnInit() {
    this.name = this.authService.getDecodedUser()?.fullname ?? null;
    this.type = this.authService.getDecodedUser()?.role ?? 'user';
    this.setMenu();
  }

  public setMenu = () => {
    if (this.type == 'user') {
      this.menu = [
        {
          label: 'Serviços',
          icon: 'matMiscellaneousServices',
          children: [
            {
              label: 'Empreendimentos',
              link: '/businesses',
              icon: 'matBusinessCenter',
              permission: true,
            },
            {
              label: 'Consulta de Viabilidade',
              link: '/feasibility',
              icon: 'matSearch',
              permission: true,
            },
          ],
          permission: true,
          open: false,
        },
        {
          label: 'Sair',
          icon: 'matSettingsPower',
          link: '/logout',
          children: [],
          action: this.logout,
        },
      ];
    } else {
      this.menu = [
        {
          label: 'Dashboard',
          icon: 'matDashboard',
          link: '/dashboard',
          children: [],
          permission: true,
        },
        {
          label: 'Personalizar',
          icon: 'matEdit',
          link: '/personalize',
          children: [],
          permission: true,
        },
        {
          label: 'Macrozonas',
          icon: 'matLibraryAdd',
          link: '/macrozonas',
          children: [],
          permission: true,
        },
        {
          label: 'Servidores',
          icon: 'matManageAccounts',
          link: '/servers',
          children: [],
          permission:
            this.type == 'manager' || // Gestor
            this.type == 'coordinator-manager' ||
            this.type == 'mayor-secretary',
        },
        {
          label: 'Atividades (CNAEs)',
          icon: 'matInsertDriveFile',
          link: '/cnaes',
          children: [],
          permission: true,
        },
        {
          label: 'Análises',
          icon: 'matCompareArrows',
          link: '/analysis',
          children: [],
          permission: true,
        },
        {
          label: 'Sair',
          icon: 'matSettingsPower',
          link: '/logout',
          children: [],
          permission: true,
          action: this.logout,
        },
      ];
    }
  };

  onLinkClick(event: MouseEvent, link: string) {
    event.preventDefault();

    if (this.router.url === link) {
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate([link]);
      });
    } else {
      this.router.navigate([link]);
    }
  }

  public openTopMenu = () => {
    this.topMenuOpen = !this.openTopMenu;
  };

  public switchType = () => {
    const type = localStorage.getItem('type');
    if (type === 'admin') {
      localStorage.setItem('type', 'user');
      this.type = 'user';
      this.router.navigate(['/negocios']);
    } else {
      localStorage.setItem('type', 'admin');
      this.type = 'admin';
      this.router.navigate(['/personalize']);
    }
    this.setMenu();
  };

  handleOpenOption(i: number) {
    this.menu[i].open = !this.menu[i].open;
  }

  public handleMenuToggle = () => {
    this.collapsed = !this.collapsed;
    this.isExpanded.emit(this.collapsed);
  };
}
