import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { RulesProps, RulesResponseProps } from '../models/rules.models';

@Injectable({
  providedIn: 'root',
})
export class RuleService {
  private httpService = inject(HttpClient);
  private url = `${environment.urlApi}/rule`;

  constructor() {}

  createRule(dto: RulesProps): Observable<RulesProps> {
    return this.httpService.post<RulesProps>(`${this.url}`, dto);
  }

  updateById(dto: RulesProps): Observable<RulesProps> {
    return this.httpService.put<RulesProps>(`${this.url}/${dto.id}`, dto);
  }

  getRule(id: string): Observable<RulesProps> {
    return this.httpService.get<RulesProps>(`${this.url}/${id}`);
  }

  getRuleByZone(id: string): Observable<RulesProps> {
    return this.httpService.get<RulesProps>(`${this.url}/zone/${id}`);
  }

  getRules(): Observable<RulesResponseProps> {
    return this.httpService.get<RulesResponseProps>(`${this.url}`);
  }
}
