<svg
  [attr.width]="width + 'px'"
  [attr.height]="height + 'px'"
  viewBox="0 0 25 24"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M11.3188 12.35C12.0888 10.96 13.5688 10.14 14.4288 8.91C15.3388 7.62 14.8288 5.21 12.2488 5.21C10.5588 5.21 9.72882 6.49 9.37882 7.55L6.78882 6.46C7.49882 4.33 9.42882 2.5 12.2388 2.5C14.5888 2.5 16.1988 3.57 17.0188 4.91C17.7188 6.06 18.1288 8.21 17.0488 9.81C15.8488 11.58 14.6988 12.12 14.0788 13.26C13.8288 13.72 13.7288 14.02 13.7288 15.5H10.8388C10.8288 14.72 10.7088 13.45 11.3188 12.35ZM14.2488 19.5C14.2488 20.6 13.3488 21.5 12.2488 21.5C11.1488 21.5 10.2488 20.6 10.2488 19.5C10.2488 18.4 11.1488 17.5 12.2488 17.5C13.3488 17.5 14.2488 18.4 14.2488 19.5Z"
    [attr.fill]="color"
  />
</svg>
