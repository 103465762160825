/* eslint-disable @angular-eslint/use-lifecycle-interface */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { CommonModule } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  inject,
} from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatDivider } from '@angular/material/divider';
import {
  CnaeResponse,
  CnaeResponseFeasibility,
} from '../../../../models/cnae.models';
import { CnaeService } from '../../../../services/cnaes.service';
import { MatIcon } from '@angular/material/icon';
import { QuestionOnCnae } from '../../../../models/questions.models';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import {
  matArrowBackIos,
  matArrowForwardIos,
} from '@ng-icons/material-icons/baseline';
import { Subject, debounceTime } from 'rxjs';
import { FeasibilityService } from '../../../../services/feasibility.service';
import {
  CreateFeasibilityDto,
  FeasibilityForm,
  FeasibilityResponse,
  FeasibilityRisks,
} from '../../../../models/feasibility.model';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../states/user.state';
import { AuthService } from '../../../../services/auth.service';

@Component({
  selector: 'app-feasibility-activity',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatDivider,
    MatIcon,
    NgIconComponent,
  ],
  providers: [
    provideIcons({
      matArrowBackIos,
      matArrowForwardIos,
    }),
  ],
  templateUrl: './activity.component.html',
  styleUrl: './activity.component.scss',
})
export class ActivityFeasibilityComponent implements OnInit {
  @Input() feasibilityForm!: FormGroup;
  @Input() step!: number;
  @Output() setStep = new EventEmitter<number>();
  form!: FormGroup;
  @Output() emitForm = new EventEmitter<FormGroup>();
  @Output() emitFeasibilityId = new EventEmitter<string>();
  @Output() emitFeasibilityResponse = new EventEmitter<string>();
  @Output() resultEvent = new EventEmitter<boolean>();

  @Input() data!: FeasibilityResponse;
  @Input() isReview: boolean = false;

  mainCnae?: CnaeResponse;
  mainCnaes: CnaeResponse[] = [];
  suggestMainCnaes: CnaeResponse[] = [];
  secondaryCnaes: CnaeResponse[] = [];
  notSecondaryCnaes: CnaeResponse[] = [];

  mainQuestionsFinalRisk!: number;
  secondaryQuestionsFinalRisk!: number;

  mainFinalRisk!: number;
  secondaryFinalRisk!: number;

  feasibilityFinalRisk!: number;
  feasibilityRisks: FeasibilityRisks[] = [];
  feasibilitySecondaryQuestionsRisks: FeasibilityRisks[] = [];

  mainSelectedCnae: CnaeResponse = {} as CnaeResponse;
  secondarySelectedCnaes: CnaeResponse[] = [];
  notSecondarySelectedCnaes: CnaeResponse[] = [];
  cnaeQuestions: QuestionOnCnae[] = [];
  secondaryCnaeQuestions: QuestionOnCnae[] = [];
  secondaryCnaeQuestionsFormArray: FormArray = new FormArray<
    AbstractControl<QuestionOnCnae>
  >([]);

  loadingMainCnae = false;
  loadingSecondaryCnaes = false;
  loadingSecondaryNotCnaes = false;
  loadingQuestions = false;
  loadingSecondaryQuestions = false;
  loadingSecondarySelect = false;

  cnaeService = inject(CnaeService);

  searchValue: string = '';
  searchSecondaryValue: string = '';
  searchNotSecondaryValue: string = '';

  private searchValueSubject = new Subject<string>();
  private searchValueSubscription: any;

  showQuestions = true;
  showSecondaryQuestions = true;

  page: number = 1;
  limit: number = 10;
  public pages: number[] = [];

  secondaryPage: number = 1;
  secondaryLimit: number = 10;
  public secondaryPages: number[] = [];

  notSecondaryPage: number = 1;
  notSecondaryLimit: number = 10;
  public notSecondaryPages: number[] = [];

  showSecondarySelected: boolean = false;
  showNotSecondarySelected: boolean = false;

  showSecondarySelectedData: CnaeResponse[] = [];

  showSuggestions: boolean = false;

  feasibilityService = inject(FeasibilityService);
  authService = inject(AuthService);
  private userStore = inject(Store<AppState>);

  private responsibleDocument: string = '';

  loading = false;

  constructor(
    private formBuilder: FormBuilder,
    private eRef: ElementRef,
  ) {}

  // @HostListener('document:click', ['$event'])
  // clickOutside(event: any): void {
  //   if ((event.target as Node).nodeName !== 'SELECT' && this.showSuggestions) {
  //     if (!this.eRef.nativeElement.contains(event.target)) {
  //       this.showSuggestions = false;
  //     }
  //   } else return;
  // }

  handleShowSecondarySelected(data: string): void {
    if (data === 'close') {
      this.showSecondarySelected = false;
      return;
    }

    if (data === 'secondary') {
      this.showSecondarySelectedData = this.secondarySelectedCnaes;
      this.showSecondarySelected = !this.showSecondarySelected;
      this.showNotSecondarySelected = false;
    } else {
      this.showSecondarySelectedData = this.notSecondarySelectedCnaes;
      this.showSecondarySelected = !this.showSecondarySelected;
      this.showNotSecondarySelected = true;
    }
  }

  handleShowQuestions(): void {
    this.showQuestions = !this.showQuestions;
  }

  handleShowSecondaryQuestions(): void {
    this.showSecondaryQuestions = !this.showSecondaryQuestions;
  }

  ngOnInit(): void {
    this.createForm();

    if (!this.isReview) {
      this.getForm();

      this.handleSearchCnae('', 1, 10, false);
      this.handleSearchSecondaryCnaes('', 1, 10);
      this.handleSearchNotSecondaryCnaes('', 1, 10);
    }

    this.searchValueSubscription = this.searchValueSubject
      .pipe(debounceTime(1000))
      .subscribe((value) => {
        this.handleSearchCnae(value, 1, 10, true);
      });

    this.responsibleDocument = this.authService.getDecodedUser()?.cpf ?? '';
  }

  handleSelectSuggestions(cnae: CnaeResponse): void {
    this.mainCnaes = this.suggestMainCnaes;
    this.mainCnae = cnae;
    this.form.get('mainActivity')?.patchValue({
      cnaeId: cnae.id,
      name: cnae.name,
      code: cnae.code,
      specialProject: cnae.specialProject,
      pgv: cnae.pgv,
      residenceSupervisor: cnae.residenceSupervisor,
      landAreaMin: cnae.landAreaMin,
      landAreaMax: cnae.landAreaMax,
      buildingAreaMin: cnae.buildingAreaMin,
      buildingAreaMax: cnae.buildingAreaMax,
      environmentalRisk: cnae.environmentalRisk,
      healthRisk: cnae.healthRisk,
      finalRisk: cnae.finalRisk,
    });
    this.getCnaeQuestions(cnae.code);
    this.showSuggestions = false;
    this.searchValue = '';
    this.feasibilityRisks.push({ name: 'main', risk: cnae.finalRisk });

    this.getFinals(this.feasibilityRisks);
  }

  getForm(): void {
    const form = this.feasibilityForm.get('activity');
    if (form) {
      this.form = form as FormGroup;

      this.secondarySelectedCnaes = this.getFormValue(
        'secondaryActivitiesCarriedOut',
      );

      this.notSecondarySelectedCnaes = this.getFormValue(
        'secondaryActivitiesNotCarriedOut',
      );

      this.mainCnae = this.mainCnaes.find(
        (cnae) => cnae.id === this.getFormValue('mainActivity.cnaeId'),
      );

      this.cnaeQuestions = this.getFormValue('mainActivity.questions');
      this.secondaryCnaeQuestions = this.getFormValue(
        'secondaryActivitiesCarriedOut',
      ).map((cnae: any) => cnae.questions);
    }
  }

  createForm(): void {
    this.form = this.formBuilder.group({
      mainActivity: new FormGroup({
        cnaeId: new FormControl('', Validators.required),
        name: new FormControl('', Validators.required),
        code: new FormControl('', Validators.required),
        specialProject: new FormControl(false),
        pgv: new FormControl(false),
        environmentalRisk: new FormControl(0, Validators.required),
        healthRisk: new FormControl(0, Validators.required),
        finalRisk: new FormControl(0, Validators.required),
        residenceSupervisor: new FormControl(false),
        landAreaMin: new FormControl(0, Validators.required),
        landAreaMax: new FormControl(0, Validators.required),
        buildingAreaMin: new FormControl(0, Validators.required),
        buildingAreaMax: new FormControl(0, Validators.required),
        questions: new FormArray([
          new FormGroup({
            id: new FormControl('', Validators.required),
            description: new FormControl('', Validators.required),
            riskIfYes: new FormControl(0, Validators.required),
            riskIfNo: new FormControl(0, Validators.required),
            answer: new FormControl('', Validators.required),
          }),
        ]),
      }),
      secondaryActivitiesCarriedOut: new FormArray([]),
      secondaryActivitiesNotCarriedOut: new FormArray([]),
    });
  }

  changeSearchField(ev: any): void {
    this.searchValue = ev.target.value;
    this.searchValueSubject.next(this.searchValue);
  }

  changeSecondarySearchField(ev: any): void {
    this.searchSecondaryValue = ev.target.value;
  }

  changeNotSecondarySearchField(ev: any): void {
    this.searchNotSecondaryValue = ev.target.value;
  }

  get questionsFormArray(): FormArray {
    return (this.form.get('mainActivity') as FormGroup).get(
      'questions',
    ) as FormArray;
  }

  get secondaryActivitiesFormArray(): FormArray {
    return this.form.get('secondaryActivitiesCarriedOut') as FormArray;
  }

  get notSecondaryActivitiesFormArray(): FormArray {
    return this.form.get('secondaryActivitiesNotCarriedOut') as FormArray;
  }

  secondaryQuestionsFormArray(i: number): FormArray {
    const secondaryActivitiesGroup = (
      this.form.get('secondaryActivitiesCarriedOut') as FormArray
    ).at(i) as FormGroup;

    const questionsArray = secondaryActivitiesGroup.get(
      'questions',
    ) as FormArray;

    questionsArray.value.forEach((control: QuestionOnCnae) => {
      const exist = this.secondaryCnaeQuestionsFormArray.value.findIndex(
        (q: QuestionOnCnae) => q.id === control.id,
      );

      if (exist < 0 && control.id) {
        this.secondaryCnaeQuestionsFormArray.push(
          this.formBuilder.group({
            id: [control.id, Validators.required],
            description: [control.description, Validators.required],
            answer: [control.answer, Validators.required],
            riskIfYes: [control.riskIfYes, Validators.required],
            riskIfNo: [control.riskIfNo, Validators.required],
          }),
        );
      }
    });

    return this.secondaryCnaeQuestionsFormArray;
  }

  secondaryFindIndex(cnae: CnaeResponse): number {
    return this.secondarySelectedCnaes.findIndex((c) => c.id === cnae.id);
  }

  getCnaeQuestions(cnaeCode: number): void {
    this.loadingQuestions = true;

    if (cnaeCode)
      this.cnaeService.getQuestionsByCnaeCode(cnaeCode).subscribe({
        next: (questions) => {
          this.cnaeQuestions = questions.map((q) => {
            return { ...q, answer: '' };
          });

          const questionsArray = (
            this.form.get('mainActivity') as FormGroup
          ).get('questions') as FormArray;
          questionsArray.clear();

          this.cnaeQuestions.forEach((question) => {
            questionsArray.push(
              this.formBuilder.group({
                id: [question.id, Validators.required],
                description: [question.description, Validators.required],
                answer: [question.answer, Validators.required],
                riskIfYes: [question.riskIfYes, Validators.required],
                riskIfNo: [question.riskIfNo, Validators.required],
              }),
            );
          });

          this.loadingQuestions = false;
        },
        error: (error) => {
          console.log(error);
          this.loadingQuestions = false;
        },
      });
  }

  handleSearchCnae(
    keyword: string,
    page: number,
    limit: number,
    isSearch: boolean,
  ): void {
    if (isSearch) {
      this.loadingMainCnae = true;
      this.cnaeService.getCnaes({ page, limit, keyword }).subscribe({
        next: (cnaes) => {
          this.suggestMainCnaes = cnaes;
          this.showSuggestions = cnaes.length > 0;
          this.loadingMainCnae = false;
        },
        error: (error) => {
          console.log(error);
          this.suggestMainCnaes = [];
          this.showSuggestions = false;
          this.loadingMainCnae = false;
        },
      });
    } else {
      this.loadingMainCnae = true;
      this.cnaeService.getCnaes({ page, limit, keyword }).subscribe({
        next: (cnaes) => {
          this.mainCnaes = cnaes;
          this.loadingMainCnae = false;
        },
        error: (error) => {
          console.log(error);
          this.loadingMainCnae = false;
          this.mainCnaes = [];
        },
      });
    }
  }

  handleSearchSecondaryCnaes(
    keyword: string,
    page: number,
    limit: number,
  ): void {
    this.loadingSecondaryCnaes = true;
    this.cnaeService.getCnaes({ page, limit, keyword }).subscribe({
      next: (cnaes) => {
        this.loadingSecondaryCnaes = false;

        this.secondaryCnaes = cnaes;
      },
      error: (error) => {
        console.log(error);
        this.loadingSecondaryCnaes = false;
      },
    });
  }

  handleSearchNotSecondaryCnaes(
    keyword: string,
    page: number,
    limit: number,
  ): void {
    this.loadingSecondaryNotCnaes = true;
    this.cnaeService.getCnaes({ page, limit, keyword }).subscribe({
      next: (cnaes) => {
        this.loadingSecondaryNotCnaes = false;

        this.notSecondaryCnaes = cnaes;
      },
      error: (error) => {
        console.log(error);
        this.loadingSecondaryNotCnaes = false;
      },
    });
  }

  getFinalRiskOnQuestions(arr: FormArray): number {
    let m = 0;

    arr.value.forEach((q: QuestionOnCnae) => {
      const answerValue =
        q.answer === 'no' ? Number(q.riskIfNo) : Number(q.riskIfYes);
      if (!isNaN(answerValue)) {
        m = Math.max(m, answerValue);
      }
    });

    return m;
  }

  getFinalRiskOnSecondaryQuestions(arr: QuestionOnCnae[]): number {
    let m = 0;

    arr.forEach((q: QuestionOnCnae) => {
      const answerValue =
        q.answer === 'no' ? Number(q.riskIfNo) : Number(q.riskIfYes);
      if (!isNaN(answerValue)) {
        m = Math.max(m, answerValue);
      }
    });

    return m;
  }

  handleSelectAnswer(ev: any, index: number): void {
    const questionsArray = (this.form.get('mainActivity') as FormGroup).get(
      'questions',
    ) as FormArray;

    questionsArray.at(index).patchValue({
      answer: ev.target.value,
    });

    const exist = this.feasibilityRisks.find((r) => r.name === 'questions');

    if (exist) {
      this.feasibilityRisks = this.feasibilityRisks.map((r) => {
        if (r.name === 'questions') {
          return {
            ...r,
            risk: this.getFinalRiskOnQuestions(questionsArray),
          };
        }

        return r;
      });
    } else
      this.feasibilityRisks.push({
        name: 'questions',
        risk: this.getFinalRiskOnQuestions(questionsArray),
      });

    this.getFinals(this.feasibilityRisks);
  }

  updateQuestionsRisk(): void {
    const questionsArray = (this.form.get('mainActivity') as FormGroup).get(
      'questions',
    ) as FormArray;

    this.feasibilityRisks = this.feasibilityRisks.map((r) => {
      if (r.name === 'questions') {
        return {
          ...r,
          risk: this.getFinalRiskOnQuestions(questionsArray),
        };
      }

      return r;
    });

    this.getFinals(this.feasibilityRisks);
  }

  updateSecondaryQuestionsRisk(): void {
    const risk = this.getFinalNumberRisk(
      this.feasibilitySecondaryQuestionsRisks,
    );

    this.feasibilityRisks = this.feasibilityRisks.map((r) => {
      if (r.name === 'secondary_questions') {
        return {
          ...r,
          risk: risk,
        };
      }

      return r;
    });

    this.getFinals(this.feasibilityRisks);
  }

  handleSelectAnswerSecondary(ev: any, question: QuestionOnCnae): void {
    const secondaryActivities = this.form.get(
      'secondaryActivitiesCarriedOut',
    ) as FormArray;

    const cnaeIndex = secondaryActivities.value.findIndex((c: CnaeResponse) =>
      c.questions.find((q) => q.id === question.id),
    );

    if (cnaeIndex >= 0) {
      this.secondaryCnaeQuestions = this.secondaryCnaeQuestions.map((q) => {
        if (q.id === question.id) {
          return {
            ...q,
            answer: ev.target.value,
          };
        }

        return q;
      });

      const questions = secondaryActivities
        .at(cnaeIndex)
        .get('questions') as FormArray;
      questions.clear();

      this.secondaryCnaeQuestions.forEach((question) => {
        questions.push(
          this.formBuilder.group({
            id: [question.id, Validators.required],
            description: [question.description, Validators.required],
            answer: [question.answer, Validators.required],
            riskIfYes: [question.riskIfYes, Validators.required],
            riskIfNo: [question.riskIfNo, Validators.required],
          }),
        );

        this.feasibilitySecondaryQuestionsRisks =
          this.feasibilitySecondaryQuestionsRisks.map((r) => {
            if (r.name === `secondary_question_for_${question.cnaeCode}`) {
              return {
                ...r,
                risk: question.answer
                  ? question.answer === 'yes'
                    ? question.riskIfYes
                    : question.riskIfNo
                  : 0,
              };
            }

            return r;
          });
      });

      const risk = this.getFinalNumberRisk(
        this.feasibilitySecondaryQuestionsRisks,
      );

      const exist = this.feasibilityRisks.find(
        (r) => r.name === 'secondary_questions',
      );

      if (exist) {
        this.feasibilityRisks = this.feasibilityRisks.map((r) => {
          if (r.name === 'secondary_questions') {
            return {
              ...r,
              risk: risk,
            };
          }

          return r;
        });
      } else
        this.feasibilityRisks.push({
          name: 'secondary_questions',
          risk: risk,
        });

      this.getFinals(this.feasibilityRisks);
    }
  }

  private addCnaeOnForm(
    cnae: CnaeResponse,
    type: 'secondaryActivitiesCarriedOut' | 'secondaryActivitiesNotCarriedOut',
  ): void {
    const formArray = this.form.get(type) as FormArray;
    formArray.push(
      this.formBuilder.group({
        code: [cnae.code],
        cnaeId: [cnae.id],
        finalRisk: [cnae.finalRisk],
        name: [cnae.name],
        environmentalRisk: [cnae.environmentalRisk],
        healthRisk: [cnae.healthRisk],
        pgv: [cnae.pgv],
        specialProject: [cnae.specialProject],
        residenceSupervisor: [cnae.residenceSupervisor],
        landAreaMin: [cnae.landAreaMin],
        landAreaMax: [cnae.landAreaMax],
        buildingAreaMin: [cnae.buildingAreaMin],
        buildingAreaMax: [cnae.buildingAreaMax],
        questions: new FormArray([
          this.formBuilder.group({
            id: [''],
            description: [''],
            riskIfYes: [0],
            riskIfNo: [0],
            answer: [''],
          }),
        ]),
      }),
    );
  }

  private removeCnaeOnForm(
    cnae: CnaeResponse,
    type: 'secondaryActivitiesCarriedOut' | 'secondaryActivitiesNotCarriedOut',
  ): void {
    const formArray = this.form.get(type) as FormArray;
    formArray.removeAt(
      formArray.controls.findIndex(
        (control) => control.get('code')?.value === cnae.code,
      ),
    );
  }

  handleSelectMainCnae(ev: any, cnae: CnaeResponse): void {
    this.loadingQuestions = true;

    if (this.mainCnae) {
      this.mainCnae = undefined;
      this.form.get('mainActivity')?.patchValue({
        cnaeId: '',
        name: '',
        code: 0,
        specialProject: false,
        pgv: false,
        residenceSupervisor: false,
        landAreaMin: 0,
        landAreaMax: 0,
        buildingAreaMin: 0,
        buildingAreaMax: 0,
        environmentalRisk: 0,
        healthRisk: 0,
        finalRisk: 0,
      });
      this.cnaeQuestions = [];
    }

    if (ev.target.checked) {
      this.mainCnae = cnae;
      this.form.get('mainActivity')?.patchValue({
        cnaeId: cnae.id,
        name: cnae.name,
        code: cnae.code,
        specialProject: cnae.specialProject,
        pgv: cnae.pgv,
        residenceSupervisor: cnae.residenceSupervisor,
        landAreaMin: cnae.landAreaMin,
        landAreaMax: cnae.landAreaMax,
        buildingAreaMin: cnae.buildingAreaMin,
        buildingAreaMax: cnae.buildingAreaMax,
        environmentalRisk: cnae.environmentalRisk,
        healthRisk: cnae.healthRisk,
        finalRisk: cnae.finalRisk,
      });
      this.getCnaeQuestions(cnae.code);
    } else {
      this.mainCnae = undefined;
      this.form.get('mainActivity')?.patchValue({
        cnaeId: '',
        name: '',
        code: 0,
        specialProject: false,
        pgv: false,
        residenceSupervisor: false,
        landAreaMin: 0,
        landAreaMax: 0,
        buildingAreaMin: 0,
        buildingAreaMax: 0,
        environmentalRisk: 0,
        healthRisk: 0,
        finalRisk: 0,
      });
      this.cnaeQuestions = [];
    }

    this.getFinals(this.feasibilityRisks);
  }

  handleSelectSecondaryCnae(ev: any, cnae: CnaeResponse): void {
    this.loadingSecondaryQuestions = true;

    if (ev.target.checked) {
      if (
        !this.secondarySelectedCnaes.some(
          (selectedCnae) => selectedCnae.code === cnae.code,
        )
      ) {
        this.secondarySelectedCnaes.push(cnae);
        this.addCnaeOnForm(cnae, 'secondaryActivitiesCarriedOut');

        this.feasibilityRisks.push({
          name: 'secondary',
          risk: this.calculateRisk(this.secondarySelectedCnaes),
        });
      }

      this.loadingSecondarySelect = true;
      this.cnaeService.getQuestionsByCnaeCode(cnae.code).subscribe({
        next: (questions) => {
          this.secondaryCnaeQuestions = this.secondaryCnaeQuestions.filter(
            (q) => q.cnaeCode !== cnae.code,
          );

          this.secondaryCnaeQuestions.push(
            ...questions.map((q) => ({
              ...q,
              answer: '',
              cnaeCode: cnae.code,
            })),
          );

          this.updateQuestionsArray(cnae.code);

          this.loadingSecondaryQuestions = false;
          this.loadingSecondarySelect = false;

          this.feasibilitySecondaryQuestionsRisks.push({
            name: `secondary_question_for_${cnae.code}`,
            risk: 0,
          });

          this.updateSecondaryQuestionsRisk();
        },
        error: (error) => {
          console.log(error);
          this.loadingSecondaryQuestions = false;
          this.loadingSecondarySelect = false;
        },
      });
    } else {
      this.secondarySelectedCnaes = this.secondarySelectedCnaes.filter(
        (c) => c.code !== cnae.code,
      );
      this.updateQuestionsArray(cnae.code);
      this.removeCnaeOnForm(cnae, 'secondaryActivitiesCarriedOut');

      this.feasibilityRisks = this.feasibilityRisks.map((r) => {
        if (r.name === 'secondary') {
          return {
            ...r,
            risk: this.calculateRisk(this.secondarySelectedCnaes),
          };
        }

        return r;
      });

      this.feasibilitySecondaryQuestionsRisks =
        this.feasibilitySecondaryQuestionsRisks.filter(
          (r) => r.name !== `secondary_question_for_${cnae.code}`,
        );

      this.updateSecondaryQuestionsRisk();

      this.secondaryCnaeQuestions = this.secondaryCnaeQuestions.filter(
        (q) => q.cnaeCode !== cnae.code,
      );

      this.loadingSecondaryQuestions = false;
      this.loadingSecondarySelect = false;
    }

    this.getFinals(this.feasibilityRisks);
  }

  handleSelectNotSecondaryCnae(ev: any, cnae: CnaeResponse): void {
    if (ev.target.checked) {
      if (
        !this.notSecondarySelectedCnaes.some(
          (selectedCnae) => selectedCnae.code === cnae.code,
        )
      ) {
        this.notSecondarySelectedCnaes.push(cnae);
        this.addCnaeOnForm(cnae, 'secondaryActivitiesNotCarriedOut');
      }
    } else {
      this.notSecondarySelectedCnaes = this.notSecondarySelectedCnaes.filter(
        (c) => c.code !== cnae.code,
      );
      this.removeCnaeOnForm(cnae, 'secondaryActivitiesNotCarriedOut');
    }
  }

  private updateQuestionsArray(code: number): void {
    const secondaryActivities = this.form.get(
      'secondaryActivitiesCarriedOut',
    ) as FormArray;

    const index: number = secondaryActivities.value.findIndex(
      (cnae: CnaeResponse) => cnae.code === code,
    );

    if (index >= 0) {
      const cnae = secondaryActivities.at(index) as FormGroup;
      const questionsFromArray = this.secondaryCnaeQuestions.find(
        (q) => q.cnaeCode === code,
      );
      const questionsArray = cnae.get('questions') as FormArray;
      questionsArray.clear();

      if (questionsFromArray && questionsFromArray.cnaeCode) {
        questionsArray.push(
          this.formBuilder.group({
            id: [questionsFromArray.id],
            description: [questionsFromArray.description],
            answer: [questionsFromArray.answer],
            riskIfYes: [questionsFromArray.riskIfYes],
            riskIfNo: [questionsFromArray.riskIfNo],
          }),
        );
      }
    }
  }

  handlePageChange(key: string, scope: string): void {
    if (scope === 'secondary') {
      if (key === 'next') {
        this.secondaryPage = this.secondaryPage + 1;
      } else if (this.secondaryPage > 1) {
        this.secondaryPage = this.secondaryPage - 1;
      }

      this.handleSearchSecondaryCnaes(
        this.searchSecondaryValue,
        this.secondaryPage,
        this.secondaryLimit,
      );
    } else {
      if (key === 'next') {
        this.notSecondaryPage = this.notSecondaryPage + 1;
      } else if (this.notSecondaryPage > 1) {
        this.notSecondaryPage = this.notSecondaryPage - 1;
      }

      this.handleSearchNotSecondaryCnaes(
        this.searchNotSecondaryValue,
        this.notSecondaryPage,
        this.notSecondaryLimit,
      );
    }
  }

  handleLimitChange(event: any, key: string, scope: string): void {
    if (scope === 'secondary') {
      if (key === 'limit') this.secondaryLimit = Number(event.target.value);

      this.handleSearchSecondaryCnaes(
        this.searchSecondaryValue,
        this.secondaryPage,
        this.secondaryLimit,
      );
    } else {
      if (key === 'limit') this.notSecondaryLimit = Number(event.target.value);

      this.handleSearchNotSecondaryCnaes(
        this.searchNotSecondaryValue,
        this.notSecondaryPage,
        this.notSecondaryLimit,
      );
    }
  }

  getMainSelected(cnae: CnaeResponse): boolean {
    return (
      this.mainCnae?.code === cnae.code ||
      this.getFormValue('mainActivity.cnaeId') === cnae.id
    );
  }

  getSelected(cnae: CnaeResponse): boolean {
    return (
      this.secondarySelectedCnaes.findIndex((c) => c.code === cnae.code) >= 0 ||
      this.getFormValue('secondaryActivitiesCarriedOut').findIndex(
        (c: any) => c.code === cnae.code,
      ) >= 0
    );
  }

  getSelectedNotSecondary(cnae: CnaeResponse): boolean {
    return (
      this.notSecondarySelectedCnaes.findIndex((c) => c.code === cnae.code) >=
        0 ||
      this.getFormValue('secondaryActivitiesNotCarriedOut').findIndex(
        (c: any) => c.code === cnae.code,
      ) >= 0
    );
  }

  getQuestionRisk(question: QuestionOnCnae): number {
    if (question.answer === 'yes') return question.riskIfYes;
    if (question.answer === 'no') return question.riskIfNo;
    return 0;
  }

  onSubmit() {
    this.loading = true;
    if (this.form.valid) {
      // this.setStep.emit(6);
      const form: FeasibilityForm = this.feasibilityForm.value;
      const activityForm = this.form.value;

      const data: CreateFeasibilityDto = {
        responsibleDocument:
          form.responsible.responsible === 'yes'
            ? this.responsibleDocument.replace(/[^0-9]/g, '')
            : form.responsible.document?.replace(/[^0-9]/g, '') || '',
        responsibleInitials: form.responsible.initials || '',
        typeOfViability: form.type.typeOfViability,
        address: {
          neighborhood: form.address.neighborhood,
          zipCode: form.address.postalcode,
          number: String(form.address.number),
          street: form.address.address,
          complement: form.address.complement || '',
          latitude: form.address.latitude,
          longitude: form.address.longitude,
        },
        buildingArea: form.enterprise.buildingArea,
        landArea: form.enterprise.landArea,
        enterpriseTypology: {
          typeOfEnterprise: Number(form.type.typeOfEnterprise),
          typeOfTaxDomicile: Number(form.type.typeOfTaxDomicile),
        },
        mainActivity: {
          ...activityForm.mainActivity,
          questions: activityForm.mainActivity.questions
            .filter((q: QuestionOnCnae) => q.id)
            .map((q: QuestionOnCnae) => {
              return {
                ...q,
                questionId: q.id,
                questionAnswer: q.answer === 'yes' ? true : false,
                questionRisk: q.answer
                  ? q.answer === 'yes'
                    ? q.riskIfYes
                    : q.riskIfNo
                  : null,
              };
            }),
        },
        secondaryActivitiesCarriedOut:
          activityForm.secondaryActivitiesCarriedOut.map(
            (cnae: CnaeResponseFeasibility) => {
              return {
                ...cnae,
                questions: cnae.questions
                  .filter((q: QuestionOnCnae) => q.id)
                  .map((q) => {
                    return {
                      ...q,
                      questionId: q.id,
                      questionAnswer: q.answer === 'yes' ? true : false,
                      questionRisk: q.answer
                        ? q.answer === 'yes'
                          ? q.riskIfYes
                          : q.riskIfNo
                        : null,
                    };
                  }),
              };
            },
          ),
        secondaryActivitiesNotCarriedOut:
          activityForm.secondaryActivitiesNotCarriedOut.map(
            (cnae: CnaeResponseFeasibility) => {
              return {
                ...cnae,
                questions: cnae.questions
                  .filter((q: QuestionOnCnae) => q.id)
                  .map((q) => {
                    return {
                      ...q,
                      questionId: q.id,
                      questionAnswer: q.answer === 'yes' ? true : false,
                      questionRisk: q.answer
                        ? q.answer === 'yes'
                          ? q.riskIfYes
                          : q.riskIfNo
                        : null,
                    };
                  }),
              };
            },
          ),
        finalRiskEnterprise: this.feasibilityFinalRisk,
      };

      this.feasibilityService.createFeasibility(data).subscribe({
        next: (response) => {
          this.setStep.emit(6);
          if (response.statusCode && response.statusCode !== 200) {
            if (response.error === 'requer análise') {
              this.emitFeasibilityResponse.emit(response.error);
              this.resultEvent.emit(false);
            } else if (
              response.error &&
              response.error?.indexOf('projeto especial') >= 0
            ) {
              this.emitFeasibilityResponse.emit(response.error);
              this.resultEvent.emit(false);
            } else if (response.error === 'aprovado') {
              this.resultEvent.emit(true);
            } else this.resultEvent.emit(false);
          } else this.resultEvent.emit(true);

          if (response.id) this.emitFeasibilityId.emit(response.id);
          else this.emitFeasibilityId.emit(response.feasibilityId);

          this.loading = false;
        },
        error: (error) => {
          this.loading = false;
          this.setStep.emit(6);
          this.resultEvent.emit(false);
          console.log(error);
        },
      });
    } else {
      this.loading = false;
      this.form.markAllAsTouched();
    }
  }

  getRisk(r: number | undefined): string {
    if (r !== undefined && r !== null) {
      switch (r) {
        case 0:
          return 'Requer Informações';
        case 1:
          return 'Risco I';
        case 2:
          return 'Risco II';
        case 3:
          return 'Risco III';
        default:
          return 'Competência Estadual';
      }
    } else return '-';
  }

  getFinalRisk(re: number, rh: number): number {
    if (re === 4 || rh === 4) return 4;
    else if (re === 0 || rh === 0) return 0;
    else if (re && rh && re > rh) return re;
    else if (rh) return rh;
    return re;
  }

  calculateRisk(cnae: CnaeResponse[]): number {
    let risk = 0;
    cnae.forEach((c) => {
      if (c.finalRisk) {
        risk = Math.max(risk, c.finalRisk);
      }
    });

    return risk;
  }

  getFinals(numbers: FeasibilityRisks[]): void {
    if (numbers.length === 0) {
      this.feasibilityFinalRisk = 0;
    }

    const frequencyMap: { [key: number]: number } = {};
    // let mostFrequentNumber = numbers[0].risk;
    let highestNumber = numbers[0].risk;
    let maxFrequency = 1;

    for (const item of numbers) {
      const number = item.risk;

      // Atualiza o maior número
      if (number > highestNumber) {
        highestNumber = number;
      }

      // Atualiza a frequência de cada número
      if (frequencyMap[number]) {
        frequencyMap[number]++;
      } else {
        frequencyMap[number] = 1;
      }

      // Atualiza o número mais frequente
      if (frequencyMap[number] > maxFrequency) {
        maxFrequency = frequencyMap[number];
        // mostFrequentNumber = number;
      }
    }

    // Verifica se há um único número mais frequente
    const frequencyValues = Object.values(frequencyMap);
    const isUniqueMostFrequent =
      frequencyValues.filter((freq) => freq === maxFrequency).length === 1;

    this.feasibilityFinalRisk = isUniqueMostFrequent
      ? highestNumber
      : highestNumber;
  }

  getFinalNumberRisk(numbers: FeasibilityRisks[]): number {
    if (numbers.length === 0) {
      return 0;
    }

    const frequencyMap: { [key: number]: number } = {};
    // let mostFrequentNumber = numbers[0].risk;
    let highestNumber = numbers[0].risk;
    let maxFrequency = 1;

    for (const item of numbers) {
      const number = item.risk;

      // Atualiza o maior número
      if (number > highestNumber) {
        highestNumber = number;
      }

      // Atualiza a frequência de cada número
      if (frequencyMap[number]) {
        frequencyMap[number]++;
      } else {
        frequencyMap[number] = 1;
      }

      // Atualiza o número mais frequente
      if (frequencyMap[number] > maxFrequency) {
        maxFrequency = frequencyMap[number];
        // mostFrequentNumber = number;
      }
    }

    // Verifica se há um único número mais frequente
    const frequencyValues = Object.values(frequencyMap);
    const isUniqueMostFrequent =
      frequencyValues.filter((freq) => freq === maxFrequency).length === 1;

    return isUniqueMostFrequent ? highestNumber : highestNumber;
  }

  getFinalRiskByName(name: string): number {
    const risk = this.feasibilityRisks.find((r) => r.name === name);
    if (risk) return risk.risk;
    return 0;
  }

  formControl(control: string): AbstractControl | null {
    return this.form.get(control);
  }

  getFormValue(control: string): string | number | any {
    return this.formControl(control)?.value || '';
  }

  ngOnDestroy(): void {
    if (this.searchValueSubscription) {
      this.searchValueSubscription.unsubscribe();
    }
  }
}
