import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { AngularEditorModule } from '@kolkov/angular-editor';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, AngularEditorModule],
  template: '<router-outlet></router-outlet>',
  providers: [],
})
export class AppComponent {
  title = 'Hublic';
}
