<!-- Modal Justificativa -->
<div
  *ngIf="showJustification"
  class="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50"
>
  <div
    class="relative top-20 mx-auto p-4 border w-[50%] max-h-[80%] overflow-y-auto max-w-screen-lg shadow-lg rounded bg-white flex flex-col justify-start items-start"
  >
    <h3 class="text-lg font-medium">Justificativa</h3>
    <p class="text-sm text-gray-700">
      Abaixo está a justificativa da reanálise enviada pelo Coordenador/Gerente.
    </p>
    <div
      class="w-full bg-slate-100 p-4 rounded-md border border-solid border-slate-700 mt-4"
    >
      {{ data.coordinatorJustification || "-" }}
    </div>
    <div class="w-full flex flex-row justify-end items-center mt-6">
      <button
        type="button"
        class="btn btn-primary text-sm"
        (click)="handleShowJustification()"
      >
        Continuar
      </button>
    </div>
  </div>
</div>
<!-- Fim do Modal Justificativa -->

<div class="card">
  <h1 class="font-medium text-2xl mb-4">Histórico de atividade</h1>

  <div
    *ngFor="let history of data.historyStatus; let index = index"
    [ngClass]="
      role === 'analyst' ||
      role === 'server' ||
      role === 'coordinator-manager' ||
      role === 'mayor-secretary'
        ? history.status === 'DEFAULT' || history.status === 'PEN_RESPONSE'
          ? 'w-full flex flex-row justify-start items-center'
          : 'w-full flex flex-row justify-end items-center'
        : !history.currentEditor || history.currentEditor !== userDocument
          ? 'w-full flex flex-row justify-start items-center'
          : 'w-full flex flex-row justify-end items-center'
    "
  >
    <div
      *ngIf="
        role === 'analyst' ||
        role === 'server' ||
        role === 'coordinator-manager' ||
        role === 'mayor-secretary'
          ? history.status === 'DEFAULT' || history.status === 'PEN_RESPONSE'
          : role === 'user'
            ? getStatusByRole(history.status)
            : !history.currentEditor || history.currentEditor !== userDocument
      "
      class="w-1/2 flex flex-col justify-start items-end"
    >
      <div class="w-2/3 flex flex-row justify-center items-center relative">
        <div
          [ngClass]="
            'w-full mr-8 rounded-md bg-gray-100 border-l-4 border-solid ' +
            getStatusColor(history.status || 'ARCHIVED', false, true) +
            ' p-4 mb-4'
          "
        >
          <div class="w-full flex flex-row items-center">
            <mat-icon
              [ngClass]="
                getStatusColor(history.status || 'ARCHIVED') +
                ' material-icons-outlined text-4xl w-auto h-auto'
              "
              aria-hidden="true"
              >{{ getStatusIcon(history.status || "ARCHIVED") }}</mat-icon
            >
            <div class="w-full flex flex-col justify-start items-start ml-2">
              <p class="text-sm w-full text-right">
                {{
                  history.status === "DEFAULT" ||
                  history.status === "PEN_RESPONSE"
                    ? "Cidadão, " + history.currentEditorName
                    : "Prefeitura"
                }}
              </p>
              <h1
                [ngClass]="
                  getStatusColor(history.status || 'ARCHIVED') +
                  ' font-medium text-xl'
                "
              >
                {{ getStatus(history.status || "ARCHIVED") }}
              </h1>
              <p class="text-sm text-gray-600">
                {{ getDateString(history.createdAt) }}
              </p>
              <div
                *ngIf="
                  (role !== 'user' && history.status === 'DEFAULT') ||
                  (role === 'user' && history.status === 'PEN_RESPONSE') ||
                  (role === 'analyst' && history.status === 'RETURNED') ||
                  (role === 'analyst' && history.status === 'PEN_RESPONSE') ||
                  (role === 'coordinator-manager' &&
                    history.status === 'RETURNED') ||
                  (role === 'mayor-secretary' &&
                    history.status === 'SECOND_REVIEW')
                "
              >
                <button
                  [ngClass]="
                    loading || loadingDownloadFile
                      ? 'btn btn-primary text-sm disabled:bg-opacity-65 flex flex-row justify-center items-center mt-2 opacity-40 cursor-not-allowed pointer-events-none'
                      : 'btn btn-primary text-sm disabled:bg-opacity-65 flex flex-row justify-center items-center mt-2'
                  "
                  (click)="
                    history.status === 'RETURNED' ||
                    history.status === 'SECOND_REVIEW'
                      ? handleShowJustification()
                      : downloadJustification(history.status)
                  "
                >
                  <mat-icon
                    *ngIf="!loadingDownloadFile"
                    [fontIcon]="
                      history.status === 'RETURNED' ||
                      history.status === 'SECOND_REVIEW'
                        ? 'visibility'
                        : 'download'
                    "
                  ></mat-icon>
                  <mat-icon
                    *ngIf="loadingDownloadFile"
                    class="animate-spin text-white mr-2"
                    aria-hidden="false"
                    fontIcon="refresh"
                  ></mat-icon>
                  <span class="ml-2">{{
                    loadingDownloadFile
                      ? "Preparando..."
                      : history.status === "RETURNED" ||
                          history.status === "SECOND_REVIEW"
                        ? "Visualizar"
                        : "Baixar"
                  }}</span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          [ngClass]="
            'w-6 h-6 ' +
            getStatusColor(history.status || 'ARCHIVED', true) +
            ' absolute rounded-full status-history-dot-left border-4 border-solid border-white z-10 mb-4'
          "
        ></div>
        <div
          *ngIf="index > 0"
          class="w-[3px] h-1/2 bg-blue-300 absolute left-full top-0 translate-x-[-50%]"
        ></div>
        <div
          *ngIf="
            index !== (data.historyStatus && data.historyStatus.length - 1)
          "
          class="w-[3px] h-1/2 bg-blue-300 absolute left-full bottom-0 translate-x-[-50%]"
        ></div>
      </div>
    </div>

    <div
      *ngIf="
        role === 'analyst' ||
        role === 'server' ||
        role === 'coordinator-manager' ||
        role === 'mayor-secretary'
          ? history.status !== 'DEFAULT' && history.status !== 'PEN_RESPONSE'
          : history.status !== 'ANSWERED' &&
            history.currentEditor &&
            history.currentEditor === userDocument
      "
      class="w-1/2 flex flex-col justify-start items-start"
    >
      <div class="w-2/3 flex flex-row justify-center items-center relative">
        <div
          [ngClass]="
            'w-full ml-8 rounded-md bg-gray-100 border-l-4 border-solid ' +
            getStatusColor(history.status || 'ARCHIVED', false, true) +
            ' p-4 mb-4'
          "
        >
          <div class="w-full flex flex-row items-center">
            <mat-icon
              [ngClass]="
                getStatusColor(history.status || 'ARCHIVED') +
                ' material-icons-outlined text-4xl w-auto h-auto'
              "
              aria-hidden="true"
              >{{ getStatusIcon(history.status || "ARCHIVED") }}</mat-icon
            >
            <div class="w-full flex flex-col justify-start items-start ml-2">
              <p class="text-sm w-full text-right capitalize">
                {{
                  userDocument === history.currentEditor
                    ? "Você, " + userName
                    : history.currentEditorName
                      ? "Prefeitura, " + history.currentEditorName
                      : "Prefeitura"
                }}
              </p>
              <h1
                [ngClass]="
                  getStatusColor(history.status || 'ARCHIVED') +
                  ' font-medium text-xl'
                "
              >
                {{ getStatus(history.status || "ARCHIVED") }}
              </h1>
              <p class="text-sm text-gray-600">
                {{ getDateString(history.createdAt) }}
              </p>
              <div
                *ngIf="
                  (history.status === 'DEFAULT' && role === 'user') ||
                  (history.status === 'PEN_RESPONSE' && role === 'user') ||
                  (history.status === 'ANSWERED' && role === 'analyst') ||
                  (history.status === 'ANALYSIS' && role === 'analyst') ||
                  (history.status === 'REANALYSIS' && role === 'analyst') ||
                  (role === 'analyst' && history.status === 'RETURNED') ||
                  (role === 'coordinator-manager' &&
                    history.status === 'RETURNED') ||
                  (role === 'mayor-secretary' &&
                    history.status === 'SECOND_REVIEW')
                "
              >
                <button
                  [ngClass]="
                    loading || loadingDownloadFile
                      ? 'btn btn-primary text-sm disabled:bg-opacity-65 flex flex-row justify-center items-center mt-2 opacity-40 cursor-not-allowed pointer-events-none'
                      : 'btn btn-primary text-sm disabled:bg-opacity-65 flex flex-row justify-center items-center mt-2'
                  "
                  (click)="
                    history.status === 'RETURNED' ||
                    history.status === 'SECOND_REVIEW'
                      ? handleShowJustification()
                      : downloadJustification(history.status)
                  "
                >
                  <mat-icon
                    *ngIf="!loadingDownloadFile"
                    [fontIcon]="
                      history.status === 'RETURNED' ||
                      history.status === 'SECOND_REVIEW'
                        ? 'visibility'
                        : 'download'
                    "
                  ></mat-icon>
                  <mat-icon
                    *ngIf="loadingDownloadFile"
                    class="animate-spin text-white mr-2"
                    aria-hidden="false"
                    fontIcon="refresh"
                  ></mat-icon>
                  <span class="ml-2">{{
                    loadingDownloadFile
                      ? "Preparando..."
                      : history.status === "RETURNED" ||
                          history.status === "SECOND_REVIEW"
                        ? "Visualizar"
                        : "Baixar"
                  }}</span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          [ngClass]="
            'w-6 h-6 ' +
            getStatusColor(history.status || 'ARCHIVED', true) +
            ' absolute rounded-full status-history-dot-right border-4 border-solid border-white z-10 mb-4'
          "
        ></div>
        <div
          *ngIf="index > 0"
          class="w-[3px] h-1/2 bg-blue-300 absolute left-0 top-0 translate-x-[-50%]"
        ></div>
        <div
          *ngIf="
            index !== (data.historyStatus && data.historyStatus.length - 1)
          "
          class="w-[3px] h-1/2 bg-blue-300 absolute left-0 bottom-0 translate-x-[-50%]"
        ></div>
      </div>
    </div>
  </div>
</div>
