/* eslint-disable @angular-eslint/use-lifecycle-interface */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, inject } from '@angular/core';
import { TableComponent } from '../../components/table/table.component';
import { FilterBoxComponent } from '../../components/filter-box/filter-box.component';
import { Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { MacrozoneService } from '../../services/macrozone.service';
import { Macrozones, MacrozonesResponse } from '../../models/macrozone.models';
import { catchError, tap, throwError } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppMacrozoneState } from '../../states/macrozone.state';
import { ActionMacrozonesSuccess } from '../../actions/macrozone.actions';
import { HttpErrorResponse } from '@angular/common/http';
import { AlertService } from '../../services/alert.service';
import { AlertComponent } from '../../components/alert/alert.component';
import { EyeIconComponent } from '../../icons/edit-zone/eye-icon/eye-icon.component';
import { EditIconComponent } from '../../icons/cnaes/edit-icon/edit-icon.component';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { matSync } from '@ng-icons/material-icons/baseline';
import { MatIcon } from '@angular/material/icon';
import {
  BreadcrumbsComponent,
  IRoute,
} from '../../components/breadcrumbs/breadcrumbs.component';

@Component({
  selector: 'app-layers',
  standalone: true,
  imports: [
    TableComponent,
    FilterBoxComponent,
    CommonModule,
    AlertComponent,
    EyeIconComponent,
    EditIconComponent,
    NgIconComponent,
    MatIcon,
    BreadcrumbsComponent,
  ],
  providers: [
    provideIcons({
      matSync,
    }),
  ],
  templateUrl: './layers.component.html',
  styleUrl: './layers.component.scss',
})
export class LayersComponent {
  routes: IRoute[] = [
    {
      label: 'Macrozonas',
      link: {
        route: `/macrozonas`,
      },
    },
  ];

  public isEditing: boolean = false;
  macrozoneService = inject(MacrozoneService);
  private store = inject(Store<AppMacrozoneState>);
  public macrozones: Macrozones[] = [];
  loading: boolean = true;

  constructor(private router: Router, private alertService: AlertService) {}

  handleEditing = () => (this.isEditing = !this.isEditing);

  handleUpdateMacrozones(): void {
    this.macrozoneService
      .updateMacrozones({ macrozones: this.macrozones })
      .subscribe({
        next: () => {
          this.alertService.add({
            id: 'create-zone-success',
            type: 'success',
            message: 'Sua nova Zona foi criada com sucesso!',
            autoClose: true,
            top: 5,
            right: 1,
          });
        },
        error: (e: HttpErrorResponse) => {
          console.error(e),
            this.alertService.add({
              id: 'create-zone-error',
              type: 'error',
              message:
                'Houve um erro na criação da Zona, verifique os campos, se o erro persistir contate o Suporte.',
              top: 5,
              right: 1,
            });
        },
      });
  }

  getZoneName = (name: string) => {
    switch (name) {
      case 'environmental':
        return 'Ambiental';
      case 'special':
        return 'Especial';
      case 'urban':
        return 'Urbana';
      default:
        return 'Rural';
    }
  };

  toEdit(d: Macrozones): void {
    this.router.navigate([
      `macrozonas/zonas/${
        d?.layer?.toLowerCase().replace('macrozona ', '') || ''
      }/${d.id}`,
    ]);
  }

  toView(r: string, d: any): void {
    this.router.navigate([
      `macrozonas/zonas/${d.layer.toLowerCase().replace('macrozona ', '')}/${
        d.id
      }`,
    ]);
  }

  getHirarchy(h: number): string {
    switch (h) {
      case 1:
        return 'Nível 1 - Baixo';
      case 2:
        return 'Nível 2 - Médio';
      default:
        return 'Nível 3 - Alto';
    }
  }

  data: Macrozones[] = [];

  ngOnInit(): void {
    this.macrozoneService
      .getMacrozones()
      .pipe(
        tap((response: MacrozonesResponse) => {
          this.macrozones = response.macrozones;
          this.store.dispatch(ActionMacrozonesSuccess({ response: response }));
          this.data = response.macrozones.map((macrozone) => {
            return {
              ...macrozone,
              layer: `Macrozona ${this.getZoneName(macrozone.name)}`,
              hierarchy: macrozone.hierarchy,
              restrictive: true,
            };
          });
          this.loading = false;
        }),
        catchError((error) => {
          console.error('Error fetching macrozones:', error);
          return throwError(() => error);
        })
      )
      .subscribe();
  }

  public newLayer = () => {
    this.router.navigate(['/new-layer']);
  };
}
