/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @angular-eslint/use-lifecycle-interface */
import { CommonModule, Location } from '@angular/common';
import { Component, HostListener, inject } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import {
  matArrowBackIos,
  matArrowForwardIos,
  matSync,
} from '@ng-icons/material-icons/baseline';
import { ZoneService } from '../../services/zone.service';
import {
  Observable,
  Subject,
  catchError,
  debounceTime,
  filter,
  tap,
  throwError,
} from 'rxjs';
import { SingleZoneResponse } from '../../models/zone.models';
import { CnaeFilter, CnaeResponse } from '../../models/cnae.models';
import { CnaeService } from '../../services/cnaes.service';
import { Store } from '@ngrx/store';
import { Error, RulesProps } from '../../models/rules.models';
import { AppRuleState } from '../../states/rules.state';
import { ActionRuleSuccess } from '../../actions/rules.actions';
import { RuleService } from '../../services/rules.service';
import { AlertService } from '../../services/alert.service';
import { ModalService } from '../../services/modal.service';
import { CreateZoneFeature } from '../../models/macrozone.models';
import { MatIcon } from '@angular/material/icon';
import {
  BreadcrumbsComponent,
  IRoute,
} from '../../components/breadcrumbs/breadcrumbs.component';
import { MacrozoneService } from '../../services/macrozone.service';
import { OrientationProps } from '../../models/orientation.model';

@Component({
  selector: 'app-new-rule',
  standalone: true,
  imports: [CommonModule, NgIconComponent, MatIcon, BreadcrumbsComponent],
  providers: [
    provideIcons({
      matSync,
      matArrowBackIos,
      matArrowForwardIos,
    }),
  ],
  templateUrl: './new-rule.component.html',
  styleUrl: './new-rule.component.scss',
})
export class NewRuleComponent {
  loading: boolean = false;
  loadingCnaes: boolean = true;
  macrozoneService = inject(MacrozoneService);
  zoneService = inject(ZoneService);
  cnaeService = inject(CnaeService);
  ruleService = inject(RuleService);
  private ruleStore = inject(Store<AppRuleState>);

  cnaes: CnaeResponse[] = [];
  selectedCnaes: CnaeResponse[] = [];
  allCnaesIsSelected: boolean = false;
  restrictByArea: boolean = false;

  notRisk: boolean = false;

  ruleObject: RulesProps = {} as RulesProps;
  risks: number[] = [];

  keywordSubject: Subject<string> = new Subject<string>();
  private confirmLeave: boolean = false;

  routes: IRoute[] = [
    {
      label: 'Macrozonas',
      link: { route: `/macrozonas` },
    },
    {
      label: 'Zonas',
      link: {
        route: `/`,
      },
    },
    {
      label: 'Adicionar zona',
      link: {
        route: `/`,
      },
    },
    {
      label: 'Adicionar regras',
      link: {
        route: `/`,
      },
    },
  ];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private alertService: AlertService,
    private modalService: ModalService
  ) {
    this.keywordSubject.pipe(debounceTime(1000)).subscribe(() => {
      this.filterCnae();
    });
  }

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any): void {
    $event.preventDefault();
    $event.returnValue =
      'Você tem alterações não salvas. Tem certeza de que deseja sair?';
  }

  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (this.confirmLeave) {
      return true;
    } else {
      return new Promise((resolve) => {
        this.modalService.add({
          id: 'warning-leaving',
          icon: 'warning',
          title: 'Tem certeza que deseja sair desta página?',
          description: 'As informações inseridas serão perdidas.',
          action: () => {
            this.confirmLeave = true;
            this.modalService.remove('warning-leaving');
            resolve(true);
          },
          actionLabel: 'Sair da página',
          cancelLabel: 'Cancelar',
        });
      });
    }
  }

  getZoneName = (name: string) => {
    switch (name) {
      case 'environmental':
        return 'Ambiental';
      case 'special':
        return 'Especial';
      case 'urban':
        return 'Urbana';
      default:
        return 'Rural';
    }
  };

  zoneId: string | null = null;
  zone: SingleZoneResponse = {} as SingleZoneResponse;

  public pages: number[] = [];

  page: number = 1;
  limit: number = 10;
  keyword: string = '';

  errors: Error = {} as Error;
  submitCount: number = 0;

  orientation: OrientationProps = {} as OrientationProps;

  verifyValidInfos(dto: RulesProps): void {
    if (this.risks.length > 0) {
      const { risks, ...rest } = this.errors;
      this.errors = rest;

      if (!this.notRisk) {
        if (!this.allCnaesIsSelected && this.selectedCnaes.length > 0) {
          if (this.restrictByArea) {
            if (!dto.minLandAreaNotApplied && !dto.minLandArea) {
              this.errors = {
                ...this.errors,
                minLandArea: {
                  message: 'O campo Mínima é obrigatório',
                },
              };
            } else {
              const { minLandArea, ...rest } = this.errors;
              this.errors = rest;
            }
            if (!dto.maxLandAreaNotApplied && !dto.maxLandArea)
              this.errors = {
                ...this.errors,
                maxLandArea: {
                  message: 'O campo Máxima é obrigatório',
                },
              };
            else {
              const { maxLandArea, ...rest } = this.errors;
              this.errors = rest;
            }
            if (!dto.minBuildingAreaNotApplied && !dto.minBuildingArea)
              this.errors = {
                ...this.errors,
                minBuildingArea: {
                  message: 'O campo Mínima é obrigatório',
                },
              };
            else {
              const { minBuildingArea, ...rest } = this.errors;
              this.errors = rest;
            }
            if (!dto.maxBuildingAreaNotApplied && !dto.maxBuildingArea)
              this.errors = {
                ...this.errors,
                maxBuildingArea: {
                  message: 'O campo Máxima é obrigatório',
                },
              };
            else {
              const { maxBuildingArea, ...rest } = this.errors;
              this.errors = rest;
            }
            if (
              !dto.minEstablishmentAreaNotApplied &&
              !dto.minEstablishmentArea
            )
              this.errors = {
                ...this.errors,
                minEstablishmentArea: {
                  message: 'O campo Mínima é obrigatório',
                },
              };
            else {
              const { minEstablishmentArea, ...rest } = this.errors;
              this.errors = rest;
            }
            if (
              !dto.maxEstablishmentAreaNotApplied &&
              !dto.maxEstablishmentArea
            )
              this.errors = {
                ...this.errors,
                maxEstablishmentArea: {
                  message: 'O campo Máxima é obrigatório',
                },
              };
            else {
              const { maxEstablishmentArea, ...rest } = this.errors;
              this.errors = rest;
            }
            if (!dto.minTotalLandAreaNotApplied && !dto.minTotalLandArea)
              this.errors = {
                ...this.errors,
                minTotalLandArea: {
                  message: 'O campo Mínima é obrigatório',
                },
              };
            else {
              const { minTotalLandArea, ...rest } = this.errors;
              this.errors = rest;
            }
            if (!dto.maxTotalLandAreaNotApplied && !dto.maxTotalLandArea)
              this.errors = {
                ...this.errors,
                maxTotalLandArea: {
                  message: 'O campo Máxima é obrigatório',
                },
              };
            else {
              const { maxTotalLandArea, ...rest } = this.errors;
              this.errors = rest;
            }
          } else {
            const {
              minLandArea,
              maxLandArea,
              minBuildingArea,
              maxBuildingArea,
              minEstablishmentArea,
              maxEstablishmentArea,
              minTotalLandArea,
              maxTotalLandArea,
              ...rest
            } = this.errors;
            this.errors = rest;
          }

          if (!dto.ocupationRate)
            this.errors = {
              ...this.errors,
              ocupationRate: {
                message: 'O campo Taxa de ocupação é obrigatório',
              },
            };
          else {
            const { ocupationRate, ...rest } = this.errors;
            this.errors = rest;
          }
          if (!dto.utilizationRate)
            this.errors = {
              ...this.errors,
              utilizationRate: {
                message: 'O campo Índice de aproveitamento é obrigatório',
              },
            };
          else {
            const { utilizationRate, ...rest } = this.errors;
            this.errors = rest;
          }
          if (!dto.permeabilityRate)
            this.errors = {
              ...this.errors,
              permeabilityRate: {
                message: 'O campo Taxa de permeabilidade é obrigatório',
              },
            };
          else {
            const { permeabilityRate, ...rest } = this.errors;
            this.errors = rest;
          }
          if (!dto.parcelableLandAreaNotApplied && !dto.parcelableLandArea)
            this.errors = {
              ...this.errors,
              parcelableLandArea: {
                message: 'O campo Terreno Parcelável é obrigatório',
              },
            };
          else {
            const { parcelableLandArea, ...rest } = this.errors;
            this.errors = rest;
          }
        } else if (this.allCnaesIsSelected) {
          if (this.restrictByArea) {
            if (!dto.minLandAreaNotApplied && !dto.minLandArea) {
              this.errors = {
                ...this.errors,
                minLandArea: {
                  message: 'O campo Mínima é obrigatório',
                },
              };
            } else {
              const { minLandArea, ...rest } = this.errors;
              this.errors = rest;
            }
            if (!dto.maxLandAreaNotApplied && !dto.maxLandArea)
              this.errors = {
                ...this.errors,
                maxLandArea: {
                  message: 'O campo Máxima é obrigatório',
                },
              };
            else {
              const { maxLandArea, ...rest } = this.errors;
              this.errors = rest;
            }
            if (!dto.minBuildingAreaNotApplied && !dto.minBuildingArea)
              this.errors = {
                ...this.errors,
                minBuildingArea: {
                  message: 'O campo Mínima é obrigatório',
                },
              };
            else {
              const { minBuildingArea, ...rest } = this.errors;
              this.errors = rest;
            }
            if (!dto.maxBuildingAreaNotApplied && !dto.maxBuildingArea)
              this.errors = {
                ...this.errors,
                maxBuildingArea: {
                  message: 'O campo Máxima é obrigatório',
                },
              };
            else {
              const { maxBuildingArea, ...rest } = this.errors;
              this.errors = rest;
            }
            if (
              !dto.minEstablishmentAreaNotApplied &&
              !dto.minEstablishmentArea
            )
              this.errors = {
                ...this.errors,
                minEstablishmentArea: {
                  message: 'O campo Mínima é obrigatório',
                },
              };
            else {
              const { minEstablishmentArea, ...rest } = this.errors;
              this.errors = rest;
            }
            if (
              !dto.maxEstablishmentAreaNotApplied &&
              !dto.maxEstablishmentArea
            )
              this.errors = {
                ...this.errors,
                maxEstablishmentArea: {
                  message: 'O campo Máxima é obrigatório',
                },
              };
            else {
              const { maxEstablishmentArea, ...rest } = this.errors;
              this.errors = rest;
            }
            if (!dto.minTotalLandAreaNotApplied && !dto.minTotalLandArea)
              this.errors = {
                ...this.errors,
                minTotalLandArea: {
                  message: 'O campo Mínima é obrigatório',
                },
              };
            else {
              const { minTotalLandArea, ...rest } = this.errors;
              this.errors = rest;
            }
            if (!dto.maxTotalLandAreaNotApplied && !dto.maxTotalLandArea)
              this.errors = {
                ...this.errors,
                maxTotalLandArea: {
                  message: 'O campo Máxima é obrigatório',
                },
              };
            else {
              const { maxTotalLandArea, ...rest } = this.errors;
              this.errors = rest;
            }
          } else {
            const {
              minLandArea,
              maxLandArea,
              minBuildingArea,
              maxBuildingArea,
              minEstablishmentArea,
              maxEstablishmentArea,
              minTotalLandArea,
              maxTotalLandArea,
              ...rest
            } = this.errors;
            this.errors = rest;
          }

          if (!dto.ocupationRate)
            this.errors = {
              ...this.errors,
              ocupationRate: {
                message: 'O campo Taxa de ocupação é obrigatório',
              },
            };
          else {
            const { ocupationRate, ...rest } = this.errors;
            this.errors = rest;
          }
          if (!dto.utilizationRate)
            this.errors = {
              ...this.errors,
              utilizationRate: {
                message: 'O campo Índice de aproveitamento é obrigatório',
              },
            };
          else {
            const { utilizationRate, ...rest } = this.errors;
            this.errors = rest;
          }
          if (!dto.permeabilityRate)
            this.errors = {
              ...this.errors,
              permeabilityRate: {
                message: 'O campo Taxa de permeabilidade é obrigatório',
              },
            };
          else {
            const { permeabilityRate, ...rest } = this.errors;
            this.errors = rest;
          }
          if (!dto.parcelableLandAreaNotApplied && !dto.parcelableLandArea)
            this.errors = {
              ...this.errors,
              parcelableLandArea: {
                message: 'O campo Terreno Parcelável é obrigatório',
              },
            };
          else {
            const { parcelableLandArea, ...rest } = this.errors;
            this.errors = rest;
          }
        } else {
          if (this.selectedCnaes.length === 0) {
            this.errors = {
              ...this.errors,
              selectedCnaes: {
                message: 'Selecione ao menos uma Atividade',
              },
            };
          } else {
            const { selectedCnaes, ...rest } = this.errors;
            this.errors = rest;
          }
        }
      } else {
        if (this.restrictByArea) {
          if (!dto.minLandAreaNotApplied && !dto.minLandArea) {
            this.errors = {
              ...this.errors,
              minLandArea: {
                message: 'O campo Mínima é obrigatório',
              },
            };
          } else {
            const { minLandArea, ...rest } = this.errors;
            this.errors = rest;
          }
          if (!dto.maxLandAreaNotApplied && !dto.maxLandArea)
            this.errors = {
              ...this.errors,
              maxLandArea: {
                message: 'O campo Máxima é obrigatório',
              },
            };
          else {
            const { maxLandArea, ...rest } = this.errors;
            this.errors = rest;
          }
          if (!dto.minBuildingAreaNotApplied && !dto.minBuildingArea)
            this.errors = {
              ...this.errors,
              minBuildingArea: {
                message: 'O campo Mínima é obrigatório',
              },
            };
          else {
            const { minBuildingArea, ...rest } = this.errors;
            this.errors = rest;
          }
          if (!dto.maxBuildingAreaNotApplied && !dto.maxBuildingArea)
            this.errors = {
              ...this.errors,
              maxBuildingArea: {
                message: 'O campo Máxima é obrigatório',
              },
            };
          else {
            const { maxBuildingArea, ...rest } = this.errors;
            this.errors = rest;
          }
          if (!dto.minEstablishmentAreaNotApplied && !dto.minEstablishmentArea)
            this.errors = {
              ...this.errors,
              minEstablishmentArea: {
                message: 'O campo Mínima é obrigatório',
              },
            };
          else {
            const { minEstablishmentArea, ...rest } = this.errors;
            this.errors = rest;
          }
          if (!dto.maxEstablishmentAreaNotApplied && !dto.maxEstablishmentArea)
            this.errors = {
              ...this.errors,
              maxEstablishmentArea: {
                message: 'O campo Máxima é obrigatório',
              },
            };
          else {
            const { maxEstablishmentArea, ...rest } = this.errors;
            this.errors = rest;
          }
          if (!dto.minTotalLandAreaNotApplied && !dto.minTotalLandArea)
            this.errors = {
              ...this.errors,
              minTotalLandArea: {
                message: 'O campo Mínima é obrigatório',
              },
            };
          else {
            const { minTotalLandArea, ...rest } = this.errors;
            this.errors = rest;
          }
          if (!dto.maxTotalLandAreaNotApplied && !dto.maxTotalLandArea)
            this.errors = {
              ...this.errors,
              maxTotalLandArea: {
                message: 'O campo Máxima é obrigatório',
              },
            };
          else {
            const { maxTotalLandArea, ...rest } = this.errors;
            this.errors = rest;
          }
        } else {
          const {
            minLandArea,
            maxLandArea,
            minBuildingArea,
            maxBuildingArea,
            minEstablishmentArea,
            maxEstablishmentArea,
            minTotalLandArea,
            maxTotalLandArea,
            ...rest
          } = this.errors;
          this.errors = rest;
        }

        if (!dto.ocupationRate)
          this.errors = {
            ...this.errors,
            ocupationRate: {
              message: 'O campo Taxa de ocupação é obrigatório',
            },
          };
        else {
          const { ocupationRate, ...rest } = this.errors;
          this.errors = rest;
        }
        if (!dto.utilizationRate)
          this.errors = {
            ...this.errors,
            utilizationRate: {
              message: 'O campo Índice de aproveitamento é obrigatório',
            },
          };
        else {
          const { utilizationRate, ...rest } = this.errors;
          this.errors = rest;
        }
        if (!dto.permeabilityRate)
          this.errors = {
            ...this.errors,
            permeabilityRate: {
              message: 'O campo Taxa de permeabilidade é obrigatório',
            },
          };
        else {
          const { permeabilityRate, ...rest } = this.errors;
          this.errors = rest;
        }
        if (!dto.parcelableLandAreaNotApplied && !dto.parcelableLandArea)
          this.errors = {
            ...this.errors,
            parcelableLandArea: {
              message: 'O campo Terreno Parcelável é obrigatório',
            },
          };
        else {
          const { parcelableLandArea, ...rest } = this.errors;
          this.errors = rest;
        }
      }
    } else
      this.errors = {
        risks: {
          ...this.errors,
          message: 'Selecione ao menos uma opção de Risco',
        },
      };
  }

  verifyUrbanFields(dto: RulesProps): boolean {
    if (
      !dto.backSetback ||
      !dto.frontSetback ||
      !dto.sideSetback ||
      !dto.maxBuildingHeight ||
      !dto.minLotSize ||
      !dto.maxLotSize ||
      !dto.minFrontage ||
      !dto.minDepth ||
      !dto.minBlockSize ||
      !dto.maxBlockSize
    ) {
      return false;
    } else return true;
  }

  handlePageChange(key: string): void {
    if (key === 'next') {
      this.page = this.page + 1;
    } else if (this.page > 1) {
      this.page = this.page - 1;
    }

    this.getCnaes({
      page: this.page,
      limit: this.limit,
      keyword: this.keyword,
    });
  }

  handleLimitChange(event: any, key: string): void {
    if (key === 'limit') this.limit = Number(event.target.value);

    this.getCnaes({
      page: this.page,
      limit: this.limit,
      keyword: this.keyword,
    });
  }

  handleChangeNotRisk(): void {
    this.notRisk = !this.notRisk;

    if (this.notRisk) {
      this.risks = [5];
      this.selectedCnaes = [];
      this.cnaes = this.cnaes.map((c) => {
        return {
          ...c,
          selected: false,
        };
      });

      const { risks, ...rest } = this.errors;
      this.errors = rest;
    } else {
      this.risks = [];
    }
  }

  verifiyRisk(risk: number): boolean {
    if (this.notRisk) return false;

    if (this.risks.find((r) => r === risk)) {
      return true;
    } else return false;
  }

  handleObjectValue(event: any, type: string): void {
    if (type !== 'allowAllCnaes' && type !== 'restrictByArea') {
      if (type.indexOf('NotApplied') >= 0) {
        this.ruleObject = {
          ...this.ruleObject,
          [type]: event.target.checked,
        };
      } else {
        this.ruleObject = {
          ...this.ruleObject,
          [type]: Number(event.target.value),
        };
      }
    }

    if (this.submitCount > 0) this.verifyValidInfos(this.ruleObject);
  }

  handleIsRestrict(): void {
    this.restrictByArea = !this.restrictByArea;
    this.ruleObject = {
      ...this.ruleObject,
      restrictByArea: this.restrictByArea,
    };
  }

  handleSelectedAll(): void {
    this.allCnaesIsSelected = !this.allCnaesIsSelected;

    if (this.allCnaesIsSelected) {
      this.ruleObject = {
        ...this.ruleObject,
        allowAllCnaes: true,
        allCnaesIsSelected: true,
      };

      const { selectedCnaes, ...rest } = this.errors;
      this.errors = rest;
      this.selectedCnaes = [];
    } else {
      this.ruleObject = {
        ...this.ruleObject,
        allowAllCnaes: false,
        allCnaesIsSelected: false,
      };
    }
  }

  handleAddCnae(cnae: CnaeResponse): void {
    this.selectedCnaes = [...this.selectedCnaes, cnae];
    this.cnaes = this.cnaes.map((c) => {
      if (c.id === cnae.id) {
        return {
          ...c,
          selected: true,
        };
      }

      return c;
    });

    if (this.selectedCnaes.length > 0 && !this.allCnaesIsSelected) {
      const { selectedCnaes, ...rest } = this.errors;
      this.errors = rest;
    } else {
      this.errors = {
        ...this.errors,
        selectedCnaes: {
          message: 'Selecione ao menos uma Atividade',
        },
      };
    }
  }

  handleRemoveCnae(id: string): void {
    this.selectedCnaes = this.selectedCnaes.filter((cnae) => cnae.id !== id);
    this.cnaes = this.cnaes.map((c) => {
      if (c.id === id) {
        return {
          ...c,
          selected: false,
        };
      }

      return c;
    });

    if (this.selectedCnaes.length > 0 && !this.allCnaesIsSelected) {
      const { selectedCnaes, ...rest } = this.errors;
      this.errors = rest;
    } else {
      this.errors = {
        ...this.errors,
        selectedCnaes: {
          message: 'Selecione ao menos uma Atividade',
        },
      };
    }
  }

  handleChangeFilter(event: any): void {
    this.keyword = event.target.value;
    this.keywordSubject.next(this.keyword);
  }

  filterCnae(): void {
    this.loadingCnaes = true;
    this.getCnaes({
      page: this.page,
      limit: this.limit,
      keyword: this.keyword,
    });
  }

  getRisk(r: number | undefined): string {
    if (r !== undefined && r !== null) {
      switch (r) {
        case 0:
          return 'Requer Informações';
        case 1:
          return 'Risco I';
        case 2:
          return 'Risco II';
        case 3:
          return 'Risco III';
        default:
          return 'Competência Estadual';
      }
    } else return '-';
  }

  getFinalRisk(re: number, rh: number): number {
    if (re === 4 || rh === 4) return 4;
    else if (re === 0 || rh === 0) return 0;
    else if (re && rh && re > rh) return re;
    else return rh;
  }

  getZone(): void {
    if (this.zoneId)
      this.zoneService
        .getZoneById(this.zoneId)
        .pipe(
          tap((response: SingleZoneResponse) => {
            this.zone = response;

            this.routes = [
              {
                label: 'Macrozonas',
                link: { route: `/macrozonas` },
              },
              {
                label: 'Zonas',
                link: {
                  route: `/macrozonas/zonas/${this.getZoneName(
                    this.zone.macrozone.name
                  ).toLowerCase()}/${this.zone.macrozoneId}`,
                },
              },
              {
                label: 'Adicionar zona',
                link: {
                  route: `/macrozonas/zona/${this.zone.macrozoneId}/create`,
                  data: {
                    name: this.zone.name,
                    acronym: this.zone.acronym,
                    hierarchy: this.zone.hierarchy,
                    description: this.zone.description,
                  },
                },
              },
              {
                label: 'Adicionar regras',
                link: {
                  route: `/rule/create/0;name=${this.zone.name};description=${this.zone.description};hierarchy=${this.zone.hierarchy};acronym=${this.zone.acronym};macrozone={${this.zone.macrozoneId}`,
                },
              },
            ];
          }),
          catchError((error) => {
            console.error('Error fetching single zone:', error);
            return throwError(() => error);
          })
        )
        .subscribe();
  }

  getCnaes({ page, limit, keyword, risk }: CnaeFilter): void {
    this.loadingCnaes = true;
    this.cnaeService
      .getCnaes({ page, limit, keyword, risk })
      .pipe(
        tap((response) => {
          const rule = history.state.rule;

          this.cnaes = response.map((cnae) => {
            if (rule?.notAllowedCnaes?.indexOf(cnae.code) >= 0) {
              return {
                ...cnae,
                selected: true,
              };
            } else
              return {
                ...cnae,
                selected: false,
              };
          });
          this.loadingCnaes = false;
        }),
        catchError((error) => {
          console.error('Error fetching cnaes:', error);
          return throwError(() => error);
        })
      )
      .subscribe();
  }

  generateAcronym(input: string): string {
    if (!input) {
      return '';
    }

    const words = input.split(' ');
    let acronym = '';
    let suffix = '';

    for (const word of words) {
      if (this.isAlphabetic(word) && acronym.length < 3) {
        acronym += word.charAt(0).toUpperCase();
      } else if (!this.isAlphabetic(word)) {
        suffix += word;
      }
    }

    return `${acronym}${suffix}`.trim();
  }

  private isAlphabetic(word: string): boolean {
    return /^[a-zA-Z]+$/.test(word);
  }

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      const name = params['name'];
      const acronym = params['acronym'];
      const description = params['description'];
      const hierarchy = params['hierarchy'];
      const macrozone = params['macrozone'];

      if (name && acronym && hierarchy) {
        const data = history.state.data;
        const rule = history.state.rule;
        const orientation = history.state.orientation;

        if (orientation) this.orientation = orientation;

        this.zone = {
          ...this.zone,
          name,
          acronym,
          description: description || '',
          hierarchy,
          macrozoneId: macrozone,
          features: data,
        };

        this.ruleObject = rule || ({} as RulesProps);
        this.risks = rule?.allowedRisks || [];
        this.allCnaesIsSelected = rule?.allCnaesIsSelected || false;
        this.cnaes = [
          ...this.cnaes.map((c) => {
            if (rule?.notAllowedCnaes?.indexOf(c.code) >= 0) {
              return {
                ...c,
                selected: true,
              };
            }

            return c;
          }),
        ];
        this.restrictByArea = rule?.restrictByArea || false;

        this.macrozoneService
          .getZonesById({ id: macrozone }, { page: 1, limit: 1 })
          .pipe(
            tap((response) => {
              this.zone.macrozone = response.zones[0].macrozone;

              this.routes = [
                {
                  label: 'Macrozonas',
                  link: { route: `/macrozonas` },
                },
                {
                  label: 'Zonas',
                  link: {
                    route: `/macrozonas/zonas/${this.getZoneName(
                      this.zone.macrozone.name
                    ).toLowerCase()}/${this.zone.macrozoneId}`,
                  },
                },
                {
                  label: 'Adicionar zona',
                  link: {
                    route: `/macrozonas/zona/${this.zone.macrozoneId}/create`,
                    data: {
                      name: this.zone.name,
                      acronym: this.zone.acronym,
                      hierarchy: this.zone.hierarchy,
                      description: this.zone.description,
                    },
                  },
                },
                {
                  label: 'Adicionar regras',
                  link: {
                    route: `/rule/create/0;name=${this.zone.name};description=${this.zone.description};hierarchy=${this.zone.hierarchy};acronym=${this.zone.acronym};macrozone={${this.zone.macrozoneId}`,
                  },
                },
              ];
            }),
            catchError((error) => {
              console.error('Error fetching macrozones:', error);
              return throwError(() => error);
            })
          )
          .subscribe();
      }
    });

    this.route.paramMap.subscribe((params) => {
      this.zoneId = params.get('zoneId');
      if (this.zoneId) {
        if (this.zoneId.length > 6) this.getZone();
        this.getCnaes({
          page: this.page,
          limit: this.limit,
          keyword: this.keyword,
        });
      } else {
        this.router.navigate([`/`]);
      }

      this.router.events
        .pipe(filter((event) => event instanceof NavigationEnd))
        .subscribe(() => {
          window.scrollTo(0, 0);
        });
    });

    this.ruleStore
      .select((state) => state.rule)
      .subscribe((ruleState) => {
        if (ruleState.rule) {
          this.ruleObject = ruleState.rule;
          this.risks = ruleState.rule.allowedRisks;

          if (this.risks.find((r) => r === 5 && this.risks.length === 1)) {
            this.notRisk = true;
          }
        }
      });
  }

  handleSelectRisk(event: any, risk: number): void {
    if (!Array.isArray(this.risks)) {
      this.risks = [];
    }

    if (event.target.checked) {
      if (!this.risks.includes(risk)) {
        this.risks = [...this.risks, risk];
        this.getCnaes({
          page: this.page,
          limit: this.limit,
          keyword: this.keyword,
          risk: this.risks,
        });
      }
    } else {
      this.risks = this.risks.filter((r) => r !== risk);
      this.getCnaes({
        page: this.page,
        limit: this.limit,
        keyword: this.keyword,
        risk: this.risks,
      });
    }

    if (this.submitCount > 0 && this.risks.length > 0) {
      const { risks, ...rest } = this.errors;
      this.errors = rest;
    }
  }

  handleSave(): void {
    this.confirmLeave = true;
    this.ruleStore.dispatch(ActionRuleSuccess({ response: this.ruleObject }));
    this.ruleService
      .createRule(this.ruleObject)
      .pipe(
        tap((response) => {
          this.ruleStore.dispatch(ActionRuleSuccess({ response: response }));
          this.loading = false;
          this.modalService.add({
            id: 'create-rule-success',
            icon: 'success',
            title: 'Sua regra foi adicionada com sucesso.',
            actionLabel: 'Continuar',
          });
          this.goBack();
        }),
        catchError((error) => {
          console.error('Error creating question:', error);
          this.alertService.add({
            id: 'create-rule-error',
            type: 'error',
            message: 'Tivemos um problema ao adicionar sua regra.',
            top: 5,
            right: 1,
          });
          this.loading = false;
          return throwError(() => error);
        })
      )
      .subscribe();
  }

  onSaveInformations(): void {
    this.submitCount = this.submitCount + 1;

    this.ruleObject = {
      ...this.ruleObject,
      allowedRisks: this.risks,
      notAllowedCnaes: this.selectedCnaes.map((cnae) => cnae.code) as number[],
      restrictByArea: this.restrictByArea,
    };

    this.ruleStore.dispatch(ActionRuleSuccess({ response: this.ruleObject }));

    if (this.zone.id) {
      this.loading = true;
      this.ruleObject = {
        ...this.ruleObject,
        zoneId: this.zone.id,
      };

      this.verifyValidInfos(this.ruleObject);

      const keys = Object.keys(this.errors);

      if (keys.length === 0) {
        if (this.verifyUrbanFields(this.ruleObject)) {
          this.confirmLeave = true;
          this.handleSave();
        } else {
          this.modalService.add({
            id: 'warning-fields',
            icon: 'warning',
            description:
              'Os campos não preenchidos serão considerados 0 (zero) para os cálculos de parâmetros urbanísticos.',
            title: 'Existem campos não preenchidos',
            action: () => {
              this.modalService.remove('warning-fields');
              this.handleSave();
            },
            actionLabel: 'Continuar e salvar',
            cancelLabel: 'Voltar',
            actionCancel: () => {
              this.modalService.remove('warning-fields');
              this.loading = false;
            },
          });
        }
      } else {
        this.modalService.add({
          id: 'warning-required-fields',
          icon: 'warning',
          title: 'Atenção!',
          description: 'Preencha todos os campos obrigatórios.',
          actionLabel: 'Continuar',
        });
        this.loading = false;
      }
    } else {
      this.verifyValidInfos(this.ruleObject);

      const keys = Object.keys(this.errors);

      if (keys.length === 0) {
        if (this.verifyUrbanFields(this.ruleObject)) {
          this.confirmLeave = true;
          this.goBack();
          this.modalService.add({
            id: 'success-add',
            icon: 'success',
            title: 'Sua regra foi adicionada com sucesso',
            actionLabel: 'Continuar',
          });
        } else {
          this.confirmLeave = true;
          this.modalService.add({
            id: 'warning-fields',
            icon: 'warning',
            description:
              'Os campos não preenchidos serão considerados 0 (zero) para os cálculos de parâmetros urbanísticos.',
            title: 'Existem campos não preenchidos',
            action: () => {
              this.goBack();
              this.modalService.add({
                id: 'success-add',
                icon: 'success',
                title: 'Sua regra foi adicionada com sucesso',
                actionLabel: 'Continuar',
              });
              this.modalService.remove('warning-fields');
            },
            actionLabel: 'Continuar e salvar',
            cancelLabel: 'Voltar',
            actionCancel: () => {
              this.modalService.remove('warning-fields');
              this.loading = false;
            },
          });
        }
      } else {
        this.modalService.add({
          id: 'warning-required-fields',
          icon: 'warning',
          title: 'Atenção!',
          description: 'Preencha todos os campos obrigatórios.',
          actionLabel: 'Continuar',
        });
        this.loading = false;
      }
    }
  }

  public goBack = () => {
    this.router.navigate(
      [
        `/macrozonas/zona/${this.zone.macrozoneId}/create`,
        {
          name: this.zone.name,
          acronym: this.zone.acronym,
          hierarchy: this.zone.hierarchy,
          description: this.zone.description,
        },
      ],
      {
        state: {
          data: this.zone.features,
          rule: this.ruleObject,
          orientation: this.orientation,
        },
      }
    );
  };

  public save = () => {
    this.router.navigate(['/regras']);
  };
}
