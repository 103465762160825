import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { StepperProps } from '../../models/stepper.models';
import { CheckedIconComponent } from '../../icons/cnaes/checked-icon/checked-icon.component';

@Component({
  selector: 'app-stepper',
  standalone: true,
  imports: [CommonModule, CheckedIconComponent],
  templateUrl: './stepper.component.html',
  styleUrl: './stepper.component.scss',
})
export class StepperComponent {
  @Input() step: number = 0;
  @Input() steps: StepperProps[] = [];
  @Output() stepChange: EventEmitter<number> = new EventEmitter<number>();

  success: string = '#408E2B';
  current: string = '#4959E2';
  default: string = '#83A0F6';

  constructor() {}

  onChangeStep(step: number): void {
    this.stepChange.emit(step);
    if (this.step === step) return;
    const exist = this.steps.find((s) => s.step === step);
    if (!exist) return;
    this.step = step;
  }
}
