<app-alert></app-alert>
<section class="h-full bg-blue-950 dark:bg-gray-900">
  <div
    class="flex flex-col items-center justify-center px-6 py-8 mx-auto mb-20 lg:py-0"
  >
    <a
      href="#"
      class="flex items-center mb-6 text-2xl font-semibold text-white"
    >
      <div class="rounded-full mr-5 bg-white">
        <img class="w-16 h-16" src="assets/images/HUBlic.png" alt="logo" />
      </div>
      Hublic
    </a>
    <div
      class="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-4xl xl:p-0 dark:bg-gray-800 dark:border-gray-700"
    >
      <div class="p-6 space-y-4 md:space-y-6 sm:p-8">
        <h1
          class="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white"
        >
          Cadastro de Funcionário
        </h1>
        <form
          class="space-y-4 md:space-y-6"
          [formGroup]="userForm"
          (ngSubmit)="onSubmit()"
        >
          <div>
            <h2
              class="text-md font-medium leading-tight tracking-tight text-gray-900 md:text-xl dark:text-white mb-2"
            >
              Informações Pessoais
            </h2>
            <div class="flex flex-col">
              <div class="grid grid-cols-1 md:grid-cols-4 gap-2.5 mb-2">
                <div>
                  <label
                    for="fullname"
                    class="mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >Nome completo</label
                  >
                  <input
                    formControlName="fullname"
                    type="text"
                    id="fullname"
                    class="input-text"
                    placeholder="John Doe"
                    required=""
                  />

                  <div
                    class="text-xs font-medium text-red-500 mt-1"
                    *ngIf="
                      (userForm.controls['fullname'].touched ||
                        userForm.controls['fullname'].dirty) &&
                      userForm.controls['fullname'].errors &&
                      userForm.controls['fullname'].errors['required']
                    "
                  >
                    Nome completo é obrigatório.
                  </div>
                </div>

                <div>
                  <label
                    for="cpf"
                    class="mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >CPF</label
                  >
                  <input
                    formControlName="cpf"
                    type="text"
                    id="cpf"
                    class="input-text"
                    placeholder="000.000.000-00"
                    mask="000.000.000-00"
                    [dropSpecialCharacters]="false"
                    required=""
                  />

                  <div
                    class="text-xs font-medium text-red-500 mt-1"
                    *ngIf="
                      (userForm.controls['cpf'].touched ||
                        userForm.controls['cpf'].dirty) &&
                      userForm.controls['cpf'].errors &&
                      userForm.controls['cpf'].errors['required']
                    "
                  >
                    CPF é obrigatório.
                  </div>
                  <div
                    class="text-xs font-medium text-red-500 mt-1"
                    *ngIf="
                      (userForm.controls['cpf'].touched ||
                        userForm.controls['cpf'].dirty) &&
                      userForm.controls['cpf'].errors &&
                      userForm.controls['cpf'].errors['pattern']
                    "
                  >
                    CPF com Formato invalido.
                  </div>
                </div>
                <div>
                  <label
                    for="birthdate"
                    class="mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >Data de nascimento</label
                  >
                  <input
                    formControlName="birthdate"
                    type="date"
                    id="birthdate"
                    class="input-text"
                    placeholder="00/00/0000"
                    required=""
                  />
                  <div
                    class="text-xs font-medium text-red-500 mt-1"
                    *ngIf="
                      (userForm.controls['birthdate'].touched ||
                        userForm.controls['birthdate'].dirty) &&
                      userForm.controls['birthdate'].errors &&
                      userForm.controls['birthdate'].errors['required']
                    "
                  >
                    Data de nascimento é obrigatória.
                  </div>
                </div>
                <!-- <div>
                  <label
                    for="phone"
                    class="mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >Telefone / WhatsApp</label
                  >
                  <input
                    formControlName="phone"
                    type="phone"
                    id="phone"
                    class="input-text"
                    placeholder="(00) 00000-0000"
                    mask="(00) 00000-0000"
                    [dropSpecialCharacters]="false"
                    required=""
                  />
                  <div
                    class="text-xs font-medium text-red-500 mt-1"
                    *ngIf="
                      (userForm.controls['phone'].touched ||
                        userForm.controls['phone'].dirty) &&
                      userForm.controls['phone'].errors &&
                      userForm.controls['phone'].errors['required']
                    "
                  >
                    Telefone / WhatsApp é obrigatório.
                  </div>
                  <div
                    class="text-xs font-medium text-red-500 mt-1"
                    *ngIf="
                      (userForm.controls['phone'].touched ||
                        userForm.controls['phone'].dirty) &&
                      userForm.controls['phone'].errors &&
                      userForm.controls['phone'].errors['pattern']
                    "
                  >
                    Telefone está no formato inválido: (00) 00000-0000.
                  </div>
                </div> -->
              </div>

              <h2
                class="text-md font-medium leading-tight tracking-tight text-gray-900 md:text-xl dark:text-white mb-2"
              >
                Contatos
              </h2>
              <div class="grid grid-cols-1 md:grid-cols-2 gap-2.5 mb-2">
                <div>
                  <label
                    for="email"
                    class="text-sm font-medium text-gray-900 dark:text-white"
                    >E-mail</label
                  >
                  <input
                    formControlName="email"
                    type="email"
                    id="email"
                    class="input-text"
                    placeholder="email@company.com"
                    autocomplete="new-email"
                    required=""
                  />
                  <div
                    class="text-xs font-medium text-red-500 mt-1"
                    *ngIf="
                      (userForm.controls['email'].touched ||
                        userForm.controls['email'].dirty) &&
                      userForm.controls['email'].errors &&
                      userForm.controls['email'].errors['required']
                    "
                  >
                    E-mail é obrigatório.
                  </div>
                  <div
                    class="text-xs font-medium text-red-500 mt-1"
                    *ngIf="
                      (userForm.controls['email'].touched ||
                        userForm.controls['email'].dirty) &&
                      userForm.controls['email'].errors &&
                      userForm.controls['email'].errors['email']
                    "
                  >
                    E-mail no formato inválido.
                  </div>
                </div>
              </div>
              <div formArrayName="contacts">
                <div
                  *ngFor="let contact of contacts.controls; let index = index"
                  [formGroupName]="index"
                  class="grid grid-cols-1 md:grid-cols-3 gap-2.5 mb-2"
                >
                  <div>
                    <label
                      for="type{{ index }}"
                      class="mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >Tipo de Contato</label
                    >
                    <select
                      formControlName="type"
                      [id]="'type' + index"
                      class="input-text"
                      required
                      (change)="onContactTypeChange($event, index)"
                    >
                      <option value="" disabled selected>
                        Selecione um tipo
                      </option>
                      <option
                        *ngFor="let type of contactTypes[index]"
                        [value]="
                          type === 'Celular'
                            ? 'cellphone'
                            : type === 'Celular/WhatsApp'
                              ? 'whatsapp'
                              : 'phone'
                        "
                      >
                        {{ type }}
                      </option>
                    </select>
                    <div
                      class="text-xs font-medium text-red-500 mt-1"
                      *ngIf="
                        (contact.get('type')?.touched ||
                          contact.get('type')?.dirty) &&
                        contact.get('type')?.errors &&
                        contact.get('type')?.errors?.['required']
                      "
                    >
                      Tipo de Contato é obrigatório.
                    </div>
                  </div>
                  <div>
                    <label
                      for="number{{ index }}"
                      class="mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >Número</label
                    >
                    <div
                      class="w-full flex flex-row justify-start items-center"
                    >
                      <input
                        formControlName="number"
                        type="tel"
                        [id]="'number' + index"
                        class="input-text"
                        [placeholder]="
                          contact.get('type')?.value === 'cellphone' ||
                          contact.get('type')?.value === 'whatsapp'
                            ? '(00) 00000-0000'
                            : '(00) 0000-0000'
                        "
                        [mask]="
                          contact.get('type')?.value === 'cellphone' ||
                          contact.get('type')?.value === 'whatsapp'
                            ? '(00) 00000-0000'
                            : '(00) 0000-0000'
                        "
                        [dropSpecialCharacters]="false"
                        required
                      />
                      <button
                        *ngIf="index !== 0"
                        class="text-sm text-red-500 font-medium ml-4"
                        (click)="removeContact(index)"
                      >
                        Remover
                      </button>
                    </div>
                    <div
                      class="text-xs font-medium text-red-500 mt-1"
                      *ngIf="
                        (contact.get('number')?.touched ||
                          contact.get('number')?.dirty) &&
                        contact.get('number')?.errors &&
                        contact.get('number')?.errors?.['required']
                      "
                    >
                      Telefone / WhatsApp é obrigatório.
                    </div>
                    <div
                      class="text-xs font-medium text-red-500 mt-1"
                      *ngIf="
                        (contact.get('number')?.touched ||
                          contact.get('number')?.dirty) &&
                        contact.get('number')?.errors &&
                        contact.get('number')?.errors?.['pattern']
                      "
                    >
                      Telefone está no formato inválido:
                      {{
                        contact.get("type")?.value === "cellphone" ||
                        contact.get("type")?.value === "whatsapp"
                          ? "(00) 00000-0000"
                          : "(00) 0000-0000"
                      }}.
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <button
                  type="button"
                  [ngClass]="
                    contacts.length < 3
                      ? 'py-2 px-4 bg-blue-600 rounded-md text-white text-sm mb-2 font-medium'
                      : 'py-2 px-4 bg-blue-600 rounded-md text-white text-sm mb-2 font-medium opacity-40 cursor-not-allowed'
                  "
                  (click)="addContact()"
                >
                  + Adicionar contato
                </button>
              </div>

              <h2
                class="text-md font-medium leading-tight tracking-tight text-gray-900 md:text-xl dark:text-white mb-2"
              >
                Endereço
              </h2>
              <div class="grid grid-cols-1 md:grid-cols-3 gap-2.5 mb-4">
                <div>
                  <label
                    for="street"
                    class="mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >Rua/Avenida</label
                  >
                  <input
                    formControlName="street"
                    type="text"
                    id="street"
                    class="input-text"
                    placeholder="Exemplo: Av. Paulista"
                    required=""
                  />

                  <div
                    class="text-xs font-medium text-red-500 mt-1"
                    *ngIf="
                      (userForm.controls['street'].touched ||
                        userForm.controls['street'].dirty) &&
                      userForm.controls['street'].errors &&
                      userForm.controls['street'].errors['required']
                    "
                  >
                    Rua/Avenida é obrigatório.
                  </div>
                </div>
                <div>
                  <label
                    for="complement"
                    class="mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >Complemento</label
                  >
                  <input
                    formControlName="complement"
                    type="text"
                    id="complement"
                    class="input-text"
                    placeholder="Apto, sala, andar, etc. (Opcional)"
                  />
                </div>
                <div>
                  <label
                    for="neighborhood"
                    class="mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >Bairro</label
                  >
                  <input
                    formControlName="neighborhood"
                    type="text"
                    id="neighborhood"
                    class="input-text"
                    placeholder="Exemplo: Vila Mariana"
                    required=""
                  />
                  <div
                    class="text-xs font-medium text-red-500 mt-1"
                    *ngIf="
                      (userForm.controls['neighborhood'].touched ||
                        userForm.controls['neighborhood'].dirty) &&
                      userForm.controls['neighborhood'].errors &&
                      userForm.controls['neighborhood'].errors['required']
                    "
                  >
                    Bairro é obrigatório.
                  </div>
                </div>
                <div>
                  <label
                    for="city"
                    class="mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >Cidade</label
                  >
                  <input
                    formControlName="city"
                    type="text"
                    id="city"
                    class="input-text"
                    placeholder="Exemplo: São Paulo"
                    required=""
                  />

                  <div
                    class="text-xs font-medium text-red-500 mt-1"
                    *ngIf="
                      (userForm.controls['city'].touched ||
                        userForm.controls['city'].dirty) &&
                      userForm.controls['city'].errors &&
                      userForm.controls['city'].errors['required']
                    "
                  >
                    Cidade é obrigatória.
                  </div>
                </div>
                <div>
                  <label
                    for="uf"
                    class="mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >Estado (UF)</label
                  >
                  <input
                    formControlName="state"
                    type="text"
                    id="state"
                    class="input-text"
                    placeholder="Exemplo: São Paulo"
                    required=""
                  />

                  <div
                    class="text-xs font-medium text-red-500 mt-1"
                    *ngIf="
                      (userForm.controls['state'].touched ||
                        userForm.controls['state'].dirty) &&
                      userForm.controls['state'].errors &&
                      userForm.controls['state'].errors['required']
                    "
                  >
                    Estado (UF) é obrigatório.
                  </div>
                </div>
              </div>
            </div>

            <h2
              class="text-md font-medium leading-tight tracking-tight text-gray-900 md:text-xl dark:text-white mb-2"
            >
              Informações Profissionais
            </h2>
            <div class="grid grid-cols-1 md:grid-cols-4 gap-2.5 mb-4">
              <div>
                <label
                  for="role"
                  class="mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >Função que exerce</label
                >
                <select
                  class="input-text"
                  formControlName="role"
                  id="role"
                  required=""
                >
                  <option value="" disabled selected>
                    Selecione sua função
                  </option>
                  <option value="server">Servidor</option>
                  <option value="analyst">Analista</option>
                  <option value="coordinator-manager">
                    Coordenador/Gestor
                  </option>
                  <option value="mayor-secretary">Secretário/Prefeito</option>
                </select>
                <div
                  class="text-xs font-medium text-red-500 mt-1"
                  *ngIf="
                    (userForm.controls['role'].touched ||
                      userForm.controls['role'].dirty) &&
                    userForm.controls['role'].errors &&
                    userForm.controls['role'].errors['required']
                  "
                >
                  Função é obrigatório.
                </div>
              </div>
              <div>
                <label
                  for="jobTitle"
                  class="mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >Cargo</label
                >
                <input
                  formControlName="jobTitle"
                  type="text"
                  id="jobTitle"
                  class="input-text"
                  placeholder="Ex: Analista Municipal"
                />
                <div
                  class="text-xs font-medium text-red-500 mt-1"
                  *ngIf="
                    (userForm.controls['jobTitle'].touched ||
                      userForm.controls['jobTitle'].dirty) &&
                    userForm.controls['jobTitle'].errors &&
                    userForm.controls['jobTitle'].errors['required']
                  "
                >
                  Cargo é obrigatório.
                </div>
              </div>
            </div>
            <h2
              class="text-md font-medium leading-tight tracking-tight text-gray-900 md:text-xl dark:text-white mb-2"
            >
              Acessos
            </h2>
            <div class="grid grid-cols-1 md:grid-cols-3 gap-2.5 mb-4">
              <div>
                <label
                  for="password"
                  class="text-sm font-medium text-gray-900 dark:text-white"
                  >Senha</label
                >
                <input
                  formControlName="password"
                  type="password"
                  id="password"
                  class="input-text"
                  placeholder="***************"
                  autocomplete="new-password"
                  required=""
                />
                <div
                  class="text-xs font-medium text-red-500 mt-1"
                  *ngIf="
                    (userForm.controls['password'].touched ||
                      userForm.controls['password'].dirty) &&
                    userForm.controls['password'].errors &&
                    userForm.controls['password'].errors['required']
                  "
                >
                  Senha é obrigatória.
                </div>
                <div
                  class="text-xs font-medium text-red-500 mt-1"
                  *ngIf="
                    (userForm.controls['password'].touched ||
                      userForm.controls['password'].dirty) &&
                    userForm.controls['password'].errors &&
                    userForm.controls['password'].errors['pattern']
                  "
                >
                  Necessário ter pelo menos 8 caracteres.
                </div>
              </div>
              <div>
                <label
                  for="confirm_password"
                  class="text-sm font-medium text-gray-900 dark:text-white"
                  >Confirmar senha</label
                >
                <input
                  formControlName="confirm_password"
                  type="password"
                  id="confirm-password"
                  class="input-text"
                  placeholder="***************"
                  required=""
                />
                <div
                  class="text-xs font-medium text-red-500 mt-1"
                  *ngIf="
                    (userForm.controls['confirm_password'].touched ||
                      userForm.controls['confirm_password'].dirty) &&
                    userForm.controls['confirm_password'].errors &&
                    userForm.controls['confirm_password'].errors['required']
                  "
                >
                  Confirmar senha é obrigatória.
                </div>
                <div
                  class="text-xs font-medium text-red-500 mt-1"
                  *ngIf="
                    (userForm.controls['confirm_password'].touched ||
                      userForm.controls['confirm_password'].dirty) &&
                    userForm.controls['confirm_password'].errors &&
                    userForm.controls['confirm_password'].errors['not_match']
                  "
                >
                  As senhas devem ser iguais.
                </div>
              </div>
            </div>
          </div>

          <div class="flex flex-col md:flex-row justify-end items-center">
            <button type="button" class="btn-secondary" (click)="push('/')">
              VOLTAR
            </button>
            <button
              type="submit"
              [ngClass]="loading ? 'btn opacity-40 cursor-not-allowed' : 'btn'"
            >
              {{ loading ? "ENVIANDO..." : "SOLICITAR APROVAÇÃO" }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</section>
