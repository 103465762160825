/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { CreateZonePlusDTO } from '../models/zone.models';
import { UpdateZonesDTO, ZoneResponse } from '../models/macrozone.models';

@Injectable({
  providedIn: 'root',
})
export class ZoneService {
  private httpService = inject(HttpClient);
  private url = `${environment.urlApi}/zone`;

  constructor() {}

  createZone(dto: CreateZonePlusDTO): Observable<CreateZonePlusDTO> {
    return this.httpService.post<CreateZonePlusDTO>(`${this.url}`, dto);
  }

  updateZone(id: string, dto: ZoneResponse): Observable<any> {
    return this.httpService.put(`${this.url}/${id}`, dto);
  }

  updateZones(dto: UpdateZonesDTO[]): Observable<any> {
    return this.httpService.put(`${this.url}`, dto);
  }

  getZoneById(id: string): Observable<any> {
    return this.httpService.get(`${this.url}/${id}`);
  }
}
