<div class="m-3">
  <div
    class="flex flex-col items-center justify-center px-6 py-8 mx-auto mb-20 lg:py-0"
  >
    <div
      class="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-4xl xl:p-0 dark:bg-gray-800 dark:border-gray-700"
    >
      <div class="p-6 space-y-4 md:space-y-6 sm:p-8">
        <h1
          class="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white"
        >
          Editar Informações
        </h1>
        <form
          class="space-y-4 md:space-y-6"
          [formGroup]="userForm"
          (ngSubmit)="onSubmit()"
        >
          <div>
            <h2
              class="text-md font-medium leading-tight tracking-tight text-gray-900 md:text-xl dark:text-white mb-2"
            >
              Informações Pessoais
            </h2>
            <div class="flex flex-col">
              <div class="grid grid-cols-1 md:grid-cols-4 gap-2.5 mb-2">
                <div>
                  <label
                    for="fullname"
                    class="mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >Nome completo</label
                  >
                  <input
                    formControlName="fullname"
                    type="text"
                    id="fullname"
                    class="input-text"
                    required=""
                    [disabled]="true"
                  />

                  <div
                    class="text-xs font-medium text-red-500 mt-1"
                    *ngIf="
                      (userForm.controls['fullname'].touched ||
                        userForm.controls['fullname'].dirty) &&
                      userForm.controls['fullname'].errors &&
                      userForm.controls['fullname'].errors['required']
                    "
                  >
                    Nome completo é obrigatório.
                  </div>
                </div>

                <div>
                  <label
                    for="cpf"
                    class="mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >CPF</label
                  >
                  <input
                    formControlName="cpf"
                    type="text"
                    id="cpf"
                    class="input-text"
                    mask="000.000.000-00"
                    required=""
                  />

                  <div
                    class="text-xs font-medium text-red-500 mt-1"
                    *ngIf="
                      (userForm.controls['cpf'].touched ||
                        userForm.controls['cpf'].dirty) &&
                      userForm.controls['cpf'].errors &&
                      userForm.controls['cpf'].errors['required']
                    "
                  >
                    CPF é obrigatório.
                  </div>
                  <div
                    class="text-xs font-medium text-red-500 mt-1"
                    *ngIf="
                      (userForm.controls['cpf'].touched ||
                        userForm.controls['cpf'].dirty) &&
                      userForm.controls['cpf'].errors &&
                      userForm.controls['cpf'].errors['pattern']
                    "
                  >
                    CPF com Formato invalido.
                  </div>
                </div>
                <div>
                  <label
                    for="birthdate"
                    class="mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >Data de nascimento</label
                  >
                  <input
                    formControlName="birthdate"
                    type="date"
                    id="birthdate"
                    class="input-text"
                    placeholder="00/00/0000"
                    required=""
                  />
                  <div
                    class="text-xs font-medium text-red-500 mt-1"
                    *ngIf="
                      (userForm.controls['birthdate'].touched ||
                        userForm.controls['birthdate'].dirty) &&
                      userForm.controls['birthdate'].errors &&
                      userForm.controls['birthdate'].errors['required']
                    "
                  >
                    Data de nascimento é obrigatória.
                  </div>
                </div>
                <!-- <div>
                  <label
                    for="phone"
                    class="mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >Telefone / WhatsApp</label
                  >
                  <input
                    formControlName="phone"
                   
                    type="phone"
                    id="phone"
                    class="input-text"
                    placeholder="(00) 00000-0000"
                    mask="(00) 00000-0000"
                    
                    required=""
                  />
                  <div
                    class="text-xs font-medium text-red-500 mt-1"
                    *ngIf="
                      (userForm.controls['phone'].touched ||
                        userForm.controls['phone'].dirty) &&
                      userForm.controls['phone'].errors &&
                      userForm.controls['phone'].errors['required']
                    "
                  >
                    Telefone / WhatsApp é obrigatório.
                  </div>
                  <div
                    class="text-xs font-medium text-red-500 mt-1"
                    *ngIf="
                      (userForm.controls['phone'].touched ||
                        userForm.controls['phone'].dirty) &&
                      userForm.controls['phone'].errors &&
                      userForm.controls['phone'].errors['pattern']
                    "
                  >
                    Telefone está no formato inválido: (00) 00000-0000.
                  </div>
                </div> -->
              </div>
              <div class="grid grid-cols-1 md:grid-cols-4 gap-2.5 mb-2">
                <div>
                  <label
                    for="email"
                    class="text-sm font-medium text-gray-900 dark:text-white"
                    >E-mail</label
                  >
                  <input
                    formControlName="email"
                    type="email"
                    id="email"
                    class="input-text"
                    placeholder="email@company.com"
                    required=""
                  />
                  <div
                    class="text-xs font-medium text-red-500 mt-1"
                    *ngIf="
                      (userForm.controls['email'].touched ||
                        userForm.controls['email'].dirty) &&
                      userForm.controls['email'].errors &&
                      userForm.controls['email'].errors['required']
                    "
                  >
                    E-mail é obrigatório.
                  </div>
                  <div
                    class="text-xs font-medium text-red-500 mt-1"
                    *ngIf="
                      (userForm.controls['email'].touched ||
                        userForm.controls['email'].dirty) &&
                      userForm.controls['email'].errors &&
                      userForm.controls['email'].errors['email']
                    "
                  >
                    E-mail no formato inválido.
                  </div>
                </div>
                <div>
                  <label
                    for="role"
                    class="mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >Tipo Contato</label
                  >
                  <select
                    class="input-text"
                    formControlName="role"
                    id="role"
                    required=""
                  >
                    <option value="" disabled selected>
                      Selecione um tipo
                    </option>
                    <option value="analyst">Telefone Celular</option>
                    <option value="server">Telefone Fixo</option>
                  </select>
                  <div
                    class="text-xs font-medium text-red-500 mt-1"
                    *ngIf="
                      (userForm.controls['role'].touched ||
                        userForm.controls['role'].dirty) &&
                      userForm.controls['role'].errors &&
                      userForm.controls['role'].errors['required']
                    "
                  >
                    Tipo é obrigatório
                  </div>
                </div>
                <div>
                  <label
                    for="phone"
                    class="mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >Contato</label
                  >
                  <input
                    formControlName="phone"
                    type="phone"
                    id="phone"
                    class="input-text"
                    placeholder="(00) 00000-0000"
                    mask="(00) 00000-0000"
                    required=""
                  />
                  <div
                    class="text-xs font-medium text-red-500 mt-1"
                    *ngIf="
                      (userForm.controls['phone'].touched ||
                        userForm.controls['phone'].dirty) &&
                      userForm.controls['phone'].errors &&
                      userForm.controls['phone'].errors['required']
                    "
                  >
                    Telefone / WhatsApp é obrigatório.
                  </div>
                  <div
                    class="text-xs font-medium text-red-500 mt-1"
                    *ngIf="
                      (userForm.controls['phone'].touched ||
                        userForm.controls['phone'].dirty) &&
                      userForm.controls['phone'].errors &&
                      userForm.controls['phone'].errors['pattern']
                    "
                  >
                    Telefone está no formato inválido: (00) 00000-0000.
                  </div>
                </div>
                <label class="flex items-center space-x-1 mt-4">
                  <input
                    type="checkbox"
                    class="form-checkbox h-5 w-5 text-green-600"
                    checked
                  />
                  <span class="text-gray-700 dark:text-gray-300"
                    >Possui WhatsApp</span
                  >
                </label>
              </div>

              <h2
                class="text-md font-medium leading-tight tracking-tight text-gray-900 md:text-xl dark:text-white mb-2"
              >
                Endereço
              </h2>
              <div class="grid grid-cols-1 md:grid-cols-3 gap-2.5 mb-4">
                <div>
                  <label
                    for="street"
                    class="mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >Rua/Avenida</label
                  >
                  <input
                    formControlName="street"
                    type="text"
                    id="street"
                    class="input-text"
                    placeholder="Exemplo: Av. Paulista"
                    required=""
                  />

                  <div
                    class="text-xs font-medium text-red-500 mt-1"
                    *ngIf="
                      (userForm.controls['street'].touched ||
                        userForm.controls['street'].dirty) &&
                      userForm.controls['street'].errors &&
                      userForm.controls['street'].errors['required']
                    "
                  >
                    Rua/Avenida é obrigatório.
                  </div>
                </div>
                <div>
                  <label
                    for="complement"
                    class="mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >Complemento</label
                  >
                  <input
                    formControlName="complement"
                    type="text"
                    id="complement"
                    class="input-text"
                    placeholder="Apto, sala, andar, etc. (Opcional)"
                  />
                </div>
                <div>
                  <label
                    for="neighborhood"
                    class="mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >Bairro</label
                  >
                  <input
                    formControlName="neighborhood"
                    type="text"
                    id="neighborhood"
                    class="input-text"
                    placeholder="Exemplo: Vila Mariana"
                    required=""
                  />
                  <div
                    class="text-xs font-medium text-red-500 mt-1"
                    *ngIf="
                      (userForm.controls['neighborhood'].touched ||
                        userForm.controls['neighborhood'].dirty) &&
                      userForm.controls['neighborhood'].errors &&
                      userForm.controls['neighborhood'].errors['required']
                    "
                  >
                    Bairro é obrigatório.
                  </div>
                </div>
                <div>
                  <label
                    for="city"
                    class="mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >Cidade</label
                  >
                  <input
                    formControlName="city"
                    type="text"
                    id="city"
                    class="input-text"
                    placeholder="Exemplo: São Paulo"
                    required=""
                  />

                  <div
                    class="text-xs font-medium text-red-500 mt-1"
                    *ngIf="
                      (userForm.controls['city'].touched ||
                        userForm.controls['city'].dirty) &&
                      userForm.controls['city'].errors &&
                      userForm.controls['city'].errors['required']
                    "
                  >
                    Cidade é obrigatória.
                  </div>
                </div>
                <div>
                  <label
                    for="uf"
                    class="mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >Estado (UF)</label
                  >
                  <input
                    formControlName="state"
                    type="text"
                    id="state"
                    class="input-text"
                    placeholder="Exemplo: São Paulo"
                    required=""
                  />

                  <div
                    class="text-xs font-medium text-red-500 mt-1"
                    *ngIf="
                      (userForm.controls['state'].touched ||
                        userForm.controls['state'].dirty) &&
                      userForm.controls['state'].errors &&
                      userForm.controls['state'].errors['required']
                    "
                  >
                    Estado (UF) é obrigatório.
                  </div>
                </div>
              </div>
            </div>

            <h2
              class="text-md font-medium leading-tight tracking-tight text-gray-900 md:text-xl dark:text-white mb-2"
            >
              Informações Profissionais
            </h2>
            <div class="grid grid-cols-1 md:grid-cols-3 gap-2.5 mb-4">
              <div>
                <label
                  for="role"
                  class="mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >Função que exerce</label
                >
                <select
                  class="input-text"
                  formControlName="role"
                  id="role"
                  required=""
                >
                  <option>Selecione sua função</option>
                  <option value="server" selected>{{ role }}</option>
                  <option value="analyst">Analista</option>
                  <option value="coordinator-manager">
                    Coordenador/Gestor
                  </option>
                  <option value="mayor-secretary">Secretário/Prefeito</option>
                </select>
                <div
                  class="text-xs font-medium text-red-500 mt-1"
                  *ngIf="
                    (userForm.controls['role'].touched ||
                      userForm.controls['role'].dirty) &&
                    userForm.controls['role'].errors &&
                    userForm.controls['role'].errors['required']
                  "
                >
                  Função é obrigatório.
                </div>
              </div>

              <div>
                <label
                  for="jobTitle"
                  class="mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >Cargo</label
                >
                <input
                  formControlName="jobTitle"
                  type="text"
                  id="jobTitle"
                  class="input-text"
                  required=""
                />
                <div
                  class="text-xs font-medium text-red-500 mt-1"
                  *ngIf="
                    (userForm.controls['jobTitle'].touched ||
                      userForm.controls['jobTitle'].dirty) &&
                    userForm.controls['jobTitle'].errors &&
                    userForm.controls['jobTitle'].errors['required']
                  "
                >
                  Cargo é obrigatório.
                </div>
              </div>
            </div>
          </div>

          <div class="flex flex-col md:flex-row justify-end items-center">
            <button
              type="button"
              class="btn-secondary"
              (click)="push('/servers')"
            >
              VOLTAR
            </button>
            <button type="submit" class="btn" (click)="onSubmit()">
              Salvar
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
