/* eslint-disable @angular-eslint/use-lifecycle-interface */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { StepperComponent } from '../../components/stepper/stepper.component';
import { StepperProps } from '../../models/stepper.models';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';
import { AddressFeasibilityComponent } from './views/address/address.component';
import { TypeFeasibilityComponent } from './views/type/type.component';
import { EnterpriseFeasibilityComponent } from './views/enterprise/enterprise.component';
import { ActivityFeasibilityComponent } from './views/activity/activity.component';
import { ResponsibleFeasibilityComponent } from './views/responsible/responsible.component';
import { ResultFeasibilityComponent } from './views/result/result.component';

@Component({
  selector: 'app-feasibility',
  standalone: true,
  imports: [
    CommonModule,
    StepperComponent,
    ReactiveFormsModule,
    NgxMaskDirective,
    ResponsibleFeasibilityComponent,
    TypeFeasibilityComponent,
    AddressFeasibilityComponent,
    EnterpriseFeasibilityComponent,
    ActivityFeasibilityComponent,
    ResultFeasibilityComponent,
  ],
  providers: [provideNgxMask()],
  templateUrl: './feasibility.component.html',
  styleUrl: './feasibility.component.scss',
})
export class FeasibilityComponent {
  feasibilityForm!: FormGroup;
  feasibilityId!: string;
  feasibilityResponse!: string;
  result: boolean = false;

  currentStep: number = 1;
  steps: StepperProps[] = [
    { step: 1, description: 'Responsável legal' },
    { step: 2, description: 'Tipo de viabilidade' },
    { step: 3, description: 'Endereço' },
    { step: 4, description: 'Áreas' },
    { step: 5, description: 'Atividades (CNAEs)' },
    { step: 6, description: 'Resultado' },
  ];

  constructor() {}

  ngOnInit(): void {
    this.feasibilityForm = new FormGroup({});
  }

  getValue(control: string): string {
    return this.feasibilityForm.get(control)?.value || '';
  }

  setValue(control: string, value: string): void {
    this.feasibilityForm.get(control)?.setValue(value);
  }

  toNext(): void {
    if (this.currentStep === this.steps.length) return;
    this.currentStep = this.currentStep + 1;
  }

  toPrevious(): void {
    if (this.currentStep === 1) return;
    this.currentStep = this.currentStep - 1;
  }

  onChangeStep(step: number): void {
    if (this.currentStep === step) return;
    this.currentStep = step;
  }

  onEmitForm(form: FormGroup, name: string): void {
    if (this.feasibilityForm.contains(name))
      this.feasibilityForm.removeControl(name);
    this.feasibilityForm.addControl(name, form);
  }

  onEmitResult(result: boolean): void {
    this.result = result;
  }

  onEmitFeasibilityId(id: string): void {
    this.feasibilityId = id;
  }

  onEmitFeasibilityResponse(response: string): void {
    this.feasibilityResponse = response;
  }
}
