import { Component, inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FilterBoxComponent } from '../../components/filter-box/filter-box.component';
import { TableComponent } from '../../components/table/table.component';
import { CommonModule } from '@angular/common';
import { BuildIconComponent } from '../../icons/cnaes/build-icon/build-icon.component';
import { QuestionIconComponent } from '../../icons/cnaes/question-icon/question-icon.component';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import {
  matArrowBackIos,
  matArrowForwardIos,
  matSync,
} from '@ng-icons/material-icons/baseline';
import { DeleteIconComponent } from '../../icons/cnaes/delete-icon/delete-icon.component';
import { EditIconComponent } from '../../icons/cnaes/edit-icon/edit-icon.component';
import { QuestionComponent } from '../../components/page-cnaes/questions/question.component';
import { QuestionsBankComponent } from '../../components/page-cnaes/questions-bank/questions-bank.component';
import { EyeIconComponent } from '../../icons/edit-zone/eye-icon/eye-icon.component';
import { CnaeService } from '../../services/cnaes.service';
import { catchError, tap, throwError } from 'rxjs';
import {
  CnaeFilter,
  CnaeQuestionProps,
  CnaeResponse,
  CnaeUpdateBulk,
  OrderByProps,
} from '../../models/cnae.models';
import { QuestionsService } from '../../services/questions.service';
import {
  QuestionProps,
  QuestionResponseProps,
  QuestionsUpdateBulk,
} from '../../models/questions.models';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { AlertService } from '../../services/alert.service';
import { CheckedIconComponent } from '../../icons/cnaes/checked-icon/checked-icon.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIcon } from '@angular/material/icon';
import {
  BreadcrumbsComponent,
  IRoute,
} from '../../components/breadcrumbs/breadcrumbs.component';
import { OrderByColumn } from '../../services/filters/orderby.filter';
import { MatDialog } from '@angular/material/dialog';
import { LinkedCnaesModalComponent } from '../../components/modal/linked-cnaes-modal/linked-cnaes-modal.component';
import { DeleteModalComponent, DeleteModalComponentProps } from '../../components/modal/delete-modal/delete-modal.component';
import { EditQuestionModalComponent, EditQuestionModalComponentProps } from '../../components/modal/edit-question-modal/edit-question-modal.component';

@Component({
  selector: 'app-cnaes',
  standalone: true,
  imports: [
    CommonModule,
    TableComponent,
    FilterBoxComponent,
    BuildIconComponent,
    QuestionIconComponent,
    DeleteIconComponent,
    EditIconComponent,
    NgIconComponent,
    QuestionComponent,
    QuestionsBankComponent,
    EyeIconComponent,
    CheckedIconComponent,
    ReactiveFormsModule,
    MatTooltipModule,
    MatIcon,
    BreadcrumbsComponent,
  ],
  providers: [
    provideIcons({
      matSync,
      matArrowBackIos,
      matArrowForwardIos,
    }),
  ],
  templateUrl: './cnaes.component.html',
  styleUrl: './cnaes.component.scss',
})
export class CnaesComponent implements OnInit {
  routes: IRoute[] = [
    {
      label: 'Atividades (CNAEs)',
      link: {
        route: '/cnaes',
      },
    },
  ];

  public tab: number = 0;
  public pages: number[] = [];

  pageQuestions: number = 1;
  limitQuestions: number = 10;
  keywordQuestions: string = '';

  page: number = 1;
  limit: number = 10;
  keyword: string = '';

  public loading: boolean = true;
  public loadingQuestions: boolean = false;
  public loadingQuestion: boolean = false;

  public showQuestions: boolean = false;
  public isNewQuestion: boolean = false;
  public showQuestionsBank: boolean = false;

  titleError: boolean = false;
  riskYesError: boolean = false;
  riskNoError: boolean = false;

  public questions: QuestionResponseProps[] = [];
  selectedCnae: number = 0;

  cnaeService = inject(CnaeService);
  questionService = inject(QuestionsService);

  cnaes: CnaeResponse[] = [];
  toEditData: CnaeResponse = {} as CnaeResponse;
  editedData: CnaeResponse = {} as CnaeResponse;
  existData: boolean = false;

  questionForm!: FormGroup;

  isEditingCnaes: boolean = false;
  isEditingQuestions: boolean = false;

  orderBy: OrderByProps = {} as OrderByProps;

  currentOrderBy: 'code' | 'name' | null = 'code';
  currentOrderDirection: 'ASC' | 'DESC' = 'ASC';

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private alertService: AlertService,
    public dialog: MatDialog
  ) {}

  handlePageChange(key: string, scope: string): void {
    if (scope === 'cnaes') {
      if (key === 'next') {
        this.page = this.page + 1;
      } else if (this.page > 1) {
        this.page = this.page - 1;
      }

      this.getCnaes({
        page: this.page,
        limit: this.limit,
        keyword: this.keyword,
      });
    } else {
      if (key === 'next') {
        this.pageQuestions = this.pageQuestions + 1;
      } else if (this.pageQuestions > 1) {
        this.pageQuestions = this.pageQuestions - 1;
      }

      this.getQuestions({
        page: this.pageQuestions,
        limit: this.limitQuestions,
        keyword: this.keywordQuestions,
      });
    }
  }

  handleLimitChange(event: Event, key: string, scope: string): void {
    const input = event.target as HTMLInputElement;

    if (scope === 'cnaes') {
      if (key === 'limit') this.limit = Number(input.value);

      this.getCnaes({
        page: this.page,
        limit: this.limit,
        keyword: this.keyword,
      });
    } else {
      if (key === 'limit') this.limitQuestions = Number(input.value);

      this.getQuestions({
        page: this.pageQuestions,
        limit: this.limitQuestions,
        keyword: this.keywordQuestions,
      });
    }
  }

  handleEditingCnaes(): void {
    this.isEditingCnaes = !this.isEditingCnaes;
  }

  handleEditingQuestions(): void {
    this.isEditingQuestions = !this.isEditingQuestions;
  }

  handleUpdateBulkCnaes(): void {
    this.loading = true;
    const dto: CnaeUpdateBulk = {
      cnaes: this.cnaes.map((cnae) => {
        return {
          ...cnae,
          finalRisk: this.getFinalRisk(cnae.environmentalRisk, cnae.healthRisk),
        };
      }),
    };

    this.cnaeService
      .updateCnaeBulk(dto)
      .pipe(
        tap(() => {
          this.alertService.add({
            id: 'update-bulk-cnae-success',
            type: 'success',
            message: 'Sua(s) atividade(s) foram atualizadas com sucesso!',
            top: 5,
            right: 1,
            autoClose: true,
          });
          this.loading = false;
          this.isEditingCnaes = false;
          this.getCnaes({
            page: this.page,
            limit: this.limit,
            keyword: this.keyword,
          });
        }),
        catchError((error) => {
          console.error('Error update bulk cnae:', error);
          this.alertService.add({
            id: 'update-bulk-cnae-error',
            type: 'error',
            message:
              'Tivemos um problema ao tentar atualizar sua(s) atividade(s).',
            top: 5,
            right: 1,
          });
          this.loading = false;
          return throwError(() => error);
        }),
      )
      .subscribe();
  }

  handleUpdateBulkQuestions(): void {
    this.loadingQuestion = true;
    const dto: QuestionsUpdateBulk = { questions: this.questions };

    this.questionService
      .updateQuestionsBulk(dto)
      .pipe(
        tap(() => {
          this.loadingQuestion = false;
          this.alertService.add({
            id: 'update-bulk-questions-success',
            type: 'success',
            message: 'Sua(s) perguntas(s) foram atualizadas com sucesso!',
            top: 5,
            right: 1,
            autoClose: true,
          });
          this.isEditingQuestions = false;
          this.getQuestions({
            page: this.pageQuestions,
            limit: this.limitQuestions,
            keyword: this.keywordQuestions,
          });
        }),
        catchError((error) => {
          console.error('Error update bulk questions:', error);
          this.alertService.add({
            id: 'update-bulk-questions-error',
            type: 'error',
            message:
              'Tivemos um problema ao tentar atualizar sua(s) perguntas(s).',
            top: 5,
            right: 1,
          });
          this.loadingQuestion = false;
          return throwError(() => error);
        }),
      )
      .subscribe();
  }

  handleEditCnae(i: number, key: string, ev: Event): void {
    const input = ev.target as HTMLInputElement;

    const updatedCnaes = [...this.cnaes];
    const updatedCnae = { ...updatedCnaes[i] };

    if (
      key === 'environmentalRisk' ||
      key === 'healthRisk' ||
      key === 'landAreaMin' ||
      key === 'landAreaMax' ||
      key === 'buildingAreaMin' ||
      key === 'buildingAreaMax'
    ) {
      updatedCnae[key] = Number(input.value);
    } else if (
      key === 'pgv' ||
      key === 'specialProject' ||
      key === 'residenceSupervisor'
    ) {
      if (key === 'residenceSupervisor') {
        updatedCnae['specialProject'] = false;
        updatedCnae['pgv'] = false;
      } else if (key === 'specialProject' || key === 'pgv') {
        updatedCnae['residenceSupervisor'] = false;
      }

      updatedCnae[key] = input.checked;
    } else {
      updatedCnae[key] = input.value;
    }

    updatedCnaes[i] = updatedCnae;
    this.cnaes = [...updatedCnaes];
  }

  handleEditQuestions(i: number, key: string, ev: Event): boolean {
    const input = ev.target as HTMLInputElement;

    const updatedQuestions = [...this.questions];
    const updatedQuestion = { ...updatedQuestions[i] };

    if (!input.value && Number(input.value) !== 0) return false;

    if (key === 'riskIfYes' || key === 'riskIfNo') {
      updatedQuestion[key] = Number(input.value);
    } else {
      updatedQuestion[key] = input.value;
    }

    updatedQuestions[i] = updatedQuestion;
    this.questions = [...updatedQuestions];
    return true;
  }

  getFinalRisk(re: number, rh: number): number {
    if (re === 4 || rh === 4) return 4;
    else if (re === 0 || rh === 0) return 0;
    else if (re && rh && re > rh) return re;
    else return rh;
  }

  getRisk(r: number | undefined): string {
    if (r !== undefined && r !== null) {
      switch (r) {
        case 0:
          return 'Requer Informações';
        case 1:
          return 'Risco I';
        case 2:
          return 'Risco II';
        case 3:
          return 'Risco III';
        default:
          return 'Competência Estadual';
      }
    } else return '-';
  }

  getQuestions({ page, limit, keyword }: CnaeFilter): void {
    this.loadingQuestions = true;
    this.questionService
      .getQuestions({ page, limit, keyword })
      .pipe(
        tap((response) => {
          if (this.toEditData && this.toEditData.code) {
            this.questions = response.questions.map((q) => {
              if (
                this.toEditData.questions.find((toQ) => toQ.code === q.code)
              ) {
                return {
                  ...q,
                  exists: true,
                };
              }

              return q;
            });
          } else this.questions = response.questions;
          this.loadingQuestions = false;
        }),
        catchError((error) => {
          console.error('Error fetching questions:', error);
          this.loadingQuestions = false;
          return throwError(() => error);
        }),
      )
      .subscribe();
  }

  handleCancelEditingQuestion(i: number): void {
    this.toEditData.questions = this.toEditData.questions.map((q, index) => {
      if (index === i) {
        return {
          ...q,
          isEditing: false,
        };
      }

      return q;
    });
  }

  handleEditQuestion(i: number, key: string, event: Event): void {
    const input = event.target as HTMLInputElement;

    const updatedQuestions = [...this.editedData.questions];
    const updatedQuestion = { ...updatedQuestions[i] };

    if (key != 'riskIfYes' && key != 'riskIfNo' && !input.value) {
      this.titleError = true;
    } else if (key != 'riskIfYes' && key != 'riskIfNo' && input.value) {
      this.titleError = false;
    }

    if (key === 'riskIfYes' || key === 'riskIfNo') {
      updatedQuestion[key] = Number(input.value);
    } else {
      updatedQuestion[key] = input.value;
    }

    updatedQuestions[i] = updatedQuestion;

    this.editedData = {
      ...this.editedData,
      questions: updatedQuestions,
    };
  }

  handleSave(i: number, isNew?: boolean): void {
    if (!isNew)
      this.toEditData = {
        ...this.toEditData,
        questions: this.editedData.questions.map((q, index) => {
          if (index === i) {
            if (!q.description) {
              this.titleError = true;
              return q;
            } else if (q.description) {
              this.titleError = false;
              return {
                ...q,
                isEditing: false,
              };
            } else if (!q.riskIfYes) {
              this.riskYesError = true;
              return q;
            } else if (q.riskIfYes) {
              this.riskYesError = false;
              return {
                ...q,
                isEditing: false,
              };
            } else if (!q.riskIfNo) {
              this.riskNoError = true;
              return q;
            } else {
              this.riskNoError = false;
              return {
                ...q,
                isEditing: false,
              };
            }
          }

          return q;
        }),
      };
    else
      this.toEditData = {
        ...this.toEditData,
        questions: this.toEditData.questions.map((q, index) => {
          if (index === i) {
            return {
              ...q,
              isEditing: false,
            };
          }

          return q;
        }),
      };
  }

  handleCancel(): void {
    this.toEditData.questions = this.editedData.questions.map((q) => {
      return {
        ...q,
        isEditing: false,
      };
    });
  }

  handleEditingQuestion(i: number): void {
    this.editedData = { ...this.toEditData };
    this.toEditData.questions.splice(i, 1);
  }

  handleDeleteQuestion(i: number): void {
    this.toEditData.questions = this.toEditData.questions.filter(
      (q, index) => index !== i,
    );
  }

  getCnaes({ page, limit, keyword, orderby, direction }: CnaeFilter): void {
    this.loading = true;
    this.cnaeService
      .getCnaes({ page, limit, keyword, orderby, direction })
      .pipe(
        tap((response) => {
          this.cnaes = response;
          this.existData = true;
          this.loading = false;
        }),
        catchError((error) => {
          console.error('Error fetching cnaes:', error);
          return throwError(() => error);
        }),
      )
      .subscribe();
  }

  filterCnae(f: string): void {
    this.loading = true;
    this.keyword = f;
    this.getCnaes({
      page: this.page,
      limit: this.limit,
      keyword: f,
    });
  }

  filterQuestions(f: string): void {
    this.loadingQuestions = true;
    this.keywordQuestions = f;
    this.getQuestions({
      page: this.pageQuestions,
      limit: this.limitQuestions,
      keyword: f,
    });
  }

  ngOnInit(): void {
    this.getCnaes({
      page: this.page,
      limit: this.limit,
      keyword: this.keyword,
    });

    this.getQuestions({
      page: this.pageQuestions,
      limit: this.limitQuestions,
      keyword: this.keywordQuestions,
    });

    this.questionForm = this.formBuilder.group({
      description: ['', Validators.required],
      riskIfYes: [null, Validators.required],
      riskIfNo: [null, Validators.required],
    });
  }

  handleShowQuestions = (isNew: boolean, code?: number): void => {
    this.isNewQuestion = isNew;

    if (!isNew && code) {
      this.toEditData = this.cnaes.find((c) => c.code === code) as CnaeResponse;

      if (
        this.toEditData.questions.length === 1 &&
        this.toEditData.questions[0].id === ''
      ) {
        this.toEditData.questions = [];
      }
    }

    this.showQuestions = !this.showQuestions;
  };

  handleShowQuestionsBank = (): void => {
    this.showQuestions = false;
    this.pageQuestions = 1;
    this.showQuestionsBank = !this.showQuestionsBank;
    this.questions = this.questions.map((q) => {
      if (this.toEditData.questions.find((toQ) => toQ.code === q.code)) {
        return {
          ...q,
          exists: true,
        };
      }

      return q;
    });
    this.loadingQuestions = true;
    this.keywordQuestions = '';
    this.getQuestions({
      page: 1,
      limit: 10,
      keyword: '',
    });
  };

  handleChangeTab = (tab: number): number => (this.tab = tab);

  validQuestions = (questions: QuestionResponseProps[]): boolean => {
    if (
      (questions.length === 1 && questions[0].id === '') ||
      questions.length === 0
    ) {
      return false;
    }

    return true;
  };

  handleSelectQuestion(id: string, ev: Event): void {
    const input = ev.target as HTMLInputElement;

    if (input.checked) {
      const question = this.questions.find((d) => d.id === id);

      if (question && question.code) {
        this.toEditData = {
          ...this.toEditData,
          questions: [
            ...this.toEditData.questions.filter((q) => q.id !== id),
            question,
          ],
        };
      }
    } else {
      this.toEditData = {
        ...this.toEditData,
        questions: this.toEditData.questions.filter((q) => q.id !== id),
      };
    }
  }

  handleAddSelecteds(): void {
    this.showQuestions = true;
    this.showQuestionsBank = false;

    this.pageQuestions = 1;
  }

  handleNewQuestion(): void {
    const newQuestion: QuestionProps = {
      description: '',
      isEditing: true,
    } as unknown as QuestionProps;

    this.toEditData = {
      ...this.toEditData,
      questions: [
        ...this.toEditData.questions,
        newQuestion as QuestionResponseProps,
      ],
    };

    this.editedData = this.toEditData;
  }

  handleSaveQuestions(): void {
    if (this.titleError || this.riskYesError || this.riskNoError) return;

    this.loadingQuestion = true;

    const dto: CnaeQuestionProps = {
      code: this.toEditData.code,
      questions: this.toEditData.questions.map((q) => {
        return {
          question: {
            ...q,
          },
          risk_type: 1,
        };
      }),
    };

    this.cnaeService
      .createQuestionOnCnae(dto)
      .pipe(
        tap(() => {
          this.loadingQuestion = false;
          this.showQuestions = false;
          this.toEditData = {} as CnaeResponse;

          if (this.isEditingCnaes) {
            this.handleUpdateBulkCnaes();
            this.isEditingCnaes = false;
          } else {
            this.alertService.add({
              id: 'attatch-question-success',
              type: 'success',
              message: 'Sua(s) pergunta(s) foram atribuídas com sucesso!',
              top: 5,
              right: 1,
              autoClose: true,
            });

            this.getCnaes({
              page: this.page,
              limit: this.limit,
              keyword: this.keyword,
            });
          }
        }),
        catchError((error) => {
          console.error('Error attatch question to cnae:', error);
          this.alertService.add({
            id: 'attatch-question-error',
            type: 'error',
            message: 'Tivemos um problema ao tentar atribuir sua pegunta.',
            top: 5,
            right: 1,
          });
          this.loadingQuestion = false;
          return throwError(() => error);
        }),
      )
      .subscribe();

    this.pageQuestions = 1;
    this.page = 1;
    this.limit = 10;
  }

  setOrderBy(column: OrderByColumn): void {
    const columnValue = column.orderby as 'code' | 'name' | null;
    if (this.currentOrderBy === column.orderby) {
      this.currentOrderDirection =
        this.currentOrderDirection === 'ASC' ? 'DESC' : 'ASC';
    } else {
      this.currentOrderBy = columnValue;
      this.currentOrderDirection = 'ASC';
    }

    this.getCnaes({
      page: this.page,
      limit: this.limit,
      keyword: this.keyword,
      orderby: this.currentOrderBy,
      direction: this.currentOrderDirection,
    });
  }

  getOrderClass(column: OrderByColumn): string {
    if (this.currentOrderBy !== column.orderby) {
      return '';
    } else {
      return this.currentOrderDirection === 'ASC' ? '' : 'rotate-180 mt-0';
    }
  }

  getOrderIcon(column: OrderByColumn): string {
    if (this.currentOrderBy !== column.orderby) {
      return 'unfold_more';
    } else {
      return this.currentOrderDirection === 'ASC'
        ? 'keyboard_arrow_up'
        : 'keyboard_arrow_up'; // rotate 180deg
    }
  }

  onSubmit() {
    this.loadingQuestion = true;

    if (this.questionForm.valid) {
      const question: QuestionProps[] = [
        {
          description: this.questionForm.controls['description'].value,
          riskIfYes: Number(this.questionForm.controls['riskIfYes'].value),
          riskIfNo: Number(this.questionForm.controls['riskIfNo'].value),
        },
      ];

      this.questionService
        .createQuestion(question)
        .pipe(
          tap(() => {
            this.alertService.add({
              id: 'create-question-success',
              type: 'success',
              message: 'Sua pergunta foi criada com sucesso!',
              top: 5,
              right: 1,
              autoClose: true,
            });
            this.loadingQuestion = false;
            this.showQuestions = false;
            this.getQuestions({
              page: this.pageQuestions,
              limit: this.limitQuestions,
              keyword: '',
            });
            this.questionForm.reset();
          }),
          catchError((error) => {
            console.error('Error creating question:', error);
            this.alertService.add({
              id: 'create-question-error',
              type: 'error',
              message: 'Tivemos um problema ao tentar criar sua pegunta.',
              top: 5,
              right: 1,
            });
            this.loadingQuestion = false;
            return throwError(() => error);
          }),
        )
        .subscribe();
    } else {
      this.loadingQuestion = false;
      this.questionForm.markAllAsTouched();
    }
  }

  canDelete(q: QuestionResponseProps): boolean {
    return q.cnaesQt === 0;
  }

  canEdit(q: QuestionResponseProps): boolean {
    return q.cnaesQt === 0;
  }

  openLinkedModal(q: QuestionResponseProps): void {
    const dialogRef = this.dialog.open(LinkedCnaesModalComponent, {
      data: {
        selectedQuestion: q,
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result !== undefined && result == true) {
        this.reloadInfos()
      }
    });
  }

  openDeleteModal(q: QuestionResponseProps): void {
    if (!this.canDelete(q)) return;

    const dialogRef = this.dialog.open<DeleteModalComponent, DeleteModalComponentProps>(DeleteModalComponent, {
      data: {
        title: 'Excluir Pergunta',
        content: ['Você tem certeza que deseja excluir a pergunta?', 'Essa ação não poderá ser desfeita.'],
      },
    })

    dialogRef.afterClosed().subscribe(result => {
      if (result !== undefined && result === true) {
        this.questionService.deleteQuestion(q.id).subscribe({
          next: () => {
            this.alertService.add({
              id: 'delete-question-success',
              message: 'Pergunta excluída com sucesso',
              type: 'success',
              top: 5,
              right: 1,
              autoClose: true,
            });
            this.reloadInfos()
          },
          error: (error: unknown) => {
            this.alertService.add({
              id: 'delete-question-error',
              message: 'Erro ao excluir pergunta',
              type: 'error',
              top: 5,
              right: 1,
              autoClose: true,
            });
            console.error(error);
          },
        })
      }
    });
  }

  openEditModal(q: QuestionResponseProps): void {
    if (!this.canDelete(q)) return;

    const dialogRef = this.dialog.open<EditQuestionModalComponent, EditQuestionModalComponentProps>(EditQuestionModalComponent, {
      data: {
       form:{
          id: q.id,
          description: q.description,
          riskIfYes: q.riskIfYes,
          riskIfNo: q.riskIfNo
       }
      },
    })

    dialogRef.afterClosed().subscribe(result => {
      if (result !== undefined && result == true) {
        this.reloadInfos()
      }
    });
  }

  reloadInfos(){
    this.getCnaes({
      page: this.page,
      limit: this.limit,
      keyword: this.keyword,
    });

    this.getQuestions({
      page: this.pageQuestions,
      limit: this.limitQuestions,
      keyword: this.keywordQuestions
    })
  }

}
