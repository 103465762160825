import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-viability-return',
  standalone: true,
  imports: [
    CommonModule
  ],
  templateUrl: './viability-return.component.html',
  styleUrl: './viability-return.component.scss'
})
export class ViabilityReturnComponent {

  type = 'reproved'

  constructor(private router: Router) {}

  public goBack = () => {
    this.router.navigate(['/negocios']);
  }

  changeType = () => {
    if(this.type === 'reproved') {
      this.type = 'analysis'
    } else if(this.type === 'analysis') {
      this.type = 'approved'
    } else {
      this.type = 'reproved'
    }
  }
}
