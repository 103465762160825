<div class="relative overflow-x-auto">
  <table class="w-full text-sm text-left rtl:text-right">
    <thead class="text-xs text-gray-600 capitalize border-b">
      <tr>
        <ng-container *ngFor="let col of columns">
          <th *ngIf="!col.hidden" scope="col" class="py-3">
            {{ col.label }}
          </th>
        </ng-container>
      </tr>
    </thead>
    <tbody *ngIf="!loading">
      <tr *ngFor="let d of data" class="bg-white border-b hover:bg-gray-100">
        <ng-container *ngFor="let col of columns">
          <td
            *ngIf="!col.hidden"
            class="py-4 text-left font-medium text-gray-900 whitespace-nowrap dark:text-white"
          >
            <div *ngIf="col.actions">
              <div class="flex-row">
                <button
                  (click)="col.editFunction && col.editFunction(d)"
                  *ngIf="
                    col.actions && col.actions.includes('edit') && !disableEdit
                  "
                  class="bg-blue-500 rounded-md cursor-pointer inline p-2 ml-2"
                >
                  <ng-icon color="white" name="matEdit"></ng-icon>
                </button>
                <!-- <button
                  (click)="
                    col.blockFunction
                      ? col.blockFunction(d)
                      : d.blockFunction(d)
                  "
                  *ngIf="!disableBlock && d.enabled"
                  class="bg-red-500 rounded-md cursor-pointer inline p-2 ml-2"
                >
                  <ng-icon color="white" name="matBlock"></ng-icon>
                </button> -->
                <!-- <button
                  (click)="
                    col.unlockFunction
                      ? col.unlockFunction(d)
                      : d.unlockFunction(d)
                  "
                  *ngIf="!disableBlock && !d.enabled"
                  class="bg-green-500 rounded-md cursor-pointer inline p-2 ml-2"
                >
                  <ng-icon color="white" name="matLockOpen"></ng-icon>
                </button> -->
                <!-- <div
                (click)="col.reproveFunction && col.reproveFunction(d)"
                *ngIf="col.actions && col.actions.includes('reprove')"
                class="bg-red-500 rounded-md cursor-pointer inline ml-2 p-2"
              >
                <ng-icon color="white" name="matClose"></ng-icon>
              </div> -->
              </div>
            </div>
            <!-- <div *ngIf="!col.actions && !col.customBody && !col.isSelect">
              {{
                getValue(col, d) === "" || getValue(col, d) === undefined
                  ? "---"
                  : getValue(col, d)
              }}
            </div> -->
            <div *ngIf="col.isSelect">
              <!-- <select
                [ngClass]="
                  isEditing
                    ? 'text-sm text-left font-medium text-gray-900 dark:text-white rounded'
                    : 'text-sm text-left font-medium text-gray-900 dark:text-white rounded border-slate-200 pointer-events-none cursor-not-allowed'
                "
                (change)="
                  col.handleChangeHierarchy &&
                    col.handleChangeHierarchy(d, $event)
                "
              >
                <option
                  *ngFor="let opt of col.selectOptions"
                  [value]="opt.value"
                  [selected]="opt.value === getValue(col, d)"
                >
                  {{ opt.label }}
                </option>
              </select> -->
            </div>
            <div *ngIf="col.customBody">
              <div [innerHTML]="col.customBody(d)"></div>
            </div></td
        ></ng-container>
      </tr>
    </tbody>
  </table>
  <div *ngIf="loading" class="w-full flex flex-row justify-center">
    <div class="flex p-3 flex-row items-center">
      <ng-icon class="mr-3 animate-spin" name="matSync"></ng-icon>
      Carregando...
    </div>
  </div>

  <div
    *ngIf="!loading && (!data || data.length === 0)"
    class="w-full flex flex-row justify-center"
  >
    <div class="flex p-3 flex-row items-center">
      Nenhum resultado encontrado
    </div>
  </div>

  <div *ngIf="!disablePaginate" class="flex flex-row justify-between">
    <select
      (ngModelChange)="changePerPage($event)"
      [(ngModel)]="perPage"
      class="mt-3 p-1 rounded-md"
    >
      <option value="10">10</option>
      <option value="20">20</option>
      <option value="50">50</option>
    </select>

    <nav
      class="mt-3 flex flex-row justify-end"
      aria-label="Page navigation example"
    >
      <ul class="flex items-center -space-x-px h-10 text-base">
        <li>
          <button
            (click)="previousPage()"
            class="flex cursor-pointer items-center justify-center px-4 h-10 ms-0 leading-tight text-gray-500 bg-white border border-e-0 border-gray-300 rounded-s-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
          >
            <span class="sr-only">Previous</span>
            <ng-icon name="matArrowBackIos"></ng-icon>
          </button>
        </li>
        <a
          href="#"
          (click)="changePage(page)"
          class="flex cursor-pointer items-center justify-center px-4 h-10 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
        >
          {{ page }}
        </a>
        <li>
          <button
            (click)="nextPage()"
            class="flex cursor-pointer items-center justify-center px-4 h-10 leading-tight text-gray-500 bg-white border border-gray-300 rounded-e-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
          >
            <span class="sr-only">Next</span>
            <ng-icon name="matArrowForwardIos"></ng-icon>
          </button>
        </li>
      </ul>
    </nav>
  </div>
</div>
