/* eslint-disable @angular-eslint/use-lifecycle-interface */
/* eslint-disable no-var */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  AfterViewInit,
  Component,
  ViewEncapsulation,
  inject,
} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import * as L from 'leaflet';
import 'leaflet-draw';
import { TableComponent } from '../../components/table/table.component';
import { LayersIconComponent } from '../../icons/edit-zone/layers-icon/layers-icon.component';
import { ModalComponent } from '../../components/modal/modal.component';
import { ModalService } from '../../services/modal.service';
import { CommonModule, Location } from '@angular/common';
import {
  Coordinates,
  CreateZoneFeature,
  CreateZoneInputDTO,
  Filter,
  SingleZoneResponse,
} from '../../models/zone.models';
import { v4 as uuidv4 } from 'uuid';
import { RemoveIconComponent } from '../../icons/edit-zone/remove-icon/remove-icon.component';
import { FormsModule } from '@angular/forms';
import { EyeIconComponent } from '../../icons/edit-zone/eye-icon/eye-icon.component';
import { ArrowDownIconComponent } from '../../icons/edit-zone/arrow-down-icon/arrow-down-icon.component';
import {
  BreadcrumbsComponent,
  IRoute,
} from '../../components/breadcrumbs/breadcrumbs.component';
import { ZoneService } from '../../services/zone.service';
import { MacrozoneService } from '../../services/macrozone.service';
import { catchError, filter, tap, throwError } from 'rxjs';
import {
  Macrozones,
  MacrozonesFilterResponse,
  MacrozonesResponse,
} from '../../models/macrozone.models';
import { NgIconComponent } from '@ng-icons/core';
import { HttpErrorResponse } from '@angular/common/http';
import { AlertService } from '../../services/alert.service';
import { AlertComponent } from '../../components/alert/alert.component';
import { RuleService } from '../../services/rules.service';
import { RulesProps } from '../../models/rules.models';
import { OrientationService } from '../../services/orientation.service';
import { OrientationProps } from '../../models/orientation.model';

@Component({
  selector: 'app-edit-zone',
  standalone: true,
  imports: [
    TableComponent,
    LayersIconComponent,
    RemoveIconComponent,
    EyeIconComponent,
    ArrowDownIconComponent,
    ModalComponent,
    CommonModule,
    FormsModule,
    BreadcrumbsComponent,
    NgIconComponent,
    AlertComponent,
  ],
  templateUrl: './edit-zone.component.html',
  styleUrls: ['./edit-zone.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class EditZoneComponent implements AfterViewInit {
  routes: IRoute[] = [
    {
      label: 'Macrozonas',
      link: { route: `/macrozonas` },
    },
    {
      label: 'Zonas',
      link: {
        route: `/`,
      },
    },
    {
      label: 'Editar zona',
      link: {
        route: `/`,
      },
    },
  ];

  zoneService = inject(ZoneService);
  loading: boolean = false;
  public macrozoneId: string = '';
  public zoneId: string = '';
  private macrozone: Macrozones = {} as Macrozones;
  public zone: SingleZoneResponse = {} as SingleZoneResponse;

  public polygonMap: { [key: string]: L.Polygon } = {};
  private idToCoordKeyMap: { [id: string]: string } = {};

  macrozoneService = inject(MacrozoneService);
  ruleService = inject(RuleService);
  orientationService = inject(OrientationService);

  private enviromentalPolygonStyle: L.PathOptions = {};
  private specialPolygonStyle: L.PathOptions = {};
  private urbanPolygonStyle: L.PathOptions = {};
  private ruralPolygonStyle: L.PathOptions = {};

  private dto: SingleZoneResponse = {
    features: [] as CreateZoneFeature[],
  } as SingleZoneResponse;
  rule: RulesProps = {} as RulesProps;
  orientation: OrientationProps = {} as OrientationProps;

  private map: L.Map | undefined;
  private drawnItems: L.FeatureGroup | undefined;
  public coordinates: Coordinates[] = [
    {
      id: uuidv4(),
      lat: 0,
      lon: 0,
    },
    {
      id: uuidv4(),
      lat: 0,
      lon: 0,
    },
    {
      id: uuidv4(),
      lat: 0,
      lon: 0,
    },
  ];
  public createdCoordinates: Coordinates[][] = [];

  coordinateName: string = 'N/A';

  public openCoordinates: boolean = false;

  public filters: Filter[] = [
    {
      id: uuidv4(),
      label: 'Visualizar tudo',
      open: false,
    },
  ];

  public environmental: any[] = [];
  public special: any[] = [];
  public urban: any[] = [];
  public rural: any[] = [];

  ruleError: boolean = false;
  nameError: boolean = true;
  acronymError: boolean = true;
  hierarchyError: boolean = true;

  constructor(
    private modalService: ModalService,
    private alertService: AlertService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
  ) {
    this.route.params.subscribe((params) => {
      this.macrozoneId = params['id'];
      this.zoneId = params['zoneId'];
    });

    this.getMacrozone();
    this.getZone();
    this.getMacrozonesFilter();
    this.ruleService
      .getRuleByZone(this.zoneId)
      .pipe(
        tap((response) => {
          this.rule = response;
        }),
        catchError((error) => {
          console.error('Error fetching single rule by zone:', error);
          return throwError(() => error);
        }),
      )
      .subscribe();
    this.orientationService
      .getOrientationByZoneId(this.zoneId)
      .pipe(
        tap((response) => {
          this.orientation = response;
        }),
        catchError((error) => {
          console.error('Error fetching single orientation by zone:', error);
          return throwError(() => error);
        }),
      )
      .subscribe();
  }

  public activeMarker: {
    lat: number;
    lng: number;
    insidePolygon: boolean;
    address: string[];
    rules: string[];
  } = {
    lat: 0,
    lng: 0,
    insidePolygon: false,
    address: [],
    rules: [],
  };
  private drawControl: L.Control.Draw | undefined;
  private highlightStyle: L.PathOptions = {};
  public colorType = '#ff5757';
  public colors = [
    { name: 'Vermelho', color: '#ff0000' },
    { name: 'Verde', color: '#00ff00' },
    { name: 'Azul', color: '#0000ff' },
    { name: 'Amarelo', color: '#ffff00' },
    { name: 'Rosa', color: '#ff00ff' },
    { name: 'Ciano', color: '#00ffff' },
    { name: 'Laranja', color: '#ff8000' },
    { name: 'Roxo', color: '#8000ff' },
    { name: 'Marrom', color: '#800000' },
    { name: 'Cinza', color: '#808080' },
  ];

  ngOnInit() {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        window.scrollTo(0, 0);
      });
  }

  handleOpenFilter(id: string) {
    this.filters = this.filters.map((filter) => {
      if (filter.id === id) {
        return {
          ...filter,
          open: !filter.open,
        };
      }

      return filter;
    });
  }

  handleChangeZoneInfos(event: any, type: string): void {
    if (type === 'hierarchy') {
      this.dto = {
        ...this.dto,
        hierarchy: Number(event.target.value),
      };

      this.updatePolygonColors(this.macrozone.name, this.dto.hierarchy);
    }

    this.dto[type] = event.target.value;

    if (this.dto.name && this.dto.acronym && this.dto.hierarchy) {
      this.ruleError = false;
    }
  }

  private updatePolygonColors(macrozoneName: string, hierarchy: number): void {
    if (!this.drawnItems) return;

    this.drawnItems.eachLayer((layer: L.Layer) => {
      if (layer instanceof L.Polygon) {
        const newColor = this.getPolygonColor(macrozoneName, hierarchy);
        layer.setStyle({
          color: newColor,
          fillColor: newColor,
        });
      }
    });
  }

  addCoordinates(): void {
    this.createdCoordinates.push(this.coordinates);
    this.coordinates = [
      {
        id: uuidv4(),
        lat: 0,
        lon: 0,
      },
      {
        id: uuidv4(),
        lat: 0,
        lon: 0,
      },
      {
        id: uuidv4(),
        lat: 0,
        lon: 0,
      },
    ];

    this.createPolygons();
    this.openCoordinates = false;
  }

  addCoordinatesPoint(): void {
    this.coordinates.push({
      id: uuidv4(),
      lat: 0,
      lon: 0,
    });
  }

  removeCoordinatePoint(id: string): void {
    this.coordinates = this.coordinates.filter((coord) => coord.id !== id);
  }

  showModal(): void {
    this.openCoordinates = true;
  }

  closeModal(): void {
    this.openCoordinates = false;
  }

  onLatitudeChange(value: string, i: number): void {
    const latitude = parseFloat(value);
    if (!isNaN(latitude)) {
      this.coordinates[i].lat = latitude;
    }
  }

  onLongitudeChange(value: string, i: number): void {
    const longitude = parseFloat(value);
    if (!isNaN(longitude)) {
      this.coordinates[i].lon = longitude;
    }
  }

  handleChangeSelectZone(id: string, macrozoneId: string): void {
    let needToUpdatePolygons = false;

    this.filters = this.filters.map((filter) => {
      if (filter.id === macrozoneId) {
        return {
          ...filter,
          childrens: filter.childrens?.map((children) => {
            if (children.id === id) {
              if (!children.selected) {
                let coordinates: Coordinates[] = [];

                children.features.forEach((feat) => {
                  if (feat.geometry.type === 'Polygon') {
                    feat.geometry.coordinates[0].forEach((coord) => {
                      coordinates.push({
                        id: children.id,
                        lat: coord[1], // latitude
                        lon: coord[0], // longitude
                      });
                    });
                  }
                });

                this.createdCoordinates.push(coordinates);
                needToUpdatePolygons = true;

                return {
                  ...children,
                  selected: true,
                };
              } else {
                this.createdCoordinates = this.createdCoordinates.filter(
                  (coordArray) => {
                    const exist = coordArray.find(
                      (coord) => coord.id === children.id,
                    );

                    if (exist) {
                      this.removePolygon(children.id);
                      return false;
                    }

                    return true;
                  },
                );

                needToUpdatePolygons = true;

                return {
                  ...children,
                  selected: false,
                };
              }
            }

            return children;
          }),
        };
      }

      return filter;
    });

    // Atualizar os polígonos no mapa apenas uma vez após todas as alterações
    if (needToUpdatePolygons) {
      this.createPolygons('filter');
    }
  }

  handleToggleAllSelected(): void {
    let allSelected = this.filters.every((filter) =>
      filter.childrens?.every((child) => child.selected),
    );

    this.filters = this.filters.map((filter) => {
      this.handleOpenFilter(filter.id);

      return {
        ...filter,
        childrens: filter.childrens?.map((children) => {
          if (allSelected) {
            if (children.selected) {
              this.removePolygon(children.id);
            }
            return { ...children, selected: false };
          } else {
            if (!children.selected) {
              let coordinates: Coordinates[] = [];
              children.features.forEach((feat) => {
                if (feat.geometry.type === 'Polygon') {
                  feat.geometry.coordinates[0].forEach((coord) => {
                    coordinates.push({
                      id: children.id,
                      lat: coord[1], // latitude
                      lon: coord[0], // longitude
                    });
                  });
                }
              });

              this.createdCoordinates.push(coordinates);
            }
            return { ...children, selected: true };
          }
        }),
      };
    });

    this.createPolygons('filter');
  }

  getMacrozonesFilter(): void {
    this.macrozoneService
      .getMacrozonesFilter()
      .pipe(
        tap((response: MacrozonesFilterResponse) => {
          const environmental = {
            id:
              (response.macrozones.environmental !== null &&
                response.macrozones.environmental.length > 0 &&
                response.macrozones.environmental[0].macrozoneId) ||
              uuidv4(),
            label: 'Macrozona Ambiental',
            open: false,
            haveChildren: true,
            childrens:
              response.macrozones.environmental !== null
                ? response.macrozones.environmental
                    .filter((zone) => zone.id !== this.zoneId)
                    .map((zone) => {
                      return {
                        ...zone,
                        selected: false,
                      };
                    })
                : [],
          };

          const special = {
            id:
              (response.macrozones.special !== null &&
                response.macrozones.special.length > 0 &&
                response.macrozones.special[0].macrozoneId) ||
              uuidv4(),
            label: 'Macrozona Especial',
            open: false,
            haveChildren: true,
            childrens:
              response.macrozones.special !== null
                ? response.macrozones.special
                    .filter((zone) => zone.id !== this.zoneId)
                    .map((zone) => {
                      return {
                        ...zone,
                        selected: false,
                      };
                    })
                : [],
          };

          const urban = {
            id:
              (response.macrozones.urban !== null &&
                response.macrozones.urban.length > 0 &&
                response.macrozones.urban[0].macrozoneId) ||
              uuidv4(),
            label: 'Macrozona Urbana',
            open: false,
            haveChildren: true,
            childrens:
              response.macrozones.urban !== null
                ? response.macrozones.urban
                    .filter((zone) => zone.id !== this.zoneId)
                    .map((zone) => {
                      return {
                        ...zone,
                        selected: false,
                      };
                    })
                : [],
          };

          const rural = {
            id:
              (response.macrozones.rural !== null &&
                response.macrozones.rural.length > 0 &&
                response.macrozones.rural[0].macrozoneId) ||
              uuidv4(),
            label: 'Macrozona Rural',
            open: false,
            haveChildren: true,
            childrens:
              response.macrozones.rural !== null
                ? response.macrozones.rural
                    .filter((zone) => zone.id !== this.zoneId)
                    .map((zone) => {
                      return {
                        ...zone,
                        selected: false,
                      };
                    })
                : [],
          };

          const merge = [environmental, special, urban, rural];

          this.filters.push(...merge);
        }),
        catchError((error) => {
          console.error('Error fetching macrozones:', error);
          return throwError(() => error);
        }),
      )
      .subscribe();
  }

  private convertDrawnItemsToGeoJSON(): CreateZoneInputDTO {
    const geojson = this.drawnItems?.toGeoJSON() as any;
    if (!geojson) return {} as CreateZoneInputDTO;

    geojson.features.forEach((feature: any) => {
      const coordinates = feature.geometry.coordinates[0];
      let matchingLayer: any;

      this.drawnItems?.eachLayer((layer: any) => {
        if (layer.getLatLngs) {
          const layerCoords = layer.getLatLngs()[0];
          if (
            layerCoords.length > 0 &&
            layerCoords[0].lat === coordinates[0][1] &&
            layerCoords[0].lng === coordinates[0][0]
          ) {
            matchingLayer = layer;
          }
        }
      });

      if (matchingLayer && matchingLayer.metadata) {
        feature.properties = {
          ...feature.properties,
          ...matchingLayer.metadata,
        };
      }
    });

    return geojson;
  }

  getMacrozone(): void {
    if (this.macrozoneId)
      this.macrozoneService
        .getMacrozones()
        .pipe(
          tap((response: MacrozonesResponse) => {
            const exist = response.macrozones.find(
              (macrozone) => macrozone.id === this.macrozoneId,
            );

            if (exist) {
              this.macrozone = exist;
              this.updateDrawControlColor(exist.name, exist.hierarchy);
            }
          }),
          catchError((error) => {
            console.error('Error fetching macrozones:', error);
            return throwError(() => error);
          }),
        )
        .subscribe();
  }

  getZone(): void {
    this.zoneService
      .getZoneById(this.zoneId)
      .pipe(
        tap((response: SingleZoneResponse) => {
          let coordinates: Coordinates[] = [];

          this.zone = response;
          this.nameError = false;
          this.acronymError = false;
          this.hierarchyError = false;

          response.features.forEach((feat) => {
            if (feat.geometry.type === 'Polygon') {
              feat.geometry.coordinates[0].forEach((coord) => {
                coordinates.push({
                  id: response.id,
                  lat: coord[1],
                  lon: coord[0],
                });
              });
            }
          });

          this.createdCoordinates.push(coordinates);
          this.createPolygons('created');

          this.routes = [
            {
              label: 'Macrozonas',
              link: { route: `/macrozonas` },
            },
            {
              label: 'Zonas',
              link: {
                route: `/macrozonas/zonas/${this.getZoneName(
                  this.zone.macrozone.name,
                ).toLowerCase()}/${this.zone.macrozoneId}`,
              },
            },
            {
              label: 'Editar zona',
              link: {
                route: `/macrozonas/zona/${this.zone.macrozoneId}/edit/${this.zone.id}`,
              },
            },
          ];
        }),
        catchError((error) => {
          console.error('Error fetching single zone:', error);
          return throwError(() => error);
        }),
      )
      .subscribe();
  }

  ngAfterViewInit(): void {
    this.initMap();
  }

  getPolygonColor(macrozoneName: string, hierarchy: number): string {
    var environmental: string[] = [
      '#CDEEC4',
      '#76C860',
      '#408E2B',
      '#347025',
      '#254A1D',
    ];
    var special: string[] = [
      '#C8DBFD',
      '#83A0F6',
      '#657DEF',
      '#3641BF',
      '#2F3980',
    ];
    var urban: string[] = [
      '#FBCDCD',
      '#F17878',
      '#E64D4D',
      '#BA2626',
      '#7A2222',
    ];
    var rural: string[] = [
      '#A7ABBB',
      '#A7ABBB',
      '#A7ABBB',
      '#A7ABBB',
      '#A7ABBB',
    ];

    switch (macrozoneName) {
      case 'environmental':
        this.enviromentalPolygonStyle = {
          color: environmental[hierarchy - 1],
          weight: 3,
          opacity: 0.6,
          fillOpacity: 0.65,
          fillColor: environmental[hierarchy - 1],
        };

        return environmental[hierarchy - 1];
      case 'special':
        this.specialPolygonStyle = {
          color: special[hierarchy - 1],
          weight: 3,
          opacity: 0.6,
          fillOpacity: 0.65,
          fillColor: special[hierarchy - 1],
        };

        return special[hierarchy - 1];
      case 'urban':
        this.urbanPolygonStyle = {
          color: urban[hierarchy - 1],
          weight: 3,
          opacity: 0.6,
          fillOpacity: 0.65,
          fillColor: urban[hierarchy - 1],
        };

        return urban[hierarchy - 1];
      default:
        this.ruralPolygonStyle = {
          color: rural[hierarchy - 1],
          weight: 3,
          opacity: 0.6,
          fillOpacity: 0.65,
          fillColor: rural[hierarchy - 1],
        };

        return rural[hierarchy - 1];
    }
  }

  private initMap(): void {
    // Configurações de L.drawLocal

    this.map = L.map('mapa', {
      center: [-3.918639, -38.378295],
      zoom: 15,
    });

    const tiles = L.tileLayer(
      'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      {
        maxZoom: 18,
        minZoom: 3,
      },
    );

    tiles.addTo(this.map);

    this.highlightStyle = {
      color: this.getPolygonColor(this.macrozone.name, 0),
      weight: 3,
      opacity: 0.6,
      fillOpacity: 0.65,
      fillColor: this.getPolygonColor(this.macrozone.name, 0),
    };

    this.drawnItems = new L.FeatureGroup().setStyle(this.highlightStyle);
    this.map.addLayer(this.drawnItems);

    this.drawControl = new L.Control.Draw({
      draw: {
        circle: false,
        circlemarker: false,
        marker: false,
        polyline: false,
        rectangle: false,
        polygon: {
          shapeOptions: {
            color: this.highlightStyle.color,
            opacity: 0.4,
          },
        },
      },
      edit: {
        featureGroup: this.drawnItems,
        remove: true,
      },
    });

    this.map.addControl(this.drawControl);

    this.map.on(L.Draw.Event.CREATED, (event: any) => {
      var layer = event.layer;

      layer.metadata = {
        name: 'N/A',
        description: 'N/A',
        type: 'drawn', // Indica que o polígono foi desenhado pelo usuário
        creationDate: new Date().toLocaleString('pt-BR'),
      };

      this.drawnItems?.addLayer(layer);

      layer.on('click', () => {
        const { name, description, creationDate } = layer.metadata;

        let coordinatesString = '';

        const latlngs = layer.getLatLngs();
        latlngs.forEach((ring: L.LatLng[], index: number) => {
          ring.forEach((coord, idx) => {
            coordinatesString += `<br> Ponto ${idx + 1}: (${coord.lat.toFixed(
              4,
            )}, ${coord.lng.toFixed(4)})`;
          });
        });

        const area = L.GeometryUtil.geodesicArea(latlngs[0]).toFixed(2);

        const popupContent = `
         <span class="text-xs"><strong>Data de Criação:</strong></span> <span class="text-xs">${creationDate}</span><br>
         <span class="text-xs"><strong>Área:</strong></span> <span class="text-xs">${area} m²<br>
         <span class="text-xs"><strong>Coordenadas:</strong></span>${coordinatesString}
      `;
        layer.bindPopup(popupContent).openPopup();
      });
    });

    // Outras configurações e manipulação de eventos do mapa

    const data: CreateZoneFeature[] = history.state.data;

    let coordinates: Coordinates[] = [];

    if (data && data.length > 0)
      data.forEach((feat) => {
        if (feat.geometry.type === 'Polygon') {
          feat.geometry.coordinates[0].forEach((coord) => {
            coordinates.push({
              id: uuidv4(),
              lat: coord[1], // latitude
              lon: coord[0], // longitude
            });
          });
        }
      });

    this.createdCoordinates.push(coordinates);
    this.createPolygons();
  }

  changeCoordinateName(value: any): void {
    console.log(value.target.value);
  }

  private updateDrawControlColor(
    macrozoneName: string,
    hierarchy: number,
  ): void {
    const newColor = this.getPolygonColor(macrozoneName, hierarchy);

    this.drawControl?.setDrawingOptions({
      polygon: {
        shapeOptions: {
          color: newColor,
          fillColor: newColor,
        },
      },
    });

    this.highlightStyle = {
      color: newColor,
      weight: 3,
      opacity: 0.6,
      fillOpacity: 0.65,
      fillColor: newColor,
    };
  }

  createPolygons(type?: 'filter' | 'created'): void {
    if (this.createdCoordinates.length > 0) {
      this.createdCoordinates.forEach((coordArray) => {
        if (coordArray.length > 0) {
          const coordKey =
            `${coordArray[0].id}|` +
            coordArray
              .map((coord) => `${coord.lat.toFixed(4)},${coord.lon.toFixed(4)}`)
              .join('|');

          if (this.polygonMap[coordKey]) {
            console.log(
              'Polígono já existe para estas coordenadas, não adicionando novamente.',
            );
            return;
          }

          const latlngs = coordArray.map(
            (coord) => new L.LatLng(coord.lat, coord.lon),
          );

          var polygonStyle: L.PathOptions = {};

          if (this.macrozone.name === 'environmental') {
            polygonStyle = this.enviromentalPolygonStyle;
          } else if (this.macrozone.name === 'special') {
            polygonStyle = this.specialPolygonStyle;
          } else if (this.macrozone.name === 'urban') {
            polygonStyle = this.urbanPolygonStyle;
          } else {
            polygonStyle = this.ruralPolygonStyle;
          }

          const polygon = new L.Polygon(latlngs, polygonStyle) as L.Polygon & {
            metadata: any;
          };

          polygon.metadata = {
            name: 'N/A',
            description: 'N/A',
            creationDate: new Date().toLocaleString('pt-BR'),
          };

          if (type) {
            polygon.metadata.type = type;
          }

          polygon.on('click', () => {
            let coordinatesString = '';
            latlngs.forEach((latlng, idx) => {
              coordinatesString += `<br> Ponto ${
                idx + 1
              }: (${latlng.lat.toFixed(4)}, ${latlng.lng.toFixed(4)})`;
            });
            const area = L.GeometryUtil.geodesicArea(latlngs).toFixed(2);

            const popupContent = `
              <span class="text-xs"><strong>Data de Criação:</strong></span> <span class="text-xs">${polygon.metadata.creationDate}</span><br>
              <span class="text-xs"><strong>Área:</strong></span> <span class="text-xs">${area} m²<br>
              <span class="text-xs"><strong>Coordenadas:</strong></span>${coordinatesString}
            `;
            polygon.bindPopup(popupContent).openPopup();
          });

          this.drawnItems?.addLayer(polygon);
          this.polygonMap[coordKey] = polygon;
          this.idToCoordKeyMap[coordArray[0].id] = coordKey;
        }
      });
    } else {
      console.log('Nenhuma coordenada criada.');
    }
  }

  removePolygon(id: string): void {
    const coordKey = this.idToCoordKeyMap[id];
    const polygon = this.polygonMap[coordKey];
    if (polygon) {
      this.drawnItems?.removeLayer(polygon); // Remover polígono do mapa
      delete this.polygonMap[coordKey]; // Remover referência do polígono
      delete this.idToCoordKeyMap[id]; // Limpar o mapeamento de ID para coordKey
    }
  }

  public goBack = () => {
    this.location.back();
  };

  public save = () => {
    this.router.navigate(['/macrozonas/zonas']);
  };

  public newRule = () => {
    this.router.navigate([
      `/rule/create/${this.zoneId}`,
      {
        name: this.zone.name,
        description: this.zone.name,
        hierarchy: this.zone.hierarchy,
        acronym: this.zone.acronym,
        macrozone: this.zone.macrozoneId,
      },
    ]);
  };

  public newOrientation = () => {
    this.router.navigate([
      `/orientation/create/${this.zoneId}`,
      {
        name: this.zone.name,
        description: this.zone.name,
        hierarchy: this.zone.hierarchy,
        acronym: this.zone.acronym,
        macrozone: this.zone.macrozoneId,
      },
    ]);
  };

  public editRule = () => {
    this.router.navigate([`rule/edit/${this.zoneId}/${this.rule.id}`]);
  };

  public editOrientation = () => {
    this.router.navigate([
      `orientation/edit/${this.zoneId}/${this.orientation.id}`,
    ]);
  };

  getZoneName = (name: string) => {
    switch (name) {
      case 'environmental':
        return 'Ambiental';
      case 'special':
        return 'Especial';
      case 'urban':
        return 'Urbana';
      default:
        return 'Rural';
    }
  };

  public handleUpdateZone(): void {
    if (!this.drawnItems) return;
    this.loading = true;
    const geonJSON = this.convertDrawnItemsToGeoJSON();
    console.log('feature before', this.dto.features);
    this.dto.features =
      this.dto.features.length > 0
        ? this.dto.features
        : geonJSON.features.filter(
            (f) => f.properties && f.properties.type !== 'filter',
          );

    this.dto.hierarchy = Number(this.dto.hierarchy) || this.zone.hierarchy;
    this.dto.id = this.zone.id;
    this.dto.name = this.dto.name || this.zone.name;
    this.dto.description = this.dto.description || this.zone.description;
    this.dto.acronym = this.dto.acronym || this.zone.acronym;
    this.dto.type = this.macrozone.name || this.zone.type;
    this.dto.macrozone = { ...this.macrozone };
    this.dto.macrozoneId = this.macrozone.id;

    if (this.dto.features.length > 0) {
      if (!this.nameError && !this.acronymError && !this.hierarchyError) {
        this.zoneService.updateZone(this.zoneId, this.dto).subscribe({
          next: () => {
            this.router.navigate([
              `macrozonas/zonas/${this.getZoneName(
                this.macrozone.name,
              ).toLowerCase()}/${this.macrozone.id}`,
            ]);
            this.alertService.add({
              id: 'create-zone-success',
              type: 'success',
              message: 'Sua nova Zona foi atualizada com sucesso!',
              autoClose: true,
              top: 5,
              right: 1,
            });
          },
          error: (e: HttpErrorResponse) => {
            console.error(e),
              this.alertService.add({
                id: 'create-zone-error',
                type: 'error',
                message:
                  'Houve um erro na atualização da Zona, verifique os campos, se o erro persistir contate o Suporte.',
                top: 5,
                right: 1,
              });
          },
        });
      } else {
        this.loading = false;
        this.modalService.add({
          title: 'Existem informações obrigatórias não preenchidas',
          description:
            'Precisamos de Nome, Hierarquia, Sigla e Descrição para que possamos criar sua Zona',
          icon: 'warning',
          id: 'warning-polygon-zone',
          actionLabel: 'Entendi',
        });
      }
    } else {
      this.loading = false;
      this.modalService.add({
        title: 'Existem informações obrigatórias não preenchidas',
        description:
          'Precisamos de um Polígono definido no mapa para que possamos criar sua Zona',
        icon: 'warning',
        id: 'warning-polygon-zone',
        actionLabel: 'Entendi',
      });
    }
  }
}
