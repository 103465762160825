import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { jwtDecode } from 'jwt-decode';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import { environment } from '../../environments/environment';
import {
  DecodedJwtPayload,
  TokenResponse,
  UserResponse,
} from '../models/token.model';
import { Store } from '@ngrx/store';
import { ActionLoginSuccess, ActionLogoutUser } from '../actions/user.actions';
import { AppState } from '../states/user.state';
import { UpdatePasswordInterface } from '../models/user.model';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly JWT_TOKEN = 'JWT_TOKEN';
  private readonly USER_INFO = 'USER_INFO';
  private loggedUser?: string;
  private isAuthenticatedSubject = new BehaviorSubject<boolean>(false);
  private router = inject(Router);
  private httpService = inject(HttpClient);
  private url = `${environment.urlApi}/auth`;
  private store = inject(Store<AppState>);

  constructor() {}

  login(user: {
    username: string;
    password: string;
  }): Observable<TokenResponse> {
    return this.httpService.post<TokenResponse>(`${this.url}/login`, user).pipe(
      tap((tokens: TokenResponse) => {
        this.doLoginUser(user.username, JSON.stringify(tokens));
        this.store.dispatch(ActionLoginSuccess({ response: tokens }));
        this.storeUserInfo(tokens);
      }),
    );
  }

  getUserById(id: string): Observable<UserResponse> {
    return this.httpService
      .get<UserResponse>(`${this.url}/user/${id}`, {
        headers: {
          Authorization: `Bearer ${
            (JSON.parse(this.getToken() ?? '') as TokenResponse).access_token
          }`,
        },
      })
      .pipe(
        tap((user: UserResponse) => {
          this.store.dispatch(ActionLoginSuccess({ response: user }));
          this.storeUserInfo(user);
        }),
      );
  }

  logout(): Observable<void> {
    const token: TokenResponse = JSON.parse(this.getToken()!);
    return this.httpService
      .post<void>(`${this.url}/logout`, { refreshToken: token.refresh_token })
      .pipe(
        tap(() => {
          localStorage.removeItem(this.JWT_TOKEN);
          localStorage.removeItem(this.USER_INFO);
          this.store.dispatch(ActionLogoutUser());
          this.isAuthenticatedSubject.next(false);
          this.router.navigate(['/login']);
        }),
      );
  }

  isLoggedIn(): boolean {
    return !!localStorage.getItem(this.JWT_TOKEN);
  }

  getDecodedUser(): UserResponse | null {
    const storage = this.getUser();
    if (!storage) return null;
    const user: UserResponse = JSON.parse(storage);

    this.getUserById(user.ID);

    return user;
  }

  getDecodedToken(accessToken: string | null = null): DecodedJwtPayload | null {
    const storagedToken = this.getToken();
    if (!storagedToken) return null;
    const token: TokenResponse = JSON.parse(storagedToken);
    const decoded = jwtDecode(
      accessToken ?? token.access_token,
    ) as DecodedJwtPayload;

    return decoded;
  }

  isTokenExpired(): boolean | null {
    const decoded = this.getDecodedToken();
    if (!decoded) return null;

    if (!decoded.exp) return true;
    const expirationDate = decoded.exp * 1000;
    const now = new Date().getTime();

    return expirationDate < now;
  }

  refreshToken(refreshToken: string): Observable<TokenResponse> {
    return this.httpService
      .post<TokenResponse>(`${this.url}/refresh`, {
        refreshToken,
      })
      .pipe(
        tap({
          next: (tokens: TokenResponse) =>
            this.storeJwtToken(JSON.stringify(tokens)),
          error: (error: string) => {
            console.error(error);
            this.removeTokenFromStorage();
          },
        }),
      );
  }

  getUser(): string | null {
    return localStorage.getItem(this.USER_INFO);
  }

  getToken(): string | null {
    return localStorage.getItem(this.JWT_TOKEN);
  }

  private removeTokenFromStorage(): void {
    localStorage.removeItem(this.JWT_TOKEN);
  }

  private doLoginUser(email: string, token: string) {
    this.loggedUser = email;
    this.storeJwtToken(token);
    this.isAuthenticatedSubject.next(true);
  }

  private storeUserInfo(info: UserResponse) {
    localStorage.setItem(this.USER_INFO, JSON.stringify(info));
  }

  private storeJwtToken(jwt: string) {
    localStorage.setItem(this.JWT_TOKEN, jwt);
  }

  forgetPassword(user: { email: string }): Observable<void> {
    return this.httpService.post<void>(`${this.url}/forget-password`, user);
  }

  updatePassword(dto: UpdatePasswordInterface): Observable<unknown> {
    return this.httpService.post(`${this.url}/new-password`, dto, {
      headers: {
        Authorization: `Bearer ${dto.token}`,
      },
    });
  }
}
