<div class="m-3">
  <button (click)="changeType()" class="card">
    <h1 class="text-3xl font-bold">Resposta da Viabilidade</h1>
    <h2 *ngIf="type === 'reproved'" class="mt-3 text-lg font-bold text-red-700">
      Recusado
    </h2>
    <h2
      *ngIf="type === 'approved'"
      class="mt-3 text-lg font-bold text-green-700"
    >
      Aprovado
    </h2>
    <h2
      *ngIf="type === 'analysis'"
      class="mt-3 text-lg font-bold text-yellow-700"
    >
      Precisamos de mais informação
    </h2>

    <p *ngIf="type === 'reproved'">
      Sua viabilidade foi recusada por motivos de: Lorem ipsum dolor sit amet,
      consectetur adipiscing elit. Morbi faucibus malesuada facilisis. Phasellus
      faucibus mi nec nisl luctus, eget elementum turpis bibendum.
    </p>

    <div>
      <button (click)="goBack()" class="btn btn-secondary mt-3">Voltar</button>
      <button *ngIf="type === 'reproved'" class="btn btn-primary ml-2 mt-3">
        Solicitar Reanálise
      </button>
      <button *ngIf="type === 'approved'" class="btn btn-primary ml-2 mt-3">
        Solicitar Licenças
      </button>
      <button *ngIf="type === 'analysis'" class="btn btn-primary ml-2 mt-3">
        Iniciar Processo Digital
      </button>
    </div>
  </button>
</div>
