/* eslint-disable @angular-eslint/component-selector */
import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'success-big-icon',
  standalone: true,
  templateUrl: './success-big-icon.component.html',
  styleUrls: ['./success-big-icon.component.scss'],
  imports: [CommonModule],
})
export class SuccessBigIconComponent {
  @Input() color: string = '#000000';
  @Input() width: number = 32;
  @Input() height: number = 32;
}
