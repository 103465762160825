<div>
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="card">
      <h1 class="font-medium text-2xl">
        Responsável legal<font color="red">*</font>
      </h1>
      <p class="text-md mt-2">
        Você é o(a) <strong>responsável legal</strong> por esse empreendimento?
      </p>
      <div class="w-full flex flex-row justify-start items-center mt-4">
        <input
          id="responsible-1"
          type="radio"
          value="yes"
          formControlName="responsible"
          (change)="changeResponsible('yes')"
          [checked]="form.controls['responsible'].value === 'yes'"
        />
        <label for="responsible-1" class="ml-2 mr-6"
          >SIM, sou o responsável legal.</label
        >
        <input
          id="responsible-2"
          type="radio"
          value="no"
          formControlName="responsible"
          (change)="changeResponsible('no')"
          [checked]="form.controls['responsible'].value === 'no'"
        />
        <label for="responsible-2" class="ml-2"
          >NÃO, não sou o responsável legal.</label
        >
      </div>
      <div
        class="w-full flex flex-row justify-start text-xs font-medium text-red-500 mt-1"
        *ngIf="
          (form.controls['responsible'].touched ||
            form.controls['responsible'].dirty) &&
          form.controls['responsible'].errors &&
          form.controls['responsible'].errors['required']
        "
      >
        O Responsável legal é obrigatório.
      </div>
    </div>

    <div *ngIf="this.form.controls['responsible']?.value === 'no'" class="card">
      <h1 class="font-medium text-2xl">
        Dados do responsável legal<font color="red">*</font>
      </h1>
      <p class="text-md mt-2">
        Informe os dados do responsável legal para continuar a solicitação.
      </p>
      <div class="w-full flex flex-row justify-start items-start">
        <div class="flex flex-col justify-start items-start mr-6">
          <label for="document" class="mt-4"
            >CPF do responsável legal<font color="red">*</font></label
          >
          <input
            id="document"
            class="input"
            type="text"
            placeholder="000.000.000-00"
            mask="000.000.000-00"
            formControlName="document"
            [dropSpecialCharacters]="false"
            (input)="onChangeDocument($event)"
            [disabled]="isLoadingInitials"
          />
          <div
            class="w-full flex flex-row justify-start text-xs font-medium text-red-500 mt-1"
            *ngIf="
              (form.controls['document'].touched ||
                form.controls['document'].dirty) &&
              form.controls['document'].errors &&
              form.controls['document'].errors['required']
            "
          >
            Documento é obrigatório.
          </div>
        </div>
        <div
          *ngIf="isLoadingInitials"
          class="flex flex-row justify-start items-center mt-9"
        >
          <mat-icon
            class="animate-spin text-black mr-2"
            aria-hidden="false"
            fontIcon="refresh"
          ></mat-icon>
          <p class="text-md">Buscando iniciais...</p>
        </div>
        <div *ngIf="alphabet.length > 0 && !isLoadingInitials">
          <div class="flex flex-col justify-start items-start">
            <label for="document" class="mt-4"
              >Selecione as iniciais do responsável legal</label
            >
            <div class="w-full flex flex-row justify-start items-center">
              <div *ngFor="let a of alphabet">
                <input
                  id="initials-{{ a }}"
                  [value]="a"
                  type="radio"
                  formControlName="initials"
                  (input)="onChangeInitials($event)"
                />
                <label for="initials-{{ a }}" class="ml-2 mr-6">{{ a }}</label>
              </div>
            </div>
            <div
              class="w-full flex flex-row justify-start text-xs font-medium text-red-500 mt-1"
              *ngIf="
                (form.controls['initials'].touched ||
                  form.controls['initials'].dirty) &&
                form.controls['initials'].errors &&
                form.controls['initials'].errors['required']
              "
            >
              As iniciais são obrigatórias.
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="w-full flex flex-row justify-end items-center mt-6">
      <button type="button" [disabled]="isLoadingNext" class="btn btn-secondary text-sm">Voltar</button>
      <button 
        class="btn btn-primary text-sm ml-2 disabled:bg-opacity-65"
        [disabled]="!isValidForm || isLoadingNext"
        type="submit"
      >
        Próximo
      </button>
    </div>
  </form>
</div>
