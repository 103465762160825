import { CommonModule } from '@angular/common';
import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  inject,
} from '@angular/core';
import { DeleteIconComponent } from '../../../icons/cnaes/delete-icon/delete-icon.component';
import { EditIconComponent } from '../../../icons/cnaes/edit-icon/edit-icon.component';
import { CheckedIconComponent } from '../../../icons/cnaes/checked-icon/checked-icon.component';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import {
  matArrowBackIos,
  matArrowForwardIos,
  matSync,
} from '@ng-icons/material-icons/baseline';
import { FilterBoxComponent } from '../../filter-box/filter-box.component';
import { QuestionResponseProps } from '../../../models/questions.models';
import { CnaeFilter, CnaeResponse } from '../../../models/cnae.models';
import { QuestionsService } from '../../../services/questions.service';
import { catchError, tap, throwError } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppCnaeState } from '../../../states/cnaes.state';
import { ActionCnaesSuccess } from '../../../actions/cnaes.actions';
import { MatIcon } from '@angular/material/icon';

@Component({
  selector: 'app-questions-bank',
  standalone: true,
  imports: [
    CommonModule,
    DeleteIconComponent,
    EditIconComponent,
    CheckedIconComponent,
    NgIconComponent,
    FilterBoxComponent,
    MatIcon,
  ],
  providers: [
    provideIcons({
      matSync,
      matArrowBackIos,
      matArrowForwardIos,
    }),
  ],
  templateUrl: './questions-bank.component.html',
  styleUrls: ['./questions-bank.component.scss'],
})
export class QuestionsBankComponent implements OnInit, OnChanges {
  private store = inject(Store<AppCnaeState>);
  questionService = inject(QuestionsService);

  @Input() showIf: boolean = false;
  @Input() handleFunction!: () => void;
  @Input() handleGetFunction: (isNew: boolean) => void = () => {};
  @Input() data: QuestionResponseProps[] = [];
  @Input() toEditData: CnaeResponse[] = [];
  @Input() loading: boolean = true;

  public pages: number[] = [];

  page: number = 1;
  limit: number = 10;
  keyword: string = '';

  constructor() {}

  getQuestions({ page, limit, keyword }: CnaeFilter): void {
    this.loading = true;
    this.questionService
      .getQuestions({ page, limit, keyword })
      .pipe(
        tap((response) => {
          this.data = response.questions;
          this.loading = false;
        }),
        catchError((error) => {
          console.error('Error fetching questions:', error);
          return throwError(() => error);
        }),
      )
      .subscribe();
  }

  ngOnInit(): void {
    this.getQuestions({
      page: this.page,
      limit: this.limit,
      keyword: this.keyword,
    });
  }

  handleSelectQuestion(id: string, ev: Event): void {
    const toEditDataCopy = [...this.toEditData]; // Cria uma cópia superficial do array toEditData

    const inputElement = ev.target as HTMLInputElement;
    if (inputElement.checked) {
      const question = this.data.find((d) => d.id === id);
      if (question && question.code) {
        toEditDataCopy[0] = {
          ...toEditDataCopy[0],
          questions: [...toEditDataCopy[0].questions, question],
        };
      }
    } else {
      toEditDataCopy[0] = {
        ...toEditDataCopy[0],
        questions: toEditDataCopy[0].questions.filter((q) => q.id !== id), // Remove a questão
      };
    }

    this.toEditData = toEditDataCopy;
  }

  handleSaveQuestions(data: CnaeResponse[]): void {
    this.store.dispatch(ActionCnaesSuccess({ response: data }));
    this.handleGetFunction(false);
    this.showIf = false;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['showIf']) {
      if (this.showIf) {
        this.getQuestions({
          page: this.page,
          limit: this.limit,
          keyword: this.keyword,
        });
      }
    }
  }

  filterQuestions(f: string): void {
    this.loading = true;
    this.getQuestions({
      page: this.page,
      limit: this.limit,
      keyword: f,
    });
  }

  getRisk(r: number | undefined): string {
    if (r !== undefined && r !== null) {
      switch (r) {
        case 0:
          return 'Requer Informações';
        case 1:
          return 'Risco I';
        case 2:
          return 'Risco II';
        case 3:
          return 'Risco III';
        default:
          return 'Competência Estadual';
      }
    } else return '-';
  }
}
