import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function compareValidator(
  firstInputName: string,
  secondInputName: string,
  direction: 'greater' | 'less' | 'equal'
): ValidatorFn {
  return (group: AbstractControl): ValidationErrors | null => {
    const firstInput = group.get(firstInputName)?.value;
    const secondInput = group.get(secondInputName)?.value;

    if (firstInput === null || secondInput === null) return null;
    switch (direction) {
      case 'greater':
        if (
          firstInput !== null &&
          secondInput !== null &&
          firstInput < secondInput
        ) {
          return { compare: true };
        }
        break;
      case 'less':
        if (
          firstInput !== null &&
          secondInput !== null &&
          firstInput >= secondInput
        ) {
          return { compare: true };
        }
        break;
      case 'equal':
        if (
          firstInput !== null &&
          secondInput !== null &&
          firstInput === secondInput
        ) {
          return { compare: true };
        }
        break;
    }

    return null;
  };
}
