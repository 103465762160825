import { AlertService } from './../../../services/alert.service';
import { Component, inject, Input, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import {
  MatDialogTitle,
  MatDialogContent,
  MatDialogActions,
  MatDialogClose,
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FilterBoxComponent } from '../../filter-box/filter-box.component';
import { QuestionResponseProps } from '../../../models/questions.models';
import { MatIconModule } from '@angular/material/icon';
import { CnaeResponse } from '../../../models/cnae.models';
import { CommonModule } from '@angular/common';
import { MatCheckbox } from '@angular/material/checkbox';
import { CnaeQuestionService } from '../../../services/cnae-question.service';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import {
  matArrowBackIos,
  matArrowForwardIos,
  matSync,
} from '@ng-icons/material-icons/baseline';
import { ConfirmUnlinkCnaeModalComponent, ConfirmUnlinkCnaeModalComponentProps } from '../confirm-unlink-cnae-modal/confirm-unlink-cnae-modal.component';

export interface LinkedCnaesModalComponentProps {
  selectedQuestion: QuestionResponseProps;
  cnaes: CnaeResponse[];
}

@Component({
  selector: 'app-linked-cnaes-modal',
  standalone: true,
  imports: [
    MatFormFieldModule,
    NgIconComponent,
    MatInputModule,
    FormsModule,
    MatButtonModule,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    FilterBoxComponent,
    MatIconModule,
    CommonModule,
    MatCheckbox,
  ],
  providers: [
    provideIcons({
      matSync,
      matArrowBackIos,
      matArrowForwardIos,
    }),
  ],
  templateUrl: './linked-cnaes-modal.component.html',
  styleUrl: './linked-cnaes-modal.component.scss',
})
export class LinkedCnaesModalComponent implements OnInit {
  readonly selfDialogRef = inject(MatDialogRef<LinkedCnaesModalComponent>);
  readonly data = inject<LinkedCnaesModalComponentProps>(MAT_DIALOG_DATA);
  @Input() selectedQuestion: QuestionResponseProps = this.data.selectedQuestion;
  cnaes: CnaeResponse[] = [];

  pageable: {
    page: number;
    limit: number;
  } = {
    page: 0,
    limit: 10,
  };

  constructor(private cnaeQuestionService: CnaeQuestionService, private dialog: MatDialog, private alertService:AlertService) {}

  ngOnInit(): void {
    this.searchCnaes();
  }

  selectedItems: { [id: string]: boolean } = {};
  riskDictionary(risk: number | null | undefined): string {
    switch (risk) {
      case 0:
        return 'Requer Informações';
      case 1:
        return 'Risco I';
      case 2:
        return 'Risco II';
      case 3:
        return 'Risco III';
      case 4:
        return 'Competência Estadual';
      default:
        return '-';
    }
  }

  toggleSelection(cnaeId: string, isChecked: boolean): void {
    this.selectedItems[cnaeId] = isChecked;
  }

  getSelectedIds(): string[] {
    return Object.keys(this.selectedItems)
      .filter((id) => this.selectedItems[id])
      .map((id) => id);
  }

  searchCnaes(): void {
    this.cnaeQuestionService
      .getCnaesByQuestionId(this.selectedQuestion.id, { ...this.pageable })
      .subscribe({
        next: (cnaes) => {
          this.cnaes = cnaes;
        },
        error: (error) => {
          console.error(error);
        },
      });
  }

  handleLimitChange(event: Event): void {
    this.pageable.limit = Number((event.target as HTMLSelectElement).value);
    this.searchCnaes();
  }

  handlePageChange(direction: 'previous' | 'next'): void {
    if (direction === 'previous') {
      this.pageable.page--;
    } else {
      this.pageable.page++;
    }
    this.searchCnaes();
  }

  get getSelectedCnaes(): CnaeResponse[] {
    return this.cnaes.filter((cnae) => this.selectedItems[cnae.id]);
  }

  openModal(): void {
    if(this.getSelectedCnaes.length === 0) return
    const dialogRef = this.dialog.open<ConfirmUnlinkCnaeModalComponent, ConfirmUnlinkCnaeModalComponentProps>(ConfirmUnlinkCnaeModalComponent, {
      data: {
        selectedCnaes: this.getSelectedCnaes,
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result !== undefined && result === true) {
        this.unlinkCnaes();
      }
    });
  }

  unlinkCnaes(): void {
    this.cnaeQuestionService.unlinkCnaes(this.selectedQuestion.id,this.getSelectedIds()).subscribe({
      next: () => {
        this.alertService.add({
          id: 'unlink-cnaes-success',
          message: 'CNAEs desvinculados com sucesso',
          type: 'success',
          top: 5,
          right: 1,
          autoClose: true,
        });
        this.selfDialogRef.close(true);
      },
      error: (error: unknown) => {
        this.alertService.add({
          id: 'unlink-cnaes-error',
          message: 'Erro ao desvincular CNAEs',
          type: 'error',
          top: 5,
          right: 1,
          autoClose: true,
        });
        console.error(error);
      },
    })
  }
}
