<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<div class="m-3 p-4">
  <app-breadcrumbs [routes]="routes"></app-breadcrumbs>

  <h1 class="font-bold text-3xl">Servidores</h1>
  <app-filter-box
    [showInclude]="true"
    (include)="newEmployee()"
    (filter)="doFilter($event)"
    (cleanner)="doClean($event)"
  />
  <div class="card">
    <table class="w-full">
      <thead>
        <tr>
          <th>
            <div class="w-full flex flex-row justify-center items-center p-2">
              <p class="text-sm font-medium">Nome</p>
              <mat-icon
                class="text-sm ml-1 mt-1 text-black"
                fontIcon="unfold_more"
              ></mat-icon>
            </div>
          </th>
          <th>
            <div class="w-full flex flex-row justify-center items-center p-2">
              <p class="text-sm font-medium">E-mail</p>
              <mat-icon
                class="text-sm ml-1 mt-1 text-black"
                fontIcon="unfold_more"
              ></mat-icon>
            </div>
          </th>
          <th>
            <div class="w-full flex flex-row justify-center items-center p-2">
              <p class="text-sm font-medium">Função</p>
              <mat-icon
                class="text-sm ml-1 mt-1 text-black"
                fontIcon="unfold_more"
              ></mat-icon>
            </div>
          </th>
          <th>
            <div class="w-full flex flex-row justify-center items-center p-2">
              <p class="text-sm font-medium">Status</p>
              <mat-icon
                class="text-sm ml-1 mt-1 text-black"
                fontIcon="unfold_more"
              ></mat-icon>
            </div>
          </th>
          <th>
            <div class="w-full flex flex-row justify-center items-center p-2">
              <p class="text-sm font-medium">Ações</p>
              <mat-icon
                class="text-sm ml-1 mt-1 text-black"
                fontIcon="unfold_more"
              ></mat-icon>
            </div>
          </th>
        </tr>
      </thead>
      <tbody *ngIf="!loading">
        <tr class="py-2" *ngFor="let d of data">
          <td>
            <div class="w-full flex flex-row justify-center items-center p-2">
              <p class="text-sm font-normal">{{ d.fullname }}</p>
            </div>
          </td>
          <td>
            <div class="w-full flex flex-row justify-center items-center p-2">
              <p class="text-sm font-normal">{{ d.email }}</p>
            </div>
          </td>
          <td>
            <div class="w-full flex flex-row justify-center items-center p-2">
              <p class="text-sm font-normal">{{ d.stringRole }}</p>
            </div>
          </td>
          <td>
            <div class="w-full flex flex-row justify-center items-center p-2">
              <p class="text-sm font-normal">
                {{ d.enabled ? "Ativo" : "Inativo" }}
              </p>
            </div>
          </td>
          <td>
            <div class="w-full flex flex-row justify-center items-center p-2">
              <button
                [ngClass]="
                  getPermission(d.role)
                    ? 'flex flex-row justify-center items-center mr-6 hover:cursor-pointer'
                    : 'flex flex-row justify-center items-center hover:cursor-pointer'
                "
                (click)="toEdit(d.ID)"
              >
                <app-eye-icon [width]="20" [height]="20"></app-eye-icon>
                <p class="text-sm font-normal text-left ml-1">Visualizar</p>
              </button>
              <!-- <div
              class="flex flex-row justify-center items-center mr-6 hover:cursor-pointer"
            >
              <app-edit-icon [width]="20" [height]="20"></app-edit-icon>
              <p class="text-sm font-normal text-left ml-1">Editar</p>
            </div> -->
              <div
                *ngIf="d.enabled && getPermission(d.role)"
                class="flex flex-row justify-center items-center mr-2 hover:cursor-pointer"
                (click)="handleStatus(d.email)"
              >
                <app-locked-icon
                  [width]="20"
                  [height]="20"
                  [color]="'#E64D4D'"
                ></app-locked-icon>

                <p
                  [ngClass]="
                    this.loadingStatus
                      ? 'text-sm font-normal text-left ml-1 text-[#E64D4D] opacity-40 cursor-not-allowed'
                      : 'text-sm font-normal text-left ml-1 text-[#E64D4D]'
                  "
                >
                  {{ this.loadingStatus ? "Inativando..." : "Inativar" }}
                </p>
              </div>
              <div
                *ngIf="!d.enabled && getPermission(d.role)"
                class="flex flex-row justify-center items-center mr-2 hover:cursor-pointer"
                (click)="handleStatus(d.email)"
              >
                <app-unlocked-icon
                  [width]="20"
                  [height]="20"
                  [color]="'#209e41'"
                ></app-unlocked-icon>

                <p
                  [ngClass]="
                    this.loadingStatus
                      ? 'text-sm font-normal text-left ml-1 text-[#209e41] opacity-40 cursor-not-allowed'
                      : 'text-sm font-normal text-left ml-1 text-[#209e41]'
                  "
                >
                  {{ this.loadingStatus ? "Ativando..." : "Ativar" }}
                </p>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <div
      *ngIf="data.length === 0 && !loading"
      class="w-full flex flex-row justify-center py-4"
    >
      <div class="flex p-3 flex-row items-center">
        <p class="text-sm font-medium">Nenhum registro encontrado.</p>
      </div>
    </div>

    <div *ngIf="loading" class="w-full flex flex-row justify-center py-4">
      <div class="flex p-3 flex-row items-center">
        <ng-icon class="mr-3 animate-spin" name="matSync"></ng-icon>
        <p class="text-sm font-medium">Carregando dados...</p>
      </div>
    </div>

    <div *ngIf="!loading" class="flex flex-row justify-between mt-4">
      <select
        class="mt-3 p-1 rounded-md w-16"
        (input)="handleLimitChange($event, 'limit')"
      >
        <option value="10" [selected]="limit === 10">10</option>
        <option value="20" [selected]="limit === 20">20</option>
        <option value="50" [selected]="limit === 50">50</option>
      </select>

      <nav
        class="mt-3 flex flex-row justify-end"
        aria-label="Page navigation example"
      >
        <ul class="flex items-center -space-x-px h-10 text-base">
          <li>
            <button
              [ngClass]="
                page > 1
                  ? 'flex cursor-pointer items-center justify-center px-4 h-10 ms-0 leading-tight text-gray-500 bg-white border border-e-0 border-gray-300 rounded-s-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white'
                  : 'opacity-40 pointer-events-none cursor-not-allowed flex cursor-pointer items-center justify-center px-4 h-10 ms-0 leading-tight text-gray-500 bg-white border border-e-0 border-gray-300 rounded-s-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white'
              "
              (click)="handlePageChange('previous')"
            >
              <span class="sr-only">Previous</span>
              <ng-icon name="matArrowBackIos"></ng-icon>
            </button>
          </li>
          <button
            *ngFor="let p of pages"
            [ngClass]="
              page === p
                ? 'flex cursor-pointer items-center justify-center px-4 h-10 leading-tight border border-gray-300 bg-gray-200 text-gray-800 pointer-events-none'
                : 'flex cursor-pointer items-center justify-center px-4 h-10 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700'
            "
            (click)="handleSetPage(p)"
          >
            {{ p }}
          </button>
          <li>
            <button
              [ngClass]="
                pages.length > 1
                  ? 'flex cursor-pointer items-center justify-center px-4 h-10 ms-0 leading-tight text-gray-500 bg-white border border-e-0 border-gray-300 rounded-s-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white'
                  : 'opacity-40 pointer-events-none cursor-not-allowed flex cursor-pointer items-center justify-center px-4 h-10 ms-0 leading-tight text-gray-500 bg-white border border-e-0 border-gray-300 rounded-s-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white'
              "
              (click)="handlePageChange('next')"
            >
              <span class="sr-only">Next</span>
              <ng-icon name="matArrowForwardIos"></ng-icon>
            </button>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</div>
