import { Router } from '@angular/router';
import { Component, inject } from '@angular/core';
import {
  FormGroup,
  FormControl,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import { HttpErrorResponse } from '@angular/common/http';
import { AlertService } from '../../services/alert.service';
import { AlertComponent } from '../../components/alert/alert.component';

@Component({
  selector: 'app-login',
  standalone: true,
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss',
  imports: [ReactiveFormsModule, AlertComponent],
})
export class LoginComponent {
  authService = inject(AuthService);

  constructor(
    private router: Router,
    private alertService: AlertService,
  ) {}

  log(event: Event, desc?: string) {
    const input = event.target as HTMLInputElement;

    desc ? console.log(desc, input.value) : console.log(input.value);
  }

  push(route: string): void {
    this.router.navigate([route]);
  }

  loginForm = new FormGroup({
    username: new FormControl('', [Validators.required]),
    password: new FormControl('', [Validators.required]),
  });

  onSubmit = () => {
    if (this.loginForm.invalid)
      return this.alertService.add({
        id: 'login-field-warning',
        type: 'warning',
        message: 'Preencha os campos!',
        autoClose: true,
      });

    const dto = {
      username: this.loginForm.controls.username.value as string,
      password: this.loginForm.controls.password.value as string,
    };
    this.authService.login(dto).subscribe({
      next: () => this.router.navigate(['/dashboard']),
      error: (e: HttpErrorResponse) => {
        console.error(e),
          this.alertService.add({
            id: 'login-field-error',
            type: 'error',
            message:
              'Houve um erro com suas credenciais ou sua conta ainda não foi aprovada, verifique os campos ou tente novamente mais tarde.',
          });
      },
    });
  };
}
