import { AlertService } from './../../../services/alert.service';
import { QuestionsService } from './../../../services/questions.service';
import { CommonModule } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { MatDialogContent, MatDialogActions, MatDialogClose, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { WarningBigIconComponent } from '../../../icons/modal/warning-big-icon/warning-big-icon.component';
import { FormsModule, FormBuilder, Validators, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';


export interface EditQuestionModalComponentProps {
  form: {
    id: string,
    description: string,
    riskIfYes: number,
    riskIfNo: number,
  }
}

@Component({
  selector: 'app-edit-question-modal',
  standalone: true,
  imports: [    WarningBigIconComponent,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    MatFormFieldModule,
    FormsModule,
    CommonModule,
    ReactiveFormsModule],
  templateUrl: './edit-question-modal.component.html',
  styleUrl: './edit-question-modal.component.scss'
})
export class EditQuestionModalComponent implements OnInit{
  readonly selfDialogRef = inject(MatDialogRef<EditQuestionModalComponent>);
  readonly data = inject<EditQuestionModalComponentProps>(MAT_DIALOG_DATA);
  questionForm!: FormGroup

  constructor(private formBuilder: FormBuilder, private questionsService:QuestionsService, private alertService:AlertService) { }

  ngOnInit(): void {
    this.createForm();
  }

  createForm(): void {
    this.questionForm = this.formBuilder.group({
      id: this.formBuilder.control(this.data.form.id),
      description: this.formBuilder.control(this.data.form.description, [Validators.required]),
      riskIfYes: this.formBuilder.control(this.data.form.riskIfYes, [Validators.required]),
      riskIfNo: this.formBuilder.control(this.data.form.riskIfNo, [Validators.required]),
    });
  }

  confirm(): boolean {
    return true;
  }

  onSubmit():void{
    if(this.questionForm.invalid){
      this.questionForm.markAllAsTouched();
      return;
    }

    this.questionsService.updateQuestion(this.data.form.id,this.questionForm.value).subscribe({
      next: () => {
        this.alertService.add({
          id: 'edit-question-success',
          message: 'Questão atualizada com sucesso',
          type: 'success',
          top: 5,
          right: 1,
          autoClose: true,
        });
        this.selfDialogRef.close(true);
      },
      error: (error) => {
        this.alertService.add({
          id: 'edit-question-error',
          message: 'Erro ao atualizar questão',
          type: 'error',
          top: 5,
          right: 1,
          autoClose: true,
        });
        console.log(error);
      }
    })
  }
}
