import { AnalysisComponent } from './pages/analysis/analysis.component';
import { Routes } from '@angular/router';
import { MainLayoutComponent } from './components/main-layout/main-layout.component';
import { LayersComponent } from './pages/layers/layers.component';
import { ZonesComponent } from './pages/zones/zones.component';
import { NewRuleComponent } from './pages/new-rule/new-rule.component';
import { PersonalizeComponent } from './pages/personalize/personalize.component';
import { BusinessesComponent } from './pages/businesses/businesses.component';
import { ServicesComponent } from './pages/services/services.component';
import { DoViabilityComponent } from './pages/do-viability/do-viability.component';
import { ViabilityReturnComponent } from './pages/viability-return/viability-return.component';
import { NewLayerComponent } from './pages/new-layer/new-layer.component';
import { EditZoneComponent } from './pages/edit-zone/edit-zone.component';
import { LoginComponent } from './pages/login/login.component';
import { RegisterEmployeeComponent } from './pages/register-employee/register.component';
import { EmployeesComponent } from './pages/employees/employees.component';
import { CnaesComponent } from './pages/cnaes/cnaes.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { NewCnaeComponent } from './pages/new-cnae/new-cnae.component';
import { authGuard } from './auth/auth.guard';
import { ForgetPasswordComponent } from './pages/forget-password/forget-password.component';
import { RegisterComponent } from './pages/register/register.component';
import { UpdateUserComponent } from './pages/update-user/update-user.component';
import { UpdatePasswordComponent } from './pages/update-password/update-password.component';
import { CreateZoneComponent } from './pages/create-zone/create-zone.component';
import { EditRuleComponent } from './pages/edit-rule/edit-rule.component';
import { canDeactivateGuard } from './can-deactivate-guard.guard';
import { CreateOrientationComponent } from './pages/create-orientation/create-orientation.component';
import { EditOrientationComponent } from './pages/edit-orientation/edit-orientation.component';
import { FeasibilityComponent } from './pages/feasibility/feasibility.component';
import { ReFeasbilityComponent } from './pages/re-feasibility/re-feasibility.component';
import { ReFeasbilityReviewComponent } from './pages/re-feasibility-review/re-feasibility-review.component';
import { EditUserComponent } from './pages/edit-user/edit-user.component';

export const routes: Routes = [
  {
    path: '',
    component: MainLayoutComponent,
    data: {
      title: 'Home',
    },
    canActivate: [authGuard],
    children: [
      {
        path: '',
        component: DashboardComponent,
      },
      {
        path: 'dashboard',
        component: DashboardComponent,
      },
      {
        path: 'analysis',
        component: AnalysisComponent,
      },
      {
        path: 'macrozonas',
        component: LayersComponent,
      },
      {
        path: 'nova-camada',
        component: NewLayerComponent,
      },
      {
        path: 'macrozonas/zonas/:zoneName/:id',
        component: ZonesComponent,
      },
      {
        path: 'servidor/edit/:id',
        component: UpdateUserComponent,
      },
      {
        path: 'macrozonas/zona/:id/create',
        component: CreateZoneComponent,
      },
      {
        path: 'macrozonas/zona/:id/edit/:zoneId',
        component: EditZoneComponent,
      },
      {
        path: 'cnaes',
        component: CnaesComponent,
      },
      {
        path: 'cnaes/novo',
        component: NewCnaeComponent,
      },
      {
        path: 'rule/edit/:zoneId/:ruleId',
        component: EditRuleComponent,
        canDeactivate: [canDeactivateGuard],
      },
      {
        path: 'rule/create/:zoneId',
        component: NewRuleComponent,
        canDeactivate: [canDeactivateGuard],
      },
      {
        path: 'orientation/create/:zoneId',
        component: CreateOrientationComponent,
      },
      {
        path: 'orientation/edit/:zoneId/:orientationId',
        component: EditOrientationComponent,
        canDeactivate: [canDeactivateGuard],
      },
      {
        path: 'feasibility',
        component: FeasibilityComponent,
      },
      {
        path: 'personalize',
        component: PersonalizeComponent,
      },
      {
        path: 'businesses',
        component: BusinessesComponent,
      },
      {
        path: 'businesses/re-feasibility/:id',
        component: ReFeasbilityComponent,
      },
      {
        path: 'businesses/re-feasibility-review/:id',
        component: ReFeasbilityReviewComponent,
      },
      {
        path: 'servers',
        component: EmployeesComponent,
      },
      {
        path: 'update-user/:id',
        component: EditUserComponent,
      },
      {
        path: 'servicos',
        component: ServicesComponent,
      },
      {
        path: 'efetuar-viabilidade',
        component: DoViabilityComponent,
      },
      {
        path: 'retorno-viabilidade',
        component: ViabilityReturnComponent,
      },
    ],
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'admin/register',
    component: RegisterEmployeeComponent,
  },
  {
    path: 'register',
    component: RegisterComponent,
  },
  {
    path: 'forgot-password',
    component: ForgetPasswordComponent,
  },
  {
    path: 'update-password',
    component: UpdatePasswordComponent,
  },
  {
    path: '**',
    canActivate: [authGuard],
    redirectTo: '',
    pathMatch: 'full',
  },
];
