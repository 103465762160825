<svg
  [attr.width]="width + 'px'"
  [attr.height]="height + 'px'"
  viewBox="0 0 120 120"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M60 10C32.4 10 10 32.4 10 60C10 87.6 32.4 110 60 110C87.6 110 110 87.6 110 60C110 32.4 87.6 10 60 10ZM60 100C37.95 100 20 82.05 20 60C20 37.95 37.95 20 60 20C82.05 20 100 37.95 100 60C100 82.05 82.05 100 60 100ZM82.95 37.9L50 70.85L37.05 57.95L30 65L50 85L90 45L82.95 37.9Z"
    [attr.fill]="color"
  />
</svg>
