import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { CreateUserInterface } from '../models/user.model';
import { UserResponse } from '../models/token.model';
import { Paginate } from '../models/paginate.model';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private httpService = inject(HttpClient);
  private url = `${environment.urlApi}/user`;

  constructor() {}

  create(dto: CreateUserInterface): Observable<unknown> {
    return this.httpService.post(`${this.url}`, dto);
  }

  getUsers(
    page: number,
    limit: number,
    search?: string,
    orderBy?: string,
    orderField?: string,
  ): Observable<Paginate<UserResponse>> {
    return this.httpService.get<Paginate<UserResponse>>(`${this.url}`, {
      params: {
        page: page.toString(),
        limit: limit.toString(),
        search: search || '',
        orderBy: orderBy || '',
        orderField: orderField || '',
      },
    });
  }
}
