<div class="m-3">
  <div class="card">
    <h1 class="text-3xl font-bold">Personalizar</h1>
    <div class="mt-3 flex-col">
      <p class="label">URL da Logo</p>
      <input type="text" class="input" />
    </div>

    <div class="flex-row">
      <div class="mt-3 flex-col">
        <p class="label">Cor primária</p>
        <select class="input">
          <option value="1">Cor A</option>
          <option value="2">Cor B</option>
          <option value="3">Cor C</option>
        </select>
      </div>
      <div class="mt-3 flex-col">
        <p class="label">Cor secundária</p>
        <select class="input">
          <option value="1">Cor A</option>
          <option value="2">Cor B</option>
          <option value="3">Cor C</option>
        </select>
      </div>
      <div class="mt-3 flex-col">
        <p class="label">Pesquisa de endereço por IPTU?</p>
        <div class="mt-3 flex flex-row">
          <div class="pr-2 flex">
            <p class="label mr-3">Sim</p>
            <input type="radio" name="motive" />
          </div>
          <div class="pr-2 flex">
            <p class="label mr-3">Não</p>
            <input type="radio" name="motive" />
          </div>
        </div>
      </div>
    </div>

    <div class="flex-row mt-3">
      <button class="btn btn-primary mr-2">Salvar</button>
      <button class="btn btn-secondary">Cancelar</button>
    </div>
  </div>
</div>
