<div class="m-3 p-4">
  <app-breadcrumbs [routes]="routes"></app-breadcrumbs>

  <div class="w-full flex flex-row justify-between items-center">
    <h1 class="font-bold text-3xl">Incluir orientações</h1>
  </div>

  <form [formGroup]="orientationForm" (ngSubmit)="onSubmit()">
    <div class="card">
      <p class="font-medium text-xl mb-4">Licenciamento ambiental</p>
      <st-editor
        formControlName="environmentLicensing"
        [ngModel]="
          orientation.environmentLicensing
            ? orientation.environmentLicensing
            : orientationForm.get('environmentLicensing')?.value !== 'undefined'
              ? orientationForm.get('environmentLicensing')?.value
              : ''
        "
        [config]="config"
      ></st-editor>
      <div
        class="text-xs font-medium text-red-500 mt-1"
        *ngIf="
          (orientationForm.controls['environmentLicensing'].touched ||
            orientationForm.controls['environmentLicensing'].dirty) &&
          orientationForm.controls['environmentLicensing'].errors &&
          orientationForm.controls['environmentLicensing'].errors['required']
        "
      >
        Licenciamento ambiental é obrigatório.
      </div>
    </div>
    <div class="card">
      <p class="font-medium text-xl mb-4">Polo gerador de viagem (PGV)</p>
      <st-editor
        formControlName="pgv"
        [ngModel]="
          orientation.pgv
            ? orientation.pgv
            : orientationForm.get('pgv')?.value !== 'undefined'
              ? orientationForm.get('pgv')?.value
              : ''
        "
        [config]="config"
      ></st-editor>
      <div
        class="text-xs font-medium text-red-500 mt-1"
        *ngIf="
          (orientationForm.controls['pgv'].touched ||
            orientationForm.controls['pgv'].dirty) &&
          orientationForm.controls['pgv'].errors &&
          orientationForm.controls['pgv'].errors['required']
        "
      >
        Polo gerador de viagem (PGV) é obrigatório.
      </div>
    </div>
    <div class="card">
      <p class="font-medium text-xl mb-4">
        Vagas, estacionamento, carga, descarga e acesso de veículos
      </p>
      <st-editor
        formControlName="parkingAndVehicles"
        [ngModel]="
          orientation.parkingAndVehicles
            ? orientation.parkingAndVehicles
            : orientationForm.get('parkingAndVehicles')?.value !== 'undefined'
              ? orientationForm.get('parkingAndVehicles')?.value
              : ''
        "
        [config]="config"
      ></st-editor>
      <div
        class="text-xs font-medium text-red-500 mt-1"
        *ngIf="
          (orientationForm.controls['parkingAndVehicles'].touched ||
            orientationForm.controls['parkingAndVehicles'].dirty) &&
          orientationForm.controls['parkingAndVehicles'].errors &&
          orientationForm.controls['parkingAndVehicles'].errors['required']
        "
      >
        Vagas, estacionamento, carga, descarga e acesso de veículos é
        obrigatório.
      </div>
    </div>
    <div class="card">
      <p class="font-medium text-xl mb-4">Comando aéreo</p>
      <st-editor
        formControlName="airCommand"
        [ngModel]="
          orientation.airCommand
            ? orientation.airCommand
            : orientationForm.get('airCommand')?.value !== 'undefined'
              ? orientationForm.get('airCommand')?.value
              : ''
        "
        [config]="config"
      ></st-editor>
      <div
        class="text-xs font-medium text-red-500 mt-1"
        *ngIf="
          (orientationForm.controls['airCommand'].touched ||
            orientationForm.controls['airCommand'].dirty) &&
          orientationForm.controls['airCommand'].errors &&
          orientationForm.controls['airCommand'].errors['required']
        "
      >
        Comando aéreo é obrigatório.
      </div>
    </div>
    <div class="card">
      <p class="font-medium text-xl mb-4">Corpo de bombeiros</p>
      <st-editor
        formControlName="fireDepartment"
        [ngModel]="
          orientation.fireDepartment
            ? orientation.fireDepartment
            : orientationForm.get('fireDepartment')?.value !== 'undefined'
              ? orientationForm.get('fireDepartment')?.value
              : ''
        "
        [config]="config"
      ></st-editor>
      <div
        class="text-xs font-medium text-red-500 mt-1"
        *ngIf="
          (orientationForm.controls['fireDepartment'].touched ||
            orientationForm.controls['fireDepartment'].dirty) &&
          orientationForm.controls['fireDepartment'].errors &&
          orientationForm.controls['fireDepartment'].errors['required']
        "
      >
        Corpo de bombeiros é obrigatório.
      </div>
    </div>
    <div class="card">
      <p class="font-medium text-xl mb-4">Patrimônio Histórico</p>
      <st-editor
        formControlName="historicalHeritage"
        [ngModel]="
          orientation.historicalHeritage
            ? orientation.historicalHeritage
            : orientationForm.get('historicalHeritage')?.value !== 'undefined'
              ? orientationForm.get('historicalHeritage')?.value
              : ''
        "
        [config]="config"
      ></st-editor>
      <div
        class="text-xs font-medium text-red-500 mt-1"
        *ngIf="
          (orientationForm.controls['historicalHeritage'].touched ||
            orientationForm.controls['historicalHeritage'].dirty) &&
          orientationForm.controls['historicalHeritage'].errors &&
          orientationForm.controls['historicalHeritage'].errors['required']
        "
      >
        Patrimônio Histórico é obrigatório.
      </div>
    </div>
    <div class="card">
      <p class="font-medium text-xl mb-4">Licenciamento Sanitário</p>
      <st-editor
        formControlName="healthLicensing"
        [ngModel]="
          orientation.healthLicensing
            ? orientation.healthLicensing
            : orientationForm.get('healthLicensing')?.value !== 'undefined'
              ? orientationForm.get('healthLicensing')?.value
              : ''
        "
        [config]="config"
      ></st-editor>
      <div
        class="text-xs font-medium text-red-500 mt-1"
        *ngIf="
          (orientationForm.controls['healthLicensing'].touched ||
            orientationForm.controls['healthLicensing'].dirty) &&
          orientationForm.controls['healthLicensing'].errors &&
          orientationForm.controls['healthLicensing'].errors['required']
        "
      >
        Licenciamento Sanitário é obrigatório.
      </div>
    </div>
    <div class="card">
      <p class="font-medium text-xl mb-4">Outras Orientações</p>
      <st-editor
        formControlName="others"
        [ngModel]="
          orientation.others
            ? orientation.others
            : orientationForm.get('others')?.value !== 'undefined'
              ? orientationForm.get('others')?.value
              : ''
        "
        [config]="config"
      ></st-editor>
      <div
        class="text-xs font-medium text-red-500 mt-1"
        *ngIf="
          (orientationForm.controls['others'].touched ||
            orientationForm.controls['others'].dirty) &&
          orientationForm.controls['others'].errors &&
          orientationForm.controls['others'].errors['required']
        "
      >
        Outras Orientações é obrigatório.
      </div>
    </div>

    <div class="w-full flex flex-row justify-end items-center mt-6">
      <button
        type="button"
        (click)="goBack()"
        [ngClass]="
          loading
            ? 'btn btn-secondary text-sm opacity-40 cursor-not-allowed pointer-events-none'
            : 'btn btn-secondary text-sm'
        "
      >
        Voltar
      </button>
      <button
        type="submit"
        [ngClass]="
          loading
            ? 'btn btn-primary text-sm ml-2 opacity-40 cursor-not-allowed pointer-events-none'
            : 'btn btn-primary text-sm ml-2'
        "
      >
        {{ loading ? "Atualizando informações..." : "Atualizar informações" }}
      </button>
    </div>
  </form>
</div>
