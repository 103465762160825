import { CommonModule } from '@angular/common';
import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  inject,
} from '@angular/core';
import { DeleteIconComponent } from '../../../icons/cnaes/delete-icon/delete-icon.component';
import { EditIconComponent } from '../../../icons/cnaes/edit-icon/edit-icon.component';
import { CheckedIconComponent } from '../../../icons/cnaes/checked-icon/checked-icon.component';
import { QuestionsService } from '../../../services/questions.service';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { QuestionProps } from '../../../models/questions.models';
import { catchError, tap, throwError } from 'rxjs';
import { AlertService } from '../../../services/alert.service';
import { CnaeResponse } from '../../../models/cnae.models';

@Component({
  selector: 'app-questions',
  standalone: true,
  imports: [
    CommonModule,
    DeleteIconComponent,
    EditIconComponent,
    CheckedIconComponent,
    ReactiveFormsModule,
  ],
  templateUrl: './question.component.html',
  styleUrls: ['./question.component.scss'],
})
export class QuestionComponent implements OnInit, OnChanges {
  questionService = inject(QuestionsService);
  questionForm!: FormGroup;
  editedData: CnaeResponse = {} as CnaeResponse;

  @Input() showIf: boolean = false;
  @Input() isNewQuestion: boolean = false;
  @Input() data: CnaeResponse = {} as CnaeResponse;
  @Input() handleCloseFunction: (isNew: boolean) => void = () => {};
  @Input() handleActionFunction: () => void = () => {};

  public loading: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private alertService: AlertService,
  ) {}

  createQuestion(): void {
    this.loading = true;
  }

  handleCancelEditingQuestion(i: number): void {
    this.data.questions = this.data.questions.map((q, index) => {
      if (index === i) {
        return {
          ...q,
          isEditing: false,
        };
      }

      return q;
    });
  }

  handleEditQuestion(i: number, key: string, event: Event): void {
    const input = event.target as HTMLInputElement;

    this.editedData.questions =
      this.editedData.questions.length > 0
        ? this.editedData.questions.map((q, index) => {
            if (index === i) {
              if (key === 'riskIfYes' || key === 'riskIfNo') {
                return {
                  ...q,
                  [key]: Number(input.value),
                };
              } else
                return {
                  ...q,
                  [key]: input.value,
                };
            }

            return q;
          })
        : this.data.questions.map((q, index) => {
            if (index === i) {
              if (key === 'riskIfYes' || key === 'riskIfNo') {
                return {
                  ...q,
                  [key]: Number(input.value),
                };
              } else
                return {
                  ...q,
                  [key]: input.value,
                };
            }

            return q;
          });
  }

  handleSave(i: number): void {
    this.data.questions = this.editedData.questions.map((q, index) => {
      if (index === i) {
        return {
          ...q,
          isEditing: false,
        };
      }

      return q;
    });

    this.editedData.questions = [];
  }

  handleCancel(): void {
    this.data.questions = this.editedData.questions.map((q) => {
      return {
        ...q,
        isEditing: false,
      };
    });
  }

  handleEditingQuestion(i: number): void {
    this.data.questions = this.data.questions.map((q, index) => {
      if (index === i) {
        if (q.isEditing)
          return {
            ...q,
            isEditing: false,
          };
        else
          return {
            ...q,
            isEditing: true,
          };
      }

      return q;
    });
  }

  ngOnInit(): void {
    this.data.questions = this.data.questions.map((question) => {
      return {
        ...question,
        isEditing: false,
      };
    });

    this.questionForm = this.formBuilder.group({
      description: ['', Validators.required],
      riskIfYes: [null, Validators.required],
      riskIfNo: [null, Validators.required],
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['showIf']) {
      if (!this.showIf) {
        this.handleCancel();
      }
    }
  }

  onSubmit() {
    this.loading = true;

    if (this.questionForm.valid) {
      const question: QuestionProps[] = [
        {
          description: this.questionForm.controls['description'].value,
          riskIfYes: Number(this.questionForm.controls['riskIfYes'].value),
          riskIfNo: Number(this.questionForm.controls['riskIfNo'].value),
        },
      ];

      this.questionService
        .createQuestion(question)
        .pipe(
          tap(() => {
            this.alertService.add({
              id: 'create-question-success',
              type: 'success',
              message: 'Sua pergunta foi criada com sucesso!',
              top: 5,
              right: 1,
            });
            this.loading = false;
            this.showIf = false;
            this.handleActionFunction();
            this.questionForm.reset();
          }),
          catchError((error) => {
            console.error('Error creating question:', error);
            this.alertService.add({
              id: 'create-question-error',
              type: 'error',
              message: 'Tivemos um problema ao tentar criar sua pegunta.',
              top: 5,
              right: 1,
            });
            return throwError(() => error);
          }),
        )
        .subscribe();
    } else {
      this.loading = false;
      console.log(this.questionForm.controls);
      this.questionForm.markAllAsTouched();
    }
  }
}
