<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<!-- Modal Coordenadas -->
<div
  *ngIf="openCoordinates"
  class="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-[1001]"
>
  <div
    class="relative top-20 mx-auto p-4 border w-[40vw] max-w-screen-lg shadow-lg rounded bg-white"
  >
    <div class="w-full flex flex-col justify-center items-start text-center">
      <h3 class="text-2xl font-bold">Adicionar Coordenadas</h3>
      <div class="w-full flex flex-col mt-2">
        <div
          *ngFor="let coordinate of coordinates; let i = index"
          class="w-full grid gap-4 mt-2"
        >
          <div class="w-full flex flex-row justify-center items-center">
            <div class="w-1/2 flex flex-col items-start mb-2 mr-1">
              <p class="label">Latitude</p>
              <input
                type="text"
                class="input"
                placeholder="Latitude"
                [(ngModel)]="coordinate.lat"
                (ngModelChange)="onLatitudeChange($event, i)"
              />
            </div>
            <div class="w-1/2 flex flex-col items-start mb-2 ml-1">
              <p class="label">Longitude</p>
              <input
                type="text"
                class="input"
                placeholder="Longitude"
                [(ngModel)]="coordinate.lon"
                (ngModelChange)="onLongitudeChange($event, i)"
              />
            </div>
            <button
              class="p-2.5 rounded bg-transparent border border-[#ab2222] ml-2 mt-2.5"
              (click)="removeCoordinatePoint(coordinate.id)"
            >
              <app-remove-icon
                [color]="'#ab2222'"
                [width]="14"
                [height]="14"
              ></app-remove-icon>
            </button>
          </div>
        </div>
        <div
          class="w-full flex flex-row justify-center items-center p-2 border border-dashed border-black rounded mt-2 hover:cursor-pointer hover:bg-[#e0e0e0]"
          (click)="addCoordinatesPoint()"
        >
          <p class="text-sm">Adicionar novo ponto</p>
        </div>
      </div>
      <p class="text-sm mt-2">
        É <strong>obrigatório</strong> ao menos três Coordenadas para a criação
        do polígono.
      </p>
      <div
        class="w-full flex flex-row justify-end items-center items-center mt-2"
      >
        <button
          class="btn btn-primary mr-5"
          [ngClass]="{
            'opacity-40 pointer-events-none': coordinates.length < 3,
          }"
          [disabled]="coordinates.length < 3"
          (click)="addCoordinates()"
        >
          Confirmar
        </button>
        <button class="btn btn-secondary" (click)="closeModal()">
          Cancelar
        </button>
      </div>
    </div>
  </div>
</div>
<!-- Fim Modal -->

<div class="m-3 p-4">
  <app-breadcrumbs [routes]="routes"></app-breadcrumbs>
  <h1 class="text-3xl font-bold mb-3">Adicionar nova zona</h1>
  <div class="card">
    <div class="w-full flex flex-row justify-start items-center mb-2">
      <div class="flex flex-col mr-4">
        <p class="label">Nome da zona</p>
        <input
          type="text"
          class="input"
          placeholder="Ex:. Zona Ambiental 234-5"
          (input)="handleChangeZoneInfos($event, 'name')"
          [value]="dto.name || ''"
        />
      </div>
      <div class="flex flex-col">
        <p class="label">Especificar hierarquia</p>
        <select
          class="input"
          (change)="handleChangeZoneInfos($event, 'hierarchy')"
        >
          <option selected disabled>Especificar hierarquia</option>
          <option
            value="1"
            [selected]="dto.hierarchy && dto.hierarchy.toString() === '1'"
          >
            Nível 1 - Muito baixo
          </option>
          <option
            value="2"
            [selected]="dto.hierarchy && dto.hierarchy.toString() === '2'"
          >
            Nível 2 - Baixo
          </option>
          <option
            value="3"
            [selected]="dto.hierarchy && dto.hierarchy.toString() === '3'"
          >
            Nível 3 - Médio
          </option>
          <option
            value="4"
            [selected]="dto.hierarchy && dto.hierarchy.toString() === '4'"
          >
            Nível 4 - Alto
          </option>
          <option
            value="5"
            [selected]="dto.hierarchy && dto.hierarchy.toString() === '5'"
          >
            Nível 5 - Muito alto
          </option>
        </select>
      </div>
    </div>
    <div class="w-full flex flex-row justify-center items-center mb-4">
      <div class="w-1/4 flex flex-col mr-4">
        <p class="label">Sigla</p>
        <input
          type="text"
          class="input"
          (input)="handleChangeZoneInfos($event, 'acronym')"
          [value]="dto.acronym || ''"
        />
      </div>
      <div class="w-full flex flex-col">
        <p class="label">Descrição</p>
        <textarea
          type="text"
          class="input"
          (input)="handleChangeZoneInfos($event, 'description')"
          [value]="dto.description || ''"
        ></textarea>
      </div>
    </div>
    <div class="w-full flex flex-row justify-start items-start">
      <div class="mapa-container">
        <button class="add-coordinates" (click)="showModal()">
          <app-layers-icon
            [color]="'#464646'"
            [width]="14"
            [height]="14"
          ></app-layers-icon>
          <span class="ml-1">Adicionar coordendas</span>
        </button>
        <div class="mapa-frame">
          <div id="mapa"></div>
        </div>
      </div>

      <div class="w-[30%] ml-4 bg-white rounded shadow-lg p-4">
        <h3 class="text-xl font-bold">Filtro</h3>

        <div
          *ngFor="let filter of filters"
          class="w-full flex flex-col justify-start items-start"
        >
          <div
            [ngClass]="
              filter.open
                ? 'w-full flex flex-row justify-between items-center p-2 rounded mb-1 mt-1 bg-[#e0e0e0] hover:cursor-pointer'
                : 'w-full flex flex-row justify-between items-center p-2 rounded mb-1 mt-1 hover:bg-[#e0e0e0] hover:cursor-pointer'
            "
            (click)="
              !filter.haveChildren
                ? handleToggleAllSelected()
                : handleOpenFilter(filter.id)
            "
          >
            <div class="w-full flex flex-row justify-start items-center">
              <app-eye-icon
                [color]="'#464646'"
                [width]="20"
                [height]="20"
              ></app-eye-icon>
              <p class="text-md ml-2 text-[#464646]">{{ filter.label }}</p>
            </div>
            <div *ngIf="filter.haveChildren">
              <app-arrow-down-icon
                [color]="'#464646'"
                [width]="18"
                [height]="18"
                [rotate]="filter.open"
              ></app-arrow-down-icon>
            </div>
          </div>

          <div *ngIf="filter.open && filter.haveChildren" class="w-full">
            <div
              *ngFor="let children of filter.childrens"
              class="w-full flex flex-row justify-start items-center p-2 rounded mb-1 mt-1 bg-[#ececec] hover:cursor-pointer"
            >
              <input
                type="checkbox"
                (change)="handleChangeSelectZone(children.id, filter.id)"
                [checked]="children.selected"
              />
              <p class="text-sm ml-2 text-[#464646]">{{ children.name }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="card">
    <div class="flex flex-row justify-between">
      <h2 class="font-bold text-lg mt-5">Regras</h2>
      <div class="flex flex-col justify-center items-end">
        <button
          (click)="newRule()"
          [ngClass]="
            dto.name && dto.hierarchy
              ? 'btn btn-primary text-sm ml-2 mt-3'
              : 'btn btn-primary text-sm ml-2 mt-3 opacity-40 pointer-events-none cursor-not-allowed'
          "
        >
          {{ rules && rules.length > 0 ? "Editar regras" : "Adicionar regras" }}
        </button>
        <p *ngIf="ruleError" class="text-sm font-medium text-[#f44336] mt-2">
          Os campos da Zona devem ser preenchidos (Nome, Hierarquia e Sigla)
        </p>
      </div>
    </div>
  </div>

  <div class="card">
    <div class="flex flex-row justify-between">
      <h2 class="font-bold text-lg mt-5">Orientações</h2>
      <div class="flex flex-col justify-center items-end">
        <button
          (click)="newOrientation()"
          [ngClass]="
            dto.name && dto.hierarchy
              ? 'btn btn-primary text-sm ml-2 mt-3'
              : 'btn btn-primary text-sm ml-2 mt-3 opacity-40 pointer-events-none cursor-not-allowed'
          "
        >
          {{
            orientation && orientation.zoneId
              ? "Editar orientações"
              : "Adicionar orientações"
          }}
        </button>
        <p
          *ngIf="orientationError"
          class="text-sm font-medium text-[#f44336] mt-2"
        >
          Os campos da Zona devem ser preenchidos (Nome, Hierarquia e Sigla)
        </p>
      </div>
    </div>
  </div>

  <div class="flex flex-row justify-end items-center mt-6 mb-6">
    <button class="btn btn-secondary text-sm" (click)="goBack()">Voltar</button>
    <button
      (click)="handleCreateZone()"
      [ngClass]="
        loading || (rules && rules.length === 0)
          ? 'btn btn-primary text-sm ml-2 opacity-40 cursor-not-allowed pointer-events-none'
          : 'btn btn-primary text-sm ml-2'
      "
    >
      {{ loading ? "Criando nova zona..." : "Criar nova zona" }}
    </button>
  </div>
</div>
