<!-- Modal Analista -->
<div
  *ngIf="showAnalystResponse"
  class="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50"
>
  <div
    class="relative top-20 mx-auto p-4 border w-[30%] max-h-[80%] overflow-y-auto max-w-screen-lg shadow-lg rounded bg-white flex flex-col justify-start items-start"
  >
    <h1 class="font-medium text-2xl">Responder pedido</h1>
    <p class="text-sm w-full text-left">
      Selecione abaixo sua resposta para o pedido de reanálise.
    </p>
    <p class="text-sm mt-4 w-full text-left">
      Outros usuários verão sua resposta e ela não poderá ser alterada.
    </p>
    <div class="w-full flex flex-col justify-start items-start mt-4">
      <label [htmlFor]="" class="label">Selecionar resposta</label>
      <select class="input" (change)="onSelectAnalystResponse($event)">
        <option value="" disabled selected>Selecionar</option>
        <option value="refuse">Recusar pedido</option>
        <option *ngIf="!isNotValidToNotify()" value="sending_notification">
          Notificar cidadão
        </option>
        <option *ngIf="isNotValidToNotify()" value="archived">
          Arquivar pedido
        </option>
        <option value="sending_refeasibility">Enviar para revisão</option>
      </select>
    </div>

    <app-refuse
      *ngIf="final_analyst_response === 'refuse'"
      (fileEmmit)="onFileSelectedRefuse($event)"
    ></app-refuse>
    <app-notification
      *ngIf="final_analyst_response === 'sending_notification'"
      (fileEmmit)="onFileSelectedNotification($event)"
      (deadline)="handleDeadline($event)"
    ></app-notification>

    <div
      *ngIf="final_analyst_response === 'sending_refeasibility'"
      class="w-full flex flex-col justify-center items-center mt-4"
    >
      <p class="text-sm w-full text-left">
        Ao confirmar, você será redirecionado para uma tela de edição dos dados
        da consulta de viabilidade.
      </p>
    </div>

    <div
      *ngIf="final_analyst_response === 'archived'"
      class="w-full flex flex-col justify-center items-center mt-4"
    >
      <p class="text-sm w-full text-left">
        Ao confirmar, você arquivará o pedido de reanálise. O cidadão será
        notificado sobre o arquivamento.
      </p>
    </div>

    <div class="w-full flex flex-row justify-center items-center mt-4">
      <button
        class="btn btn-secondary mr-2 shadow-none bg-transparent text-red-500"
        (click)="handleShowAnalystResponse()"
      >
        Cancelar
      </button>
      <button
        [ngClass]="
          loading ||
          !final_analyst_response ||
          (final_analyst_response === 'refuse' && !fileRefuse) ||
          (final_analyst_response === 'sending_notification' &&
            !fileNotification)
            ? 'btn btn-primary text-sm ml-2 opacity-40 pointer-events-none'
            : 'btn btn-primary text-sm ml-2'
        "
        (click)="onSendAnalystResponse()"
      >
        {{ loading ? "Enviando..." : "Confirmar e enviar" }}
      </button>
    </div>
  </div>
</div>
<!-- Fim do Modal Analista -->
<!-- Modal Coordenador/Gerente -->
<div
  *ngIf="showCoordinatorResponse"
  class="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50"
>
  <div
    class="relative top-20 mx-auto p-4 border w-[30%] max-h-[80%] overflow-y-auto max-w-screen-lg shadow-lg rounded bg-white flex flex-col justify-start items-start"
  >
    <h1 class="font-medium text-2xl">Responder pedido de revisão</h1>
    <p class="text-sm w-full text-left">
      Selecione abaixo sua resposta para o pedido de revisão da consulta de
      viabilidade.
    </p>
    <p class="text-sm mt-4 w-full text-left">
      Outros usuários verão sua resposta e ela não poderá ser alterada.
    </p>
    <div class="w-full flex flex-col justify-start items-start mt-4">
      <label [htmlFor]="" class="label">Selecionar resposta</label>
      <select class="input" (change)="onSelectAnalystResponse($event)">
        <option value="" disabled selected>Selecionar</option>
        <option value="second_review">Enviar para 2ª revisão</option>
        <option value="returned">Devolver para analista</option>
      </select>
    </div>

    <app-second-review
      *ngIf="final_analyst_response === 'second_review'"
      (justification)="onCoordinatorJustification($event)"
    ></app-second-review>
    <app-returned
      *ngIf="final_analyst_response === 'returned'"
      (justification)="onCoordinatorJustification($event)"
    ></app-returned>

    <div class="w-full flex flex-row justify-center items-center mt-4">
      <button
        class="btn btn-secondary mr-2 shadow-none bg-transparent text-red-500"
        (click)="handleShowAnalystResponse()"
      >
        Cancelar
      </button>
      <button
        [ngClass]="
          loading ||
          !final_analyst_response ||
          (final_analyst_response === 'returned' && !coordinatorJustification)
            ? 'btn btn-primary text-sm ml-2 opacity-40 pointer-events-none'
            : 'btn btn-primary text-sm ml-2'
        "
        (click)="onSendCoordinatorResponse()"
      >
        {{ loading ? "Enviando..." : "Confirmar e enviar" }}
      </button>
    </div>
  </div>
</div>
<!-- Fim do Modal Coordenador/Gerente -->
<!-- Modal Justificativa -->
<div
  *ngIf="showJustification"
  class="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50"
>
  <div
    class="relative top-20 mx-auto p-4 border w-[50%] max-h-[80%] overflow-y-auto max-w-screen-lg shadow-lg rounded bg-white flex flex-col justify-start items-start"
  >
    <h3 class="text-lg font-medium">Justificativa</h3>
    <p class="text-sm text-gray-700">
      Abaixo está a justificativa da reanálise enviada pelo Coordenador/Gerente.
    </p>
    <div
      class="w-full bg-slate-100 p-4 rounded-md border border-solid border-slate-700 mt-4"
    >
      {{ data.coordinatorJustification || "-" }}
    </div>
    <div class="w-full flex flex-row justify-end items-center mt-6">
      <button
        type="button"
        class="btn btn-primary text-sm"
        (click)="handleShowJustification()"
      >
        Continuar
      </button>
    </div>
  </div>
</div>
<!-- Fim do Modal Justificativa -->
<!-- Modal Secretario/Prefeito -->
<div
  *ngIf="showSecretaryResponse"
  class="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50"
>
  <div
    class="relative top-20 mx-auto p-4 border w-[30%] max-h-[80%] overflow-y-auto max-w-screen-lg shadow-lg rounded bg-white flex flex-col justify-start items-start"
  >
    <h1 class="font-medium text-2xl">Responder pedido de revisão</h1>
    <p class="text-sm w-full text-left">
      Selecione abaixo sua resposta para o pedido de revisão da consulta de
      viabilidade.
    </p>
    <p class="text-sm mt-4 w-full text-left">
      Outros usuários verão sua resposta e ela não poderá ser alterada.
    </p>
    <div class="w-full flex flex-col justify-start items-start mt-4">
      <label [htmlFor]="" class="label">Selecionar resposta</label>
      <select class="input" (change)="onSelectSecretaryResponse($event)">
        <option value="" disabled selected>Selecionar</option>
        <option value="approve">Aprovar</option>
        <option value="returned">Devolver para analista</option>
      </select>
    </div>

    <app-approve
      *ngIf="final_secretary_response === 'approve'"
      (justification)="onSecretaryApprove($event)"
    ></app-approve>

    <app-returned
      *ngIf="final_secretary_response === 'returned'"
      (justification)="onSecretaryApprove($event)"
    ></app-returned>

    <div class="w-full flex flex-row justify-center items-center mt-4">
      <button
        class="btn btn-secondary mr-2 shadow-none bg-transparent text-red-500"
        (click)="handleShowSecretaryResponse()"
      >
        Cancelar
      </button>
      <button
        [ngClass]="
          loading ||
          !final_secretary_response ||
          (final_secretary_response === 'returned' && !secretaryJustification)
            ? 'btn btn-primary text-sm ml-2 opacity-40 pointer-events-none'
            : 'btn btn-primary text-sm ml-2'
        "
        (click)="onSendSecretaryResponse()"
      >
        {{ loading ? "Enviando..." : "Confirmar e enviar" }}
      </button>
    </div>
  </div>
</div>
<!-- Fim Modal Secretario/Prefeito -->

<div class="m-3 p-4">
  <app-breadcrumbs [routes]="routes"></app-breadcrumbs>
  <h1 class="font-bold text-3xl">
    {{
      role === "server"
        ? "Encaminhar Pedido"
        : role === "analyst"
          ? "Revisão da Consulta de Viabilidade"
          : "Detalhes da Reanálise"
    }}
  </h1>
  <div class="card">
    <h1 class="font-medium text-2xl mb-4">Pedido de Reanálise</h1>
    <div class="w-full grid grid-cols-4 gap-4">
      <div class="flex flex-col justify-start items-start">
        <label [htmlFor]="" class="label">Protocolo da Reanálise</label>
        <input
          type="text"
          class="input uppercase bg-slate-100"
          [value]="data.refeasibilityProtocol || '-'"
          readonly
        />
      </div>
      <div class="flex flex-col justify-start items-start">
        <label [htmlFor]="" class="label">Protocolo da Consulta</label>
        <input
          type="text"
          class="input uppercase bg-slate-100"
          [value]="data.protocol || '-'"
          readonly
        />
      </div>
      <div class="flex flex-col justify-start items-start">
        <label [htmlFor]="" class="label">Risco Municipal</label>
        <input
          type="text"
          class="input bg-slate-100"
          [value]="getRisk(data.finalRisk || 0)"
          readonly
        />
      </div>
      <div class="flex flex-col justify-start items-start">
        <label [htmlFor]="" class="label">Resultado da Consulta</label>
        <input
          type="text"
          class="input bg-slate-100"
          [value]="getResult(data.feasibilityResult)"
          readonly
        />
      </div>
    </div>
    <div class="w-full grid grid-cols-5 gap-4 mt-4">
      <div class="flex flex-col justify-start items-start col-span-2">
        <label [htmlFor]="" class="label">Endereço do empreendimento</label>
        <input
          type="text"
          class="input bg-slate-100"
          [value]="data.fullAddress || '-'"
          readonly
        />
      </div>
      <div class="flex flex-col justify-start items-start">
        <label [htmlFor]="" class="label">Solicitante</label>
        <input
          type="text"
          class="input bg-slate-100"
          [value]="data.requester || '-'"
          readonly
        />
      </div>
      <div class="flex flex-col justify-start items-start">
        <label [htmlFor]="" class="label">CPF do Solicitante</label>
        <input
          type="text"
          class="input bg-slate-100"
          [value]="useMask(data.requesterCpf) || '-'"
          readonly
        />
      </div>
      <div class="flex flex-col justify-start items-start">
        <label [htmlFor]="" class="label">Responsável Legal</label>
        <input
          type="text"
          class="input bg-slate-100"
          [value]="data.responsable || '-'"
          readonly
        />
      </div>
    </div>
    <div class="flex flex-col justify-start items-start">
      <button
        [ngClass]="
          loading || loadingDownload
            ? 'w-full max-w-96 btn btn-primary text-sm disabled:bg-opacity-65 flex flex-row justify-center items-center opacity-40 cursor-not-allowed pointer-events-none mt-4'
            : 'w-full max-w-96 btn btn-primary text-sm disabled:bg-opacity-65 flex flex-row justify-center items-center mt-4'
        "
        (click)="downloadPdf()"
      >
        <mat-icon *ngIf="!loadingDownload" fontIcon="download"></mat-icon>
        <mat-icon
          *ngIf="loadingDownload"
          class="animate-spin text-white mr-2"
          aria-hidden="false"
          fontIcon="refresh"
        ></mat-icon>
        <span class="ml-2">{{
          loadingDownload
            ? "Gerando arquivos..."
            : "Baixar relatório da consulta"
        }}</span>
      </button>
    </div>
    <mat-divider class="mt-4"></mat-divider>

    <div *ngIf="data.status === 'DEFAULT' || !data.status" class="w-full mt-4">
      <div class="w-full flex flex-col justify-start items-start">
        <label [htmlFor]="" class="label">Justificativa da reanálise</label>
        <button
          [ngClass]="
            file
              ? 'w-full p-4 rounded-md bg-green-100 border border-dashed border-green-300 hover:bg-green-200 hover:cursor-pointer'
              : 'w-full p-4 rounded-md bg-slate-100 border border-dashed border-slate-300 hover:bg-slate-200 hover:cursor-pointer'
          "
          (click)="handleUpload()"
        >
          <div class="w-full flex flex-row">
            <mat-icon
              class="text-black material-icons-outlined text-4xl w-auto h-auto mr-4"
              aria-hidden="true"
              >{{ file ? "picture_as_pdf" : "upload" }}</mat-icon
            >
            <div class="w-full flex flex-col justify-start items-start">
              <p class="text-sm font-medium text-gray-900">
                {{
                  file
                    ? file.name
                    : "Anexe a justificativa da solicitação de reanálise"
                }}
              </p>
              <p class="text-xs font-medium text-gray-600">
                {{
                  file
                    ? getFileSize(file.size)
                    : "PDF, menos de 10mb, contendo ambos os lados ou todas as páginas"
                }}
              </p>
            </div>
          </div>
        </button>
        <input
          #upload
          type="file"
          class="input hidden"
          (change)="onFileSelected()"
          accept="application/pdf"
        />
      </div>
    </div>

    <div *ngIf="data.status && data.status !== 'DEFAULT'" class="w-full mt-4">
      <h1 class="font-medium text-2xl">Status do pedido de reanálise</h1>
      <div class="w-full flex flex-row items-center">
        <mat-icon
          [ngClass]="
            getStatusColor(data.status) +
            ' material-icons-outlined text-4xl w-auto h-auto mr-2'
          "
          aria-hidden="true"
          >{{ getStatusIcon(data.status) }}</mat-icon
        >
        <div [ngClass]="'flex flex-col justify-start items-start ml-2 mb-4'">
          <h1 [ngClass]="getStatusColor(data.status) + ' font-medium text-xl'">
            {{ getStatus(data.status) }}
          </h1>
          <p class="text-sm text-gray-600">
            {{ getDateString(data.updatedAt) }}
          </p>
        </div>
      </div>
      <p
        *ngIf="data.status === 'PEN_RESPONSE'"
        class="text-md font-medium text-red-500 mt-2"
      >
        Prazo para envio: {{ data.deadline }} dias úteis
      </p>
      <div
        *ngIf="
          (role === 'user' && data.status === 'PEN_RESPONSE') ||
          (role === 'analyst' && data.status === 'PEN_RESPONSE')
        "
      >
        <button
          [ngClass]="
            loading || loadingDownloadFile
              ? 'btn btn-primary text-sm disabled:bg-opacity-65 flex flex-row justify-center items-center mt-2 opacity-40 cursor-not-allowed pointer-events-none mb-4'
              : 'btn btn-primary text-sm disabled:bg-opacity-65 flex flex-row justify-center items-center mt-2 mb-4'
          "
          (click)="downloadJustification(data.status)"
        >
          <mat-icon *ngIf="!loadingDownloadFile" fontIcon="download"></mat-icon>
          <mat-icon
            *ngIf="loadingDownloadFile"
            class="animate-spin text-white mr-2"
            aria-hidden="false"
            fontIcon="refresh"
          ></mat-icon>
          <span class="ml-2">{{
            loadingDownloadFile ? "Preparando arquivo..." : "Baixar"
          }}</span>
        </button>
      </div>
      <mat-divider
        *ngIf="data.status && data.status === 'PEN_RESPONSE'"
      ></mat-divider>
      <div
        *ngIf="
          (role === 'user' && data.status === 'PEN_RESPONSE') ||
          (role === 'analyst' && data.status === 'PEN_RESPONSE')
        "
      >
        <input
          #upload_response
          type="file"
          class="input hidden"
          (change)="onFileSelectedResponse()"
          accept="application/pdf"
        />
        <button
          *ngIf="role === 'user' && data.status === 'PEN_RESPONSE'"
          [ngClass]="
            fileResponse
              ? 'w-2/4 p-4 rounded-md bg-green-100 border border-dashed border-green-300 hover:bg-green-200 hover:cursor-pointer mt-2'
              : 'w-2/4 p-4 rounded-md bg-slate-100 border border-dashed border-slate-300 hover:bg-slate-200 hover:cursor-pointer mt-2'
          "
          (click)="handleUploadResponse()"
        >
          <div class="w-full flex flex-row">
            <mat-icon
              class="text-black material-icons-outlined text-4xl w-auto h-auto mr-4"
              aria-hidden="true"
              >{{ fileResponse ? "picture_as_pdf" : "upload" }}</mat-icon
            >
            <div class="w-full flex flex-col justify-start items-start">
              <p class="text-sm font-medium text-gray-900">
                {{
                  fileResponse
                    ? fileResponse.name
                    : "Anexe a sua resposta à solicitação de reanálise"
                }}
              </p>
              <p class="text-xs font-medium text-gray-600">
                {{
                  fileResponse
                    ? getFileSize(fileResponse.size)
                    : "PDF, menos de 10mb, contendo ambos os lados ou todas as páginas"
                }}
              </p>
            </div>
          </div>
        </button>
      </div>
    </div>
    <mat-divider
      *ngIf="
        data.status &&
        data.status !== 'DEFAULT' &&
        data.status !== 'PEN_RESPONSE'
      "
    ></mat-divider>
    <div
      *ngIf="
        role === 'user' || role === 'analyst'
          ? data.status === 'REVIEW' ||
            data.status === 'REJECTED' ||
            data.status === 'APPROVED' ||
            data.status === 'REANALYSIS'
          : role === 'coordinator-manager' || role === 'mayor-secretary'
            ? data.status === 'REVIEW' || data.status === 'SECOND_REVIEW'
            : false
      "
    >
      <button
        [ngClass]="
          loading || loadingDownloadFile
            ? 'btn btn-primary text-sm disabled:bg-opacity-65 flex flex-row justify-center items-center mt-2 opacity-40 cursor-not-allowed pointer-events-none'
            : 'btn btn-primary text-sm disabled:bg-opacity-65 flex flex-row justify-center items-center mt-2'
        "
        (click)="downloadJustification(data.status)"
      >
        <mat-icon *ngIf="!loadingDownloadFile" fontIcon="download"></mat-icon>
        <mat-icon
          *ngIf="loadingDownloadFile"
          class="animate-spin text-white mr-2"
          aria-hidden="false"
          fontIcon="refresh"
        ></mat-icon>
        <span class="ml-2">{{
          loadingDownloadFile ? "Preparando arquivo..." : "Baixar"
        }}</span>
      </button>
    </div>
    <div
      *ngIf="
        role === 'analyst' || role === 'coordinator-manager'
          ? data.status === 'RETURNED'
          : role === 'mayor-secretary'
            ? data.status === 'SECOND_REVIEW'
            : false
      "
    >
      <button
        class="btn btn-primary text-sm disabled:bg-opacity-65 flex flex-row justify-center items-center mt-2"
        (click)="handleShowJustification()"
      >
        <mat-icon fontIcon="visibility"></mat-icon>
        <span class="ml-2">Visualizar justificativa</span>
      </button>
    </div>
  </div>

  <div *ngIf="role === 'server'" class="card">
    <h1 class="font-bold text-xl">Encaminhar para Analista</h1>

    <div class="w-2/4 flex flex-col justify-start items-start mt-4">
      <label [htmlFor]="" class="label">Analista</label>
      <select class="input" (change)="handleSelectAnalyst($event)">
        <option value="" disabled selected>Selecione um analista</option>
        <option *ngFor="let analyst of analystList" [value]="analyst.cpf">
          {{ analyst.fullname }}
        </option>
      </select>
    </div>
  </div>

  <app-status-history
    *ngIf="data.historyStatus && data.historyStatus.length > 0"
    [data]="toHistory()"
  ></app-status-history>

  <div class="w-full flex flex-row justify-end items-center">
    <button class="btn btn-secondary mr-2" (click)="goBack()">Voltar</button>
    <button
      [ngClass]="
        role === 'server'
          ? analyst && !loading
            ? 'btn btn-primary'
            : 'btn btn-primary opacity-40 pointer-events-none cursor-not-allowed'
          : role === 'analyst'
            ? !loading &&
              (data.status === 'PEN_ANALISYS' ||
                data.status === 'ANALYSIS' ||
                data.status === 'RETURNED' ||
                data.status === 'PEN_REANALYSIS' ||
                data.status === 'REANALYSIS')
              ? 'btn btn-primary'
              : 'btn btn-primary opacity-40 pointer-events-none cursor-not-allowed'
            : role === 'user' && data.status === 'PEN_RESPONSE'
              ? fileResponse && !loading
                ? 'btn btn-primary'
                : 'btn btn-primary opacity-40 pointer-events-none cursor-not-allowed'
              : role === 'coordinator-manager'
                ? data.status === 'SECOND_REVIEW'
                  ? 'btn btn-primary opacity-40 pointer-events-none cursor-not-allowed'
                  : !loading
                    ? 'btn btn-primary'
                    : 'btn btn-primary opacity-40 pointer-events-none cursor-not-allowed'
                : role === 'mayor-secretary'
                  ? !loading
                    ? 'btn btn-primary'
                    : 'btn btn-primary opacity-40 pointer-events-none cursor-not-allowed'
                  : file && !loading
                    ? 'btn btn-primary'
                    : 'btn btn-primary opacity-40 pointer-events-none cursor-not-allowed'
      "
      (click)="
        role === 'analyst' || role === 'coordinator-manager'
          ? handleShowAnalystResponse()
          : role === 'mayor-secretary'
            ? handleShowSecretaryResponse()
            : sendRefeasbility()
      "
    >
      {{
        role === "server"
          ? loading
            ? "Encaminhando..."
            : "Encaminhar"
          : role === "analyst"
            ? loading
              ? "Enviando..."
              : "Responder reanálise"
            : role === "coordinator-manager"
              ? loading
                ? "Enviando..."
                : "Responder"
              : role === "user" && data.status === "PEN_RESPONSE"
                ? loading
                  ? "Enviando..."
                  : "Enviar resposta"
                : role === "mayor-secretary"
                  ? loading
                    ? "Enviando..."
                    : "Responder pedido"
                  : loading
                    ? "Solicitando..."
                    : "Solicitar reanálise"
      }}
    </button>
  </div>
</div>
